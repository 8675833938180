// Angular
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Cer
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { FieldFormatEnum, FieldMetadata, FieldWidthEnum, ViewMetadata } from 'src/cer/cer-data/cer-data.service';
import { UiActionTypeEnum, UiCommand, UiCommandEvent, UiKeyboardShortcut } from 'src/cer/cer-grid/cer-grid-command.service';

// Royalty
import { RoyaltyService } from './royalty.service';
import { RoyaltyApproveStatusFormatter } from './royalty-approve-status-formatter';
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';

@Component({
  selector: 'royalty-approve',
  templateUrl: './royalty-approve.component.html',
  providers: [RoyaltyService]
})

export class RoyaltyApproveComponent {

  protected viewMetadata: ViewMetadata = {
    name: 'royalty-approve', dataApiName: "RoyaltyAfregningAktuelView", text: "Royalty godkendelser", textSingular: "Royalty godkendelser",
    primaryKey: "id", allowEdit: false, allowCreate: false, allowDelete: false,
  };

  protected fieldMetadata: FieldMetadata[] = [
    { name: "id", text: "Royalty ID", visible: false, allowEdit: false },
    { name: "redaktorNavn", text: "Redaktør" },
    { name: "afregningId", text: "Modtager ID", width: "60px" },
    { name: "afregningNavn", text: "Modtager", widthType: FieldWidthEnum.XXL },
    { name: "forlagId", text: "Forlag ID", width: "60px" },
    { name: "forlagNavn", text: "Forlag" },
    { name: "forfatterNavn", text: "Forfatter" },
    { name: "hID", text: "Varenummer ID", visible: false },
    { name: "hovedKontraktId", text: "HKontrakt ID", visible: false },
    { name: "hovedKontrakt", text: "Hovedkontrakt", visible: false },
    { name: "kontrakt", text: "Kontrakt", width: "90px" },
    { name: "vareNR", text: "Varenr", visible: false },
    { name: "arbTitel", text: "Arb. titel" },
    { name: "bogTitel", text: "Bog titel", visible: false },
    { name: "kontraktType", text: "Kontrakt type", visible: false },
    { name: "afregningstype", text: "Afregn. type", visible: false },
    { name: "afregningAdresse1", text: "Modtager adresse 1", visible: false },
    { name: "afregningAdresse2", text: "Modtager adresse 2", visible: false },
    { name: "afregningAdresse3", text: "Modtager adresse 3", visible: false },
    { name: "afregningLand", text: "Modtager Land", visible: false },
    { name: "forlagAdresse1", text: "Adresse 1", visible: false },
    { name: "forlagAdresse2", text: "Adresse 2", visible: false },
    { name: "forlagAdresse3", text: "Adresse 3", visible: false },
    { name: "forlagLand", text: "Land", visible: false },
    { name: "endeligSalgspris", text: "Endelig salgspris", format: FieldFormatEnum.Amount, visible: false },
    { name: "royaltyAmount", text: "Royalty beløb", format: FieldFormatEnum.Amount },
    { name: "valutaKode", text: "Valuta", width: "40px" },
    { name: "valutaKurs", text: "Kurs", format: FieldFormatEnum.Amount, visible: false },
    { name: "godkendt", text: "Godkendt", visible: false},
    { name: "godkendtAf", text: "Godkendt af", visible: false },
    { name: "godkendtDato", text: "Godkendt dato", visible: false, format: FieldFormatEnum.DateShort },
    { name: "afsluttet", text: "Afsluttet", visible: false,  },
    { name: "afsluttetAf", text: "Afsluttet af", visible: false },
    { name: "afsluttetDato", text: "Afsluttet dato", visible: false, format: FieldFormatEnum.DateShort },
    { name: "createdDate", text: "Beregnet dato", visible: false, format: FieldFormatEnum.DateShort },
    { name: "status", text: "Status kode", visible: false },
    { name: "statusText", text: "Status", widthType: FieldWidthEnum.XXL, formatter: new RoyaltyApproveStatusFormatter() }
  ];

  private showDetailsCmd : UiCommand = { id: 'details', text: "Vis detaljer (Alt+O)", iconCss: "e-menu-icon e-icons e-edit", target: ".e-content" };

  protected contextMenuCommands: UiCommand[] =
    [
      this.showDetailsCmd,
      { id: 'sep', text: '', separator: 'content' },
      { id: 'wordMergeDraft', text: "Dan afregning (kladde)", iconCss: "e-menu-icon e-icons e-wordexport", target: ".e-content" },
      { id: 'pdfMergeDraft', text: "Dan afregning (kladde/PDF) ", iconCss: "e-menu-icon e-icons e-pdfexport", target: ".e-content" },
    ];

    protected toolbarCommands: UiCommand[] =
    [
      this.showDetailsCmd
    ];

    protected keyboardShortcuts: UiKeyboardShortcut[] = 
    [
      { cmd: this.showDetailsCmd, code: 'KeyO', alt: true, ctrl: false, shift: false, enabled: true }
    ]

  // Get grid control to handle grouping collapse/showDropArea
  private grid: CerGridComponent;
  @ViewChild(CerGridComponent) set setGrid(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
    }
  }

  constructor(
    private royaltyService: RoyaltyService,
    private routeService: CerAppRouteService,
    public dialog: MatDialog,
  ) {
    this.routeService.routeData$.subscribe(routeData => this.initFormRouteData(routeData));
  }

  protected onCommand(e: UiCommandEvent) {
    switch (e.actionType) {
      case UiActionTypeEnum.Created:
        this.gridInit();
        break;
    }
    this.royaltyService.onGridCommand(e, this.dialog, this.grid);
  }

  // Route paramters
  private isViewSet: boolean = false;
  private isViewEditor: boolean = false;
  private isViewPublisher: boolean = false;

  private initFormRouteData(data: any) {
    if (data && data.hasOwnProperty('view')) {
      switch (data['view']) {
        case 'editor':
          this.isViewEditor = true;
          break;
        case 'publisher':
          this.isViewPublisher = true;
          break;
      }
      this.isViewSet = true;
    }
  }

  private gridInit() {
    if (this.grid?.gridService && this.isViewSet) {
      if (this.isViewPublisher) {
        this.grid.gridService.doColumnGroup('forlagNavn');
      }
      if (this.isViewEditor) {
        this.grid.gridService.doColumnGroup('redaktorNavn');
      }
    }
  }

}
