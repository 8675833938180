import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { Injectable, OnDestroy, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { View } from '@syncfusion/ej2-angular-schedule';

export type CerFormUserViewType = 'standard' | 'design' | 'complete' | 'custom';

export class CerMetaUserView {
   public name: string;
   public type: CerFormUserViewType;
   public isPrimary: boolean;
   public isDefault: boolean;
   public formPersistenceData: string;
}

export declare interface ICerViewPersistenceService {
   getPersistenceView(): string;
   applyPersistenceView(view: CerMetaUserView): void;
}

export declare interface ICerViewPersistenceComponent {
   getPersistenceId(): string;
   getPersistence(): any;
   applyPersistence(viewType: CerFormUserViewType, persistenceObject: any): void;
   applyPersistenceComplete(viewType: CerFormUserViewType): void;
}

export type CerDataRelationRole = "parent" | "child";

@Injectable({ providedIn: 'root' })
export class CerFormPersistenceService implements OnDestroy, ICerViewPersistenceService {
   private subscriptionManager$: Subscription = new Subscription();

   private components: ICerViewPersistenceComponent[] = [];  // Array of persistence components in form
   private activeView: CerMetaUserView;
   private activePersistenceData: any;

   constructor(private routeService: CerAppRouteService, private authorizeService: AuthorizeService) {
      this.routeService.activeViewPersistenceService$.next(this as ICerViewPersistenceService);
   }

   ngOnDestroy(): void {
      this.subscriptionManager$.unsubscribe();
   }

   public linkRouter() {
      this.subscriptionManager$.add(
         this.routeService.activeView$.subscribe(view => this.applyPersistenceView(view))
      );
   }

   // Apply persistence data
   public registerComponent(component: ICerViewPersistenceComponent) {
      this.components.push(component);
      var id : string =  component.getPersistenceId();
      if (id && this.activePersistenceData) {
         var obj = this.activePersistenceData[id];
         if (obj) {
            component.applyPersistence(this.activeView.type,obj);
         }
      }
   }

   public applyPersistenceView(view: CerMetaUserView) {
      if (view && view.name != this.activeView?.name) {
         this.activeView = view;
         if (view.formPersistenceData) {
            this.activePersistenceData = JSON.parse(view.formPersistenceData);
            if (this.activePersistenceData != null) {
               // Apply
               this.components.forEach(c => {
                  var id = c.getPersistenceId();
                  var obj = this.activePersistenceData[id];
                  if (obj) {
                     c.applyPersistence(view.type, obj);
                  }
               });
            }
         }
         else {
            this.activePersistenceData = null;
            this.components.forEach(c => {
               c.applyPersistence(view.type, null);
            });
         }
         // Apply Complete
         this.components.forEach(c => {
            c.applyPersistenceComplete(view.type);
         });
      }
   }

   public getPersistenceView(): string {
      var viewObj: any = new Object();
      this.components.forEach(c => {
         var id = c.getPersistenceId();
         if (id) {
            var obj = c.getPersistence();
            viewObj[id] = obj;
         }
      });
      var formPersistenceData: string = JSON.stringify(viewObj);
      return formPersistenceData;
   }
}
