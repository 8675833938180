<hotkeys-cheatsheet title="Genvejstaster"></hotkeys-cheatsheet>
<mat-sidenav-container fullscreen>
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar color="primary" [style]="toolbarStyle$ | async">
      <mat-toolbar-row>
        <button mat-icon-button class="app-title-button" [routerLink]="['/']">
          <img class="appTitle" src="assets/logo.svg" />
          <h2 class="appTitle">{{ appTitle$ | async }}</h2>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-nav-list class="sidenav-list">
      <cer-app-sidenav></cer-app-sidenav>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="content-container">
    <mat-toolbar color="primary" [style]="toolbarStyle$ | async">
      <mat-toolbar-row>
        <button
          type="button"
          class="sidenav-toggle"
          aria-label="Vis/skjul menu"
          mat-icon-button
          (click)="drawer.toggle()"
        >
          <mat-icon aria-label="Vis menu">menu</mat-icon>
        </button>
        <cer-app-route-title></cer-app-route-title>
        <span class="filler"></span>
        <cer-app-login-menu></cer-app-login-menu>
        <button mat-icon-button [matMenuTriggerFor]="helpmenu">
          <mat-icon>help</mat-icon>
        </button>
        <mat-menu #helpmenu="matMenu">
          <button mat-menu-item (click)="showAppHelpAbout()">
            <mat-icon>build</mat-icon>
            <span>Om {{ appTitle$ | async }}</span>
          </button>
        </mat-menu>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="content-router" [ngClass]="routerOverflowClass">
      <router-outlet
        name="primary"
        class="content-router-outlet"
        (activate)="onRouterOutletActivate($event)"
        (deactivate)="onRouterOutletDeactivate($event)"
      ></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
