import { Column, DatePickerEditCell, IGrid } from "@syncfusion/ej2-angular-grids";
import { DatePicker } from "@syncfusion/ej2-angular-calendars";
import { CerLocaleService } from "src/cer/cer-locale/cer-locale.service";
import { EventHandler } from "@syncfusion/ej2-base";


export class CerEditDatePickerAdapter {
  private baseEditCell: any; // Instance is DatePickerEditCell

  private input: HTMLInputElement;
  private obj: DatePicker;

  constructor(private grid: IGrid, private col: Column, private localeService: CerLocaleService) {
    this.baseEditCell = col.edit ? col.edit : new DatePickerEditCell(grid);
    col.edit = this.baseEditCell;
    this.baseEditCell.write_orig = this.baseEditCell.write;
    this.baseEditCell.write = this.write.bind(this);
    this.baseEditCell.destroy_orig = this.baseEditCell.destroy;
    this.baseEditCell.destroy = this.destroy.bind(this);
  }

  public editCell() : DatePickerEditCell {
    return <DatePickerEditCell> this.baseEditCell;
  }

  public write(args: {
    rowData: Object;
    element: Element;
    column: Column;
    requestType: string;
    row: Element;
  }) {
    this. baseEditCell.write_orig(args);
    this.obj = this.baseEditCell.obj;
    this.input = this.baseEditCell.obj.inputElement as HTMLInputElement;
    this.cerAddEventListener();
  }

  public destroy() {
    this.baseEditCell.destroy_orig();
    this.cerRemoveEventListener();
  }

  private cerAddEventListener() {
    this.input?.addEventListener("change", this.onInputChanged.bind(this));
    //this.obj?.addEventListener("change", this.onEditChanged.bind(this));
  }

  private cerRemoveEventListener() {
    this.input?.removeEventListener("change", this.onInputChanged.bind(this));
    //this.obj?.removeEventListener("change", this.onEditChanged.bind(this));
  }

  private onInputChanged(args: any) {
    var value: string = this.input?.value;
    if (value?.length > 0 && this.obj) {
      var d: Date = this.localeService.parseDate(value);
      if (d && d != this.obj.value) {
        this.obj.value = d;
        (<any>this.obj).changeTrigger(args);
      }
    }
  }

  private onEditChanged(args: any) {
    var value: Date = this.obj.value;
  }
}

