import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { CerAppMetaService } from '../../cer-app/cer-app-meta/cer-app-meta.service';
import { UserSessionService } from '../../platform/app-user/user-session-service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';

export class DataTableCache {
  public name?: string;
  public tableName?: string;
  public filerName?: string;
  public pending?: boolean;
  public loaded?: boolean;
  public data?: object[];
  public dataSubject?: BehaviorSubject<object[]>;
}

@Injectable({
  providedIn: 'root'
})
export class AppStateService implements OnDestroy {
  private subscriptionManager$: Subscription = new Subscription();

  public dataTableCache = new Array<DataTableCache>();

  constructor(
    private userSessionService: UserSessionService,
    private metaService: CerAppMetaService,
    private dialogService: CerDialogService,
    private appDialogService: CerAppDialogService) {

    // User suthorization
    this.subscriptionManager$.add(
      this.userSessionService.userSession$.subscribe(user => this.metaService.readEnvironment(user?.vm?.environment))
    );

    // User settings
    this.subscriptionManager$.add(
      this.userSessionService.userStatusVm$.subscribe(vm => this.metaService.readEnvironment(vm?.environment))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  public getDataTableCache(tableName: string, filterName: string = undefined): DataTableCache {
    var tableNameLwr: string = tableName.toLowerCase();
    if (filterName?.length > 0) {
      tableNameLwr += "#" + filterName.toLowerCase();
    }
    var c: DataTableCache = this.dataTableCache.find(f => f.name == tableNameLwr);
    if (!c) {
      c = { name: tableNameLwr, tableName: tableName, filerName: filterName, pending: false, loaded: false, data: new Array<Object>(), dataSubject: new BehaviorSubject<object[]>(null) };
      this.dataTableCache.push(c);
    }
    return c;
  }

  public setDataTableCache(c: DataTableCache, data: object[]) {
    if (!c.loaded) {
      c.data = data;
      c.pending = false;
      c.loaded = true;
      c.dataSubject.next(c.data);
    }
  }

  // API - user status
  public handleApiError(error: any) {
    this.appDialogService.handleApiError(error);
  }

  public setClientOnPause() {
    this.dialogService.confirmPrompt("Pause", this.metaService.name$.getValue() + " holder pause.");
  }

}
