import { Injectable } from '@angular/core';
import { parse as dateParse, format as dateFormat, differenceInCalendarDays } from 'date-fns';
import localeDa from 'date-fns/locale/da';

@Injectable({
  providedIn: 'root'
})
export class CerLocaleService {

  // Formatting
  public dateFormat: any = { type: "date", format: "dd.MM.yyyy" };
  public dateFormatMD: any = { type: "date", format: "d.MM" };
  public dateFormatMMMD: any = { type: "date", format: "d. MMM" };
  public dateTimeTodayFormat: any = { type: "date", format: "H:mm" };
  public dateTimeFormat: any = { type: "date", format: "d.MM.yyyy H:mm" };

  constructor() {
  }

  public today(): Date {
    var dateTime = new Date();
    var today = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
    return today;
  }

  public createDateAsUTC(date: Date) {
    if (date != null) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }
    return date;
  }

  public isDtosEqual(dto1: any, dto2: any): boolean {
    var ret: boolean = true;
    if (dto1 && dto2) {
      Object.keys(dto1).forEach(key => { if (!this.isValuesEqual(dto1[key], dto2[key])) { ret = false; } });
    }
    return ret;
  }

  public isValuesEqual(f1: any, f2: any): boolean {
    var ret: boolean = true;
    if (f1 instanceof Date) {
      ret = this.isDatesEqual(<Date>f1, <Date>f2);
    }
    else {
      ret = (f1 == f2);
    }
    return ret;
  }

  public isDatesEqual(d1: Date, d2: Date): boolean {
    var ret: boolean = false;
    if ((d1 == null && d2 == null) ||
      (d1 != null && d2 != null &&
        d1.getFullYear() == d2.getFullYear() &&
        d1.getMonth() == d2.getMonth() &&
        d1.getDay() == d2.getDay())) {
      ret = true;
    }
    return ret;
  }

  public formatDateRelative(date: any, relative: any = new Date()): string {

    var dateD: Date = date == null ? new Date() : date instanceof Date ? date : new Date(date);
    var relativeD: Date = relativeD == null ? new Date() : relativeD instanceof Date ? relativeD : new Date(relativeD);
    if (dateD.getFullYear() === relativeD.getFullYear()) {
      if (differenceInCalendarDays(dateD, relativeD) === 0) {
        return this.formatDateTimeToday(dateD);
      }
      return this.formatDateMMMD(dateD)
    }
    return this.formatDate(dateD);

  }

  public formatDate(d: Date): string {
    var s: string = null;
    if (d) {
      s = this.formatDateFns(d, this.dateFormat);
    }
    return s;
  }


  public formatDateMMMD(d: Date): string {
    var s: string = null;
    if (d) {
      s = this.formatDateFns(d, this.dateFormatMMMD);
    }
    return s;
  }

  public formatDateMD(d: Date): string {
    var s: string = null;
    if (d) {
      s = this.formatDateFns(d, this.dateFormatMD);
    }
    return s;
  }

  public formatDateTimeToday(d: Date): string {
    var s: string = null;
    if (d) {
      s = dateFormat(d, this.dateTimeTodayFormat.format, { locale: localeDa });
    }
    return s;
  }


  public formatDateFns(d: Date, format: any): string {
    return dateFormat(d, format.format.replace('m', 'M'), { locale: localeDa });
  }

  public parseDate(s: string): Date {
    s = s.trim();

    if (s == 'd') {
      return new Date(new Date().setHours(0, 0, 0, 0));
    }

    s = s.replace(/\./g, '-');
    s = s.replace(/,/g, '-');
    s = s.replace(/ /g, '-');
    s = s.replace(/\//g, '-');
    s = s.replace(/\\/g, '-');
    // Implicit separations on length
    if (s.length > 2) {
      var n: any = s;
      if (!isNaN(n)) {
        s = s.substring(0, 2) + '-' + s.substring(2);
        if (s.length > 5) {
          s = s.substring(0, 5) + '-' + s.substring(5);
        }
      }
    }

    var seqments: string[] = s.split('-');
    var d: Date = null;

    if (seqments.length == 1) {
      if (s.toUpperCase() == 'd'.toUpperCase()) {
        d = new Date();
      }
      else {
        d = dateParse(s, 'dd', new Date());
      }
    }
    else {
      if (seqments.length == 2) {
        d = dateParse(s, 'dd-MM', new Date());
      }
      else if (seqments.length == 3) {
        if (seqments[2].length > 2) {
          d = dateParse(s, 'dd-MM-yyyy', new Date());
        }
        else {
          d = dateParse(s, 'dd-MM-yy', new Date());
        }
      }
    }
    if (d instanceof Date && !isNaN(d.valueOf())) {
      return d;
    }
    else {
      return null;
    }
  }

  private convertDateToUTC(date: Date): Date {
    if (date != null) {
      date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }
    return date;
  }


  // To do: Move to static helper class
  public textToHtml(text: string | string[]): string {
    return Array.isArray(text) ? text.join('<br/>') : text;
  }

}
