import { Column, DefaultEditCell, IGrid } from "@syncfusion/ej2-angular-grids";
import { FieldMetadata } from "../cer-data/cer-data.service";
import { TextBox } from "@syncfusion/ej2-angular-inputs";

export class CerGridEditDefault {
    private parent: any;
    private fieldMeta: FieldMetadata;
    private baseEditCell: DefaultEditCell;
    private baseEditCellAny: any;
    private input: HTMLInputElement;
    private obj: TextBox;
    private row: any;
    private activeClasses: string[] = ['e-selectionbackground', 'e-active'];
  
    constructor(parent: IGrid, baseEditCell: DefaultEditCell, fieldMeta: FieldMetadata) {
      this.parent = parent;
      this.baseEditCell = baseEditCell;
      this.baseEditCellAny = <any>baseEditCell;
      this.fieldMeta = fieldMeta;
  
      (<any>baseEditCell).create_orig = baseEditCell.create;
      this.baseEditCell.create = this.create.bind(this);
  
      (<any>baseEditCell).destroy_orig = baseEditCell.destroy;
      baseEditCell.destroy = this.destroy.bind(this);
  
      (<any>baseEditCell).write_orig = baseEditCell.write;
      baseEditCell.write = this.write.bind(this);
  
      (<any>baseEditCell).read_orig = baseEditCell.read;
      baseEditCell.read = this.read.bind(this);
    }
  
    public create(args: any): any {
      /* args: { column: Column; data: any; type: string; value: boolean; } // type = Action Type "beginEdit"*/
      if (this.baseEditCellAny.create_orig) {
        return this.baseEditCellAny.create_orig(args);
      }
    }      
  
    public write(args: {
      rowData: Object;
      element: Element;
      column: Column;
      requestType: string;
      row: Element;
    }) {
      if (this.baseEditCellAny.write_orig) {
        var ret = this.baseEditCellAny.write_orig(args);
        this.obj = this.baseEditCellAny.obj;
        //return ret;
      }
      if (this.fieldMeta) {
        this.addMyEventListener();
      }
    }
  
    public destroy() {
      if (this.baseEditCellAny.destroy_orig) {
        return this.baseEditCellAny.destroy_orig();
      }
      if (this.fieldMeta) {
        this.removeMyEventListener();
      }
    }
  
    public read(element: Element): string {
      return this.obj.value;
    }
  
    private onEditChanged(args: any) {
      if (this.fieldMeta?.onEditChanged) {
        this.fieldMeta.onEditChanged(args);
      }
    }
    private addMyEventListener() {
      this.obj.addEventListener("change", this.onEditChanged.bind(this));
    };
  
    private removeMyEventListener() {
      this.obj.removeEventListener("change", this.onEditChanged.bind(this));
    }
  }
  
  