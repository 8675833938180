<button mat-icon-button *ngIf="routeTitle$ | async" class="router-title-button" (click)="reloadUrl()">
  <div class="router-title">{{ routeTitle$ | async }}</div>
</button>
<div *ngIf="(activeViewPersistenceService$ | async) != null" class="flex-row">
  <div>
    <button mat-icon-button [matMenuTriggerFor]="pageTitleMenu" class="page-title-menu-button"
      aria-label="Vælg gemt visning">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
  <div class="e-chip-list" >
    <div *ngIf="(viewNameTitle$ | async) != null" [matMenuTriggerFor]="pageTitleMenu" class="e-chip e-success">
      <div class="e-chip-text">{{selectedMenuItem.name}}</div>
    </div>
    <div *ngFor="let view of primaryMenuItems; trackBy: trackViewMenuItem;" [ngClass]="view.isSelected ? 'e-success' : 'e-info'" class="e-chip">
      <div *ngIf="!view.isSelected" (click)="clickedViewMenu(view)" class="e-chip-text">{{view.name}}</div>
      <div *ngIf="view.isSelected" class="cer-disabled e-chip-text">{{view.name}}</div>
    </div>
  </div>
  <mat-menu #pageTitleMenu="matMenu" xPosition="before">
    <button mat-menu-item *ngFor="let menuItem of viewMenuItems; trackBy: trackViewMenuItem"
      (click)="clickedViewMenu(menuItem)">
      <mat-icon>{{ menuItem.icon }}</mat-icon>
      <span>{{ menuItem.text }}</span>
    </button>
  </mat-menu>
  <span class="pageIsLoading" *ngIf="pageIsLoading$ | async">
    <img src="../../../assets/loading.svg" />
  </span>