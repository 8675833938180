import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CerAppMetaUserRoute, CerAppMetaUserService, CerAppMetaUserSettings } from '../cer-app-meta/cer-app-meta-user.service';

import { AppMetaClient, AppMetaUserViewCommand, AppMetaUserViewItem, AppMetaUserViewQuery, RoleDto, RoleTypeEnum, AppRouteDto, AppUserRouteDto, AppUserRouteViewDto, UserVm } from 'src/app/api';
import { NavigationAll } from 'src/app/sidenav/app-navigation';
import { CerAppMetaService } from 'src/cer-app/cer-app-meta/cer-app-meta.service';
import { CerMetaUserView } from 'src/cer/cer-form/cer-form-persistence.service';

@Injectable({
  providedIn: 'root'
})
export class CoreUserService implements OnDestroy {
  private subscriptionManager$: Subscription = new Subscription();

  constructor(private metaUserService: CerAppMetaUserService, private metaService: CerAppMetaService, private appMetaClient: AppMetaClient) {
    this.subscriptionManager$.add(
      this.metaUserService.userRouteSave$.subscribe(router => this.metaUserRouteSave(router))
    );
    this.subscriptionManager$.add(
      this.metaUserService.userRouteLoad$.subscribe(url => this.metaUserRouteGet(url))
    );
  }

  ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  public metaRead(vm: UserVm) {
    this.metaNavigationRead(NavigationAll, vm.roles);
    this.metaService.readEnvironment(vm.environment);
    this.metaUserSettingsRead(vm.routes, vm.userRoutes, vm.userRouteViews);
  }

  public metaNavigationRead(navigationAll: Object[], rolesActive: RoleDto[]) {
    var navigation = Object.assign([], navigationAll);
    this.setupNavigationNode(navigation, navigation, rolesActive);
    this.metaUserService.navigation$.next(navigation);
  }

  private setupNavigationNode(navigation: Object[], node: Object[], rolesActive: RoleDto[]) {
    for (var i = node.length - 1; i >= 0; i--) {
      let item = <any>node[i];
      var allow: boolean = true;
      if (item && item.roles) {
        var allow: boolean = true;
        if (!this.userRolesCheck(item.roles, rolesActive, true)) {
          allow = false;
        }
        else if (this.userRolesCheck(item.rolesNot, rolesActive, false)) {
          allow = false;
        }
        if (!allow) {
          node.splice(i, 1);
        }
        else {
          if (item.nodeChild) {
            this.setupNavigationNode(navigation, item.nodeChild, rolesActive);
          }
        }
      }
    }
  }

  public userRolesCheck(requiredRoles: RoleTypeEnum[], userRoles: RoleDto[], defaultOk: boolean = false): boolean {
    var ok: boolean = defaultOk;

    if (requiredRoles && requiredRoles.length > 0) {
      ok = false;
      if (userRoles && userRoles.length > 0) {
        requiredRoles.forEach(r =>
          userRoles.forEach(ur => {
            if (ur.roleType == r) {
              ok = true;
            }
          }));
      }
    }

    return ok;
  }

  // Meta routes
  private metaUserRouteSave(route: CerAppMetaUserRoute) {
    var cmd: AppMetaUserViewCommand = new AppMetaUserViewCommand();
    cmd.routeUrl = route.routeUrl;
    cmd.defaultView = route.defaultView;
    cmd.views = [];
    route.views.filter(v => v.type == 'custom').forEach(v => {
      var view: AppMetaUserViewItem = new AppMetaUserViewItem();
      view.name = v.name;
      view.isPrimary = v.isPrimary;
      view.isDefault = v.isDefault;
      view.formPersistenceData = v.formPersistenceData;
      cmd.views.push(view);
    });

    this.appMetaClient.postUserViews(cmd).subscribe(
      result => { },
      error => {
        console.log(error);
        //this.appStateService.handleApiError(error, true);
      }
    );
  }

  public metaUserRouteGet(url: string) {
    var query = new AppMetaUserViewQuery({ url: url });
    this.appMetaClient.getUserViews(query).subscribe(
      vm => {
        var settings = this.metaUserService.settings$.getValue();
        var routers = settings?.routers;
        if (routers) {
          var routeIdx = routers.findIndex(r => r.routeUrl == url);
          if (routeIdx != -1) {
            routers.splice(routeIdx, 1);
          }
          routers.push(this.metaUserRouteRead(vm.route, vm.userRoute, vm.userRouteViews));
          this.metaUserService.settings$.next(settings);
        }
      });
  }

  public metaUserSettingsRead(routesDto: AppRouteDto[], userRoutesDto: AppUserRouteDto[], userRouteViewsDto: AppUserRouteViewDto[]) {
    var settings = new CerAppMetaUserSettings()
    settings.viewNameStandard = 'Standard';
    settings.routers = [];

    userRoutesDto.forEach(userRouteDto => {
      var routeDto: AppRouteDto = routesDto.find(r => r.id == userRouteDto.routeId);
      var route = this.metaUserRouteRead(routeDto, userRouteDto, userRouteViewsDto);
      settings.routers.push(route);
    });

    this.metaUserService.settings$.next(settings);
  }

  private metaUserRouteRead(routeDto: AppRouteDto, userRouteDto: AppUserRouteDto, userRouteViewsDto: AppUserRouteViewDto[]): CerAppMetaUserRoute {
    var route: CerAppMetaUserRoute = this.metaUserService.getRouteDefault(routeDto.url, userRouteDto.defaultView);
    route.defaultView = userRouteDto.defaultView;

    userRouteViewsDto.filter(urv => urv.userRouteId == userRouteDto.id).forEach(urv => {
      var view: CerMetaUserView = new CerMetaUserView();
      view.name = urv.name;
      view.isPrimary = urv.isPrimary;
      view.isDefault = urv.isDefault;
      view.formPersistenceData = urv.formPersistenceData;
      view.type = 'custom';
      route.views.push(view);
    });

    return route;
  }
}
