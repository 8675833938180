<cer-form *ngIf="setupComplete" #form [panes]="panes">
  <ng-template #mainTemplate>
    <cer-grid #grid></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-app-file-viewer-tab #tabAttachments [token]="attachmentToken" [refTableId]="1" [refRowId]="attachmentRefRowId"
      [attachments]="attachments" [enableChat]="true" [chats]="chats" [saveUrl]="attachmentSaveUrl"
      [removeUrl]="attachmentRemoveUrl" [tabSelectedIndexDefault]="attachmentTabSelectedIndexDefault"
      [tabCheckBoxAllowed]="true" (tabSelectedIndexChanged)="onAttachmentTabSelectedIndexChanged($event)"
      (fileUploadChanged)="attachmentService.onUploadChange($event)"
      (tabCheckBoxApply)="onAttachmentCheckBoxApply($event)">
    </cer-app-file-viewer-tab>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid #gridBottom selectionInitial="none" mode="simpleToolbar" autoFocus="disabled" dataRelationRole="child"></cer-grid>
  </ng-template>
</cer-form>