import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { FieldFormatEnum, FieldWidthEnum } from '../../../cer/cer-data/cer-data.service';
import { UiCommand, UiKeyboardShortcut, UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';
import { BankAccountSettlementClient, BankAccountSettlementStatusSetCommand, BankSettlementStatusEnum } from 'src/app/api';

export class BankAccountTransactionSettlementFormAdapter extends CerAppFormModelAdapter {

  private uiCmdReOpen: UiCommand = { id: 'Reopen', text: 'Genåbn afstemning', tooltipText: 'Genåbn alle afstemte transaktioner (ALT+O)', iconCss: 'e-menu-icon e-icons e-research', target: ".e-content", needSelection: true };
  private uiCmdReOpenShortcut: UiKeyboardShortcut = { cmd: this.uiCmdReOpen, code: 'KeyO', shift: false, ctrl: false, alt: true };

  override init() {

    this.viewMetadata = {
      name: 'bank-transaktion-afstemning', dataApiName: 'BankAccountSettlement', text: 'Afstemning', textSingular: 'Afstemning',
      primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: false, baseFields: ['id', 'history']
    };

    //var userId: number = this.appStateService.appUserId();
    this.fieldMetadata =
      [
        { name: 'bankAccount', text: 'Bankkonto', foreignKeyTableName: 'BankAccount', widthType: FieldWidthEnum.XXS },
        { name: 'settlementDate', text: 'Dato', format: FieldFormatEnum.DateShort, visible: false },
        { name: 'status', text: 'Status', foreignKeyTableName: 'BankAccountTransactionStatus', widthType: FieldWidthEnum.XXS },
        { name: 'createdBy', text: 'Afstemt af', foreignKeyTableName: 'User', widthType: FieldWidthEnum.XS, foreignKeyValue: 'shortName' /*, filterOperator: 'equal', filterValue: userId*/, filterOnClient: true },
        { name: 'created', text: 'Afstemt', format: FieldFormatEnum.DateLong, widthType: FieldWidthEnum.M, orderBy: 'Descending' }
      ];

    this.toolbarCommands = [this.uiCmdReOpen];
    this.keyboardShortcuts = [this.uiCmdReOpenShortcut];

    this.detailViewMetadata = {
      name: 'bank-transaktion', dataApiName: 'BankAccountTransaction', text: 'Poster',
      primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: '$settlementIdFilter', allowEdit: false, allowCreate: false, allowDelete: false, baseFields: ['id', 'history']
    };

    this.detailFieldMetadata =
      [
        { name: 'sourceBatch', text: 'Batch', visible: false, widthType: FieldWidthEnum.XXS },
        { name: 'bankAccount', text: 'Bankkonto', foreignKeyTableName: "BankAccount", widthType: FieldWidthEnum.XXS },
        { name: 'source', text: 'Kilde', foreignKeyTableName: "BankAccountTransactionSource", widthType: FieldWidthEnum.XXS, visible: true },
        { name: 'transDate', text: 'Dato', format: FieldFormatEnum.DateShort, widthType: FieldWidthEnum.XXS, orderBy: 'Descending' },
        { name: 'txt', text: 'Tekst', widthType: FieldWidthEnum.XXXXL },
        { name: 'authReference', text: "Nota Indløser", widthType: FieldWidthEnum.XXS },
        { name: 'bankCreditCard', text: "Kreditkort", widthType: FieldWidthEnum.XS, foreignKeyTableName: 'BankCreditCard', visible: false },
        { name: 'user', text: 'Medarbejder', widthType: FieldWidthEnum.XXS, foreignKeyTableName: 'User', foreignKeyValue: 'shortName' },
        { name: 'amount', text: 'Beløb', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.XXS, footerType: 'Sum' },
        { name: 'currency', text: 'Valuta', foreignKeyTableName: 'Currency', foreignKeyValue: 'iso', widthType: FieldWidthEnum.XXXS },
        { name: 'status', text: 'Status', foreignKeyTableName: "BankAccountTransactionStatus", widthType: FieldWidthEnum.XXS }
      ];

    this.rightPaneWidthPct = 70;
  }

  override onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      case this.uiCmdReOpen.id:
        this.reOpen();
        break;
    }
  }

  override onDetailCommand(event: UiCommandEvent) {
  }

  private reOpen() {
    var dto: any = this.grid.rowSelectedData();
    if (dto.id) {
      var settlementId: number = dto.id;
      var cmd = new BankAccountSettlementStatusSetCommand(
        {
          settlementId: settlementId,
          status: BankSettlementStatusEnum.Open
        });
      this.statusSet(cmd);
    }
  }

  private statusSet(cmd: BankAccountSettlementStatusSetCommand) {
    var statusClient: BankAccountSettlementClient = this.injector.get(BankAccountSettlementClient);
    statusClient.setStatus(cmd).subscribe(
      result => {
        this.dialogService.snackBar(result.length + ' afstemninger åbnet');
        this.grid.research();
        this.routeService.isLoading$.next(false);
      },
      error => {
        this.routeService.isLoading$.next(false);
        this.appDialogService.handleApiError(error);
        console.error(error)
      }
    );
  }
}
