import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldFormatEnum, FieldMetadata, FieldWidthEnum, ViewMetadata } from 'src/cer/cer-data/cer-data.service';
import { UiCommand, UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { FormRecognizerDocumentClient } from '../../../app/api';
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { uiCmdPaneRightToggleNoText, uiCmdPaneRightClose, CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';

const uiCmdFormRecognizeDocumentAnalyze: UiCommand = { id: 'FormRecognizeDocumentAnalyze', text: 'Analyser dokument', iconCss: "e-menu-icon e-icons e-print-layout", tooltipText: 'Send dokument til analyse', target: ".e-content", needSelection: true };
const uiCmdFormRecognizeDocumentExtract: UiCommand = { id: 'FormRecognizeDocumentExtract', text: 'Udtræk data', iconCss: "e-menu-icon e-icons e-text-form", tooltipText: 'Udtræk data opsat til genkendelse', target: ".e-content", needSelection: true };
const uiCmdFormRecognizeDocumentResultToClipboard: UiCommand = { id: 'FormRecognizeDocumentResultToClipboard', text: 'Kopier resultat', iconCss: "e-menu-icon e-icons e-copy2", tooltipText: 'Kopier analyserede data til clipboard', target: ".e-content", needSelection: true };

@Component({
  selector: 'app-form-recogizer-document',
  templateUrl: './app-form-recogizer-document.component.html',
  styleUrls: ['./app-form-recogizer-document.component.css']
})
export class AppFormRecogizerDocumentComponent implements OnInit {
 
  protected panes: CerFormPanesSettings = {
    main: { visible: 'show', size: '70%' },
    right: { visible: 'show', size: '30%' },
    orientationPrimary: 'horizontal'
  };

  // View
  public viewMetadata: ViewMetadata = {
    name: 'form-recogizer-document', dataApiName: 'FormRecognizerDocument', text: 'Dokumenter',
    primaryKey: 'id', titleFields: ['id'], dialogEdit: true, allowEdit: false, allowCreate: true, allowDelete: true, baseFields: ['history']
  };
  // Fields
  public fieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS, orderBy: 'Descending' },
    { name: 'refTableId', text: 'Tabel', tooltipText: '', required: true, allowEdit: true, widthType: FieldWidthEnum.M, visible: false, visibleAdd: true },
    { name: 'refRowId', text: 'Post Id', tooltipText: '', required: true, allowEdit: true, widthType: FieldWidthEnum.M },
    { name: 'fileName', text: 'Fil', tooltipText: 'Filnavn', required: true, allowEdit: true, widthType: FieldWidthEnum.M },
    { name: 'folder', text: 'Sti', tooltipText: 'Stinavn', required: true, allowEdit: true, widthType: FieldWidthEnum.M, visible: false, visibleAdd: true },
    { name: 'blobStorage', text: 'Datalager', tooltipText: '', required: false, allowEdit: true, widthType: FieldWidthEnum.M, foreignKeyTableName: "BlobStorage" },
    { name: 'formRecognizer', text: 'Formular', tooltipText: '', required: false, allowEdit: true, widthType: FieldWidthEnum.M, foreignKeyTableName: "FormRecognizer" },
    { name: 'processed', text: 'Behandlet', tooltipText: '', allowEdit: true, format: FieldFormatEnum.CheckBox },
    { name: 'result', text: 'Resultat', tooltipText: '', required: false, allowEdit: true, widthType: FieldWidthEnum.L, visible: false }
    ];

  // Detail view
  public detailViewMetadata: ViewMetadata = {
    name: 'form-recogizer-document-field', dataApiName: 'FormRecognizerDocumentField', text: 'Felter',
    primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'document', titleFields: ['value'], allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };

  // Detail  fields
  public detailFieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false, isIdentity: true, widthType: FieldWidthEnum.S },
    { name: 'field', text: 'Felt', tooltipText: 'Grundlæggende felt', widthType: FieldWidthEnum.S, foreignKeyTableName: 'FormRecognizerFieldType', orderBy: 'Ascending' },
    { name: 'value', text: 'Værdi', tooltipText: '', widthType: FieldWidthEnum.S }
    ];

  public toolbarCommands: UiCommand[] = [uiCmdFormRecognizeDocumentAnalyze,
     uiCmdFormRecognizeDocumentExtract, uiCmdFormRecognizeDocumentResultToClipboard,
     uiCmdPaneRightToggleNoText];
  public contextMenuCommands: UiCommand[] = [uiCmdFormRecognizeDocumentAnalyze, uiCmdFormRecognizeDocumentExtract, uiCmdFormRecognizeDocumentResultToClipboard];

  public detailToolbarCommands: UiCommand[] = [uiCmdPaneRightClose];

  mainGrid: CerGridComponent;
  @ViewChild('mainGrid', { static: false }) set mainGridContent(content: CerGridComponent) {
    if (content && !this.mainGrid) {
      this.mainGrid = content;
      this.mainGrid.commmand.subscribe(e => this.onCommandMain(e));
    }
  }

  constructor(private client: FormRecognizerDocumentClient, private ui: CerAppDialogService) {
  }

  ngOnInit(): void {
  }

  // Commands
  public onCommandMain(event: UiCommandEvent) {
    switch (event.commandId) {
      case uiCmdFormRecognizeDocumentAnalyze.id:
        this.documentAnalyze();
        break
      case uiCmdFormRecognizeDocumentExtract.id:
        this.documentExtract();
        break;
      case uiCmdFormRecognizeDocumentResultToClipboard.id:
        this.documentResultToClipboard();
        break;
    }
  }

  private documentResultToClipboard() {
    var document: any = this.mainGrid.rowSelectedData();
    if (document && document.result) {
      navigator.clipboard.writeText(document.result).then().catch(e => console.error(e));
    }
  }

  private documentAnalyze() {
    this.process(true, true);
  }

  private documentExtract() {
    this.process(false, true);
  }

  public process(doAnalyze: boolean, doExtract: boolean) {
    var document: any = this.mainGrid.rowSelectedData();
    if (document) {
      var documentId: number = document.id;
      if (documentId) {
        this.client.process(documentId, doAnalyze, doExtract).subscribe(document => {
          if (document) {
            this.mainGrid.research();
          }
        },
          error => {
            this.ui.handleApiError(error);
          }
        );
      }
    }
  }
}
