import { Component, OnDestroy, ViewChild } from '@angular/core';
import { TreeViewComponent, NodeSelectEventArgs } from '@syncfusion/ej2-angular-navigations';
import { Subject, Subscription, combineLatest } from 'rxjs';

import { CerAppMetaUserService } from '../cer-app-meta/cer-app-meta-user.service';
import { CerAppRouteService, RouteCount } from '../cer-app-route/cer-app-route.service';

export class UrlNodeId {
  public url: string;
  public nodeId: string;
}

@Component({
  selector: 'cer-app-sidenav',
  templateUrl: './cer-app-sidenav.component.html',
  styleUrls: ['./cer-app-sidenav.component.css']
})
export class CerAppSideNavComponent implements OnDestroy {
  public subscriptionManager$: Subscription = new Subscription();

  private navigation: any = null;
  private navigationNodeUrlMap: UrlNodeId[];
  public fields: any;

  private treeview: TreeViewComponent;
  private treeviewLoaded$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('treeview', { static: false }) set setTreeview(content: TreeViewComponent) {
    if (content && !this.treeview) {
      this.treeview = content;
      this.treeviewLoad();
    }
  }

  constructor(private routeService: CerAppRouteService, private metaUserService: CerAppMetaUserService) {

    this.subscriptionManager$.add(
      this.metaUserService.navigation$.subscribe(navigation => {
        this.navigation = navigation;
        this.fields = { dataSource: this.navigation, id: 'nodeId', text: 'nodeText', child: 'nodeChild', iconCss: 'iconCss' };
        if (navigation) {
          this.treeviewLoad();
        }
      })
    );

    this.subscriptionManager$.add(
      combineLatest([this.treeviewLoaded$, this.routeService.url$]).subscribe(([loaded, url]) => { if (loaded) { this.onUrlNavigated(url) } })
    );

    this.subscriptionManager$.add(
      combineLatest([this.treeviewLoaded$, this.routeService.routeCounts$]).subscribe(([loaded, routeCounts]) => { if (loaded) { this.readRouteCounts(routeCounts); } })
    );
  }

  public ngOnDestroy() {
    this.subscriptionManager$.unsubscribe();
  }

  private treeviewLoad() {
    if (this.treeview && this.navigation) {
      this.treeview.setProperties({ selectedNodes: [] }, true);
      this.treeview.setProperties({ expandedNodes: [] }, true);
      this.navigationNodeUrlMap = [];
      this.setupNodes(this.navigation, null);
      this.treeviewLoaded$.next(true);
    }
  }

  private readRouteCounts(routeCounts: RouteCount[]) {
    if (routeCounts) {
      routeCounts.forEach(count => this.setNodeCountFormRouteCount(count));
    }
  }

  private setNodeCountFormRouteCount(count: RouteCount) {
    var nodeId = count.nodeId;
    if (!nodeId && count.url && this.navigationNodeUrlMap) {
      nodeId = this.navigationNodeUrlMap.find(map => map.url == count.url)?.nodeId;
    }
    if (nodeId) {
      this.setNodeCountFromNodeId(count.nodeId, count.count);
    }
  }

  public setNodeCountFromNodeId(nodeId: string, count: number) {
    if (nodeId && this.treeview) {
      let treeData: any = this.treeview.getTreeData(nodeId);
      if (treeData?.length > 0) {
        var nodeData = treeData[0];
        if (nodeData.count != count) {
          nodeData.count = count;
          this.treeview.updateNode(nodeData.nodeId, nodeData.nodeText); // Dummy to refresh UI
        }
      }
    }
  }

  public onNodeSelected(args: NodeSelectEventArgs): void {
    try {
      let data: any = this.treeview.getTreeData(args.node);
      if (data?.length > 0) {
        var nodeData = data[0];
        if (nodeData.url) {
          this.routeService.redirectToUrl(nodeData.url, false);
        }
        else {
          args.cancel = true;
        }
      }
    }
    catch
    {
    }
  }

  private onUrlNavigated(url: string) {
    if (this.treeview && url) {
      var needSelect: boolean = true;
      var selected: string[] = this.treeview.selectedNodes;
      if (selected?.length > 0) {
        var selectedId = selected[0];
        var selectedData: any = this.treeview.getTreeData(selectedId);
        if (selectedData?.length > 0) {
          var nodeData = selectedData[0];
          if (nodeData.url == url) {
            needSelect = false;
          }
        }
      }
      if (needSelect === true) {
        this.selectNode(url);
      }
    }
  }

  private selectNode(url: string) {
    if (url) {
      let treeData: any = this.treeview.getTreeData(url);
      if (treeData && treeData.length > 0) {
        var selectedNode = treeData[0];
        this.expandNodeParents(selectedNode);
        this.treeview.selectedNodes = [selectedNode.nodeId];
      }
      else {
        this.treeview.selectedNodes = [];
      }
    }
  }

  private expandNodeParents(childNode: any) {
    var parentId = childNode.parentId;
    if (parentId) {
      if (!this.treeview.expandedNodes.includes(parentId)) {
        this.treeview.expandedNodes = this.treeview.expandedNodes.concat(parentId);
      }
      var parentNode = this.treeview.getTreeData(parentId);
      if (parentNode && parentNode.length > 0) {
        this.expandNodeParents(parentNode[0]);
      }
    }
  }

  private setupNodes(nodes: any[], parentId: string) {
    nodes.forEach(node => {
      node.parentId = parentId;
      if (node.url) {
        this.navigationNodeUrlMap.push({ nodeId: node.nodeId, url: node.url });
      }
      if (node.nodeChild) {
        this.setupNodes(node.nodeChild, node.nodeId);
      }
    });
  }
}
