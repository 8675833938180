<button
  mat-icon-button
  *ngIf="isAuthenticated | async"
  style="width: auto"
  [matMenuTriggerFor]="identitymenu"
>
<div class="user-name-div">
  <mat-icon>person_outline</mat-icon><span class="user-name-span">{{ userNameShort }}</span>
</div>
</button>
<button
  mat-icon-button
  *ngIf="!(isAuthenticated | async)"
  style="width: auto"
  [matMenuTriggerFor]="loginmenu"
>
  <mat-icon>person_outline</mat-icon><span>Ingen bruger</span>
</button>
<mat-menu #identitymenu="matMenu">
  <button
    *ngif="defaultIdentityActive"
    mat-menu-item
    [routerLink]="['/authentication/profile']"
    title="Ændre brugerprofil"
  >
    <mat-icon>perm_identity</mat-icon>
    <span>App konto profil</span>
  </button>
  <button
    mat-menu-item
    [routerLink]="['/authentication/logout']"
    [state]="{ local: true }"
    title="Log af app"
  >
    <mat-icon>exit_to_app</mat-icon>
    <span>Log af</span>
  </button>
</mat-menu>
<mat-menu #loginmenu="matMenu">
  <button mat-menu-item [routerLink]="['/authentication/login']">
    <mat-icon>login</mat-icon>
    <span>Log ind</span>
  </button>
  <button mat-menu-item [routerLink]="['/authentication/register']">
    <mat-icon>how_to_reg</mat-icon>
    <span>Opret app konto</span>
  </button>
</mat-menu>
