import { FieldFormatEnum, FieldWidthEnum } from '../../cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';
import { UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';

export class AppDataProcessFormAdapter extends CerAppFormModelAdapter {

  public override init() {

    this.viewMetadata = {
      name: 'data-processer', dataApiName: 'DataProcess', text: 'Dataprocesser', textSingular: 'Dataproces',
      primaryKey: 'id', baseFields: ['id', 'history'], titleFields: ['name']
    };

    //var userId: number = this.appStateService.appUserId();
    this.fieldMetadata =
      [
        { name: 'dataTable', text: 'Hovedtabel', foreignKeyTableName: 'AppTable', required: true, orderBy: 'Ascending', orderByPriority: 1, 
          foreignKeyFilterFields: ['name'], foreignKeyFilterValues: ['name'] },
        { name: 'name', text: 'Navn', required: true, orderBy: 'Ascending', orderByPriority: 2 }
      ];

    this.toolbarCommands = [];
    this.keyboardShortcuts = [];

    this.detailViewMetadata = {
      name: 'data-proces-stadier', dataApiName: 'DataProcessStage', text: 'Stadier', textSingular: 'Stadie',
      primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'dataProcess', baseFields: ['id', 'history'], titleFields: ['name']
    };

    this.detailFieldMetadata =
      [
        { name: 'dataProcess', text: 'Proces', required: true, foreignKeyTableName: 'DataProcess', visible: false, orderBy: 'Ascending', orderByPriority: 1 },
        { name: 'name', text: 'Stadienavn', required: true },
        { name: 'order', text: 'Rækkefølge', required: true, format: FieldFormatEnum.Integer, orderBy: 'Ascending', orderByPriority: 2 },
        { name: 'responsibleUser', text: 'Ansvarlig medarbejder', widthType: FieldWidthEnum.XXS, foreignKeyTableName: 'User', foreignKeyValue: 'shortName' },
        { name: 'responsibleRole', text: 'Ansvarlig rolle', widthType: FieldWidthEnum.XXS, foreignKeyTableName: 'Role' }
      ];

    this.rightPaneWidthPct = 70;
  }

  override onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      //      case this.uiCmdReOpen.id:
      //        this.reOpen();
      //        break;
    }
  }

  override onDetailCommand(event: UiCommandEvent) {
  }
}
