import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { CerCookieService } from 'src/cer-platform/cer-cookie/cer-cookie.service';
import { CerAppRouteService } from '../cer-app-route/cer-app-route.service';
import { CerSessionService } from 'src/cer-platform/cer-session/cer-session.service';

const JOBSCHEDULER_AUTHTOKEN_COOKIE: string = "JOBSCHEDULER_ID";
const COOKIE_JOBSCHEDULER_JWT_EXPIRE_DAYS: number = 1;

@Component({
  selector: 'cer-app-job-scheduler',
  template: '<iframe [src]="urlJobScheduler" frameborder="0" width="100%" height="100%"></iframe>',
  styleUrls: ['./cer-app-job-scheduler.component.css']
})
export class CerAppJobSchedulerComponent implements OnInit, OnDestroy {

  protected sessionState: string;
  private subscriptionManager: Subscription = new Subscription();
  private urlJobSchedulerLoaded: string = '/status/jobs-dashboard';
  public urlJobScheduler: SafeResourceUrl;
  public urlSet: boolean = false;

  constructor(
    private routeService: CerAppRouteService,
    protected session: CerSessionService, 
    private cookieService: CerCookieService,
    private domSanitizer: DomSanitizer, 
    private authorize: AuthorizeService) {
    this.urlJobScheduler = this.domSanitizer.bypassSecurityTrustResourceUrl("");
  }

  ngOnInit(): void {
    this.subscriptionManager.add(
      this.authorize.getAccessToken().subscribe(
        authToken => this.handleAuthCookie(authToken)
      )
    );
    this.subscriptionManager.add( this.session.state$.subscribe(state => { this.sessionState = state; }));
    
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  private handleAuthCookie(authToken: string) {
    if (authToken) {
      var jobSchedulerAuthToken: string = this.cookieService.getCookie(JOBSCHEDULER_AUTHTOKEN_COOKIE);
      if (jobSchedulerAuthToken != authToken) {
        if (jobSchedulerAuthToken) {
          this.cookieService.deleteCookie(JOBSCHEDULER_AUTHTOKEN_COOKIE);
        }
        this.cookieService.setCookie(JOBSCHEDULER_AUTHTOKEN_COOKIE, authToken, COOKIE_JOBSCHEDULER_JWT_EXPIRE_DAYS, "/");
      }
      this.setIsLoaded();
    }
  }

  private setIsLoaded() {
    if (!this.urlSet) {
      this.urlSet = true;
      this.urlJobScheduler = this.domSanitizer.bypassSecurityTrustResourceUrl(this.urlJobSchedulerLoaded);
      this.routeService.isLoading$.next(false);
    }
  }
}
