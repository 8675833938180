import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CerSessionService, SessionState, SessionUser } from 'src/cer-platform/cer-session/cer-session.service';
import { RoleDto, UserClient, UserDto, UserVm, RoleTypeEnum, UserStatusVm, UserStatusClient } from 'src/app/api';

import { CoreUserService } from 'src/cer-app/cer-app-user/cer-app-user.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';

export class AppUser {
  public sessionUser: SessionUser;
  public roles?: RoleDto[];
  public dto: UserDto;
  public vm: UserVm;
}

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  private subscriptionManager$: Subscription = new Subscription();

  public user$ = new BehaviorSubject<AppUser>(null);
  public userSession$ = new Subject<AppUser>;  // Used for user session changes internally
  public userIsAuthenticated$ = new BehaviorSubject<boolean>(false);  // User is authenticated completely
  public userStatusVm$ = new BehaviorSubject<UserStatusVm>(null);

  constructor(private router: Router,
    private authorizeGuard: AuthorizeGuard,
    private coreUserService: CoreUserService,
    private sessionService: CerSessionService,
    private userClient: UserClient,
    private userStatusClient: UserStatusClient,
    private snackBar: MatSnackBar) {

    this.subscriptionManager$.add(
      this.sessionService.state$.subscribe(state => this.sessionChange(state))
    );
  }

  public userDto(): UserDto {
    return this.user$?.getValue()?.dto;
  }

  public userId(): number {
    return this.userDto()?.id;
  }

  public userName(): string {
    return this.userDto()?.name;
  }

  private sessionChange(newState: SessionState) {
    switch (newState) {
      case 'activate':
        this.sessionActivate();
        break;
      case 'active':
        this.sessionActive();
        break;
      case 'inactive':
        this.sessionDeactivate();
        break;
      case 'paused':
        this.sessionReset();
        break;
      case 'noAccess':
        // Stopped!!
        break;
    }
  }


  private userHasNoAccess(user: AppUser): boolean {
      return (user?.dto == null || user.dto.active !== true || user?.roles === null || user?.roles.length == 0);
  }

  private sessionActivateUser(user: AppUser, sessionUser: SessionUser) {
    if (this.userHasNoAccess(user)) {
      this.authorizeGuard.handleUserSession([], sessionUser?.name);
      this.authorizeGuard.handleNoAccess(sessionUser?.name);
      this.sessionService.deactivate();
    }
    else {
      user.sessionUser = sessionUser;
      this.authorizeGuard.handleUserSession(user.roles.map(r => r.roleType), user.dto.name);

      this.userSession$.next(user);
      this.user$.next(user);
      this.apiUserStatusGet(); // Get user status now - no need to wait for real time service broadcast
      this.sessionService.active();
    };
  }

  private sessionActive() {
    this.userIsAuthenticated$.next(true); // Complete login
  }

  private sessionReset() {
    this.userSession$.next(null);
    this.user$.next(null);
  }

  private sessionDeactivate() {
    this.sessionReset();
    this.userIsAuthenticated$.next(false); // Complete logoff
  }

  private sessionActivate() {
    var sessionUser = this.sessionService.sessionUser$.getValue();
    if (sessionUser) {
      this.apiGetAppUser(sessionUser)
        .then(
          (appUser: AppUser) => this.sessionActivateUser(appUser, sessionUser)
        );
    }
  }

  private apiGetAppUser(sessionUser: SessionUser): Promise < AppUser > {
  return new Promise<AppUser>((resolve, reject) => {
    if (sessionUser) {
      this.userClient.get().subscribe({
        next: vm => {
          if (vm.user != null) {
            var user: AppUser = new AppUser();
            user.sessionUser = sessionUser;
            user.dto = vm.user;
            user.roles = vm.roles;
            user.vm = vm;
            this.coreUserService.metaRead(vm);
            resolve(user);
          }
          else {
            resolve(null);
          }
        },
        error: error => {
          resolve(null);
          this.snackBar.open(error);
          console.error(error);
          this.snackBar.open('Log venligt ind igen');
          this.router.navigate(['/authentication/logout']);
        }
      });
    }
    else {
      resolve(null);
    }
  });
}

  private apiUserStatusGet() {
  this.userStatusClient.get().subscribe(data => {
    this.userStatusVm$.next(data);
  });
}

  public userRolesCheck(requiredRoles: RoleTypeEnum[], userRoles: RoleDto[], defaultOk: boolean = false): boolean {
  return this.coreUserService.userRolesCheck(requiredRoles, userRoles, defaultOk);
}
}
