import { differenceInCalendarDays, differenceInMinutes } from 'date-fns';

export function calcDaysDiff(d1: Date, d2: Date) {
    var date1: any = d1;
    var date2: any = d2;
    var diffDays: any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));

    return diffDays;
}

export function totalDifferenceInMinutes(from: Date, to: Date) {
    return differenceInMinutes(from,to);
}


/*
  private convertDateToUTC(date: Date): Date {
    if (date != null) {
      date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }
    return date;
  }
  */
