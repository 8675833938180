import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class ProductCategoryFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'udgivelse-kategorier', dataApiName: 'ProductCategory', text: 'Kategorier, udgivelser', textSingular: "Kategori",
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'sorting', 'history']
    };
   
  }
}
