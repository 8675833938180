import { FieldFormatEnum, FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class RefDataNemHandelsRegisterFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'ref-data-nem-handels-register', dataApiName: 'RefDataNemHandelsRegister', text: 'NemHandelsregister',
      primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: false
    };

    this.fieldMetadata = [
      { name: 'id', text: 'Nøgle', format: FieldFormatEnum.Text, visible: false, widthType: FieldWidthEnum.M},
      { name: 'keyType', text: 'Addressetype', format: FieldFormatEnum.Text, widthType: FieldWidthEnum.XXS},
      { name: 'key', text: 'GLN/CVR Adresse', format: FieldFormatEnum.Text, widthType: FieldWidthEnum.XS},
      { name: 'unitName', text: 'Navn', format: FieldFormatEnum.Text, widthType: FieldWidthEnum.XXXL},
      { name: 'unitCVR', text: 'CVR nr.', format: FieldFormatEnum.Text, widthType: FieldWidthEnum.XXS}
    ];
   
  }
}
