import { NgModule } from '@angular/core';

// Material Design
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule, MatDialogActions, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card'
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPseudoCheckboxModule, MatNativeDateModule } from '@angular/material/core';
import { MatAccordion } from '@angular/material/expansion'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

var materialDesignImports =
  [
    MatToolbarModule, MatIconModule, MatBadgeModule, MatSidenavModule, MatListModule, MatButtonModule, MatMenuModule, MatDatepickerModule, MatNativeDateModule,
    MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatCardModule, MatStepperModule, MatTabsModule, MatCheckboxModule, MatSnackBarModule, DragDropModule
  ];

@NgModule({
  declarations: [],
  imports: materialDesignImports,
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, restoreFocus: true } },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true, displayDefaultIndicatorType: false } },
    { provide: ErrorStateMatcher, useClass: ErrorStateMatcher }
  ],
  exports: materialDesignImports
})
export class MaterialDesignModule { }
