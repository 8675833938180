import { FieldFormatEnum } from "src/cer/cer-data/cer-data.service";
import { CerAppFormModelAdapter } from "src/platform/models/cer-app-form-model-adapter";

export class ProjectTaskStatusFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    this.viewMetadata = {
      name: 'projekt-opgave-status', dataApiName: 'ProjectTaskStatus', text: 'Opgavestatus',
      primaryKey: 'num', baseFields: ['id','num','history']
    };

    this.fieldMetadata =
      [
        { name: 'num', text: 'Status' },
        { name: 'name', text: 'Statusnavn' },
        { name: 'isClosed', text: 'Lukket?', format: FieldFormatEnum.CheckBox }
      ];
  }

}
