import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum } from 'src/cer/cer-grid/cer-grid-command.service';

import { RoyaltyAfregningAktuelDetailViewClient, RoyaltyAfregningAktuelViewDto, RoyaltyForlagViewClient, RoyaltyForlagViewDto, RoyaltyStatementDocMergeCommand, RunRoyaltyCalculationCommand } from '../api';
import { RoyaltyApproveDetailComponent } from './royalty-approve-detail.component';

@Injectable()
export class RoyaltyService {

  public onRefresh$ : Subject<RoyaltyAfregningAktuelViewDto> = new Subject<RoyaltyAfregningAktuelViewDto>();

  constructor(
    private clientForlag: RoyaltyForlagViewClient,
    private clientDetail: RoyaltyAfregningAktuelDetailViewClient,
    private uiDialog: CerDialogService,
    private snackBar: MatSnackBar) {
  }

  public onGridCommand(e: UiCommandEvent, dialog: MatDialog, grid: CerGridComponent) {
    switch (e.source) {
      case UiCommandSourceEnum.ContextMenu:
      case UiCommandSourceEnum.KeyboardShortcut:
      case UiCommandSourceEnum.Toolbar:
        switch (e.commandId) {
          case 'details':
            var dto = e.args.rowData ?? e.args.rowInfo?.rowData;
            this.openDetails(dialog, dto, grid);
            break;
            case 'reCalcRoyalty':
              this.reCalcRoyaltyConfirm(grid);
              break;
          case 'wordMerge':
            this.wordDocumentMergeGridRowArgs(grid, e.args, false, false);
            break;
          case 'pdfMerge':
            this.wordDocumentMergeGridRowArgs(grid, e.args, true, false);
            break;
          case 'wordMergeDraft':
            this.wordDocumentMergeGridRowArgs(grid, e.args, false, true);
            break;
          case 'pdfMergeDraft':
            this.wordDocumentMergeGridRowArgs(grid, e.args, true, true);
            break;
        }
        break;
      case UiCommandSourceEnum.ActionBegin:
        switch (e.actionType) {
          case UiActionTypeEnum.RowDoubleClick:
            this.openDetails(dialog, e.args.rowData, grid);
            break;
        }
    }
  }

  public gridSelectedAktuelViewDto(grid: CerGridComponent): RoyaltyAfregningAktuelViewDto {
    var data = grid.rowSelectedData();
    return <RoyaltyAfregningAktuelViewDto>data;
  }

  // Do word document merge from grid
  public wordDocumentMergeGridSelectedRow(grid: CerGridComponent, createPdf: boolean, createDraft: boolean) {
    var dto = grid.rowSelectedData();
    this.wordDocumentMergeGridRowDto(grid, dto, createPdf, createDraft);
  }

  public wordDocumentMergeGridRowArgs(grid: CerGridComponent, args: any, createPdf: boolean, createDraft: boolean) {
    var dto = args.rowData ?? args.rowInfo?.rowData;
    this.wordDocumentMergeGridRowDto(grid, dto, createPdf, createDraft);
  }

  public wordDocumentMergeGridRowDto(grid: CerGridComponent, dto: any, createPdf: boolean, createDraft: boolean) {
    var id: number;
    var fid: number;
    var dato: Date;
    if (grid.viewMetadata.dataApiName == 'RoyaltyAfregningAktuelView') {
      var rdto = <RoyaltyAfregningAktuelViewDto>dto;
      id = rdto.id;
    }
    else {
      var fdto = <RoyaltyForlagViewDto>dto;
      fid = fdto.fid;
      dato = fdto.slut;
    }
    this.wordDocumentMerge(id, fid, dato, createPdf, createDraft);
  }

  // Do word document merge
  public wordDocumentMerge(id: number | undefined, fid: number | undefined, dato: Date | undefined, createPdf: boolean | false, createDraft: boolean | false) {
    if (!((id) || (fid && dato))) {
      this.uiDialog.errorPrompt('Ingen royalty post markeret');
      return;
    }

    var snackBarRef = this.snackBar.open('Danner dokument..', '', { duration: 10000 });
    this.clientDetail.post(<RoyaltyStatementDocMergeCommand>{ fid: fid, id: id, dato: dato, createPDF: createPdf, createDraft: createDraft }).subscribe(
      (fileName) => {
        snackBarRef.dismiss();
        if (fileName?.length > 0) {
          this.snackBar.open('Dokument er klar.', 'Vis dokument', { duration: 7000 })
          .onAction()
          .subscribe(() => window.open(fileName, '_blank'));
        }
        else {
          console.log('Ingen dokument oprettet');
          this.snackBar.open('Ingen dokument oprettet');
        }
      },
      (err) => {
        snackBarRef.dismiss();
        console.log('Fejl ved dokumentoprettelse: ' + err);
        this.snackBar.open('Fejl ved dokumentoprettelse:' + err);
      });
  }

  // Open details from grid
  public openDetailsSelectedRow(dialog: MatDialog, grid: CerGridComponent) {
    var dto = this.gridSelectedAktuelViewDto(grid);
    this.openDetails(dialog, dto, grid);
  }

  // Open details
  private detailRowView: RoyaltyAfregningAktuelViewDto;
  private inDetails: boolean = false;

  public openDetails(dialog: MatDialog, dto: RoyaltyAfregningAktuelViewDto, grid: CerGridComponent = null) {
    if (!dto || !dto.id) {
      this.uiDialog.errorPrompt('Ingen royalty beregning markeret.');
      return;
    }

    if (!this.inDetails) {
      this.inDetails = true;
      this.detailRowView = dto;
      var config = <MatDialogConfig>{ width: '95%', height: '95%', panelClass: 'custom-mat-dialog-panel', data: { royaltyTransId: this.detailRowView.id } };
      let dialogRef: MatDialogRef<RoyaltyApproveDetailComponent> = dialog.open(RoyaltyApproveDetailComponent, config);
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.dto && grid) {
          this.refreshDetailStatus(result.dto, this.detailRowView, grid);
        }
        this.inDetails = false;
      });
    }
  }

  // Refresh grid, when detais saved/closed
  private refreshDetailStatus(fromDto: RoyaltyAfregningAktuelViewDto, toDto: RoyaltyAfregningAktuelViewDto, grid: CerGridComponent) {
    Object.assign(toDto, fromDto);
    if (grid) {
      grid.gridService.refreshColumns();
      this.onRefresh$.next(toDto);
    }
  }

  private async reCalcRoyaltyConfirm(grid: CerGridComponent) {
    this.uiDialog.confirmPrompt('Er du sikker?','Genberegning alle ikke godkendte kontrakter').then(
      ok => {if (ok) {this.reCalcRoyaltyConfirmForSure(grid)};}
    );
  }

  private async reCalcRoyaltyConfirmForSure(grid: CerGridComponent) {
    this.uiDialog.confirmPrompt('Er du HELT sikker på af genberegne alle ikke godkendte kontrakter?','Genberegning alle ikke godkendte kontrakter').then(
      ok => {if (ok) {this.reCalcRoyaltyConfirmed(grid)};}
    );
  }

  private async reCalcRoyaltyConfirmed(grid: CerGridComponent) {
    var snackBarRef = this.snackBar.open('Genberegner royalty', 'Vent nogle minutter...', { duration: 10000000 });
    await this.clientForlag.post(<RunRoyaltyCalculationCommand>{}).subscribe({
      next: (result) => {
        if (grid) {
          grid.research();
        }
        snackBarRef.dismiss();
        console.log('Genberegnet royalty');
        this.snackBar.open('Beregning af royalty er færdig', 'Ok', { duration: 10000 });
      },
      error: (err) => {
        snackBarRef.dismiss();
        console.log('Beregning af royalty er færdig: ' + err);
        this.snackBar.open('Beregning af royalty er færdig:', err, { duration: 10000 });
      }
    });
  }
}
