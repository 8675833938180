<cer-form #form pattern="parentChildPane" [panes]="panes" (commmand)="onCommand($event)">
  <ng-template #mainTemplate>
    <cer-grid #grid 
    #Grid
    id="Grid"
    [viewMetadata]="viewMetadata" 
    [fieldMetadata]="fieldMetadata" 
    [toolbarCommands]="toolbarCommands"
    [contextMenuCommands]="contextMenuCommands" 
    [keyboardShortcuts]="keyboardShortcuts"
    dataRelationRole="parent"
    (commmand)="onCommand($event)"
    ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid
      #DetailGrid
      id="DetailGrid"
      autoFocus="disabled"
      [viewMetadata]="detailViewMetadata"
      [fieldMetadata]="detailFieldMetadata"
      [toolbarCommands]="detailToolbarCommands"
      [contextMenuCommands]="detailContextMenuCommands" 
      [keyboardShortcuts]="keyboardShortcuts"
      [editOnDoubleClick]="false"
      (commmand)="onDetailCommand($event)"
      
      dataRelationRole="child"
    ></cer-grid>
  </ng-template>
</cer-form>
