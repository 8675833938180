<cer-form #form [panes]="{right: {visible: 'show', size: '400px'}}">
  <ng-template #mainTemplate>
    <cer-grid
      #mainGrid
      [viewMetadata]="viewMetadata"
      [fieldMetadata]="fieldMetadata"
      [toolbarCommands]="toolbarCommands"
      [contextMenuCommands]="contextMenuCommands"
      [keyboardShortcuts]="keyboardShortcuts"
      dataRelationRole="parent"
    ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-app-file-viewer-tab
      #fileViewerTab
      [tabSelectedIndexDefault]="attachmentSelectIndexDefault"
      [enableChat]="true"
      [chats]="chats"
      [refTableId]="2"
      [refRowId]="dto?.id"
      [token]="dto?.folder"
      [attachments]="attachmentDtoList"
      [uploadIsActive]="false"
      [enableClose]="false"
    ></cer-app-file-viewer-tab>
  </ng-template>
</cer-form>
