import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CerAppMetaService } from 'src/cer-app/cer-app-meta/cer-app-meta.service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';

@Component({
  selector: 'cer-app-help-about',
  templateUrl: 'cer-app-help-about.component.html',
  styleUrls: ['cer-app-help-about.component.css']

})
export class CerAppHelpAboutComponent implements OnInit {

  public appName: string;
  public license: string;
  public copyright: string = 'Copyright 2020-2024 AIE ApS';
  public contact: string = 'mail@allaniversen.dk';
  public appVersion: string = '';
  public clientVersion: string = '';
  public hasSystemUpdate: boolean = false;
  protected hasLicence: boolean = false;

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private metaService: CerAppMetaService,
    private dialogService: CerDialogService,
    private dialogRef: MatDialogRef<CerAppHelpAboutComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogParm: any) {

    
    this.appName = this.metaService.title$.getValue();
    var licenseIssuedTo : string = this.metaService.licenseIssuedTo$.getValue()
    this.hasLicence = licenseIssuedTo != null && licenseIssuedTo.length > 0;
    this.license = 'Brugsretten til denne app er licenseret uden begrænsninger til "'+licenseIssuedTo+'".',
    this.appVersion = this.metaService.appVersion$.getValue();
    this.clientVersion = this.metaService.clientVersion$.getValue();
    this.hasSystemUpdate = this.clientVersion != this.appVersion;

    this.form = fb.group({

      license: [this.license],

      copyright: [this.copyright],

      appVersion: [this.appVersion],

      clientVersion: [this.clientVersion],

      contact: [this.contact]

    });
  }

  ngOnInit(): void {
    if (this.dialogParm?.askClientUpdate) {
      this.askClientUpdate();
    }
  }

  private askClientUpdate() {
    const that = this;
    this.dialogService.dialogConfirm('Klient skal opdateres. Opdater klient?').then(
      ok => {
        if (ok) {
          that.clientUpdate();
        }
      }
    );
  }

  public clientUpdate() {
    var location: any = window.location;
    location.reload(true);
  }

  close() {
    this.dialogRef.close();
  }

}
