import { FieldFormatEnum } from "src/cer/cer-data/cer-data.service";
import { CerAppFormModelAdapter } from "src/platform/models/cer-app-form-model-adapter";

export class RoyaltyPeriodFormAdapter extends CerAppFormModelAdapter {

    public override init() {

        this.viewMetadata = {
            name: 'royaltyPeriods', dataApiName: 'RoyaltyDatoer', text: 'Perioder', textSingular: 'Periode',
            primaryKey: 'id', titleFields: ['royaltyDato'], baseFields: ['id', 'history']
        };

        this.fieldMetadata =
            [
                { name: 'aktuel', text: 'Aktuel', tooltipText: 'Er dette den aktuelle periode', allowEdit: true, allowEditOnCreate: true, format: FieldFormatEnum.CheckBox },
                { name: 'royaltyDato', text: 'Periode slut', tooltipText: 'Slutdato for perioden', required: true, allowEdit: true, allowEditOnCreate: true, format: FieldFormatEnum.DateShort, orderBy: 'Descending' },
                { name: 'forrige', text: 'Forrige', tooltipText: 'Slutdato for forrige periode', required: true, allowEdit: true, allowEditOnCreate: true, format: FieldFormatEnum.DateShort }
            ];
    }
}