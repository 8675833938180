import { AfterViewInit, OnDestroy } from '@angular/core';
// Angular
import { Component, Inject, LOCALE_ID, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe, DecimalPipe } from '@angular/common';
import { subMonths } from 'date-fns';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';

// Platform
import { setCulture, Internationalization } from '@syncfusion/ej2-base';
import { ColumnChooserService, SortService, ResizeService, ReorderService, ColumnMenuService, ExcelExportService, ColumnMenuItem } from '@syncfusion/ej2-angular-grids';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';

// WebApi
import { RoyaltyAfregningAktuelDetailViewClient, RoyaltyAfregningAktuelViewDto, RoyaltyAfregningAktuelDetailViewVm, RoyaltyAfregningTrappeAktuelViewDto, RoyaltyAfregningTransHistorikViewDto, RoyaltyAfregningTransAktuelViewDto, RoyaltyNoteDto, UpdateRoyaltyTransCommand } from "../api";
import { RoyaltyTransGodkendtEnum, RoyaltyTransAfsluttetEnum } from '../royalty/royalty-enums';

@Component({
  selector: 'royalty-approve-detail',
  templateUrl: './royalty-approve-detail.component.html',
  styleUrls: ['./royalty-approve-detail.component.css'],
  providers: [SortService, ResizeService, ReorderService, ColumnChooserService, ColumnMenuService, ExcelExportService]
})

export class RoyaltyApproveDetailComponent implements AfterViewInit, OnDestroy {

  // WebApi
  public royaltyAfregningAktuelDetailViewVm: RoyaltyAfregningAktuelDetailViewVm;
  public dto: RoyaltyAfregningAktuelViewDto;
  public royaltyAfregningTransAktuelView?: RoyaltyAfregningTransAktuelViewDto[];
  public royaltyAfregningTrappeAktuelView?: RoyaltyAfregningTrappeAktuelViewDto[];
  public royaltyAfregningTransHistorikView?: RoyaltyAfregningTransHistorikViewDto[];
  public royaltyNote?: RoyaltyNoteDto[]

  // Grid options
  public columnMenuItems: ColumnMenuItem[] = ['ColumnChooser'];
  public allowPaging: Boolean;
  public isDataBound: boolean;

  // Form controls
  protected periodText: string;
  public hasAgent: boolean;
  public formForlag: FormGroup;
  public formAgent: FormGroup;
  public formKontrakt: FormGroup;
  public formRoyalty: FormGroup;
  public formApproval: FormGroup;
  public formClose: FormGroup;
  public formNote: FormGroup;
  public intl: Internationalization = new Internationalization();
  public parseDecimalHelper: Function = this.intl.getNumberParser({ format: 'N2', useGrouping: true });
  public datePipe: DatePipe;
  public decimalPipe: DecimalPipe;

  // State
  public isLoading: boolean = true;
  public royaltyTransId: number; // Passed from caller
  public valutaKode: string = '';
  public dtoGodkendtStr: string;
  public dtoAfsluttetStr: string;
  public betaltBelobText: string;
  public isValid: boolean = false;
  public inPayment: boolean = false;
  public canRecalc: boolean = false;
  public inNoteClose: boolean = false;
  public inNoteApprove: boolean = false;
  public existingNoteVisible: boolean = false;
  public tabGroupSelectedIndex = 0;

  // Hotkeys
  private saveAndCloseHotkey: Hotkey = new Hotkey('alt+x', (event: KeyboardEvent): boolean => { this.saveAndclose(); return false; /* Prevent bubbling */ }, undefined, 'Gem og luk detaljer');
  private cancelHotkey: Hotkey = new Hotkey('esc', (event: KeyboardEvent): boolean => { return true; }, undefined, 'Annuller og luk detaljer');
  private hotkeys: Hotkey[] = [this.saveAndCloseHotkey, this.cancelHotkey];

  godkendt: any;
  @ViewChild('godkendt', { static: false }) set setGodkendt(godkendt: any) {
    if (!this.godkendt && godkendt) {
      this.godkendt = godkendt;
      this.godkendt.focus();
    }
  }

  constructor(private client: RoyaltyAfregningAktuelDetailViewClient, private fb: FormBuilder,
    private routeService: CerAppRouteService,
    private hotkeysService: HotkeysService,
    private dialogRef: MatDialogRef<RoyaltyApproveDetailComponent>, private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) callerData: any, @Inject(LOCALE_ID) public locale: string) {

    setCulture(locale);

    this.datePipe = new DatePipe(locale);
    this.decimalPipe = new DecimalPipe(locale);

    this.royaltyTransId = callerData.royaltyTransId;

    this.getWebApi();
  }

  ngAfterViewInit(): void {
    this.hotkeysService.add(this.hotkeys);
  }

  ngOnDestroy(): void {
    this.hotkeysService.remove(this.hotkeys);
  }

  private setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
    this.routeService.isLoading$.next(isLoading);
  }

  private async getWebApi() {
    this.setIsLoading(true);
    this.client.get(this.royaltyTransId).subscribe(vm => {
      this.royaltyAfregningAktuelDetailViewVm = vm;
      if (this.royaltyAfregningAktuelDetailViewVm.royaltyAfregningAktuelViewDto) {
        this.dto = this.royaltyAfregningAktuelDetailViewVm.royaltyAfregningAktuelViewDto;
        this.royaltyAfregningTransAktuelView = this.royaltyAfregningAktuelDetailViewVm.royaltyAfregningTransAktuelViewDto;
        this.royaltyAfregningTrappeAktuelView = this.royaltyAfregningAktuelDetailViewVm.royaltyAfregningTrappeAktuelViewDto;
        this.royaltyAfregningTransHistorikView = this.royaltyAfregningAktuelDetailViewVm.royaltyAfregningTransHistorikViewDto;
        this.royaltyNote = this.royaltyAfregningAktuelDetailViewVm.royaltyNoteDto;

        this.buildForm();
        this.setIsLoading(false);
      }
    });
  }

  private async postWebApi(godkendtInt: number, afsluttetInt: number, betaltDato?: Date, betaltBelob?: number, note?: string, noteIsPublic?: boolean, recalcRoyalty?: boolean, recalcRoyaltyHovedKontrakt?: number) {
    var snackBarRef = this.snackBar.open('Gemmer status');

    this.royaltyAfregningAktuelDetailViewVm = await this.client.put(this.royaltyTransId, <UpdateRoyaltyTransCommand>
      { id: this.royaltyTransId, godkendt: godkendtInt, afsluttet: afsluttetInt, betaltDato: betaltDato, betaltBelob: betaltBelob, note: note, noteIsPublic: noteIsPublic, recalcRoyalty: recalcRoyalty, recalcRoyaltyHovedKontrakt: recalcRoyaltyHovedKontrakt }).toPromise();

    snackBarRef.dismiss();
    this.snackBar.open('Gemt');
  }

  /*
  @ViewChild('numberInput') numberInput: ElementRef;

  numeric : NumericTextBox = null;
  attachNumericTextBox() {
    if (this.numberInput != undefined && this.numeric == null) {
      console.log("sample:", this.numberInput.nativeElement);

      this.numeric = new NumericTextBox({
        format: '2N',
        value: this.dto.royaltyAmount,
        placeholder: "Betalt beløb "+this.dto.valutaKode,
        floatLabelType: 'Always'
      });
      this.numeric.appendTo(this.numberInput.nativeElement);
    }
  }
  */

  buildForm() {
    var dto = <RoyaltyAfregningAktuelViewDto>this.dto;

    this.hasAgent = (dto.forlagId != dto.afregningId);

    this.valutaKode = this.dto.valutaKode;

    if (this.royaltyNote && this.royaltyNote.length > 0) {
      var currentDateMin = subMonths(new Date(),-3);
      if (this.royaltyNote.find(n => n.dato >= currentDateMin)) {
        this.activateExistingNote();
      }
    }
    else {
      this.existingNoteVisible = false;
    }


    this.formKontrakt = this.fb.group({
      hovedKontrakt: [dto.hovedKontrakt],
      arbTitel: [dto.arbTitel],
      bogTitel: [dto.bogTitel],
      forfatterNavn: [dto.forfatterNavn]
    });

    var forlagAdresse = <string>
      (dto.forlagNavn.trim() + '\n' +
        dto.forlagAdresse1.trim() + '\n' +
        dto.forlagAdresse2.trim() + '\n' +
        dto.forlagAdresse3.trim() + '\n' +
        dto.forlagLand.trim() + '\n').replace("\n\n", "\n");

    this.formForlag = this.fb.group({
      forlagId: [dto.forlagId],
      forlagAdresse: [forlagAdresse],
      forlagEmail: [dto.forlagEmail]
    });

    this.formAgent = this.fb.group({
      afregningId: [dto.afregningId],
      afregningAdresse: [dto.afregningAdresse],
      afregningEmail: [dto.afregningEmail]
    });

    this.periodText = this.formatDate(dto.start) + ' - ' + this.formatDate(dto.slut);
    var endeligSalgsprisText = this.formatDecimal(dto.endeligSalgspris);
    var valutaKursText = this.formatDecimal(dto.valutaKurs);
    var royaltyAmountText = this.formatDecimal(dto.royaltyAmount);
    var createdDateText = this.formatDateTime(dto.createdDate);

    this.formRoyalty = this.fb.group({
      royaltyId: [dto.id],
      endeligSalgspris: [endeligSalgsprisText],
      valutaKurs: [valutaKursText],
      royaltyAmount: [royaltyAmountText],
      createdDate: [createdDateText]
    });

    this.formNote = this.fb.group({
      note: '',
      noteIsPublic: false
    });

    this.formNote.valueChanges.subscribe(values => {
      this.changeNote(values);
    });

    this.dtoGodkendtStr = (dto.godkendt == null) ? "0" : this.dto.godkendt.toString();
    var godkendtDatoText = this.formatDate(dto.godkendtDato);

    this.formApproval = this.fb.group({
      redaktorNavn: [dto.redaktorNavn],
      godkendt: [this.dtoGodkendtStr],
      godkendtDato: [godkendtDatoText],
      godkendtAf: [dto.godkendtAf]
    });

    this.dtoAfsluttetStr = (dto.afsluttet == null) ? "0" : this.dto.afsluttet.toString();
    var afsluttetDatoText = this.formatDate(dto.afsluttetDato);
    this.betaltBelobText = dto.royaltyAmount <= 0 ? null : this.formatDecimal(dto.royaltyAmount);

    this.formClose = this.fb.group({
      afsluttet: [this.dtoAfsluttetStr, {}],
      afsluttetDato: [afsluttetDatoText],
      afsluttetAf: [dto.afsluttetAf],
      betaltDato: [null, { updateOn: 'blur' }],
      betaltBelob: [this.betaltBelobText, { updateOn: 'blur' }],
      recalcRoyalty: false
    });

    if (dto.afsluttet === RoyaltyTransAfsluttetEnum.Betalt) {
      this.formApproval.disable();
      this.formClose.disable();
    }
    else {
      this.formApproval.valueChanges.subscribe(values => {
        this.changeApproval(values);
      });

      this.formClose.valueChanges.subscribe(values => {
        this.changeClose(values);
      });
    }
    this.validate();

  }

  formatDate(d: Date): string {
    let s: string = '';
    if (d != null) {
      s = this.datePipe.transform(d, 'shortDate');
    }
    return s;
  }

  createDateAsUTC(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  convertDateToUTC(date: Date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  formatDateTime(d: Date): string {
    let s: string = '';
    let dUTC = this.convertDateToUTC(d);
    if (d != null) {
      s = this.datePipe.transform(dUTC, 'shortDate') + ' ' + this.datePipe.transform(dUTC, 'shortTime');
    }
    return s;
  }

  formatDecimal(n: number): string {
    let s: string = '';
    if (n != null) {
      s = this.decimalPipe.transform(n, "1.2-2");
    }
    return s;
  }

  parseDecimal(s: string) {
    let n: number = 0;
    try {
      n = this.parseDecimalHelper(s);
    }
    catch
    {
    }
    return n;
  }

  changeApproval(values: any) {
    var godkendtDato = this.dto.godkendtDato;
    var godkendtAf = this.dto.godkendtAf;

    if (values.godkendt != this.dtoGodkendtStr) {
      this.inNoteApprove = (values.godkendt == String(RoyaltyTransGodkendtEnum.Afvist));

      if (this.inNoteApprove) {
        this.activateNewNote();
      }

      godkendtDato = new Date();
      godkendtAf = this.royaltyAfregningAktuelDetailViewVm.userNameShort;
    }
    else {
      this.inNoteApprove = false;
    }

    this.validate();

    this.formApproval.patchValue({ godkendtDato: this.formatDate(godkendtDato), godkendtAf: godkendtAf }, { emitEvent: false });
  }

  changeNote(values: any) {
    this.validate();
  }

  changeClose(values: any) {

    if (this.inPayment) {
      if (values.betaltBelob != null) {
        let betaltBelobParsed: number = this.parseDecimal(values.betaltBelob);
        let betaltBelobStr: string = this.formatDecimal(betaltBelobParsed);
        if (betaltBelobStr != this.betaltBelobText) {
          this.betaltBelobText = betaltBelobStr;
          this.formClose.patchValue({ betaltBelob: this.betaltBelobText }, { emitEvent: false });
        }
      }
    }

    this.inNoteClose = false;

    var afsluttetDato = this.dto.afsluttetDato;
    var afsluttetAf = this.dto.afsluttetAf;

    if (values.afsluttet != this.dtoAfsluttetStr) {
      this.inPayment = (values.afsluttet == String(RoyaltyTransAfsluttetEnum.Betalt));

      this.inNoteClose = (values.afsluttet == String(RoyaltyTransAfsluttetEnum.Annulleret)) && (this.dto.royaltyAmount > 0);

      afsluttetDato = new Date();
      afsluttetAf = this.royaltyAfregningAktuelDetailViewVm.userNameShort;
    }
    else {
      this.inPayment = false;
    }

    if (values.recalcRoyalty && values.afsluttet == String(RoyaltyTransAfsluttetEnum.RettetTilGodkendelse)) {
      this.inNoteClose = true;
    }

    if (this.inNoteClose) {
      this.activateNewNote();
    }

    this.validate();

    this.formClose.patchValue({ afsluttetDato: this.formatDate(afsluttetDato), afsluttetAf: afsluttetAf }, { emitEvent: false });
  }

  activateNewNote() {
    this.tabGroupSelectedIndex = 4;
  }

  activateExistingNote() {
    this.tabGroupSelectedIndex = 3;
  }


  validate() {
    this.isValid = false;

    const afsluttet = parseInt(this.formClose.get('afsluttet').value);
    this.canRecalc = (parseInt(this.formApproval.get('godkendt').value) == 0 && (afsluttet == 0 || afsluttet == 4 || afsluttet == 5));

    if (!this.canRecalc && this.formClose.get('recalcRoyalty').value == true) {
      this.formClose.patchValue({ recalcRoyalty: false }, { emitEvent: false });
    }

    if ((this.formApproval.get('godkendt').value != this.dtoGodkendtStr) ||
      (this.formClose.get('afsluttet').value != this.dtoAfsluttetStr)) {
      if (this.inPayment) {
        var betaltDato: Date = this.formClose.get('betaltDato').value;
        let betaltBelob: number = this.parseDecimal(this.formClose.get('betaltBelob').value);

        this.isValid = (betaltDato != null && betaltBelob > 0);
      }
      else {
        if ((this.inNoteApprove || this.inNoteClose) && (this.formNote.get('note').value == '')) {
          this.isValid = false;
        }
        else {
          this.isValid = true;
        }
      }
    }
    else if (this.formNote.get('note').value != '') {
      this.isValid = true;
    }
    else if (this.formClose.get('recalcRoyalty').value == true) {
      this.isValid = true;
    }
  }

  getLoadingStyle() {
    return this.isLoading ? 'opacity: 0.0;' : '';
  }

  public async saveAndclose() {
    if (this.isValid) {
      this.isValid = false; // Avoid multiple clicks on save

      var godkendtInt = parseInt(this.formApproval.get('godkendt').value);
      var afsluttetInt = parseInt(this.formClose.get('afsluttet').value);

      let note: string = this.formNote.get('note').value;
      let noteIsPublic: boolean = this.formNote.get('noteIsPublic').value;

      let betaltDato: Date = this.formClose.get('betaltDato').value;
      let betaltBelob: string = this.formClose.get('betaltBelob').value;
      let betaltBelobParsed: number = (betaltBelob == null ? 0.0 : this.parseDecimal(betaltBelob));

      let recalcRoyalty: boolean = this.formClose.get('recalcRoyalty').value;
      let recalcRoyaltyHovedKontrakt: number = parseInt(this.formKontrakt.get('hovedKontrakt').value);

      await this.postWebApi(godkendtInt, afsluttetInt, betaltDato, betaltBelobParsed, note, noteIsPublic, recalcRoyalty, recalcRoyaltyHovedKontrakt);

      this.dialogRef.close({ dto: this.royaltyAfregningAktuelDetailViewVm.royaltyAfregningAktuelViewDto });
    }
  }

  close() {
    this.dialogRef.close();
  }

}
