import { CerAppFormModelAdapter } from "src/platform/models/cer-app-form-model-adapter";

export class ProjectLevelFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    this.viewMetadata = {
      name: 'projekt-niveau', dataApiName: 'ProjectLevel', text: 'Projektniveauer', textSingular: 'Projektniveau',
      primaryKey: 'id', baseFields: ['id','history']
    };

    this.fieldMetadata =
      [
      { name: 'name', text: 'Niveaunavn' }
      ];
  }

}
