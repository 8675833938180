import { BooleanEditCell, Column, IGrid } from "@syncfusion/ej2-angular-grids";
import { FieldMetadata } from "../cer-data/cer-data.service";
import { CheckBox } from "@syncfusion/ej2-angular-buttons";

export class CerGridEditBoolean {
    private parent: any;
    private fieldMeta: FieldMetadata;
    private baseEditCell: BooleanEditCell;
    private baseEditCellAny: any;
    private input: HTMLInputElement;
    private obj: CheckBox;
    private row: any;
    private activeClasses: string[] = ['e-selectionbackground', 'e-active'];
  
    constructor(parent: IGrid, baseEditCell: BooleanEditCell, fieldMeta: FieldMetadata) {
      this.parent = parent;
      this.baseEditCell = baseEditCell;
      this.baseEditCellAny = <any>baseEditCell;
      this.fieldMeta = fieldMeta;
  
      (<any>baseEditCell).create_orig = baseEditCell.create;
      this.baseEditCell.create = this.create.bind(this);
  
      (<any>baseEditCell).destroy_orig = baseEditCell.destroy;
      baseEditCell.destroy = this.destroy.bind(this);
  
      (<any>baseEditCell).write_orig = baseEditCell.write;
      baseEditCell.write = this.write.bind(this);
  
      (<any>baseEditCell).read_orig = baseEditCell.read;
      baseEditCell.read = this.read.bind(this);
    }
  
    public create(args: any): any {
      /* args: { column: Column; data: any; type: string; value: boolean; } // type = Action Type "beginEdit"*/
      if (this.baseEditCellAny.create_orig) {
        return this.baseEditCellAny.create_orig(args);
      }
  
      /*
      var col = args.column;
      var classNames = 'e-field e-boolcell';
      if (col.type === 'checkbox') {
          classNames = 'e-field e-boolcell e-edit-checkselect';
      }
      this.removeEventHandler = this.removeEventListener;
      return createEditElement(this.parent, args.column, classNames, { type: 'checkbox', value: args.value });
      */
    }
  
    public write(args: {
      rowData: Object;
      element: Element;
      column: Column;
      requestType: string;
      row: Element;
    }) {
      if (this.baseEditCellAny.write_orig) {
        var ret = this.baseEditCellAny.write_orig(args);
        this.obj = this.baseEditCellAny.obj;
        //return ret;
      }
      /*
      var selectChkBox;
      var chkState;
      if (args.row) {
        selectChkBox = args.row.querySelector('.e-edit-checkselect');
      }
      if (getObject(args.column.field, args.rowData) != null) {
        chkState = JSON.parse(getObject(args.column.field, args.rowData).toString().toLowerCase());
      }
      if (selectChkBox && args.column.type === 'checkbox') {
        this.baseEditCellAny.editType = this.parent.editSettings.mode;
        this.baseEditCellAny.editRow = args.row;
        if (args.requestType !== 'add') {
          var row$$1 = this.parent.getRowObjectFromUID(args.row.getAttribute('data-uid'));
          chkState = row$$1 ? row$$1.isSelected : false;
        }
        var cells = args.row.getElementsByClassName('e-rowcell');
        addRemoveActiveClasses(cells, this.activeClasses, chkState);
      }
      this.obj = new CheckBox(extend({
        label: this.parent.editSettings.mode !== 'Dialog' ? ' ' : args.column.headerText,
        checked: chkState,
        disabled: !isEditable(args.column, args.requestType, args.element), enableRtl: this.parent.enableRtl,
        cssClass: this.parent.cssClass ? this.parent.cssClass : ''
      }, args.column.edit.params));
      this.addEventListener();
      this.obj.appendTo(<HTMLElement>args.element);
      */
      if (this.fieldMeta) {
        this.addMyEventListener();
      }
    }
  
    public destroy() {
      if (this.baseEditCellAny.destroy_orig) {
        return this.baseEditCellAny.destroy_orig();
      }
      /*
  
        if (this.obj && !this.obj.isDestroyed) {
            if (this.removeEventHandler) {
                this.removeEventHandler();
            }
            this.obj.destroy();
        }
      }
      */
      if (this.fieldMeta) {
        this.removeMyEventListener();
      }
    }
  
    public read(element: Element): boolean {
      return this.obj.checked;
      /*
      if (this.baseEditCellAny.read_orig) {
        return this.baseEditCellAny.read_orig();
      }
      return null;
      //return (<any> element).ej2_instances[0].value;
      */
    }
  
    private onEditChanged(args: any) {
      args.value = args.checked;
      if (this.fieldMeta?.onEditChanged) {
        this.fieldMeta.onEditChanged(args);
      }
    }
    /*
        private checkBoxChange(args : any) {
          if (this.editRow && args.editType !== 'Dialog') {
              var add = false;
              if (!args.checked) {
                  this.editRow.removeAttribute('aria-selected');
              }
              else {
                  add = true;
                  this.editRow.setAttribute('aria-selected', add.toString());
              }
              addRemoveActiveClasses(args.editRow.getElementsByClassName(rowCell), this.activeClasses, add);
          }    
      }
    */
    private addMyEventListener() {
      //this.input.addEventListener("change", this.onEditChanged.bind(this));
      this.obj.addEventListener("change", this.onEditChanged.bind(this));
    };
  
    private removeMyEventListener() {
      //this.input.removeEventListener("change", this.onEditChanged.bind(this));
      this.obj.removeEventListener("change", this.onEditChanged.bind(this));
    }
  }
  
  