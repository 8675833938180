import { ErrorHandler, NgModule } from '@angular/core';
import { CerModule } from 'src/cer/cer.module';

import { CerAppApplicationInsightsService } from './cer-application-insights/cer-app-application-insights.service';
import { CerAppApplicationInsightsErrorHandler } from './cer-application-insights/cer-app-application-insights-error.service';

import { CerAppChatComponent } from './cer-app-chat/cer-app-chat.component';
import { CerAppFileViewerTabComponent } from './cer-app-file-viewer-tab/cer-app-file-viewer-tab.component';

import { CerAppHelpAboutComponent } from './cer-app-help-about/cer-app-help-about.component';
import { CerAppLayoutComponent } from './cer-app-layout/cer-app-layout.component';
import { CerAppLoginMenuComponent } from './cer-app-login-menu/cer-app-login-menu.component';
import { CerAppSideNavComponent } from './cer-app-sidenav/cer-app-sidenav.component';
import { CerAppRouteTitleComponent } from 'src/cer-app/cer-app-route/title/cer-app-route-title.component';

import { CerAppPromptsService } from './cer-app-prompts/cer-apps-prompts-service';
import { CerAppMetaService } from './cer-app-meta/cer-app-meta.service';
import { CerAppMetaUserService } from './cer-app-meta/cer-app-meta-user.service';
import { CerAppRouteService } from './cer-app-route/cer-app-route.service';

import { CerAppFormGridComponent } from './cer-app-form-models/cer-app-form-grid/cer-app-form-grid.component';
import { CerAppFormGridDialogComponent } from './cer-app-form-models/cer-app-form-grid-dialog/cer-app-form-grid-dialog.component';
import { CerAppFormGridDetailComponent } from './cer-app-form-models/cer-app-form-grid-detail/cer-app-form-grid-detail.component';

import { CerAppDialogService } from './cer-app-dialog/cer-app-dialog.service';

import { AppStateService } from 'src/app-core/app-state/app-state.service';

var cerAppDeclarations = [
  CerAppChatComponent,
  CerAppFileViewerTabComponent,
  CerAppHelpAboutComponent,
  CerAppLayoutComponent,
  CerAppLoginMenuComponent,
  CerAppSideNavComponent,
  CerAppRouteTitleComponent,
  CerAppFormGridComponent,
  CerAppFormGridDialogComponent,
  CerAppFormGridDetailComponent
]


var cerAppImports = [
  CerModule
];

var cerAppProviders = [
  CerAppDialogService,
  CerAppMetaService,
  CerAppMetaUserService,
  CerAppRouteService,
  CerAppPromptsService,
  AppStateService,
  CerAppApplicationInsightsService,
  { provide: ErrorHandler, useClass: CerAppApplicationInsightsErrorHandler },
];


var cerAppExports = [].concat(cerAppDeclarations).concat(cerAppImports);

@NgModule({
  declarations: cerAppDeclarations,
  imports: cerAppImports,
  providers: cerAppProviders,
  exports: cerAppExports
})
export class CerAppModule { }
