// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

// Cer
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerAppChatService } from 'src/cer-app/cer-app-chat/cer-app-chat.service';
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum } from 'src/cer/cer-grid/cer-grid-command.service';

// WebApi
import {
  VoucherMessageMailClient,
  VoucherMessageMailSyncCommand,
  VoucherMessageViewClient,
  VoucherViewClient,
  VoucherMessageViewDetailsClient,
  VoucherMessageViewDetailsVm as DetailsVm,
  VoucherMessageViewDetailsVm,
  VoucherMessageViewDto as MessageDto,
  VoucherViewDto as VoucherDto,
  LinkedAttachmentDto,
  ChatDto,
  VoucherMessageStatusAllEnum,
  VoucherMessageChannelTypeEnum,
  VoucherApprovalStatusAllEnum,
  VoucherMessageStatusEnum,
  VoucherApprovalStatusEnum,
  VoucherMessageCreateCommand
} from "../api";

import { MatDialogConfig } from '@angular/material/dialog';
import { VoucherDetailComponent } from './voucher-detail/voucher-detail.component';
import { CerAppDialogService, DialogResult } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';

export class VoucherUpdateResult {
  public ok: boolean;
  public messageDtoOrig?: MessageDto;
  public messageDtoUpdated?: MessageDto;
  public voucherDtoUpdated?: VoucherDto;
  public statusChange?: boolean;
  public attachmentChange?: boolean;
  public editChange?: boolean;
  public error?: string;
}

@Injectable(
)

export class VoucherDataService implements OnDestroy {
  // State
  public setupComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public detailsVm$: BehaviorSubject<VoucherMessageViewDetailsVm> = new BehaviorSubject<VoucherMessageViewDetailsVm>(null);
  public detailsVmMap = new Map<number, DetailsVm>();

  // Options
  public filterChannelType: VoucherMessageChannelTypeEnum = VoucherMessageChannelTypeEnum.VendorVoucher;
  public filterMessageStatus: VoucherMessageStatusAllEnum = VoucherMessageStatusAllEnum.All;
  public filterApprovalStatus: VoucherApprovalStatusAllEnum = VoucherApprovalStatusAllEnum.All;
  public filterMyVouchers: boolean = false;
  public userIsAdmin: boolean = false;
  public isExpenseVoucher: boolean = false;
  public isVendorVoucher: boolean = false;
  public isManualVoucher: boolean = false;

  constructor(
    private routeService: CerAppRouteService,
    private chatService: CerAppChatService,
    private dialog: CerDialogService,
    private appDialog: CerAppDialogService,
    private voucherMessageMailClient: VoucherMessageMailClient,
    private voucherMessageViewClient: VoucherMessageViewClient,
    private voucherViewClient: VoucherViewClient,
    private messageViewDetailsClient: VoucherMessageViewDetailsClient
  ) {
  }

  public init() {
    this.routeService.isLoading$.next(true);
    this.manage(this.chatService.chats$.subscribe(chat => VoucherDataService.detailsVmMapRefreshChat(this.detailsVmMap, this.chatService, chat)));
    this.manage(this.routeService.routeData$.subscribe(data => this.setupFromRouteData(data)));
  }

  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  private routeDataComplete: boolean = false;
  private setupFromRouteData(routeData: any) {
    if (routeData) {
      this.filterChannelType = routeData?.channelType;
      this.isVendorVoucher = this.filterChannelType == VoucherMessageChannelTypeEnum.VendorVoucher;
      this.isExpenseVoucher = this.filterChannelType == VoucherMessageChannelTypeEnum.ExpenseVoucher;
      this.isManualVoucher = this.filterChannelType == VoucherMessageChannelTypeEnum.ManualVoucher;

      this.filterMessageStatus = routeData['messageStatus'];
      this.filterApprovalStatus = routeData['approvalStatus'];
      this.filterMyVouchers = routeData['myApprovals'];
      this.userIsAdmin = !this.filterMyVouchers;
      this.routeDataComplete = true;
      this.setupComplete();
    }
  }

  public setupComplete() {
    if (this.routeDataComplete) {
      this.setupComplete$.next(true);
      this.routeService.isLoading$.next(false);
    }
  }

  // Grid
  public initGrid(grid: CerGridComponent) {
    grid.commmand.subscribe(e => this.onCommand(e));
  }

  // Events
  private onCommand(e: UiCommandEvent): void {
    if (e.source == UiCommandSourceEnum.ActionComplete
     && e.actionType == UiActionTypeEnum.DataBound) {
      this.detailsVmReset();
    }
  }

  // DetailsVm
  public detailsVmRefresh(messageId: number = null) {
    if (!messageId) {
      messageId = this.detailsVm$.value?.voucherMessageViewDto?.id;
    }
    if (messageId) {
      this.detailsVmMap.delete(messageId);
      this.detailsVmGet(messageId);
    }
  }

  public detailsVmMessageDtoUpdate(messageDto: MessageDto) {
    if (messageDto?.id) {
      var vm = this.detailsVmMap.get(messageDto.id);
      if (vm) {
        vm.voucherMessageViewDto = messageDto;
        this.detailsVm$.next(vm);
      }
    }
  }

  public detailsVmReset() {
    this.detailsVmMap.clear();
    this.detailsVm$.next(null);
  }

  public detailsVmGetFromMap(messageId: number) {
    this.detailsVmGetFromMapPromise(messageId).then();
  }

  public detailsVmGetFromMapPromise(messageId: number, _this: VoucherDataService = this): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve, reject) => {
      if (messageId) {
        if (_this.detailsVmMap.has(messageId)) {
          var vm: DetailsVm = _this.detailsVmMap.get(messageId)
          _this.detailsVm$.next(vm);
          resolve(vm);
        }
        else {
          this.detailsVmGetPromise(messageId, null, _this)
            .then(vm => resolve(vm))
            .catch(error => reject(error));
        }
      }
      else {
        reject('Mangler id ved hentning af detaljer');
      }
    });
  }

  public detailsVmGet(messageId: number) {
    this.detailsVmGetPromise(messageId, null).then();
  }

  public detailsVmGetByVoucherNum(voucherNum: number) {
    this.detailsVmGetPromise(null, voucherNum).then();
  }

  public messageDtoGetByMsgId(messageId: number): Promise<MessageDto> {
    return new Promise<MessageDto>((resolve, reject) => {
      this.detailsVmGetFromMapPromise(messageId).then(vm => resolve(vm?.voucherMessageViewDto));
    });
  }

  public detailsVmGetPromise(messageId: number, voucherNum: number, _this: VoucherDataService = this): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve, reject) => {
      if (messageId || voucherNum) {
        (voucherNum ? _this.messageViewDetailsClient.getByVoucherNum(voucherNum)
          : _this.messageViewDetailsClient.get(messageId))
          .subscribe({
            next: detailsVm => {
              messageId = detailsVm?.voucherMessageViewDto?.id;
              if (messageId) {
                _this.detailsVmMap.set(messageId, detailsVm);
                _this.detailsVm$.next(detailsVm);

                //var voucherDto = detailsVm?.voucherViewDtoList?.length > 0 ? detailsVm.voucherViewDtoList[0] : null;
                //console.log('DetailsVm hentet', detailsVm?.voucherMessageViewDto.id, voucherDto?.id, voucherDto?.chat?.length, detailsVm?.chatDtoList?.length);
              }
              else {
                _this.dialog.dialogError('Besked ikke fundet', 'Besked ikke fundet');
              }
              resolve(detailsVm);
            },
            error: error => {
              var num: number = messageId ?? voucherNum;
              _this.dialog.snackBarError('Fejl ved hentning af detaljer ' + num);
              reject('Fejl ved hentning af detaljer ' + num + ' ' + error);
            }
          });
      }
      else {
        reject('Mangler id eller bilagsnr. ved hentning af detaljer');
      }
    });
  }

  // DetailsVM (Attachment)
  public detailsVmAttachmentList(): LinkedAttachmentDto[] {
    var detailsVm: DetailsVm = this.detailsVm$.getValue();
    return detailsVm?.voucherMessageAttachmentDtoList;
  }

  // DetailsVm (Chat)
  public detailsVmMapRefreshFromChat(chatPostedList: ChatDto[]): boolean {
    return VoucherDataService.detailsVmMapRefreshChat(this.detailsVmMap, this.chatService, chatPostedList);
  }

  public static detailsVmMapRefreshChat(detailsVmMap: Map<number, DetailsVm>, chatService: CerAppChatService, chatPostedList: ChatDto[]): boolean {
    var refreshed: boolean = false;
    chatPostedList.forEach(chatPosted => {
      if (chatPosted?.refRowId && chatPosted?.refTableId) {
        if (detailsVmMap.has(chatPosted.refRowId)) {
          var detailsVm: DetailsVm = detailsVmMap.get(chatPosted.refRowId)
          if (VoucherDataService.detailsVmRefreshChat(detailsVm, chatService, chatPosted)) {
            refreshed = true;
          }
        }
      }
    });
    return refreshed;
  }

  public static detailsVmRefreshChats(detailsVm: DetailsVm, chatService: CerAppChatService, chatsPosted: ChatDto[]): boolean {
    var refreshed: boolean = false;
    chatsPosted.forEach(chatPosted => refreshed = refreshed || this.detailsVmRefreshChat(detailsVm, chatService, chatPosted));
    return refreshed;
  }

  public static detailsVmRefreshChat(detailsVm: DetailsVm, chatService: CerAppChatService, chatPosted: ChatDto): boolean {
    var refreshed: boolean = false;
    if (chatPosted.refRowId && chatPosted.refTableId == 1 && detailsVm && detailsVm.voucherMessageViewDto.id && detailsVm.voucherMessageViewDto.id == chatPosted.refRowId) {
      if (chatService.chatToChatsSync(detailsVm.chatDtoList, chatPosted)) {
        refreshed = true;
      }
    }
    return refreshed;
  }

  public voucherDetailsDialogGetConfig(dto: MessageDto,
    attachmentIsActive: boolean, calledFromVoucher: boolean, editPosting: boolean, attachmentSelectedIdxDefault: number,
    jumpToPostingOnVoucherComplete: boolean, showChat: boolean): MatDialogConfig {
    var data: any = {
      dto: dto,
      editVoucher: calledFromVoucher,
      editPosting: editPosting,
      showChat: showChat,
      attachmentsIsActive: attachmentIsActive,
      attachmentSelectedIdxDefault: attachmentSelectedIdxDefault,
      jumpToPostingOnVoucherComplete: jumpToPostingOnVoucherComplete,
      userIsAdmin: this.userIsAdmin,
      detailsVmMap: this.detailsVmMap
    };
    var config = <MatDialogConfig>{
      width: '98vw', height: '98vh', maxWidth: '98vw', disableClose: true, data: data
    };
    return config;
  }

  public voucherDetailsOpen(messageDto: MessageDto, config: MatDialogConfig, updateResult: VoucherUpdateResult = null): Promise<VoucherUpdateResult> {
    return new Promise<VoucherUpdateResult>((resolve) => {
      if (!updateResult) {
        updateResult = { ok: false, messageDtoOrig: messageDto };
      }
      updateResult.editChange = true;

      config.data.dto = messageDto;
      this.appDialog.showDialogComponent(VoucherDetailComponent, config).then(
        result => {
          updateResult.ok = true; //(result.needRefresh === true);
          resolve(updateResult);
        },
        error => {
          updateResult.ok = false;
          updateResult.error = error;
          this.dialog.snackBarError('Fejl ved dialog visning: ', error);
          resolve(updateResult);
        });
    });
  }

  public apiSetMessageStatus(messageDto: MessageDto, toStatus: VoucherMessageStatusEnum, proformaStatusChange: boolean = false, dialogConfig: MatDialogConfig = null): Promise<VoucherUpdateResult> {
    var _this: VoucherDataService = this;
    return new Promise<VoucherUpdateResult>((resolve, reject) => {
      var updateResult: VoucherUpdateResult = { ok: false, messageDtoOrig: messageDto, statusChange: true };

      if (!messageDto?.id) {
        updateResult.ok = false;
        updateResult.error = 'Ingen meddelelse er valgt';
        reject(updateResult);
      }
      else if (!toStatus || toStatus == messageDto.status) {
        updateResult.ok = false;
        updateResult.error = 'Ingen status ændring angivet';
        reject(updateResult);
      }
      else {
        this.voucherMessageViewClient.setStatus(messageDto.id, toStatus, proformaStatusChange).subscribe({
          next: (messageDtoUpdated: MessageDto) => {
            if (proformaStatusChange) {
              _this.detailsVmGetPromise(messageDto.id, null, _this).then(detailsVm => {
                dialogConfig.data.setMessageStatusVoucherOnClose = true;
                dialogConfig.data.dto = messageDtoUpdated;
                _this.voucherDetailsOpen(messageDtoUpdated, dialogConfig, updateResult).then(result => {
                  resolve(updateResult);
                });
              });
            }
            else {
              this.detailsVmMessageDtoUpdate(messageDtoUpdated);
              updateResult.ok = true;
              updateResult.messageDtoUpdated = messageDtoUpdated;
              resolve(updateResult);
            }
          },
          error: (error) => {
            this.dialog.snackBarError("Kunne ikke opdatere status");
            updateResult.ok = false;
            updateResult.error = error;
            reject(updateResult);
          }
        });
      }
    });
  }

  public apiCreatePostedVoucher(voucherNum: string, transDate: Date, txt: string): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      var cmd: VoucherMessageCreateCommand = new VoucherMessageCreateCommand();
      cmd.voucherNum = voucherNum;
      cmd.channelType = this.filterChannelType;
      cmd.messageStatus = VoucherMessageStatusEnum.Voucher;
      cmd.voucherStatus = VoucherApprovalStatusEnum.Posted;
      cmd.transDate = transDate;
      cmd.description = txt;
      this.voucherMessageViewClient.messageCreate(cmd).subscribe({
        next: messageId => {
          this.detailsVmGetFromMap(messageId);
          this.dialog.showInfo('Bilaget er oprettet.');
          resolve(messageId);
        },
        error: error => {
          this.appDialog.handleApiError(error);
          reject(0);
        }
      });
    });
  }

  public apiSetApprovalStatus(voucherIdList: number[], approvalStatus: VoucherApprovalStatusEnum): Promise<VoucherUpdateResult> {
    return new Promise<VoucherUpdateResult>((resolve, reject) => {
      var updateResult = new VoucherUpdateResult();
      if (voucherIdList?.length > 0) {
        this.voucherViewClient.setApprovalStatus(approvalStatus, voucherIdList).subscribe({
          next: (voucherDtoUpdated: VoucherDto) => {
            updateResult.ok = true;
            updateResult.voucherDtoUpdated = voucherDtoUpdated;
            resolve(updateResult);
          },
          error: (error) => {
            this.appDialog.handleApiError(error);
            updateResult.ok = false;
            resolve(updateResult);
          }
        });
      }
      else {
        updateResult.ok = false;
        updateResult.error = 'Ingen bilag valgt';
        this.dialog.snackBarError(updateResult.error);
        reject(updateResult);
      }
    });
  }

  public apiVoucherChannelSync(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      if (this.userIsAdmin) {
        var snackBarRef = this.dialog.snackBar('Synkroniserer indbakker', 'Vent venligst...', undefined);
        this.routeService.isLoading$.next(true);
        var cmd = <VoucherMessageMailSyncCommand>{ allVoucherChannels: true, channelType: this.filterChannelType };
        this.voucherMessageMailClient.post(cmd).subscribe({
          next: (result) => {
            snackBarRef.dismiss();
            this.dialog.snackBar(result == 0 ? 'Ingen nye beskeder hentet' : 'Antal beskeder hentet: ' + result);
            this.routeService.isLoading$.next(false);
            resolve(result);
          },
          error: (error) => {
            this.dialog.snackBarDismiss();
            this.routeService.isLoading$.next(false);
            this.dialog.snackBarError('Kan ikke hente beskeder');
            this.appDialog.handleApiError(error);
            resolve(0);
          }
        });
      }
      else {
        this.dialog.snackBarError('Bruger har ikke ret til at hente beskeder');
        reject(0);
      }
    });
  }
}
