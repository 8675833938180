import { FieldFormatEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class LogisticsOrderProfileFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'logistik-ordre-profiler', dataApiName: 'LogisticsOrderProfile', text: 'Ordreprofil', textSingular: 'Profil',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'warehouse', text: 'Lagersted', foreignKeyTableName: 'LogisticsWarehouse', foreignKeyField: 'num' },
      { name: 'inboundOrderCreate', text: 'Opret indgående ordre', format: FieldFormatEnum.CheckBox },
      { name: 'outboundOrderCreate', text: 'Opret udgående ordre', format: FieldFormatEnum.CheckBox },
      { name: 'outboundOrderType', text: 'Ordretype udgående' },
      { name: 'outboundOrderGroup', text: 'Ordregruppe udgående' },
      { name: 'outboundHandlingType', text: 'Ekspeditionstype udgående' },
      { name: 'outboundCarrierType', text: 'Fragttype udgående' },
      { name: 'outboundPriority', text: 'Prioritet udgående' },
      { name: 'outboundPrintDeliveyNote', text: 'Udskriv følgeseddel', format: FieldFormatEnum.CheckBox },
      { name: 'outboundPrintInvoice', text: 'Udskriv faktura', format: FieldFormatEnum.CheckBox },
      { name: 'outboundPrintFooter', text: 'Faktura fodtekst' }
    ];
   
  }
}
