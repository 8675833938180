import { CerAppFormModelAdapter } from 'src/platform/models/cer-app-form-model-adapter';
import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class VoucherMessageChannelFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    // View
    this.viewMetadata = {
      name: 'mailkanaler', dataApiName: 'VoucherMessageChannel', text: 'Mail indgående kanaler', textSingular: 'Kanal',
      primaryKey: 'id', baseFields: ['id', 'name', 'history']
    };
    // Fields
    this.fieldMetadata =
      [
        { name: 'type', text: 'Type', tooltipText: 'Type af kanal', required: true, foreignKeyTableName: 'VoucherMessageChannelType' },
        { name: 'formRecognizer', text: 'Fast dokument genkendelse', tooltipText: 'Anvendes til altid til ', foreignKeyTableName: 'FormRecognizer' },
        { name: 'voucherStatus', text: 'Bilagsstatus', tooltipText: 'Standard bilagsstatus for bilag', allowEdit: true, widthType: FieldWidthEnum.M, foreignKeyTableName: 'VoucherApprovalStatus' },
        { name: 'ledgerJournalName', text: 'Finanskladde', tooltipText: 'Finanskladde som bilag placeres i', foreignKeyTableName: 'FinanceLedgerJournalName', foreignKeyField: 'num' }
      ];
  }
}
