import { Injectable, OnDestroy } from '@angular/core';
import { Route, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';

import { CerAppRouteService, RouteCount } from 'src/cer-app/cer-app-route/cer-app-route.service';

import { UserStatusVm, VoucherApprovalStatusAllEnum, VoucherApprovalStatusEnum, VoucherMessageChannelTypeEnum, VoucherMessageStatusAllEnum, VoucherMessageStatusEnum } from '../api';
import { UserSessionService } from 'src/platform/app-user/user-session-service';

@Injectable()
export class VoucherSidenavStateService implements OnDestroy {
  private subscriptionManager$: Subscription = new Subscription();
  private userId: number;
  private userStatusVm: UserStatusVm;

  constructor(private router: Router, private userSessionService: UserSessionService, private routeService: CerAppRouteService) {
    this.subscriptionManager$.add(
      combineLatest([this.userSessionService.user$, this.userSessionService.userStatusVm$]).subscribe(
        ([appUser, vm]) => {
          this.userId = appUser?.dto?.id;
          this.userStatusVm = vm;
          this.userCountRead();
        })
    );
  }

  ngOnDestroy() {
    this.subscriptionManager$.unsubscribe();
  }

  private userCountRead() {
    if (this.userId && this.userStatusVm) {
      var routeCounts: RouteCount[] = [];

      this.router.config.forEach(r => { this.countByRoute(routeCounts, r) });

      // My voucher (any type) Sum fields
      routeCounts.push(addNodeId('voucher-my', this.voucherApprovalStatusCount(null, VoucherApprovalStatusAllEnum.Pending, true)));

      // Vendor Voucher Sum fields
      var channelType: VoucherMessageChannelTypeEnum = VoucherMessageChannelTypeEnum.VendorVoucher;
      var count: number = this.voucherApprovalStatusCount(channelType, VoucherApprovalStatusAllEnum.Approved, false) + this.voucherApprovalStatusCount(channelType, VoucherApprovalStatusAllEnum.Created, false);
      var countMsg: number = this.voucherMessageStatusCount(channelType, VoucherMessageStatusAllEnum.Inbox) +
        this.voucherMessageStatusCount(channelType, VoucherMessageStatusAllEnum.OnHold);
      routeCounts.push(addNodeId('voucher-vendor-voucher', count));
      routeCounts.push(addNodeId('voucher-vendor-message', countMsg));
      routeCounts.push(addNodeId('voucher-vendor', count + countMsg));

      // Expense voucher Sum fields
      channelType = VoucherMessageChannelTypeEnum.ExpenseVoucher;
      count = this.voucherApprovalStatusCount(channelType, VoucherApprovalStatusAllEnum.Approved, false) + this.voucherApprovalStatusCount(channelType, VoucherApprovalStatusAllEnum.Created, false);
      countMsg = this.voucherMessageStatusCount(channelType, VoucherMessageStatusAllEnum.Inbox) + this.voucherMessageStatusCount(channelType, VoucherMessageStatusAllEnum.OnHold);
      routeCounts.push(addNodeId('voucher-expense-voucher', count));
      routeCounts.push(addNodeId('voucher-expense-message', countMsg));
      routeCounts.push(addNodeId('voucher-expense', count + countMsg));

      this.routeService.routeCounts$.next(routeCounts);
    }
  }

  private countByRoute(routeCounts: RouteCount[], r: Route) {
    if (r.path && r.data) {
      let data: any = r.data;
      if (data) {
        let count: number = this.voucherApprovalStatusCount(data.channelType, data.approvalStatus, data.myApprovals ? true : false);
        routeCounts.push(addNodeId(r.path, count));
      }
      if (data.messageStatus != null) {
        let count: number = this.voucherMessageStatusCount(data.channelType, data.messageStatus);
        routeCounts.push(addNodeId(r.path, count));
      }
    }
    if (r.children) {
      r.children.forEach(r => { this.countByRoute(routeCounts, r) });
    }
  }

  private voucherApprovalStatusCount(channelType: VoucherMessageChannelTypeEnum, approvalStatusAll: VoucherApprovalStatusAllEnum, my: boolean): number {
    var approvalStatus: VoucherApprovalStatusEnum = approvalStatusAll == VoucherApprovalStatusAllEnum.All ? null : <VoucherApprovalStatusEnum>(<number>approvalStatusAll);
    var count: number = null;
    if (my) {
      if (this.userId) {
        this.userStatusVm.voucherStatusCountByUserList.filter(v => v.approver == this.userId && v.status == approvalStatus).forEach(v => count == null ? count = v.count : count += v.count);
      }
    }
    else if (channelType != null) {
      this.userStatusVm.voucherStatusCountByChannelTypeList.filter(v => channelType == v.channelType && (approvalStatusAll == VoucherApprovalStatusAllEnum.All || approvalStatus == v.status)).forEach(v => count == null ? count = v.count : count += v.count);
    }
    return count;
  }

  private voucherMessageStatusCount(channelType: VoucherMessageChannelTypeEnum, messageStatusAll: VoucherMessageStatusAllEnum): number {
    var messageStatus: VoucherMessageStatusEnum = (messageStatusAll == VoucherMessageStatusAllEnum.All ? null : <VoucherMessageStatusEnum>(<number>messageStatusAll));

    var count: number = null;
    this.userStatusVm.messageStatusCountByChannelTypeList.filter(m => channelType == m.channelType && (messageStatus == m.status || messageStatusAll == VoucherMessageStatusAllEnum.All)).forEach(m => count == null ? count = m.count : count += m.count);
    return count;
  }
}

function addNodeId(nodeId: string, count: number): RouteCount {
  return <RouteCount>{ nodeId: nodeId, url: null, count: count };
}

function addUrl(url: string, count: number): RouteCount {
  return <RouteCount>{ nodeId: null, url: url, count: count };
}
