<cer-form #form>
  <ng-template #mainTemplate>
    <cer-grid
      #grid
      [viewMetadata]="viewMetadata"
      [fieldMetadata]="fieldMetadata"
      [toolbarCommands]="toolbarCommands"
      [contextMenuCommands]="contextMenuCommands"
      [keyboardShortcuts]="keyboardShortcuts"
      selectionMode="checkboxMulti"
      selectionInitial="none"
    ></cer-grid>
  </ng-template>
</cer-form>
