export function debounce(func: any, that: any, delay: number) {
    let debounceTimer : any = 0;
    return function () {
        const context = that;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }  
}
  

/*
export function debounce<F extends Function>(func:F, wait:number):F {
  let timeoutID:number;

  if (!Number.isInteger(wait)) {
    console.warn("Called debounce without a valid number")
    wait = 300;
  }

  // conversion through any necessary as it wont satisfy criteria otherwise
  return <any>function(this:any, ...args: any[]) {
      clearTimeout(timeoutID);
      const context = this;

      timeoutID = window.setTimeout(function() {
		    func.apply(context, args);
      }, wait);
   };
  }
*/