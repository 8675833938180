import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { EnvironmentDto } from '../../app/api';
import { clientVersion } from 'src/environments/client-version';

@Injectable({
  providedIn: 'root'
})
export class CerAppMetaService implements OnDestroy, OnInit {
  private subscriptionManager$: Subscription = new Subscription();

  public primaryColor$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public title$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public licenseIssuedTo$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public name$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public appVersion$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public clientVersion$: BehaviorSubject<string> = new BehaviorSubject<string>(clientVersion);
  public sessionIdleMinutes$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public sessionKeepaliveMinutes$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  public readEnvironment(environment : EnvironmentDto) {
    var e: EnvironmentDto = environment;
    if (e) {
      if (e.title != this.title$.getValue()) {
        this.title$.next(e.title);
      }

      if (e.licenseIssuedTo != this.licenseIssuedTo$.getValue()) {
        this.licenseIssuedTo$.next(e.licenseIssuedTo);
      }
      
      if (e.name != this.name$.getValue()) {
        this.name$.next(e.name);
      }

      if (e.version != this.appVersion$.getValue()) {
        this.appVersion$.next(e.version);
      }

      if (e.primaryColor != this.primaryColor$.getValue()) {
        this.primaryColor$.next(e.primaryColor);
      }

      if (e.sessionIdleMinutes != this.sessionIdleMinutes$.getValue()) {
        this.sessionIdleMinutes$.next(e.sessionIdleMinutes);
      }

      if (e.sessionKeepaliveMinutes != this.sessionKeepaliveMinutes$.getValue()) {
        this.sessionKeepaliveMinutes$.next(e.sessionKeepaliveMinutes);
      }
    }
  }
}
