import { NgModule } from '@angular/core';
import {
  GridModule, PagerModule, PageService, EditService, SortService, GroupService, FilterService, SelectionService,
  ContextMenuService, ColumnChooserService, ResizeService, ReorderService,
  ExcelExportService, PdfExportService, CommandColumnService, AggregateService, VirtualScrollService, SearchService
} from '@syncfusion/ej2-angular-grids';
import { AutoCompleteModule, DropDownListModule, ListBoxAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { SplitterModule } from '@syncfusion/ej2-angular-layouts';
import { TextBoxModule, NumericTextBoxModule, UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';

import { L10n, loadCldr, enableRipple, Internationalization, setCurrencyCode, setCulture } from '@syncfusion/ej2-base';
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('ORg4AjUWIQA/Gnt2VlhhQlVEfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9Td0JjXn1fcHRXRGRe');

import EJ2_LOCALE from './syncfusion.da.json';

L10n.load({ da: EJ2_LOCALE.da });

setCurrencyCode("DKK"); 
setCulture("da");

enableRipple(true);


import cldrNumberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import cldrDaCaGregorian from 'cldr-data/main/da/ca-gregorian.json';
import cldrDaTimeZoneNames from 'cldr-data/main/da/timeZoneNames.json';
import cldrDaNumbers from 'cldr-data/main/da/numbers.json';
import cldrSupplementalWeekdata from 'cldr-data/supplemental/weekdata.json'; // To load the culture based first day of week
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';

loadCldr(
  cldrNumberingSystems,
  cldrDaCaGregorian,
  cldrDaTimeZoneNames,
  cldrDaNumbers,
  cldrSupplementalWeekdata
);

var syncFusionImports =
  [
    AutoCompleteModule, DatePickerModule, DateTimePickerModule, DialogModule, DropDownListModule, GridModule, ListBoxAllModule, ListViewModule, PagerModule,
    CheckBoxModule, TextBoxModule, NumericTextBoxModule, SplitterModule, ToolbarModule, TreeViewModule, UploaderModule, RichTextEditorAllModule
  ];

var syncFusionProviders = [
  PageService, EditService, SortService, SearchService, GroupService, FilterService, SelectionService, ColumnChooserService,
  ResizeService, ReorderService, ContextMenuService, ExcelExportService, PdfExportService, CommandColumnService, AggregateService,
  VirtualScrollService
];

@NgModule({
  declarations: [],
  imports: syncFusionImports,
  providers: syncFusionProviders,
  exports: syncFusionImports
})
export class SyncFusionModule { }
