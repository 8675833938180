export enum RoyaltyTransGodkendtEnum
{
    Ingen = 0,
    Godkendt = 1,
    Afvist = 2,
    GodkendtOgLukkes = 3
}

export enum RoyaltyTransAfsluttetEnum
{
    Ingen = 0,
    Lukket = 1,
    Annulleret = 2,
    Betalt = 3,
    RettetTilGodkendelse = 4
}
