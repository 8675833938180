import { FieldFormatEnum } from '../../cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class BankAccountSourceFormAdapter extends CerAppFormModelAdapter {
  
  public override init() {

    this.viewMetadata = {
      name: 'bankkonto-transaktion-kilder', dataApiName: 'BankAccountSource', text: 'Kilder',
      primaryKey: 'id', allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['id','history']
    };

    this.fieldMetadata =
      [
      { name: 'bankAccount', text: 'Bankkonto', foreignKeyTableName: 'BankAccount' },
      { name: 'source', text: 'Transaktionskilde', foreignKeyTableName: 'BankAccountTransactionSource' },
      { name: 'address', text: 'Data sti' },
      { name: 'active', text: 'Aktiv', format: FieldFormatEnum.CheckBox },
      ];
  }

}
