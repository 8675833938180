import { Component, ViewChild } from '@angular/core';
import { AppStateService } from 'src/app-core/app-state/app-state.service';
import { FieldFormatEnum, FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../../cer/cer-data/cer-data.service';
import { CerFormComponent } from '../../../cer/cer-form/cer-form.component';
import { CerGridComponent } from '../../../cer/cer-grid/cer-grid.component';
import { UiCommandEvent, UiCommand, UiKeyboardShortcut } from '../../../cer/cer-grid/cer-grid-command.service';
import { RightsPeriodClient as Client } from '../../api';

const uiCmdRightsPeriodSync: UiCommand = { id: 'RightsPeriodSync', text: 'Synkroniser periode', iconCss: "e-menu-icon e-icons e-repeat", tooltipText: 'Synkroniser periode og dan godkendelser', target: ".e-content", needSelection: true };


@Component({
  selector: 'app-rights-period',
  templateUrl: './rights-period.component.html',
  styleUrls: ['./rights-period.component.css']
})

export class RightsPeriodComponent {

  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  grid: CerGridComponent;
  @ViewChild('grid', { static: false }) set gridContent(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
      this.grid.commmand.subscribe(e => this.onCommand(e));
    }
  }

  public viewMetadata: ViewMetadata = {
    name: 'rettigheder-periode', dataApiName: 'RightsPeriod', text: 'Perioder',
    primaryKey: 'id', allowEdit: true, allowCreate: true, allowDelete: true
  };

  public fieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false },
    { name: 'name', text: 'Navn', tooltipText: 'Navn på periode', widthType: FieldWidthEnum.L },
    { name: 'path', text: 'Folder', tooltipText: 'Folder for periode på fil-system', widthType: FieldWidthEnum.M },
    { name: 'active', text: 'Aktiv', tooltipText: 'Er perioden aktiv?', format: FieldFormatEnum.CheckBox }
    ];

  public toolbarCommands: UiCommand[] = [uiCmdRightsPeriodSync];
  public contextMenuCommands: UiCommand[] = [uiCmdRightsPeriodSync];
  public keyboardShortcuts: UiKeyboardShortcut[] = [{ cmd: uiCmdRightsPeriodSync, code: 'KeyS', alt: false, ctrl: true, shift: true, enabled: true }];

  constructor(private client: Client, private appStateService: AppStateService) {
  }

  public syncPeriod() {
    var idList: number[] = this.grid.selection.rowsSelectedId();
    if (idList.length > 0) {
      var id: number = idList[0];
      this.grid.selection.rowSelectedKeep();
      this.client.syncPeriod(id).subscribe(
        data => this.grid.research(),
        error => this.appStateService.handleApiError(error)
      );
    }
  }

  // Commands
  public onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      case uiCmdRightsPeriodSync.id:
        this.syncPeriod();
        break;
    }
  }
}
