import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { FieldFormatEnum, FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class SalesChannelFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'salg-kanaler', dataApiName: 'SalesChannel', text: 'Salgskanaler', textSingular: 'Salgskanal',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'orderType', text: 'Ordretype salg', foreignKeyTableName: 'SalesOrderType', foreignKeyField: 'num' },
      { name: 'orderNumFormat', text: 'Ordrenr. format salg' },
      { name: 'orderNumIntNextInt', text: 'Ordrenr. næste salg', format: FieldFormatEnum.Number },  
      { name: 'orderNumIntNextCompleted', text: 'Ordrenr. seneste afsluttet', format: FieldFormatEnum.Number },  
      { name: 'orderMaintenance', text: 'Adgang salgsordre', foreignKeyTableName: 'DataLogActionType' },
      { name: 'logisticsOrderProfile', text: 'Logistik ordreprofil', foreignKeyTableName: 'LogisticsOrderProfile', foreignKeyField: 'num' },
      { name: 'synced', text: 'Synkoniseret', format: FieldFormatEnum.DateLong }
    ];
   
    this.detailViewMetadata = { name: 'salg-kanal-produkter', dataApiName: 'SalesChannelProduct', text: 'Salgskanal produkter',
       primaryKey: 'id', parentPrimaryKey: 'num', parentForeignKey: 'salesChannel', 
       baseFields: ['id', 'history'], titleFields: ['num', 'product', 'name'] };  
    this.detailFieldMetadata = [
      { name: 'salesChannel', text: 'Salgskanal', foreignKeyTableName: 'SalesChannel', foreignKeyField: 'num', visible: false, widthType: FieldWidthEnum.M },
      { name: 'num', text: 'Varenr.',  tooltipText: 'Varenr. i salgskanalen', required: true, widthType: FieldWidthEnum.S },  
      { name: 'product', text: 'ISBN',  required: true,  /*foreignKeyTableName: 'ProductLegacy', foreignKeyField: 'num' */ widthType: FieldWidthEnum.M}, 
      { name: 'name', text: 'Navn', widthType: FieldWidthEnum.M }, 
      { name: 'convertQtyFactor', text: 'Faktor', tooltipText: 'Omregningsfaktor for antal', defaultValue: 1, format: FieldFormatEnum.Number, required: true, widthType: FieldWidthEnum.XXS },
      { name: 'convertBOM', text: 'Omregning via bogpakke', defaultValue: 1, format: FieldFormatEnum.CheckBox, required: true, widthType: FieldWidthEnum.XXS  }
    ]
  }
}
