import { Component, ViewChild } from '@angular/core';
import { FieldFormatEnum, FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../../cer/cer-data/cer-data.service';
import { CerFormComponent } from '../../../cer/cer-form/cer-form.component';
import { CerGridComponent } from '../../../cer/cer-grid/cer-grid.component';
import { UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum, UiCommand, uiCmdResearch, UiKeyboardShortcut, uiCmdSeparator } from '../../../cer/cer-grid/cer-grid-command.service';
import { debounce } from 'src/cer-platform/cer-util/cer-util';
import { ProductService } from '../product.service';

@Component({
  selector: 'product-legacy',
  templateUrl: './product-legacy.component.html',
  styleUrls: ['./product-legacy.component.css']
})
export class ProductLegacyComponent {

  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  grid: CerGridComponent;
  @ViewChild('grid', { static: false }) set gridContent(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
      this.grid.commmand.subscribe(e => this.onCommand(e));
      this.grid.grid.element.addEventListener('keydown', debounce((e: KeyboardEvent) => this.searchEvent(e), this, 1000));
    }
  }

  public viewMetadata: ViewMetadata = {
    name: 'udgivelser-historisk', dataApiName: 'ContractProjectBasicView', text: 'Udgivelser (historisk)', textSingular: 'Udgivelse (historisk)',
    primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: false
  };

  public fieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', widthType: FieldWidthEnum.XS, visible: false },
    { name: 'isbn', text: 'ISBN', tooltipText: 'ISBN', widthType: FieldWidthEnum.M, filterOperator: 'startswith', orderBy: 'Descending'},
    { name: 'num', text: 'Projekt', tooltipText: 'Projekt id', widthType: FieldWidthEnum.XS },
    { name: 'numMain', text: 'Kontrakt', tooltipText: 'Kontrakt', widthType: FieldWidthEnum.XS, visible: false },
    { name: 'name', text: 'Titel', tooltipText: 'Titel', widthType: FieldWidthEnum.XXL },
    { name: 'nameAlt', text: 'Arb.titel', tooltipText: 'Arbejdstitel (opr. titel)', widthType: FieldWidthEnum.XL, visible: false },
    { name: 'responsible', text: 'Redaktør', tooltipText: 'Redaktørnavn', widthType: FieldWidthEnum.XS, allowSearching: false, foreignKeyTableName: 'Redaktoer', foreignKeyField: 'redaktorInitialer', foreignKeyValue: 'redaktorInitialer' },
    { name: 'type', text: 'Type', tooltipText: 'Udgivelsesformat', widthType: FieldWidthEnum.S, allowSearching: false, foreignKeyTableName: 'Bogtype', foreignKeyField: 'bogtype1', foreignKeyValue: 'bogtype1' },
    { name: 'createdDate', text: 'Oprettet', tooltipText: 'Dato for oprettelse', format: FieldFormatEnum.DateShort, widthType: FieldWidthEnum.S},
    { name: 'startDate', text: 'Udgivet', tooltipText: 'Dato for udgivelse', format: FieldFormatEnum.DateShort, widthType: FieldWidthEnum.S, visible: false }
    ];

  public contextMenuCommands: UiCommand[] = [this.productService.uiCmdSyncSelected];

  constructor(private productService: ProductService) {

  }

  // Commands
  public searchEvent(e: any) {
    var id = e.target?.getAttribute('id');
    if (id && id.indexOf('_searchbar') !== - 1) {
      this.grid.grid.search((e.target as HTMLInputElement).value);
    }
  }

  public onCommand(event: UiCommandEvent) {
    switch (event.source) {
      case UiCommandSourceEnum.ActionBegin:
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.RowSelect:
            //this.rowSelect();
            break;
        }
        break;
      default:
        switch (event.commandId) {
          case this.productService.uiCmdSyncSelected.id:
            this.productService.syncFromProductAllList(this.grid, [event.args.rowData]);
            break;
        }
    }
  }
}
