import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { UiCommandEvent } from '../cer-grid/cer-grid-command.service';

@Injectable({ providedIn: 'root' })
export class CerStepperService implements OnDestroy {

  private subscriptionManager$: Subscription = new Subscription();

  public parentData: BehaviorSubject<object> = new BehaviorSubject<object>(undefined);
  public formCommand$: Subject<UiCommandEvent> = new Subject<UiCommandEvent>();
  public childCommand$: Subject<UiCommandEvent> = new Subject<UiCommandEvent>();


  constructor(private authorizeService: AuthorizeService) {
  }

  public ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }
}
