import { FieldFormatEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class LogisticsWarehouseFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'lagersteder', dataApiName: 'LogisticsWarehouse', text: 'Lagersteder', textSingular: 'Lagersted',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
    ];
   
  }
}
