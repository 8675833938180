import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { IdentityClient, IdentityDto, UserDto, UserRoleVm } from 'src/app/api';
import { CerFormService } from 'src/cer/cer-form/cer-form.service';
import { FieldFormatEnum, FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../../cer/cer-data/cer-data.service';
import { CerGridComponent } from '../../../cer/cer-grid/cer-grid.component';
import { UiActionTypeEnum, UiCommand, UiCommandEvent } from '../../../cer/cer-grid/cer-grid-command.service';
import { AppUser } from '../user-session-service';

@Component({
  selector: 'app-app-user-identity',
  templateUrl: './app-user-identity.component.html',
  styleUrls: ['./app-user-identity.component.css'],
  providers: [CerFormService]
})
export class AppUserIdentityComponent implements OnInit {

  // Identity view
  public viewMetadata: ViewMetadata = {
    name: 'identities', textSingular: 'Login', textPlural: 'Logins',
    primaryKey: 'id', titleFields: ['name'], dialogEdit: false, allowEdit: true, allowCreate: false, allowDelete: false
  };
  // User fields
  public fieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for login', allowEdit: false, widthType: FieldWidthEnum.XL, format: FieldFormatEnum.Text, visible: false },
    { name: 'email', text: 'E-mail', tooltipText: 'Brugers e-mail', allowEdit: false, defaultValue: '', widthType: FieldWidthEnum.L },
    { name: 'emailConfirmed', text: 'Bekræftet', tooltipText: 'Er konto bekræftet?', allowEdit: true, format: FieldFormatEnum.CheckBox },
    { name: 'userShortName', text: 'Brugertilknytning', tooltipText: 'Er konto tilknyttet en bruger?', allowEdit: false, widthType: FieldWidthEnum.L }
    ];

  public toolbarCommands: UiCommand[] = [];
  public contextMenuCommands: UiCommand[] = [];

  public vm: UserRoleVm;

  public dialog: Dialog;
  public dialogId: 'validateStep1' | 'validateStep2';
  public returnConfirmEmail: boolean;
  public returnIdentity: IdentityDto;

  @ViewChild(CerGridComponent, { static: false }) grid: CerGridComponent;

  constructor(private dialogRef: MatDialogRef<AppUser>, @Inject(MAT_DIALOG_DATA) callerData: any,
    private identityClient: IdentityClient) {
    // this.formService.formPersistenceComponents.push(this.grid); (not supported)
    this.vm = callerData.vm;
    this.viewMetadata.data = this.addUserToIdentity(this.vm.identities, this.vm.users, this.vm.user);
  }

  ngOnInit(): void {

  }

  public addUserToIdentity(identities: IdentityDto[], users: UserDto[], user: UserDto): IdentityDto[] {

    // Add linked user short name
    identities.forEach(iDto => {
      var userFound = users.filter(u => u.appUserId == iDto.id);
      var i: any = <any>iDto;
      i.userShortName = (userFound.length > 0) ? userFound[0].shortName : '';
    });
    // Sort by users current identity first

    var hasUserLogin = (user.appUserId != null && user.appUserId.length > 0);
    var hasUserEmail = (user.email != null && user.email.length > 0);

    identities = identities.sort((i1, i2) => {
      if (hasUserLogin) {
        if (i1.id == user.appUserId) {
          return -1;
        }
        if (i2.id == user.appUserId) {
          return 1;
        }
      }
      if (hasUserEmail) {
        if (i1.email == user.email) {
          return -1;
        }
        if (i2.email == user.email) {
          return 1;
        }
      }
      return ((<any>i1).userShortName > (<any>i2).userShortName ? 1 : (<any>i1).userShortName < (<any>i2).userShortName ? -1 : 0);
    });
    return identities;
  }

  public onCommand(event: UiCommandEvent) {
    if (event.source == 'actionComplete' && event.actionType == UiActionTypeEnum.Save) {
      console.log(event.args);
      var identity: IdentityDto = event.args.rowData;
      this.identityClient.setIdentityConfirmed(identity.id, identity.emailConfirmed).subscribe();
    }
  }

  public alert(msg: string) {
    DialogUtility.alert(msg);
  }

  public confirm(dialogId: any, content: string, title: string = "Bekræft") {
    this.dialogId = dialogId;
    this.dialog = DialogUtility.confirm({
      title: title,
      content: content,
      okButton: { text: 'OK', click: this.confirmOk.bind(this) },
      cancelButton: { text: 'Annuller' },
      showCloseIcon: true,
      closeOnEscape: true
    });
  }

  public confirmOk() {
    this.dialog.hide();
    switch (this.dialogId) {
      case 'validateStep1':
        this.validateStep2();
        break;
      case 'validateStep2':
        this.validateStep3();
        break;
    }
  }

  public validateStep1() {
    var selected = this.grid.rowSelectedRowsData();

    if (selected.length != 1) {
      this.alert("Der skal markeres netop et login");
      return;
    }

    this.returnIdentity = <IdentityDto>selected[0];

    var identity: any = selected[0];
    var linkedUser: string = identity.userShortName;
    if (linkedUser && linkedUser.length > 0 && linkedUser != this.vm.user.shortName) {
      this.confirm("validateStep1", "Login er allerede linket til en anden bruger. Overdrag dette login til brugeren '" + this.vm.user.shortName + "'?");
    }
    else {
      this.validateStep2();
    }
  }

  public validateStep2() {
    if (this.returnIdentity.emailConfirmed == false) {
      this.returnConfirmEmail = true;
      this.confirm("validateStep2", "<p>Login for '" + this.returnIdentity.email + "' er ikke aktiveret til logind. Ønskes logind aktiveret og tilknyttet brugeren?</p><p class='note'>Bemærk: Det kan udgøre en sikkerhedsrisiko, når brugeren ikke har bekræftet logind.</p>");
    }
    else {
      this.returnConfirmEmail = false;
      this.validateStep3();
    }
  }

  public validateStep3() {
    this.close(true, this.returnIdentity.id, this.returnConfirmEmail);
  }

  public saveAndclose() {
    this.validateStep1();
  }

  public close(dataChanged: boolean, identityId: string = null, confirmEmail: boolean = null) {
    this.dialogRef.close({ dataChanged: dataChanged, identityId: identityId, confirmEmail: confirmEmail });
  }

}
