<div *ngIf="orientationVertical | async" #stepper>
  <div>Vertical</div>
  <mat-vertical-stepper [linear]="isLinear"> </mat-vertical-stepper>
</div>
<div *ngIf="!(orientationVertical | async)" #stepper>
  <mat-horizontal-stepper [linear]="isLinear">
    <ng-template *ngFor="let step of stepsByState('completed')" [matStepperIcon]="step.id">
      <mat-icon class="next-step-completed" role="img">{{
        step.icon
        }}</mat-icon>
    </ng-template>
    <ng-template *ngFor="let step of stepsByState('overdue')" [matStepperIcon]="step.id">
      <mat-icon class="next-step-overdue" role="img">{{ step.icon }}</mat-icon>
    </ng-template>
    <ng-template *ngFor="let step of stepsByState('scheduled')" [matStepperIcon]="step.id">
      <mat-icon class="next-step-scheduled" role="img">{{
        step.icon
        }}</mat-icon>
    </ng-template>
    <ng-template *ngFor="let step of stepsByState('open')" [matStepperIcon]="step.id">
      <mat-icon class="next-step-open" role="img">{{ step.icon }}</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="edit" let-index="index">
      <mat-icon role="img">{{ steps[index].icon }}</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="done" let-index="index">
      <mat-icon role="img">{{ steps[index].icon }}</mat-icon>
    </ng-template>
    <mat-step *ngFor="let step of steps" [state]="step.id" completed="false">
      <ng-template matStepLabel>
        <div>
          {{ step.title }}
          <div class="next-step-status-text" [ngClass]="step.cssClass"><mat-icon class="next-step-status-icon">{{ step.statusIcon }}</mat-icon>
            {{ step.statusText }}
          </div>
        </div>
      </ng-template>
      <mat-card>
        <mat-card-title-group>
          <mat-card-title>Content for {{ step.title }}</mat-card-title>
          <mat-card-subtitle>Adresse</mat-card-subtitle>
        </mat-card-title-group>
        <input matInput placeholder="Address" formControlName="secondCtrl" required />
        <input />
      </mat-card>
    </mat-step>
  </mat-horizontal-stepper>
</div>
