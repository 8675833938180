import { FieldFormatEnum, FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class PartyFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'modparter', dataApiName: 'Party', text: 'Modparter', numberSeq: 'Party',
      primaryKey: 'id', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'address', text: 'Adresse', format: FieldFormatEnum.TextArea, widthType: FieldWidthEnum.XXXL },
      { name: 'email', text: 'E-mail', format: FieldFormatEnum.Email},
      { name: 'phone', text: 'Telefon', format: FieldFormatEnum.Phone },
      { name: 'isAuthor', text: 'Forfatter', format: FieldFormatEnum.CheckBox, defaultValue: false },
      { name: 'isPublisher', text: 'Forlag', format: FieldFormatEnum.CheckBox, defaultValue: false },
      { name: 'isAgent', text: 'Agent', format: FieldFormatEnum.CheckBox, defaultValue: false },
      { name: 'legacyId', text: 'Opr. Id', tooltipText: 'Id fra oprindelig database', visible: false, format: FieldFormatEnum.Integer}
    ];
  }
}
