import { NgModule } from '@angular/core';
import { CerAppModule } from 'src/cer-app/cer-app.module';

// Main app
import { AppStateService } from './app-state/app-state.service';
import { AppComponent } from 'src/app-core/app/app.component';
import { AppHomeComponent } from './app-home/app-home.component';

// Standard user tools
import { AppExchangeMsgQueueComponent } from 'src/app-core/app-exchange/app-exchange-msg-queue/app-exchange-msg-queue.component';
import { AppExchangeMsgViewComponent } from 'src/app-core/app-exchange/app-exchange-msg-view/app-exchange-msg-view.component';
import { AppFormRecogizerComponent } from 'src/app-core/app-form-recognizer/app-form-recogizer/app-form-recogizer.component';
import { AppFormRecogizerDocumentComponent } from 'src/app-core/app-form-recognizer/app-form-recognizer-document/app-form-recogizer-document.component';
import { AppMailChannelComponent } from 'src/app-core/app-mail/app-mail-channel/app-mail-channel.component';
import { AppMailTemplateComponent } from 'src/app-core/app-mail/app-mail-template/app-mail-template.component';
import { AppUserComponent } from 'src/platform/app-user/app-user.component';
import { AppUserRoleComponent } from 'src/platform/app-user/app-user-role/app-user-role.component';
import { AppUserIdentityComponent } from 'src/platform/app-user/app-user-identity/app-user-identity.component';

var appCoreDeclarations = [
  AppComponent,
  AppHomeComponent,
  AppExchangeMsgQueueComponent,
  AppExchangeMsgViewComponent,
  AppFormRecogizerComponent,
  AppFormRecogizerDocumentComponent,
  AppMailChannelComponent,
  AppMailTemplateComponent,
  AppUserComponent,
  AppUserRoleComponent,
  AppUserIdentityComponent
]

var appCoreImports = [
  CerAppModule,
]

var appCoreProviders : any[] = [
  AppStateService
]

var appCoreExports = [].concat(appCoreDeclarations).concat(appCoreImports);

@NgModule({
  declarations: appCoreDeclarations,
  imports: appCoreImports,
  providers: appCoreProviders,
  exports: appCoreExports
})
export class AppCoreModule { }
