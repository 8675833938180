import { FieldFormatEnum, FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class PartyRoleFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'kontakt-roller', dataApiName: 'PartyRole', text: 'Kontaktroller',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'isContract', text: 'Kontrakt', tooltipText: 'Kan tilknyttes kontrakter?', format: FieldFormatEnum.CheckBox, defaultValue: false },
      { name: 'isProduct', text: 'Udgivelse', tooltipText: 'Kan tilknyttes udgivelser?', format: FieldFormatEnum.CheckBox, defaultValue: false },
      { name: 'isProductSample', text: 'Udgivelse frieksemplar', tooltipText: 'Anvendes ved frieksemplar?', format: FieldFormatEnum.CheckBox, defaultValue: false }
    ];
  }
}
