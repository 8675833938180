// Angular
import { Component, ViewChild, Inject, HostListener } from '@angular/core';

// Platform
import { CerAppFileViewerTabComponent } from 'src/cer-app/cer-app-file-viewer-tab/cer-app-file-viewer-tab.component';
import { FieldMetadata, FieldWidthEnum, ViewMetadata } from 'src/cer/cer-data/cer-data.service';
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { UiActionTypeEnum, UiCommandSourceEnum, UiCommand, UiKeyboardShortcut, UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';
import { uiCmdPaneRightToggle } from 'src/cer/cer-form/cer-form-panes.service';


// WebApi
import { HovedkontraktViewDto as Dto, LinkedAttachmentDto, HovedkontraktViewClient, ContractViewDetailsVm } from '../../api';
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';
import { AppStateService } from 'src/app-core/app-state/app-state.service';

@Component({
  selector: 'contract-legacy',
  templateUrl: './contract-legacy.component.html',
  styleUrls: ['./contract-legacy.component.css']
})

export class ContractLegacyComponent {
  public dto: Dto = null;
  public attachmentDtoList: LinkedAttachmentDto[] = null;
  public attachmentSelectIndexDefault: number = 0;

  public viewMetadata: ViewMetadata = {
    name: 'kontrakter-historisk', text: 'Kontrakter (historisk)', textSingular: 'Kontrakt (historisk)',
    dataApiName: 'ContractLegacy', dataApiParams: ['$includeDataLinks'], 
    primaryKey: 'id',
    allowEdit: false, allowCreate: false, allowDelete: false,
    titleFields: ['isbn', 'bogtitel']
  };

  public fieldMetadata: FieldMetadata[] =
    [
      { name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false },
      { name: 'hkontraktStr', text: 'Kontrakt', tooltipText: 'Kontraktnr.', widthType: FieldWidthEnum.S, allowEdit: false, filterOperator: 'startswith', orderBy: 'Descending' },
      { name: 'bogtitel', text: 'Titel', tooltipText: 'Titel', widthType: FieldWidthEnum.XL, allowEdit: false },
      { name: 'isbn', text: 'ISBN (primær)', tooltipText: 'ISBN på hovedudgivelse', widthType: FieldWidthEnum.S, allowEdit: false },
      { name: 'redaktorNavn', text: 'Redaktør', tooltipText: 'Redaktørnavn', widthType: FieldWidthEnum.S, allowEdit: false },
      { name: 'gruppeNavn', text: 'Kategori intern', widthType: FieldWidthEnum.S, allowEdit: false },
      { name: 'formaalNavn', text: 'Formål', tooltipText: 'Formål', widthType: FieldWidthEnum.S, allowEdit: false, visible: false },
      { name: 'kontraktType', text: 'Kontrakttype', tooltipText: 'Type af kontrakt', widthType: FieldWidthEnum.S, allowEdit: false },
      { name: 'afregningstypeNavn', text: 'Afregn.type', tooltipText: 'Type for afregning', widthType: FieldWidthEnum.S, allowEdit: false, visible: false },
      { name: 'afregnTerminNavn', text: 'Afregn. termin', tooltipText: 'Termin for afregning', widthType: FieldWidthEnum.S, allowEdit: false, visible: false },
      { name: 'forfatterId', text: 'Forfatter/Opr. forlag ID', tooltipText: 'Forfatter eller opr. forlag', widthType: FieldWidthEnum.S, allowEdit: false },
      { name: 'forfatterNavn', text: 'Forfatter/Opr. forlag navn', tooltipText: 'Navn på forfatter eller opr. forlag', widthType: FieldWidthEnum.M, allowEdit: false },
      { name: 'agent', text: 'Agent ID', tooltipText: 'Agent ID', widthType: FieldWidthEnum.S, allowEdit: false },
      { name: 'agentNavn', text: 'Agent', tooltipText: 'Navn på agent', widthType: FieldWidthEnum.M, allowEdit: false },
      { name: 'oprindeligTitel', text: 'Opr. titel', tooltipText: 'Oprindelig titel', widthType: FieldWidthEnum.M, allowEdit: false },
      { name: 'oprindeligForfatter', text: 'Opr. forfatter', tooltipText: 'Navn på opr. forfatter', widthType: FieldWidthEnum.M, allowEdit: false },
    ];

  private uiCmdDetailToggle = Object.assign(new Object(), uiCmdPaneRightToggle, { text: 'Dokumenter', tooltipText: 'Vis/luk kontraktdokument [Ctrl]+[Pil venste]', toolbarShowTextOn: 'Both' });

  public toolbarCommands: UiCommand[] = [this.uiCmdDetailToggle];
  public contextMenuCommands: UiCommand[] = [];
  public keyboardShortcuts: UiKeyboardShortcut[] = [
    { code: 'KeyV', ctrl: false, alt: true, shift: false, cmd: this.uiCmdDetailToggle },
    { code: 'ArrowLeft', ctrl: true, alt: false, shift: false, cmd: this.uiCmdDetailToggle },
    { code: 'ArrowRight', ctrl: true, alt: false, shift: false, cmd: this.uiCmdDetailToggle }
  ];

  // State
  protected attachmentAccessToken: string = null;

  constructor(private client: HovedkontraktViewClient, private appStateService: AppStateService) {
  }

  form: CerFormComponent;
  @ViewChild(CerFormComponent) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
    }
  }

  grid: CerGridComponent = null;
  @ViewChild(CerGridComponent) set mainGridContent(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
    }
  }

  fileViewerTab: CerAppFileViewerTabComponent;
  @ViewChild('fileViewerTab', { static: false }) set fileViewerTabContent(content: CerAppFileViewerTabComponent) {
    if (content && !this.fileViewerTab) {
      this.fileViewerTab = content;
    }
  }

  // Commands
  protected onCommand(event: UiCommandEvent) {
    switch (event.actionType) {
      case UiActionTypeEnum.RowSelect:
        if (event.source == UiCommandSourceEnum.ActionComplete) {
          this.rowSelect(event.args.data);
        }
        break;
      case UiActionTypeEnum.Reflow:
        this.fileViewerTab.resize();
        break;
    }
  }

  public rowSelect(rowData: any): any {
    var dto: Dto = <Dto>rowData;
    if (dto?.id) {
      if (dto != this.dto) {
        this.client.get2(dto.id).subscribe(
          vm => this.selectLinkedAttachment(vm),
          error => this.appStateService.handleApiError(error)
        );
        this.attachmentDtoList = dto.linkedAttachment;
      }
    }
    else {
      this.selectLinkedAttachment(null);
    }
    this.dto = dto;
  }

  private selectLinkedAttachment(vm: ContractViewDetailsVm): void {
    this.attachmentDtoList = vm?.attachmentDtoList;
  }

}
