import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';
import { FieldFormatEnum, FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class VoucherMessageChannelNextVoucherFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    // View
    this.viewMetadata = {
      name: 'kanal-bilagsnumre', dataApiName: 'VoucherMessageChannelNextVoucher', text: 'Bilagsnumre',
      primaryKey: 'id', titleFields: ['year'], baseFields: ['id', 'history']
    };
    // Fields
    this.fieldMetadata =
      [
        { name: 'channelId', text: 'Kanal', tooltipText: 'Bilagskanal', required: true, allowEdit: true, allowEditOnCreate: true, widthType: FieldWidthEnum.M, foreignKeyField: 'id', foreignKeyValue: 'name', foreignKeyTableName: 'VoucherMessageChannel', orderBy: 'Ascending' },
        { name: 'year', text: 'År', tooltipText: 'År for bilagsserie', required: true, allowEdit: true, allowEditOnCreate: true, format: FieldFormatEnum.Integer, orderBy: 'Descending' },
        { name: 'maskVoucher', text: 'Maske', tooltipText: 'Bilagserie formattering', allowEdit: true, defaultValue: '', allowEditOnCreate: true, widthType: FieldWidthEnum.M, visible: false },
        { name: 'nextVoucher', text: 'Næste nr', tooltipText: 'Næste bilagsnr.', required: true, allowEdit: true, allowEditOnCreate: true, format: FieldFormatEnum.Number },
      ];
  }
}
