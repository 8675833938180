import { Component, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';

import { CerAppRouteService } from '../cer-app-route/cer-app-route.service';
import { CerAppMetaService } from '../cer-app-meta/cer-app-meta.service';
import { CerAppHelpAboutComponent } from '../cer-app-help-about/cer-app-help-about.component';
import { CerAppThemeService } from '../cer-app-theme/cer-app-theme.service';

@Component({
  selector: 'cer-app-layout',
  templateUrl: './cer-app-layout.component.html',
  styleUrls: ['cer-app-layout.component.css']
})
export class CerAppLayoutComponent implements OnDestroy {
  private subscriptionManager$: Subscription = new Subscription();

  @ViewChild('drawer') drawer: MatDrawer;

  public appTitle$: BehaviorSubject<string>;
  public toolbarStyle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public routerOverflowClass: string = '';
  public routerOverflowIgnore: boolean = true;

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 932.00px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private routeService: CerAppRouteService,
    private themeService: CerAppThemeService,
    private metaService: CerAppMetaService, private dialog: MatDialog) {
    this.appTitle$ = this.metaService.title$;

    this.subscriptionManager$.add(
      this.metaService.primaryColor$.subscribe(c => this.toolbarStyle$.next('backgroundColor: ' + c + ';'))
    );
    this.subscriptionManager$.add(
      this.metaService.appVersion$.subscribe(appVersion => this.checkClientVersion(appVersion))
    );
    this.subscriptionManager$.add(
      this.routeService.url$.subscribe(url => this.urlSelected(url))
    );

  }

  ngOnDestroy() {
    this.subscriptionManager$.unsubscribe();
  }

  ngAfterViewInit() {
    var alerts = (document.querySelector('.app-alerts') as HTMLElement);
    if (alerts) {
      alerts.style.top = '150px';
    }
  }

  public onRouterOutletActivate(component : any) {
    if (!component.routerOverflowIgnore) {
      this.routerOverflowClass = component.routerOverflowAuto ? 'overflow-auto' : '';
    }
  }

  public onRouterOutletDeactivate(component: any) {
    // you have access to the component instance
  }

  private urlSelected(url: string) {
    var drawer = this.drawer;
    if (url?.length > 0 && drawer?.mode == 'over' && drawer.opened) {
      drawer.close();
    }
  }

  public checkClientVersion(appVersion: string) {
    if (appVersion?.length > 0) {
      const clientVersion: string = this.metaService.clientVersion$.getValue();
      if (appVersion != clientVersion) {
        this.showAppHelpAbout(true);
      };
    }
  }

  public showAppHelpAbout(askClientUpdate: boolean = false) {
    this.dialog.open(CerAppHelpAboutComponent, { width: '350px', height: 'auto',  panelClass: 'custom-mat-dialog-panel', data: { askClientUpdate: askClientUpdate } });
  }
}
