import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { CerAppMetaService } from '../cer-app-meta/cer-app-meta.service';
import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CerSessionService, SessionState } from 'src/cer-platform/cer-session/cer-session.service';
import { Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CerAppPromptsService implements OnDestroy {
  private subscriptionManager$: Subscription = new Subscription();

  constructor(private session: CerSessionService, private dialogService: CerDialogService, private metaService: CerAppMetaService, private snackBar: MatSnackBar) {
    this.subscriptionManager$.add(
      this.session.state$.subscribe(state => this.sessionStatePrompt(state))
    );
  }

  ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  private sessionStatePrompt(state: SessionState) {
    switch (state) {
      case 'paused':
        this.sessionPausedPrompt();
        break;
      case 'expired':
        this.sessionExpiredPrompt();
        break;
    }
  }

  private sessionPausedPrompt() {
    // Ask for user de reactivate session
    var title: string = this.metaService.title$.getValue();
    if (this.session.isAuthenticated$.getValue()) {
      this.dialogService.infoPrompt(title + ' er på  pause. Fortsæt?', "Pause").then(
        ok => {
          this.session.activate();
        }
      );
    }
  }

  private sessionExpiredPrompt() {
    // Ask for user de reactivate session
    var title: string = this.metaService.title$.getValue();
    if (this.session.isAuthenticated$.getValue()) {
      this.dialogService.infoPrompt(title + ' login er udløbet. Fortsæt?', "Logind udløbet").then(
        ok => {
          this.session.expire();
        }
      );
    }
  }

}
