<div class="form-container">
  <div class="group-content">
    <div class="group-scroller">
      <div class="group" *ngIf="hasVoucherRequest">
        <div class="fields-group">
          <div #fields class="fields">
            <div class="field-row">
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>Bilagsanmodning</mat-label>
                  <select matNativeControl [formControl]="voucherRequestCtrl">
                    <option
                      *ngFor="let voucherRequest of vm.voucherRequestList"
                      [ngValue]="voucherRequest.id"
                    >
                    {{ this.requestFullDescription(voucherRequest) }}
                    </option>
                  </select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="group">
        <h3 class="group">Vedhæftninger</h3>
        <div
          class="capture-group"
          [class.hovering]="isDragging"
          (drop)="captureDrop($event)"
          (dragover)="captureDragOver($event)"
          (dragleave)="captureDragStop($event)"
          (dragend)="captureDragStop($event)"
        >
          <label class="capture-button" *ngIf="hasCameraSupport">
            <div class="icon">
              <img src="/assets/icons/camera.svg" />
              <input
                type="file"
                accept="image/*"
                capture="camera"
                capture="environment"
                id="cameraInput"
                multiple
                (change)="handleFiles(1, $event)"
              />
            </div>
            <div class="text" [class]="camaraTextClass">Kamera</div>
          </label>
          <label class="capture-button">
            <div class="icon">
              <img src="/assets/icons/photos.svg" />
              <input
                type="file"
                accept="image/*"
                id="imageInput"
                multiple
                (change)="handleFiles(2, $event)"
              />
            </div>
            <div class="text">Fotos</div>
          </label>
          <label class="capture-button">
            <div class="icon">
              <img src="/assets/icons/files.svg" />
              <input
                type="file"
                id="fileInput"
                multiple
                (change)="handleFiles(3, $event)"
              />
            </div>
            <div class="text">Filer</div>
          </label>
        </div>
        <div
          class="gallery"
          [class.hovering]="isDragging"
          (drop)="captureDrop($event)"
          (dragover)="captureDragOver($event)"
          (dragleave)="captureDragStop($event)"
          (dragend)="captureDragStop($event)"
        >
          <div class="gallery-file" *ngFor="let attachment of attachments">
            <div class="'gallery-file-preview'">
              <img
                *ngIf="attachment.isImage"
                class="gallery-file-preview"
                [src]="attachment.dataURL"
              />
              <h3 *ngIf="!attachment.isImage" class="gallery-file-preview">
                {{ attachment.extension }}
              </h3>
            </div>
            <div class="gallery-file-filename">
              <input id="gallery-file-checkbox-{{ attachment.id }}"  type="checkbox" 
              [checked]="attachment.active" (changed)="attachment.active = attachment.active "/>
              <label
                [attr.for]="'gallery-file-checkbox-' + attachment.id"
                class="label"
              >
                <span>{{ attachment.fileName }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="group group-expand">
        <h3 class="group">Information</h3>
        <div class="fields-group">
          <div #fields class="fields">
            <div class="field-row">
              <div class="field">
                <mat-form-field>
                  <mat-label>Tekst:</mat-label>
                  <input
                    matInput
                    #description
                    type="text"
                    required
                    placeholder="Indtast postingstekst"
                    [formControl]="descriptionCtrl"
                    [errorStateMatcher]="descriptionMatcher"
                  />
                  <mat-error *ngIf="descriptionCtrl.hasError('required')">
                    Tekst <strong>skal</strong> angives.
                  </mat-error>
                  <mat-error
                    *ngIf="
                      descriptionCtrl.hasError('minlength') &&
                      !descriptionCtrl.hasError('required')
                    "
                  >
                    Tekst minimum 5 karakterer.
                  </mat-error>
                  <mat-error *ngIf="descriptionCtrl.hasError('maxlength')">
                    Tekst længde {{ descriptionCtrl.value.length }}, overstiger
                    <strong>maks.</strong> 40 karakterer.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="field-row">
              <div class="field">
                <mat-form-field>
                  <mat-label>Beløb:</mat-label>
                  <input
                    matInput
                    type="number"
                    inputmode="decimal"
                    required
                    placeholder="Indtast beløb i valuta"
                    [formControl]="amountCtrl"
                    [errorStateMatcher]="amountMatcher"
                  />
                  <mat-error *ngIf="descriptionCtrl.hasError('required')">
                    Beløb <strong>skal</strong> angives
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>Valuta</mat-label>
                  <select matNativeControl [formControl]="currencyCtrl">
                    <option
                      *ngFor="let currency of currencyList"
                      [ngValue]="currency.id"
                    >
                      {{ currency.name }}
                    </option>
                  </select>
                </mat-form-field>
              </div>
            </div>
            <div class="field-row">
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>Dato</mat-label>
                  <input
                    matInput
                    type="date"
                    required
                    placeholder="Vælg dato"
                    [formControl]="transDateCtrl"
                    [errorStateMatcher]="transDateMatcher"
                  />
                  <mat-error *ngIf="transDateCtrl.hasError('required')">
                    Dato <strong>skal</strong> angives.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>Kategori</mat-label>
                  <select matNativeControl [formControl]="categoryCtrl">
                    <option
                      *ngFor="let category of categoryNullList"
                      selected
                      [ngValue]="category.id"
                    ></option>
                    <option
                      *ngFor="let category of voucherCategoryList"
                      [ngValue]="category.id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                </mat-form-field>
              </div>
            </div>
            <div class="field-row">
              <div class="field">
                <mat-form-field>
                  <mat-label>Chat:</mat-label>
                  <textarea
                    type="text"
                    matInput
                    #comment
                    cdkTextareaAutosize
                    placeholder="Indtast chat besked"
                    #autosize="cdkTextareaAutosize"
                    [formControl]="chatCtrl"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="group-end">
      <div class="actions">
        <mat-dialog-actions style="margin-bottom: 0px">
          <button
            mat-button
            mat-raised-button
            color="accent"
            (click)="saveForm()"
            [disabled]="createForm.invalid"
          >
            <i class="material-icons">send</i>Send
          </button>
          <button
            mat-button
            mat-raised-button
            color=""
            (click)="resetForm()"
            [disabled]="false"
          >
            <i class="material-icons">clear</i>Nulstil
          </button>
        </mat-dialog-actions>
      </div>
    </div>
  </div>
</div>
