import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class VoucherValidationStatusFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    this.viewMetadata = {
      name: 'bilags-validerings-status', dataApiName: 'VoucherValidationStatus', text: 'Valideringsstatus',
      primaryKey: 'id', baseFields: ['id', 'name', 'history']
    };
  }

}
