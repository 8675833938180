import { FieldFormatEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class ProductTypeFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'udgivelse-typer', dataApiName: 'ProductType', text: 'Typer, udgivelser',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'sorting', 'history']
    };

    this.fieldMetadata = [
      { name: 'isPrimary', text: 'Tryk', format: FieldFormatEnum.CheckBox },
      { name: 'isAudio', text: 'Audio', format: FieldFormatEnum.CheckBox },
      { name: 'isDigital', text: 'Digital', format: FieldFormatEnum.CheckBox },
      { name: 'legacyId', text: 'Opr. Id', tooltipText: 'Id fra oprindelig database', visible: false, format: FieldFormatEnum.Integer}
    ];
   
  }
}
