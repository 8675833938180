import { NgModule } from '@angular/core';
import { CerPlatformModule } from 'src/cer-platform/cer-platform.module';

import { CerLocaleService } from 'src/cer/cer-locale/cer-locale.service';
import { CerDialogService } from './cer-dialog/cer-dialog.service';

import { CerDateEditComponent } from './cer-control/cer-date-edit/cer-date-edit.component';
import { CerFormComponent } from './cer-form/cer-form.component';
import { CerGridComponent } from './cer-grid/cer-grid.component';
import { CerStepperComponent } from './cer-stepper/cer-stepper.component';


//import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

var cerDeclarations = [
  CerFormComponent,
  CerGridComponent,
  CerStepperComponent,
  CerDateEditComponent,
]

var cerImports = [
  CerPlatformModule
];

var cerProviders : any[] = [
  CerLocaleService,
  CerDialogService
];

var cerExports = [].concat(cerDeclarations).concat(cerImports);

@NgModule({
  declarations: cerDeclarations,
  imports: cerImports,
  providers: cerProviders,
  exports: cerExports
})
export class CerModule { }
