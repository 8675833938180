import { CerAppFormModelAdapter } from 'src/platform/models/cer-app-form-model-adapter';
import { FieldFormatEnum } from '../../../cer/cer-data/cer-data.service';

export class BankAccountTransactionSourceFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    this.viewMetadata = {
      name: 'bilag-transaktion-kilder', dataApiName: 'BankAccountTransactionSource', text: 'Kilder', textSingular: 'Kilde',
      primaryKey: 'id', allowEdit: true, allowCreate: false, allowDelete: false, baseFields: ['id', 'history']
    };

    this.fieldMetadata =
      [
        { name: 'name', text: 'Kildenavn' },
        { name: 'sourceGroup', text: 'Gruppe', foreignKeyTableName: 'BankAccountTransactionSourceGroup' },
        { name: 'voucherMessageChannel', text: 'Bilagskanal', tooltipText: 'Kanal der er tilknyttet ved bilag', foreignKeyTableName: "VoucherMessageChannel" },
        { name: 'offsetAmount', text: 'Vend fortegn', tooltipText: 'Vend fortegn for beløb', format: FieldFormatEnum.CheckBox }
      ];

  }

}
