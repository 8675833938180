import { ProjectTaskComponent } from './../project-task/project-task.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RichTextEditorComponent, ToolbarService, LinkService, ImageService, ResizeService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-project-task-doc',
  templateUrl: './project-task-doc.component.html',
  styleUrls: ['./project-task-doc.component.css'],
  providers: [ToolbarService, LinkService, ImageService, ResizeService, HtmlEditorService]
})
export class ProjectTaskDocComponent implements OnInit {

  // Rich text
  public tools: object = {
    type: 'Expand',
    items: ['FullScreen', '|', 'Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
      'Outdent', 'Indent', '|',
      'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
      'SourceCode', '|', 'Undo', 'Redo']
  };
  public richText: RichTextEditorComponent = null;
  @ViewChild(RichTextEditorComponent, { static: false }) set setViewChildRichText(richText: RichTextEditorComponent) {
    this.richText = richText;
    this.richText.value = this.html;
  }

  protected loaded : boolean = false;
  public html: string = '';

  constructor(private dialogRef: MatDialogRef<ProjectTaskComponent>, @Inject(MAT_DIALOG_DATA) callerData: any) {
    this.html = callerData.html;
  }

  ngOnInit(): void {
  }

  public create(e: any) {
    var ele: HTMLElement = this.richText.element;
    ele.style.height = '100%';
    var views: HTMLCollectionOf<Element> = ele.getElementsByClassName('e-rte-content');
    console.log(views);
    if (views && views.length > 0) {
      var view: HTMLElement = <HTMLElement>views[0];
      console.log(view);
      if (view) {
        view.style.height = 'calc(100% - 40px)';
      }
    }
  }

  public actionBegin(e: any) {
  }

  public saveAndclose() {
    var html = this.richText.getHtml();
    this.close(true, html);
  }

  public close(dataChanged: boolean = false, html: string = null) {
    this.dialogRef.close({ dataChanged: dataChanged, html: html });
  }

}
