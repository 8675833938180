import { ErrorHandler, Injector, Injectable } from '@angular/core';
import { CerAppApplicationInsightsService } from './cer-app-application-insights.service';

@Injectable()
export class CerAppApplicationInsightsErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(error: any): void {
    console.log(error);
    this.injector.get<CerAppApplicationInsightsService>(CerAppApplicationInsightsService).logException(error);
  }
}
