import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationMessageDto } from 'src/app/api';
import { AppUser, UserSessionService } from '../../platform/app-user/user-session-service';
import { Observable, Subscription } from 'rxjs';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerAppNotificationService } from 'src/cer-app/cer-app-notifications/cer-app-notification.service';
import { CerLocaleService } from 'src/cer/cer-locale/cer-locale.service';


@Component({
  selector: 'app-home',
  templateUrl: './app-home.component.html',
  styleUrls: ['./app-home.component.css']
})
export class AppHomeComponent implements OnDestroy, OnInit {
  private subscriptionManager$: Subscription = new Subscription();

  public user: AppUser = null;
  protected hasLogin: boolean  = false;  
  protected hasUser: boolean  = false;  

  public userNotifications: NotificationMessageDto[] = null;
  public hasNotifications: boolean = false;
  public unreadCount: number = 0;

  constructor(
    private userSessionService: UserSessionService,
    private notificationService: CerAppNotificationService,
    private localeService: CerLocaleService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptionManager$.add(
      this.userSessionService.user$.subscribe(user => {
        this.user = user;
        this.hasLogin = this.user != null;
        this.hasUser = this.user?.dto != null;
      })
    );

    this.subscriptionManager$.add(
      this.notificationService.userNotifications$.subscribe(data => this.initNotifications(data))
    );
  }

  private initNotifications(data: NotificationMessageDto[]): void {
    if (data) {
      this.userNotifications = data.filter(m => m.userId === this.user?.dto?.id);
      this.hasNotifications = this.userNotifications && this.userNotifications.length != 0;
      this.unreadCount = this.userNotifications ? this.userNotifications.filter(m => m.isRead == false).length : 0;
      this.cdr.detectChanges();
    }
  }

  public formatDateRelative(d: Date): string {
    return this.localeService.formatDateRelative(d);
  }

}
