<cer-form #form [panes]="panes">
  <ng-template #mainTemplate>
    <cer-grid
      #mainGrid
      id="mainGrid"
      [viewMetadata]="viewMetadata"
      [fieldMetadata]="fieldMetadata"
      dataRelationRole="parent"
    ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid
      #detailGrid
      id="detailGrid"
      [viewMetadata]="detailViewMetadata"
      [fieldMetadata]="detailFieldMetadata"
      mode="simpleToolbar"
      dataRelationRole="child"
    ></cer-grid>
  </ng-template>
</cer-form>
