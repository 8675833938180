<div #containerDiv [class.hideContent]="!isGridDataBound" class="loadedDiv" id="loadedDiv">
    <ejs-grid #grid>
        <ng-template *ngIf="editTemplate || tabMetadata" #editSettingsTemplate let-data>
            <ng-container *ngIf="editTemplate" [ngTemplateOutlet]="editTemplate"
                context="{ data: data }"></ng-container>
            <ng-container *ngIf="!editTemplate" [ngTemplateOutlet]="editTabTemplate"
                context="{ data: data }"></ng-container>
        </ng-template>
    </ejs-grid>
</div>
<ng-template #chatTemplate let-data><mat-icon color="primary" class="chat-link-cell"
        [matBadgeHidden]="!(data?.chat?.length > 0)" [matBadge]="data?.chat?.length" matBadgeColor="primary" >chat</mat-icon>
</ng-template>
<ng-template #editTabTemplate let-data="data">
    <div ngForm>
        <mat-tab-group *ngIf="templateSetup?.tabs.length > 1" animationDuration="200ms">
            <mat-tab *ngFor="let tabSetup of (templateSetup).tabs">
                <ng-template mat-tab-label>
                    <mat-icon class="edit-tab-icon">{{tabSetup.tab.icon}}</mat-icon>
                    {{tabSetup.tab.text}}
                </ng-template>
                <div *ngFor="let groupSetup of tabSetup.groups">
                    <h2>{{groupSetup.group.text}}</h2>
                    <div *ngFor="let column of groupSetup.columns">
                        {{ column.field }}:
                        <ng-container [ngTemplateOutlet]="editFieldTemplate"
                            context="{ data, field = 'column.field', column}"></ng-container>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <div *ngIf="templateSetup && templateSetup.tabs.length <= 1">
            <div *ngFor="let groupSetup of templateSetup.tabs[0].groups">
                <h2>{{groupSetup.group.text}}</h2>
                <div *ngFor="let column of groupSetup.columns">
                    {{ column.field }}:
                    <ng-container [ngTemplateOutlet]="editFieldTemplate"
                        context="{ data, field = 'column.field', column}"></ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #editFieldTemplate let-data let-field let-column>
    {{ field}}
    <ng-container *ngIf="false">
        <input class="e-input" name="field" value="data[field]" required type="text" />
    </ng-container>
    <ng-container *ngIf="false">
        <ejs-datepicker id="value" placeholder="Date" format='MM/dd/yyyy' floatLabelType='Never'></ejs-datepicker>
    </ng-container>
    <ng-container *ngIf="false">
        <ejs-checkbox></ejs-checkbox>
    </ng-container>
</ng-template>