import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class VoucherCategoryFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    this.viewMetadata = {
      name: 'bilags-kategorier', dataApiName: 'VoucherCategory', text: 'Kategorier', textSingular: 'Kategori',
      primaryKey: 'id', baseFields: ['id', 'name', 'history']
    };
  }

}
