import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FieldSettingsModel, ToolbarSettingsModel } from '@syncfusion/ej2-dropdowns';
import { RoleDto, RoleUserDto, UserDto, UserRoleVm } from 'src/app/api';
import { AppUser } from '../user-session-service';
import { ListBoxComponent } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'app-app-user-role',
  templateUrl: './app-user-role.component.html',
  styleUrls: ['./app-user-role.component.css'],
  //encapsulation: ViewEncapsulation.None
})

export class AppUserRoleComponent implements OnInit {

  @ViewChild("selectedListBox", { static: false }) public selectedListBox : ListBoxComponent;

  public selectedData: RoleDto[] = [];
  public remainingData: RoleDto[] = [];

  //public selectedData: { [key: number]: Object }[] = [];
  //public remainingData: { [key: number]: Object }[] = [];
  public fields: FieldSettingsModel = { value: 'id', text: 'name' };
  public toolbarSettings: ToolbarSettingsModel = { items: ['moveTo', 'moveFrom', 'moveAllTo', 'moveAllFrom'] }

  public vm: UserRoleVm;

  constructor(private dialogRef: MatDialogRef<AppUser>, @Inject(MAT_DIALOG_DATA) callerData: any) {
    this.vm = callerData.vm;
    this.dataBuild(this.vm.roleUsers, this.vm.roles);
  }

  ngOnInit(): void {

  }

  public dataBuild(roleUsers: RoleUserDto[], roles: RoleDto[]) {
    roles.forEach(role => {
      var roleData: Object = { key: role.id, name: role.name };
      if (roleUsers.filter(ru => (ru.roleId == role.id && ru.isActive == true)).length > 0) {
        this.selectedData.push(role);
      }
      else {
        this.remainingData.push(role);
      }
    });
  }

  public dataResult(): number[] {
    var ids: number[] = [];

    var selectedData : RoleDto[] = <RoleDto[]> <unknown> this.selectedListBox.getDataList();
    selectedData.forEach(role=> {
      ids.push(role.id);
    });
    return ids;
  }

  public saveAndclose() {
    var data = this.dataResult();
    this.close(true, data);

  }

  public close(dataChanged: boolean, data: number[] = null) {
    this.dialogRef.close({ dataChanged: dataChanged, data: data });
  }

}
