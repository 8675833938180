import { Column, ICellFormatter } from "@syncfusion/ej2-angular-grids";

export class RoyaltyApproveStatusFormatter implements ICellFormatter {
  public getValue(column: Column, data: Object): Object {
    var rowData: any = data;
    if (!rowData) {
      return '';
    }
    return ChipDiv(RoyaltyApproveStatusClass(rowData.status), rowData["statusText"]);
  }
}

export function ChipDiv(cls: string, value: string) : string {
  return '<div class="e-chip-list"><div class="e-chip ' + cls + '"><div class="e-chip-text">' + value + '</div></div></div>';
}

export function RoyaltyApproveStatusAttributes(godkendt: any): { [x: string]: Object } {
  var attr: { [x: string]: Object } = { 'class': ['.e-grid-cell-chip',RoyaltyApproveStatusClass(status)] };
  return attr;
}

export function RoyaltyApproveStatusClass(status: any): string {
  var cls: string = '';
  switch (status) {
    case 0:
      cls = 'e-primary'; // Til godkendelse
      break;
    case 1:
      cls = 'e-success'; // Godkendt
      break;
    case 2:
      cls = 'e-danger'; // Afvist godkendt
      break
    case 3:
      cls = "e-warning"; //Godkendt og lukkes
      break
    case 4:
      cls = "e-info"; //Afsendt
      break
    case 5: 
      cls = ""; //Annulleret
      break
    case 6:
      cls = "e-success"; // Udbetalt
      break
    case 7:
      cls = "e-info"; //Godkend rettelse
      break
  }
  return cls;
}
