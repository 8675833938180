import { CerAppFormModelAdapter } from 'src/platform/models/cer-app-form-model-adapter';
import { FieldFormatEnum, FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class VoucherMessageMailVendorHistoryFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    // View
    this.viewMetadata = {
      name: 'bilag-afsendere', dataApiName: 'VoucherMessageMailVendorHistory', text: 'Afsendere', textSingular: 'Afsender',
      primaryKey: 'id', titleFields: ['fromEmail', 'vendorNum'], baseFields: ['id', 'history']
    };
    // Fields
    this.fieldMetadata =
      [
        { name: 'fromEmail', text: 'Afsender e-mail', tooltipText: 'Kreditor afsender e-mail adresse', required: true, allowEdit: true, allowEditOnCreate: true, widthType: FieldWidthEnum.L },
        { name: 'postingType', text: 'Posteringstype', tooltipText: 'Type af kontonummer', allowEdit: true, widthType: FieldWidthEnum.M, foreignKeyTableName: 'VoucherPostingType' },
        { name: 'accountNum', text: 'Kontonr.', tooltipText: 'Kontonummer på kreditor/finans mm', allowEdit: true, widthType: FieldWidthEnum.M },
        { name: 'accountName', text: 'Kontonavn', tooltipText: 'Navn på kontonummer', allowEdit: false, widthType: FieldWidthEnum.L },
        { name: 'useCount', text: 'Antal bilag', tooltipText: 'Antal bilag hvor kreditor e-mail er anvendt', defaultValue: 0, required: true, allowEdit: false, visibleAdd: false, visibleEdit: false, format: FieldFormatEnum.Number },
        { name: 'isDefault', text: 'Standard', tooltipText: 'Brug altid som konteringskonto', defaultValue: 0, allowEdit: true, format: FieldFormatEnum.CheckBox },
        { name: 'formRecognizer', text: 'Dokument genkendelse', tooltipText: 'Opsætning til automatisk dokument genkendelse', allowEdit: true, widthType: FieldWidthEnum.M, foreignKeyTableName: 'FormRecognizer', foreignKeyField: 'id', foreignKeyValue: 'name' }, 
        { name: 'voucherApprover', text: 'Godkender', tooltipText: 'Standard godkender for bilag', allowEdit: true, widthType: FieldWidthEnum.M, foreignKeyTableName: 'User', foreignKeyValue: 'shortName' }
      ];

  }

}
