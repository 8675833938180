<div class="flex flex-column">
  <div class="flex-fixed">
    <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Royalty detaljer {{periodText}}</h1>
  </div>
  <div *ngIf="!isLoading" class="flex-column flex-grow flex-overflow">
    <div id="topGroup" class="flex-row flex-fixed">
      <mat-card>
        <mat-card-title>Kontrakt</mat-card-title>
        <mat-card-content>
          <mat-dialog-content [formGroup]="formKontrakt" class="flex-column">
            <mat-form-field floatLabel="always">
              <mat-label>Hovedkontrakt</mat-label>
              <input matInput formControlName="hovedKontrakt" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Arb.titel</mat-label>
              <input matInput formControlName="arbTitel" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Bogtitel</mat-label>
              <input matInput formControlName="bogTitel" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Forfatter</mat-label>
              <input matInput formControlName="forfatterNavn" [readonly]="true" />
            </mat-form-field>
          </mat-dialog-content>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Forlag</mat-card-title>
        <mat-card-content>
          <mat-dialog-content [formGroup]="formForlag" class="flex-column">
            <mat-form-field floatLabel="always">
              <mat-label>Forlag id</mat-label>
              <input matInput placeholder="" formControlName="forlagId" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Forlag email</mat-label>
              <input matInput type="email" formControlName="forlagEmail" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Forlag adresse</mat-label>
              <textarea matInput style="box-sizing: content-box" [cdkTextareaAutosize]="true"
                formControlName="forlagAdresse" [readonly]="true"></textarea>
            </mat-form-field>
          </mat-dialog-content>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="hasAgent">
        <mat-card-title>Agent</mat-card-title>
        <mat-card-content>
          <mat-dialog-content [formGroup]="formAgent" class="flex-column">
            <mat-form-field floatLabel="always">
              <mat-label>Agent id</mat-label>
              <input matInput formControlName="afregningId" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Agent email</mat-label>
              <input matInput class="e-input" type="email" formControlName="afregningEmail" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Agent adresse</mat-label>
              <textarea matInput style="box-sizing: content-box" [cdkTextareaAutosize]="true"
                formControlName="afregningAdresse" [readonly]="true"></textarea>
            </mat-form-field>
          </mat-dialog-content>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Beregning</mat-card-title>
        <mat-card-content>
          <mat-dialog-content [formGroup]="formRoyalty" class="flex-column">
            <mat-form-field floatLabel="always" [hidden]="true">
              <mat-label>Royalty id</mat-label>
              <input matInput formControlName="royaltyId" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Salgspris DKK ex. moms</mat-label>
              <input matInput formControlName="endeligSalgspris" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Valutakurs {{ valutaKode }}/DKK</mat-label>
              <input matInput formControlName="valutaKurs" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Royalty {{ valutaKode }}</mat-label>
              <input matInput formControlName="royaltyAmount" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Beregnet dato</mat-label>
              <input matInput formControlName="createdDate" [readonly]="true" />
            </mat-form-field>
          </mat-dialog-content>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Redaktør</mat-card-title>
        <mat-card-content>
          <mat-dialog-content [formGroup]="formApproval" class="flex-column">
            <mat-form-field floatLabel="always" appearance="fill">
              <mat-label color="primary">Godkendelse</mat-label>
              <mat-select #godkendt id="godkendt" color="primary" formControlName="godkendt">
                <mat-option value="0">Til godkendelse</mat-option>
                <mat-option value="1">Godkendt</mat-option>
                <mat-option value="3">Godkendt og lukkes</mat-option>
                <mat-option value="2">Afvist</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Godkendt dato</mat-label>
              <input matInput id="godkendtDato" formControlName="godkendtDato" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Godkendt af</mat-label>
              <input matInput id="godkendtAf" formControlName="godkendtAf" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Redaktør</mat-label>
              <input matInput formControlName="redaktorNavn" [readonly]="true" />
            </mat-form-field>
          </mat-dialog-content>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Økonomi</mat-card-title>
        <mat-card-content>
          <mat-dialog-content [formGroup]="formClose" class="flex-column">
            <mat-form-field floatLabel="always" appearance="fill">
              <mat-label color="primary">Afslutning</mat-label>
              <mat-select id="afsluttet" color="primary" formControlName="afsluttet">
                <mat-option value="0">Til afslutning</mat-option>
                <mat-option value="4">Godkend rettelse</mat-option>
                <mat-option value="1">Afsendt</mat-option>
                <mat-option value="2">Annulleret</mat-option>
                <mat-option value="3">Udbetalt</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Afsluttet dato</mat-label>
              <input matInput id="afsluttetDato" formControlName="afsluttetDato" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-label>Afsluttet af</mat-label>
              <input matInput id="afsluttetAf" formControlName="afsluttetAf" [readonly]="true" />
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="fill" [hidden]="!inPayment">
              <mat-label>Betalt dato</mat-label>
              <input matInput id="betaltDato" placeholder="Angiv Betalingsdato" formControlName="betaltDato"
                [matDatepicker]="picker" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="fill" [hidden]="!inPayment">
              <mat-label>Betalt beløb {{ valutaKode }}</mat-label>
              <input matInput id="betaltBelob" placeholder="Angiv udbetalt beløb" formControlName="betaltBelob" />
            </mat-form-field>
            <mat-checkbox formControlName="recalcRoyalty" [hidden]="!canRecalc">Foretag genberegning</mat-checkbox>
          </mat-dialog-content>
        </mat-card-content>
      </mat-card>
    </div>
    <div>
      <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="tabGroupSelectedIndex" class="flex-grow">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="tab-icon">functions</mat-icon>
            Oversigt
          </ng-template>
          <ng-template matTabContent>
            <ejs-grid #gridTrans id="GridTrans" [dataSource]="royaltyAfregningTransAktuelView" [allowReordering]="true"
              [allowResizing]="true" [allowExcelExport]="true" [showColumnChooser]="true" [showColumnMenu]="true"
              [columnMenuItems]="columnMenuItems" [enablePersistence]="true">
              <e-columns>
                <e-column field="id" headerText="Royalty ID" [visible]="false"></e-column>
                <e-column field="edition" headerText="Udgave"></e-column>
                <e-column field="kontrakt" headerText="Kontrakt" width="140px"></e-column>
                <e-column field="hid" headerText="Vare ID" [visible]="false"></e-column>
                <e-column field="vareNR" headerText="Varenr" [visible]="false"></e-column>
                <e-column field="title" headerText="Titel"></e-column>
                <e-column field="isbn" headerText="ISBN" width="140px">
                  <ng-template #template let-data>
                    <span><a target="_new" href="https://turbine.dk/?s={{ data.isbn }}">{{ data.isbn }}</a></span>
                  </ng-template>
                </e-column>
                <e-column field="udgivelsesDato" headerText="Udgivet" type="date" format="yMd" width="140px"></e-column>
                <e-column field="lukketDato" headerText="Afsluttet" type="date" format="yMd" width="140px"
                  [visible]="false"></e-column>
                <e-column field="omsAntal" headerText="Antal solgt" textAlign="Right" headerTextAlign="Left" format="N0"
                  width="100px"></e-column>
                <e-column field="omsBelobVal" headerText="Anden oms {{ valutaKode }}" textAlign="Right" headerTextAlign="Left" format="N2"
                  width="120px"></e-column>
                <e-column field="royaltyBelobBeregnVal" headerText="Royalty {{ valutaKode }}" textAlign="Right" headerTextAlign="Left" 
                  format="N2" width="120px"></e-column>
                <e-column field="dato" headerText="Royalty dato" [visible]="false" type="date" format="yMd"></e-column>
                <e-column field="sort" headerText="Sortering" [visible]="false"></e-column>
                <e-column field="hovedKontrakt" headerText="Hovedkontrakt" [visible]="false"></e-column>
              </e-columns>
            </ejs-grid>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="tab-icon">stairs</mat-icon>
            Beregning trapper
          </ng-template>
          <ng-template matTabContent>
            <ejs-grid #gridStairs id="GridStair" [dataSource]="royaltyAfregningTrappeAktuelView"
              [allowReordering]="true" [allowResizing]="true" [allowExcelExport]="true" [showColumnChooser]="true"
              [showColumnMenu]="true" [columnMenuItems]="columnMenuItems" [enablePersistence]="true">
              <e-columns>
                <e-column field="edition" headerText="Udgave"></e-column>
                <e-column field="kontrakt" headerText="Kontrakt" [visible]="false"></e-column>
                <e-column field="vareNR" headerText="Varenr" [visible]="false"></e-column>
                <e-column field="trappeFra" headerText="Trappe fra" textAlign="Right" headerTextAlign="Left" format="N0"
                  width="120px"></e-column>
                <e-column field="trappeTil" headerText="Trappe til" textAlign="Right" headerTextAlign="Left" format="N0"
                  width="120px"></e-column>
                <e-column field="isbn" headerText="ISBN" width="140px">
                  <ng-template #template let-data>
                    <span><a target="_new" href="https://turbine.dk/?s={{ data.isbn }}">{{ data.isbn }}</a></span>
                  </ng-template>
                </e-column>
                <e-column field="omsAntal" headerText="Antal solgt" textAlign="Right" headerTextAlign="Left" format="N0"
                  width="100px"></e-column>
                <e-column field="salgsPrisVal" headerText="Salgspris {{ valutaKode }}" textAlign="Right" headerTextAlign="Left" format="N2"
                  width="120px"></e-column>
                <e-column field="omsBelobVal" headerText="Anden oms {{ valutaKode }}" textAlign="Right" headerTextAlign="Left" format="N2"
                  width="120px"></e-column>
                <e-column field="rate" headerText="Royalty sats" textAlign="Right" headerTextAlign="Left" format="P1" width="110px"></e-column>
                <e-column field="royalty" headerText="Royalty {{ valutaKode }}" textAlign="Right" headerTextAlign="Left" format="N2"
                  width="120px"></e-column>
                <e-column field="salgsPrisFraDato" headerText="Pris fra dato" [visible]="true" type="date" format="yMd"
                  width="120px"></e-column>
                <e-column field="salgsPrisTilDato" headerText="Pris til dato" [visible]="true" type="date" format="yMd"
                  width="120px"></e-column>
                <e-column field="dato" headerText="Royalty dato" [visible]="false" type="date" format="yMd"></e-column>
              </e-columns>
            </ejs-grid>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="tab-icon">history</mat-icon>
            Historik
          </ng-template>
          <ng-template matTabContent>
            <ejs-grid #grid id="GridHistory" [dataSource]="royaltyAfregningTransHistorikView" [allowReordering]="true"
              [allowResizing]="true" [allowExcelExport]="true" [showColumnChooser]="true" [showColumnMenu]="true"
              [columnMenuItems]="columnMenuItems" [enablePersistence]="true">
              <e-columns>
                <e-column field="edition" headerText="Udgave"></e-column>
                <e-column field="kontrakt" headerText="Kontrakt" [visible]="false"></e-column>
                <e-column field="vareNR" headerText="Varenr" [visible]="false"></e-column>
                <e-column field="bogtitel" headerText="Titel"></e-column>
                <e-column field="isbn" headerText="ISBN" width="140px">
                  <ng-template #template let-data>
                    <span><a target="_new" href="https://turbine.dk/?s={{ data.isbn }}">{{ data.isbn }}</a></span>
                  </ng-template>
                </e-column>
                <e-column field="dato" headerText="Royalty dato" type="date" format="yMd" width="120px"></e-column>
                <e-column field="type" headerText="Type"></e-column>
                <e-column field="omsAntal" headerText="Antal solgt" textAlign="Right" headerTextAlign="Left" format="N0"
                  width="120px"></e-column>
                <e-column field="omsBelobVal" headerText="Anden oms {{ valutaKode }}" textAlign="Right" headerTextAlign="Left" format="N2"
                  width="120px"></e-column>
                <e-column field="royaltyBelobBeregnVal" headerText="Royalty {{ valutaKode }}" textAlign="Right" headerTextAlign="Left" 
                  format="N2" width="120px">
                </e-column>
              </e-columns>
            </ejs-grid>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="tab-icon">note</mat-icon>
            Noter
          </ng-template>
          <ng-template matTabContent>
            <ejs-grid #grid id="GridNote" [dataSource]="royaltyNote" [allowReordering]="true" [allowResizing]="true"
              [allowExcelExport]="true" [showColumnChooser]="true" [showColumnMenu]="true"
              [columnMenuItems]="columnMenuItems" allowTextWrap="true" [enablePersistence]="true">
              <e-columns>
                <e-column field="id" headerText="ID" [visible]="false"></e-column>
                <e-column field="hid" headerText="Kontrakt ID" [visible]="false"></e-column>
                <e-column field="dato" headerText="Notat dato" type="date" width="150px"
                  [format]="{ type: 'dateTime', skeleton: 'short' }"></e-column>
                <e-column field="userId" headerText="Bruger" width="100px"></e-column>
                <e-column field="isPublic" headerText="Offentlig" type="boolean" [displayAsCheckBox]="true"
                  width="100px"></e-column>
                <e-column field="note" headerText="Note" allowTextWrap="true" width="90%"></e-column>
              </e-columns>
            </ejs-grid>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="tab-icon">note_add</mat-icon>
            Ny note
          </ng-template>
          <ng-template matTabContent>
            <div style="height: 100%; width: 100%">
              <mat-dialog-content [formGroup]="formNote" class="flex-column">
                <mat-form-field floatLabel="always" appearance="fill">
                  <textarea matInput placeholder="Indtast note der beskriver status/mangler" formControlName="note"
                    cdkTextareaAutosize cdkAutosizeMinRows="6"></textarea>
                </mat-form-field>
                <mat-checkbox formControlName="noteIsPublic">Offentlig note</mat-checkbox>
              </mat-dialog-content>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="flex-fixed flex-column-at-end">
    <mat-dialog-actions>
      <button mat-button mat-raised-button color="primary" [disabled]="!isValid" (click)="saveAndclose()">
        <i class="material-icons">save</i>Gem
      </button>
      <button mat-button mat-raised-button cdkFocusInitial (click)="close()">
        <i class="material-icons">close</i>Annuller
      </button>
    </mat-dialog-actions>
  </div>
</div>