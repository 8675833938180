import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, Injector, LOCALE_ID, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter'
import { UiCommand, UiKeyboardShortcut } from 'src/cer/cer-grid/cer-grid-command.service';
import { FieldGroupMetadata, FieldMetadata, ViewMetadata } from 'src/cer/cer-data/cer-data.service';
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';
import { CerGridComponent, CerGridSelectionMode } from 'src/cer/cer-grid/cer-grid.component';
import { AppStateService } from 'src/app-core/app-state/app-state.service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';

@Component({
  selector: 'cer-app-form-grid-dialog',
  templateUrl: './cer-app-form-grid-dialog.component.html',
  styleUrls: ['./cer-app-form-grid-dialog.component.css']
})

export class CerAppFormGridDialogComponent {

  public routerLinkActive: boolean = false;
  public formPersistenceActive: boolean = false;

  public form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.adapter.form = this.form;
      this.form.commmand.subscribe(e => this.adapter.onCommand(e));
    }
  }

  public grid: CerGridComponent;
  @ViewChild('grid', { static: false }) set gridContent(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
      this.adapter.grid = this.grid;
      this.grid.commmand.subscribe(e => this.adapter.onCommand(e));
    }
  }

  public selectionMode: CerGridSelectionMode;
  public viewMetadata: ViewMetadata;
  public fieldGroupMetadata: FieldGroupMetadata[] = [];
  public fieldMetadata: FieldMetadata[] = [];
  public toolbarCommands: UiCommand[];
  public contextMenuCommands: UiCommand[];
  public keyboardShortcuts: UiKeyboardShortcut[];

  public adapter: CerAppFormModelAdapter;

  constructor(private routeService: CerAppRouteService, private dialogService: CerDialogService, private appDialogService: CerAppDialogService, private injector: Injector, private dialogRef: MatDialogRef<CerAppFormGridDialogComponent>, private appStateService: AppStateService, private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) callerData: any, @Inject(LOCALE_ID) locale: string) {
    this.setupFormAdapterFromCallerData(callerData);
    dialogRef.backdropClick().subscribe(() => {
      this.close(false);
    })
  }

  protected setupFormAdapterFromCallerData(callerData : any) {
    if (callerData?.formAdapterType) {
      this.setupFormAdapter(callerData.formAdapterType);
    }
  }

  protected setupFormAdapter(formAdapterType: typeof CerAppFormModelAdapter) {
    if (formAdapterType) {
      this.adapter = this.makeFormAdapterObject(formAdapterType);
      //this.adapter.route = null;
      this.adapter.injector = this.injector;
      this.adapter.dialogService = this.dialogService;
      this.adapter.appDialogService = this.appDialogService;
      this.adapter.appStateService = this.appStateService;
      this.adapter.routeService = this.routeService
      this.adapter.init();
      this.initFromAdapter();
    }
  }

  protected makeFormAdapterObject(formAdapterType: typeof CerAppFormModelAdapter) {
    return new formAdapterType();
  }

  protected initFromAdapter() {
    if (this.adapter) {
      var adapter: CerAppFormModelAdapter = this.adapter;

      if (adapter.selectionMode) {
        this.selectionMode = adapter.selectionMode;
      }
      if (adapter.viewMetadata) {
        this.viewMetadata = adapter.viewMetadata;
      }
      if (adapter.fieldGroupMetadata) {
        this.fieldGroupMetadata = adapter.fieldGroupMetadata;
      }
      if (adapter.fieldMetadata) {
        this.fieldMetadata = adapter.fieldMetadata;
      }
      if (adapter.toolbarCommands) {
        this.toolbarCommands = adapter.toolbarCommands;
      }
      if (adapter.contextMenuCommands) {
        this.contextMenuCommands = adapter.contextMenuCommands;
      }
      if (adapter.keyboardShortcuts) {
        this.keyboardShortcuts = adapter.keyboardShortcuts;
      }
    }
  }

  public close(ok: boolean = true) {
    this.dialogRef.close({ ok: ok });
  }
}
