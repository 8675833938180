import { Query } from '@syncfusion/ej2-data';
import { Column, DropDownEditCell, IGrid, extend } from "@syncfusion/ej2-angular-grids";
import { FieldMetadata } from "../cer-data/cer-data.service";
import { isEditable, getObject, getComplexFieldID } from "@syncfusion/ej2-grids";
import { CerDropDownList } from '../cer-control/cer-drop-down-list/cer-drop-down-list';

export class CerGridEditDropDownList {
  private grid: any;
  private column: Column;
  private rowData: Object;
  private row: HTMLElement;
  private requestType: string;
  private foreignKeyData?: Object[]
  private fieldMeta: FieldMetadata;
  private baseEditCell: DropDownEditCell;
  private baseEditCellAny: any;
  private element: Element;
  private input: HTMLInputElement;
  private obj: CerDropDownList;
  private originalValue: any;
  private baseQuery: Query;

  constructor(grid: IGrid, baseEditCell: DropDownEditCell, fieldMeta: FieldMetadata, baseQuery: Query) {
    this.grid = grid;
    this.baseEditCell = baseEditCell;
    this.baseEditCellAny = <any>baseEditCell;
    this.fieldMeta = fieldMeta;
    this.baseQuery = baseQuery;

    (<any>baseEditCell).create_orig = baseEditCell.create;
    this.baseEditCell.create = this.create.bind(this);

    (<any>baseEditCell).destroy_orig = baseEditCell.destroy;
    baseEditCell.destroy = this.destroy.bind(this);

    //(<any>baseEditCell).write_orig = baseEditCell.write;
    baseEditCell.write = this.write.bind(this);

    (<any>baseEditCell).read_orig = baseEditCell.read;
    baseEditCell.read = this.read.bind(this);
  }

  public create(args: any): any {
    this.input = this.baseEditCellAny.create_orig(args);
    return this.input;
  }

  public write(args: {
    rowData: Object; element: Element; column: Column; row: HTMLElement;
    requestType: string; foreignKeyData?: Object[]
  }) {
    this.rowData = args.rowData;
    this.element = args.element;
    this.column = args.column;
    this.fieldMeta = (<any>this.column).meta;
    this.row = args.row;
    this.requestType = args.requestType;
    this.foreignKeyData = this.fieldMeta?.foreignKeyData ?? args.foreignKeyData;

    this.baseEditCellAny.column = this.column;

    if (this.rowData && this.fieldMeta.name) {
      this.originalValue = (<any>args.rowData)[this.fieldMeta.name];
    }

    this.writeDropDownList();

    this.baseEditCellAny.obj = this.obj;
    this.baseEditCellAny.addEventListener();
    this.addEventListener();
    this.obj.appendTo(<any>this.element);
    this.element.setAttribute('name', getComplexFieldID(this.column.field));
  }

  public destroy() {
    if (this.baseEditCellAny.destroy_orig) {
      return this.baseEditCellAny.destroy_orig();
    }
    this.removeEventListener();
  }

  public read(element: Element): string | boolean | Date {
    if (this.baseEditCellAny.read_orig) {
      return this.baseEditCellAny.read_orig(element);
    }
    return null;
  }

  private writeDropDownList(): any {
    var isInline: boolean = this.grid.editSettings.mode !== 'Dialog';
    var params: any = {
      fieldMeta: this.fieldMeta,
      baseQuery: this.baseQuery,
      dataSource: this.column.dataSource,
      //data: this.fieldMeta?.foreignKeyData ? this.fieldMeta.foreignKeyData : this.column?.dataSource instanceof DataManager ? (this.column.dataSource as DataManager)?.dataSource?.json : this.column.dataSource as any[],
      fields: { text: this.column.foreignKeyValue, value: this.column.foreignKeyField },
      enabled: isEditable(this.column, this.requestType, this.input),
      value: getObject(this.column.field, this.rowData),
      enableRtl: this.grid.enableRtl,
      preventAutoFill: true,
      placeholder: isInline ? '' : this.column.headerText,
      floatLabelType: isInline ? 'Never' : 'Always',
      cssClass: this.grid.cssClass ? this.grid.cssClass : null,
      close: this.dropDownClose.bind(this)
    };
    if (this.column.edit.params) {
      var paramsAny: any = this.column.edit.params;
      var keys = Object.keys(this.column.edit.params);
      for (var i = 0; i < keys.length; i++) {
        var key: string = keys[parseInt(i.toString(), 10)];
        params[key] = paramsAny[key] === 'query' ? (paramsAny[key]).clone() : paramsAny[key];
      }
    }
    this.obj = new CerDropDownList(params); // new DropDownList(params);
  }

  private addEventListener() {
    if (this.input) {
      this.baseEditCellAny.obj.addEventListener("select", this.onSelect.bind(this));
      this.baseEditCellAny.obj.addEventListener("change", this.onEditChanged.bind(this));

    }
  };

  private removeEventListener() {
    this.baseEditCellAny.obj.removeEventListener("change", this.onEditChanged.bind(this));
    this.baseEditCellAny.obj.removeEventListener("select", this.onSelect.bind(this));
  }


  private dropDownClose(args: any) {
    if (args.event && args.event.action === 'escape') {
      this.grid.editModule.editCellDialogClose = false;
    }
  }

  // ** to-do: validate select args.cancel = true
  private onSelect(args: any) {
    if (this.fieldMeta?.onEditChanging) {
      this.fieldMeta.onEditChanging(args);
      /*
      if (args?.cancel === true && this.obj) {
        var previousValue = null;
        var previousInputValue = null;
        var objAny : any = this.obj;
        var prevData : any = objAny.previousItemData;
        if (prevData && this.fieldMeta?.foreignKeyValue) {
          previousValue = prevData[this.fieldMeta.foreignKeyField];
          previousInputValue = prevData[this.fieldMeta.foreignKeyValue];
        }
        if (this.obj.value != this.originalValue) {
          this.obj.value = previousValue;
            this.input.value = previousInputValue;
        }
      */
    }
  }

  private onEditChanged(args: any) {
    if (this.fieldMeta?.onEditChanged) {
      this.fieldMeta.onEditChanged(args);
    }
    this.validate();
  }

  public validate() {
    /*
    setTimeout(() => {
      this.grid?.editModule?.editFormValidate();
    }, 1);
    */
  }
  
}
