<div class="flex-column flex-grow">
  <div style="padding: 10px; padding-bottom: 0px;">
    <h2>Vælg login der skal tilknyttes brugeren {{ vm?.user?.shortName }}:</h2>
  </div>
  <div class="flex-grow" style="padding: 10px; padding-top: 0px; padding-bottom: 20px;">
    <cer-grid
      #AppUserIdentityGrid
      id="AppUserIdentityGrid"
      [viewMetadata]="viewMetadata"
      [fieldMetadata]="fieldMetadata"
      [toolbarCommands]="toolbarCommands"
      [contextMenuCommands]="contextMenuCommands"
      selectionMode="checkboxSingle"
      mode="simpleToolbar"
      (commmand)="onCommand($event)"
    ></cer-grid>
  </div>
  <mat-dialog-actions>
    <button
      mat-button
      mat-raised-button
      color="primary"
      (click)="saveAndclose()"
    >
      <i class="material-icons">done</i>Gem og luk
    </button>
    <button
      mat-button
      mat-raised-button
      (click)="close(false)"
      title="Annuller"
    >
      <i class="material-icons">clear</i>Annuller
    </button>
  </mat-dialog-actions>
</div>
