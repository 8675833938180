import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { CerMetaUserView } from 'src/cer/cer-form/cer-form-persistence.service';

export class CerAppMetaUserSettings {
  public viewNameStandard: string;
  public routers: CerAppMetaUserRoute[];
  public navigation: object[];
}

export class CerAppMetaUserRoute {
  public routeUrl: string;
  public defaultView: string;
  public views: CerMetaUserView[] = [];
};

@Injectable({
  providedIn: 'root'
})
export class CerAppMetaUserService implements OnDestroy {
  private subscriptionManager$: Subscription = new Subscription();

  public settings$: BehaviorSubject<CerAppMetaUserSettings> = new BehaviorSubject<CerAppMetaUserSettings>(new CerAppMetaUserSettings());
  public settings: CerAppMetaUserSettings = null;

  public navigation$: BehaviorSubject<Object[]> = new BehaviorSubject<Object[]>(null);

  public userRouteSave$: Subject<CerAppMetaUserRoute> = new Subject<CerAppMetaUserRoute>();
  public userRouteLoad$: Subject<string> = new Subject<string>();

  constructor() {

    this.subscriptionManager$.add(
      this.settings$.subscribe(settingsObject => this.settings = settingsObject)
    );
  }

  ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  public getRouteOrDefault(routeUrl: string, add: boolean = false): CerAppMetaUserRoute {
    var route = this.getRoute(routeUrl);
    if (!route) {
      route = this.getRouteDefault(routeUrl);
      if (add) {
        this.settings.routers.push(route);
      }
    }
    return route;
  }

  public hasRoute(routeUrl: string): boolean {
    var route = this.getRoute(routeUrl);
    return (route != null);
  }

  public setRoute(routeUrl: string, route: CerAppMetaUserRoute) {
    var routers = this.settings?.routers;
    if (routers) {
      var index = routers.findIndex(r => r.routeUrl == route.routeUrl);
      if (index >= 0) {
        routers[index] = route;
      }
      else {
        routers.push(route);
      }
    }
  }

  public getRoute(routeUrl: string): CerAppMetaUserRoute {
    var route: CerAppMetaUserRoute = this.settings?.routers?.find(r => r.routeUrl == routeUrl);
    return route;
  };

  public getRouteDefault(routeUrl: string, defaultViewName = this.getViewNameStandard()): CerAppMetaUserRoute {
    var route: CerAppMetaUserRoute = { routeUrl: routeUrl, defaultView: defaultViewName ?? this.getViewNameStandard(), views: [] };
    route.views.push(<CerMetaUserView>{ name: this.getViewNameStandard(), type: 'standard', formPersistenceData: null });
    route.views.push(<CerMetaUserView>{ name: 'Alle felter', type: 'complete', formPersistenceData: null });
    return route;
  }

  public getRouterView(routeUrl: string, viewName: string = null): CerMetaUserView {
    var route = this.getRouteOrDefault(routeUrl);
    var view = this.getView(route, viewName);
    return view;
  }

  public getView(route: CerAppMetaUserRoute, viewName: string = null): CerMetaUserView {
    var view = route?.views?.find(v => v.name == (viewName ? viewName : route.defaultView));
    return view;
  }

  /*
  public setRouterView(routeUrl: string, viewName: string = null, formPersistenceData: string): CerMetaUserView {
    var route = this.getRouteOrDefault(routeUrl, true);
    return this.setView(route, viewName, formPersistenceData);
  }
  */

  public setView(route: CerAppMetaUserRoute, viewName: string, isPrimary: boolean, isDefault: boolean, formPersistenceData: string): CerMetaUserView {
    var view: CerMetaUserView = null;
    if (route && viewName != this.getViewNameStandard() && viewName?.length > 0 && formPersistenceData?.length > 0) {
      view = { name: viewName, type: 'custom', isPrimary: isPrimary, isDefault: isDefault, formPersistenceData: formPersistenceData };
      var index = route.views.findIndex(v => v.name == viewName);
      if (index >= 0) {
        route.views[index] = view;
      }
      else {
        route.views.push(view);
      }
      this.userRouteSave$.next(route);
    }
    return view;
  }

  public getViewNameStandard(): string {
    return this.settings?.viewNameStandard;
  }
}
