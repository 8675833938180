<cer-form
  #form
  routerLink="disabled"
  class="flex-col"
  formPersistence="disabled"
>
  <ng-template #mainTemplate>
    <div class="flex-col"></div>
    <div style="height: calc(100% - 54px); padding: 10px">
      <cer-grid
        #grid
        [viewMetadata]="viewMetadata"
        [fieldGroupMetadata]="fieldGroupMetadata"
        [fieldMetadata]="fieldMetadata"
        [toolbarCommands]="toolbarCommands"
        [contextMenuCommands]="contextMenuCommands"
        [keyboardShortcuts]="keyboardShortcuts"
        [selectionMode]="selectionMode"
      ></cer-grid>
    </div>
    <div style="height: 54px; padding-top: 10px; padding-bottom: 10px">
      <mat-dialog-actions>
        <button mat-button mat-raised-button cdkFocusInitial (click)="close()">
          <i class="material-icons">close</i>Luk
        </button>
      </mat-dialog-actions>
    </div>
  </ng-template>
</cer-form>
