import { FieldFormatEnum } from '../../cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class CerAppMetaUserRouteViewFormAdapter extends CerAppFormModelAdapter {

  constructor() {
    
    super();
  }

  public override init() {
    super.init();
    

    this.viewMetadata = {
      name: 'bruger-visninger', dataApiName: 'AppUserRouteView', text: 'Visninger',
      dataApiParams: ['$myUrlFilterEq=' + this.routeService.url$.getValue()],
      primaryKey: 'id', allowEdit: true, allowCreate: false, allowDelete: true, baseFields: ['id']
    };

    this.fieldMetadata =
      [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', visible: false },
      { name: 'name', text: 'Visning',orderBy: 'Ascending', orderByPriority: 2 },
      { name: 'isPrimary', text: 'Primær?', format: FieldFormatEnum.CheckBox, tooltipText: 'Primær visning?', orderBy: 'Ascending', orderByPriority: 1 },
      { name: 'isDefault', text: 'Valgt ved opstart?', format: FieldFormatEnum.CheckBox, tooltipText: 'Brug visning ved opstart?' },
      { name: 'created', text: 'Oprettet', format: FieldFormatEnum.DateLong, allowEdit: false, visibleAdd: false, visibleEdit: false }
      ];

    this.selectionMode = "row";
  }

}
