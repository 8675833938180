import { Injectable, OnDestroy } from '@angular/core';

import { LinkedAttachmentDto, VoucherMessageViewClient, VoucherMessageViewDetailsVm } from '../api';

import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { CerAppFileViewerTabComponent } from 'src/cer-app/cer-app-file-viewer-tab/cer-app-file-viewer-tab.component';
import { CerFormPanesService } from 'src/cer/cer-form/cer-form-panes.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VoucherDataService } from './voucher-data.service';
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';
import { UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum } from 'src/cer/cer-grid/cer-grid-command.service';

@Injectable(
)

export class VoucherAttachmentService implements OnDestroy {


  constructor(private voucherDataService: VoucherDataService, private messageClient: VoucherMessageViewClient, private dialog: CerDialogService, private appDialog: CerAppDialogService) {
  }

  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  private fileViewerTab: CerAppFileViewerTabComponent;
  private form: CerFormComponent;

  public init(form: CerFormComponent, fileViewerTab: CerAppFileViewerTabComponent) {
    this.form = form;
    this.form.commmand.subscribe(cmd => this.onFormCommand(cmd));
    this.fileViewerTab = fileViewerTab;
  }

  private onFormCommand(cmd: UiCommandEvent) {
    if (cmd.source == UiCommandSourceEnum.DesignChange) {
      if ([UiActionTypeEnum.PanesShow, UiActionTypeEnum.Splitter].includes(cmd.actionType)) {
        this.attachmentsResize();
      }
      /*
    switch (cmd.commandId) {
      case 'attachmentsShowHide':
        //this.attachmentsShowHide();
        break;
      case 'attachmentsPageNext':
        //this.attachmentsPageNext();
        break;
      case 'attachmentsPagePrev':
        //this.attachmentsPagePrev();
        break;
      case 'attachmentsHide':
        //this.attachmentsHide();
        break;
        */
    }
  }

  private attachmentsHide() {
    this.form.panesService.close('right');
  }

  private attachmentsResize(addPx: number = 0) {
    if (this.fileViewerTab) {
      this.fileViewerTab.resize();
    }
    /*
    if (this.attachmentsIsActive) {
      var size: string = this.splitterObj.paneSettings[1].size;
      var sizeNum: number = Number(size.substring(0, size.length - 2)) + addPx;
      size = sizeNum + 'px';
      this.splitterObj.paneSettings[1].size = size;
      if (this.fileViewerTab) {
        this.fileViewerTab.resize();
      }
    }
    */
  }

  public attachmentsPageNext() {
    /*
    if (this.attachmentsIsActive && this.detailsVm && this.detailsVm.voucherMessageAttachmentDtoList) {
      if (this.attachmentSelectedIdxDefault + 1 < this.detailsVm.voucherMessageAttachmentDtoList.length) {
        this.attachmentSelectedIdxDefault++;
      }
    }
    */
  }
  public attachmentsPagePrev() {
    /*
    if (this.attachmentsIsActive && this.detailsVm && this.detailsVm.voucherMessageAttachmentDtoList) {
      if (this.attachmentSelectedIdxDefault > 0) {
        this.attachmentSelectedIdxDefault--;
      }
    }
    */
  }

  public messageSplitByAttachmentHere(msgId: number, checkedFileNames: string[], successCallBack: any) {
    VoucherAttachmentService.messageSplitByAttachment(msgId, checkedFileNames, successCallBack, this.messageClient, this.dialog, this.appDialog);
  }

  public static messageSplitByAttachment(msgId: number, checkedFileNames: string[], successCallBack: any, messageClient: VoucherMessageViewClient, dialog: CerDialogService, appDialog: CerAppDialogService) {
    if (checkedFileNames && checkedFileNames.length > 1) {
      var snackBarRef = dialog.snackBar('Splitter besked', 'Vent venligst...', /* duration */ undefined);
      messageClient.splitByAttachment(msgId, checkedFileNames).subscribe(result => {
        snackBarRef.dismiss();
        if (result) {
          if (successCallBack) {
            successCallBack();
          }
          snackBarRef.dismiss();
        }
        else {
          dialog.dialogError("Split kunne ikke gennemføres");
        }
      }, error => {
        dialog.snackBarDismiss();
        appDialog.handleApiError(error);
      });
    }
    else {
      dialog.dialogWarning("Split kræver at mindst to dokumenter markeres");
    }
  }

  // Shortcuts
  public handleKeyDownEvent(event: KeyboardEvent) {
    /*
    //console.log(event);
    if (event.code == 'Escape') {
      if (this.attachmentsIsActive) {
        this.attachmentsShowHide()
        event.preventDefault();
      }
    }
    else if (event.altKey == true && !event.ctrlKey && !event.shiftKey) {
      switch (event.code) {
        case 'KeyY':
          this.apiSyncAll();
          break;
        case 'KeyV':
          this.attachmentsShowHide();
          break;
        case 'KeyO':
          if (this.attachmentsIsActive) {
            this.fileViewerTab.openAttachInNewTab();
          }
          break;
        case 'KeyX':
          if (this.grid.selectedRowIndex) {
            let selectedRecords: object[] = this.grid.getSelectedRecords();
            if (selectedRecords.length > 0) {
              let dto: Dto = <Dto>selectedRecords[0];
              if (dto) {
                if (dto.status == VoucherMessageStatusEnum.Inbox || dto.status == VoucherMessageStatusEnum.Voucher) {
                  this.service.detailFormOpen(dto, false);
                }
              }
            }
          }
          break;
        case 'NumpadAdd':
          if (this.attachmentsIsActive) {
            this.fileViewerTab.pdfZoomIn();
            event.preventDefault();
          }
          break;
        case 'NumpadSubtract':
          if (this.attachmentsIsActive) {
            this.fileViewerTab.pdfZoomOut();
            event.preventDefault();
          }
          break;
        case 'PageUp':
          if (this.attachmentsIsActive) {
            this.attachmentsPagePrev();
            event.preventDefault();
          }
          break;
        case 'PageDown':
          if (this.attachmentsIsActive) {
            this.attachmentsPageNext();
            event.preventDefault();
          }
          break;
      }
    }
    else if (event.altKey == true && !event.ctrlKey && event.shiftKey) {
      switch (event.code) {
        case 'NumpadAdd':
          this.splitterResize(100);
          event.preventDefault();
          break;
        case 'NumpadSubtract':
          this.splitterResize(-100);
          event.preventDefault();
          break;
      }
      */
  }

  public getAttachmentSelectextIdxDefault(attachments: LinkedAttachmentDto[]): number {
    var idx: number = 0;
    var i: number = 0;
    attachments?.forEach(a => {
      if (idx == 0 && a.fileExtension.toLocaleLowerCase() == '.pdf') {
        idx = i
      };
      i++;
    });
    return idx;
  }

  public onUploadChange(args: any) {
    if (args == 'Close') {
      this.attachmentsHide();
    }
    else {
      this.voucherDataService.detailsVmRefresh();
    }
  }
}
