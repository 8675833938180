// Angular
import { Injectable, OnDestroy } from '@angular/core';

// SyncFusion
import { ContextMenuClickEventArgs, ContextMenuItemModel, EditEventArgs, GridComponent, QueryCellInfoEventArgs, ToolbarItem, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { DisplayMode, ItemAlign, ItemModel } from '@syncfusion/ej2-angular-navigations';

// Third party
import { GridDesign } from './cer-grid.component';
import { CerEditMetaService } from '../cer-edit/cer-edit-meta.service';
import { Subject, Subscription } from 'rxjs';
import { CerDataService, FieldMetadata } from '../cer-data/cer-data.service';
import { Tooltip } from '@syncfusion/ej2-angular-popups';

// Action types
export enum UiActionTypeEnum {
  Created = 'created',
  Destroyed = 'destroyed',
  Loaded = 'loaded',
  DataBound = 'dataBound',
  DataSourceChanged = 'dataSourceChanged',
  DataLoad = 'dataLoad',
  DataFKLoad = 'dataFKLoad',
  DataStateChange = 'dataStateChange',
  VirtualScroll = 'virtualscroll',
  Refresh = 'refresh',
  RefreshColumns = 'refreshColumns',
  Redraw = 'redraw',
  Reflow = 'reflow',
  BeginEdit = 'beginEdit',
  Add = 'add',
  Save = 'save',
  Delete = 'delete',
  Cancel = 'cancel',
  Sorting = 'sorting',
  RowSelecting = 'rowSelecting',
  RowSelect = 'rowSelect',
  RowDeselect = 'rowDeselect',
  RowClick = 'rowClick',
  RowDoubleClick = 'rowDoubleClick',
  FilterSearchBegin = 'filtersearchbegin',
  FilterBeforeOpen = 'filterbeforeopen',
  FilterChoiceRequest = 'filterchoicerequest',
  FilterAfterOpen = 'filterafteropen',
  Filtering = 'filtering',
  Paging = 'paging',
  Searching = 'searching',
  ColumnState = 'columnstate',
  BeforeOpenColumnChooser = 'beforeOpenColumnChooser',
  BeforeCloseColumnChooser = 'beforeCloseColumnChooser',
  BeforeOpenAdaptiveDialog = 'beforeOpenAdaptiveDialog',
  BeforeExcelExport = 'beforeExcelExport',
  ViewPort = 'viewPort',
  PanesShow = 'panesShow',
  Splitter = 'splitter',
  ContainerResize = 'containerResisze',
  ActionFailure = 'failure',
  Grouping = 'grouping',
  Ungrouping = 'ungrouping',
  Reorder = 'reorder',
  KeyboardShortcut = 'keyboardShortcut',
  ContextMenuOpen = 'contextMenuOpen',
  ContextMenuItem = 'contextMenuItem',
  ToolbarMenuItem = 'toolbarMenuItem',
  QueryCellInfo = 'queryCellInfo',
  ChatClicked = 'chatClicked',
  CommandClicked = 'commandClicked'
}

// Command sources
export enum UiCommandSourceEnum {
  Toolbar = "toolbar",
  ContextMenu = "contextMenu",
  KeyboardShortcut = "keyboardShortcut",
  Command = "gridCommand",
  ActionBegin = "actionBegin",
  ActionComplete = "actionComplete",
  ActionFailure = "actionFailure",
  DesignChange = "designChange",
  Event = "event"
};

function enumFromString<T>(enm: { [s: string]: T }, value: string): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? value as unknown as T
    : undefined;
}

export type UiCommand = {
  id: string;
  text: string;
  tooltipText?: string;
  cssClass?: string;
  iconCss?: string;
  target?: string;
  align?: ItemAlign;
  separator?: 'all' | 'header' | 'content';
  toolbarShowTextOn?: DisplayMode;
  disabled?: boolean;
  visible?: boolean;
  needSelection?: boolean;
  htmlAttributes?: { [key: string]: string; };
  data?: any;
}

// Keyboard
export type UiKeyboardShortcut = {
  code: string;
  ctrl: boolean;
  alt: boolean;
  shift: boolean;
  cmd: UiCommand;
  allowsPreventDefault?: boolean;
  enabled?: boolean;
  global?: boolean;
}

// Emit events
export type UiCommandEvent = {
  source: UiCommandSourceEnum;
  appComponent?: Object;
  commandId: string;
  args: any;
  rowsData?: any[];
  rowData?: any;
  actionType: UiActionTypeEnum;
  columnName?: string;
  command?: UiCommand;
  cancel?: boolean;
};

export const uiCmdChatClicked: UiCommand = { id: 'uiCmdChatClicked', text: "Klik på chat" };

export const uiCmdSeparator: UiCommand = { id: 'uiCmdSeparator', text: '', separator: 'content' };
export const uiCmdSeparatorHeader: UiCommand = { id: 'uiCmdSeparator', text: '', separator: 'header' };
export const uiCmdResearch: UiCommand = { id: 'Research', text: 'Genlæs data', iconCss: "e-menu-icon e-icons-org e-research", tooltipText: 'Genlæs og opdater visning af data', target: ".e-headercontent", align: "Right", toolbarShowTextOn: "Overflow" };

export const uiCmdColumnGroup: UiCommand = { id: 'ColumnGroup', text: "Grupper", iconCss: "e-menu-icon e-icons e-icon-group", tooltipText: 'Grupper ud fra denne kolonne', target: ".e-headercontent" };
export const uiCmdColumnHide: UiCommand = { id: 'ColumnHide', text: "Skjul kolonne", iconCss: "e-menu-icon e-icons e-icon-hide", tooltipText: 'Skjul denne kolonne', target: ".e-headercontent" };
export const uiCmdColumnsChoose: UiCommand = { id: 'ColumnsChoose', text: "Kolonner ..", iconCss: "e-grid-menu e-icons-org e-columnchooserdiv", tooltipText: 'Tilpas viste kolonnere', target: ".e-headercontent", align: "Right", toolbarShowTextOn: "Overflow" };
export const uiCmdExportExcel: UiCommand = { id: 'ExportExcel', text: "Excel eksport", iconCss: "e-menu-icon e-icons e-excelexport", tooltipText: 'Exporter listens data til Excel', target: ".e-headercontent", align: "Right", toolbarShowTextOn: "Overflow" };
export const uiCmdCopy: UiCommand = { id: 'ClipboardCopy', text: 'Kopier', tooltipText: 'Kopier markering [Ctrl+C]', iconCss: "e-menu-icon e-icons-org e-copy", target: ".e-content" };
export const uiCmdCopyWithHeader: UiCommand = { id: 'ClipboardCopyWithHeader', text: 'Kopier med overskrift', tooltipText: 'Kopier med overskrift [Ctrl+Shift+H]', iconCss: "e-menu-icon e-icons-org e-copy", target: ".e-content" };

export const uiCmdEditStart: UiCommand = { id: 'EditStart', text: "Rediger valgt post", iconCss: "e-menu-icon e-icons", tooltipText: 'Start redigering af den markerede post', target: ".e-headercontent" };
export const uiCmdPagingToggle: UiCommand = { id: 'uiCmdPagingToggle', text: "Vis alt/sider", iconCss: "e-menu-icon e-icons", tooltipText: 'Skift mellem vis alle poster eller vis poster opdelt på sider', target: ".e-headercontent" };
export const uiCmdSearchBarFocus: UiCommand = { id: 'uiCmdSearchBarFocus', text: "Start søgning", iconCss: "e-menu-icon e-icons e-search", tooltipText: 'Søg fritekst', target: ".e-headercontent" };
export const uiCmdSearchClear: UiCommand = { id: 'uiCmdSearchClear', text: "Annuller søgning/detaljer", iconCss: "e-menu-icon e-icons e-search", tooltipText: 'Annuller fritekst søgning/detaljer', target: ".e-headercontent"};
export const uiCmdFilterClear : UiCommand = { id: 'uiCmdFilterClear', text: "Ryd filter", iconCss: "e-menu-icon e-icons e-icon-filter e-filtered", tooltipText: 'Ryd filter/søgning [Alt+L]', target: ".e-headercontent", align: "Right", toolbarShowTextOn: "Overflow" };  
export const uiCmdEditModeTooggle: UiCommand = { id: 'uiCmdEditModeTooggle', text: "Skift redigeringsmåde", iconCss: "", tooltipText: '', target: ".e-headercontent" };
export const uiCmdDebugModeToogle: UiCommand = { id: 'uiCmdDebugModeToogle', text: "Skift fejlfindingstilstand", iconCss: "", tooltipText: '', target: ".e-headercontent" };
export const uiCmdEditCancel: UiCommand = { id: 'uiCmdCancelEdit', text: "Annuller redigering/oprettelse", iconCss: "", tooltipText: '', target: "" };
export const gridToolbarSeparator: ItemModel = { type: 'Separator' };
const gridMenuItemToolbarTitle: ItemModel = {
  id: 'uiCmdToolbarTitle', text: '?', prefixIcon: '', tooltipText: 'Data ikke hentet', align: "Right"
};

@Injectable()
export class CerGridCommandService implements OnDestroy {

  // Context menu
  public gridContextMenuSeparatorHeader: ContextMenuItemModel = { separator: true, target: ".e-headercontent" };
  public gridContextMenuSeparatorContent: ContextMenuItemModel = { separator: true, target: ".e-content" };
  private gridContextMenuItems: ContextMenuItemModel[] = [];
  private gridContextMenuItemsHeader: ContextMenuItemModel[] = [
    this.contextMenuItemCreate(uiCmdColumnGroup),
    this.contextMenuItemCreate(uiCmdColumnHide),
    this.contextMenuItemCreate(uiCmdColumnsChoose),
    this.contextMenuItemCreate(uiCmdExportExcel),
    this.gridContextMenuSeparatorHeader,
    this.contextMenuItemCreate(uiCmdFilterClear),
    this.contextMenuItemCreate(uiCmdResearch)
  ];
  private gridContextMenuItemsContent: ContextMenuItemModel[] = [
    this.contextMenuItemCreate(uiCmdCopy),
    this.contextMenuItemCreate(uiCmdCopyWithHeader)
  ];

  private contextMenuCommands: UiCommand[] = [];

  // Toolbar
  private toolbarCommands: UiCommand[] = [];

  // Keyboard event
  public keyboardShortcuts: UiKeyboardShortcut[] =
    [
      { cmd: uiCmdExportExcel, code: 'KeyE', alt: false, ctrl: true, shift: true, enabled: true },
      { cmd: uiCmdColumnsChoose, code: 'KeyK', alt: false, ctrl: true, shift: true, enabled: true },
      { cmd: uiCmdResearch, code: 'F5', alt: true, ctrl: true, shift: false, enabled: true },
      //{ cmd: uiCmdEditStart, code: 'Space', alt: false, ctrl: false, shift: false, enabled: true },
      //{ cmd: uiCmdPagingToggle, code: 'KeyT', alt: true, ctrl: true, shift: false, enabled: true },
      { cmd: uiCmdSearchBarFocus, code: 'KeyS', alt: true, ctrl: false, shift: false, enabled: true },
      { cmd: uiCmdSearchClear, code: 'KeyS', alt: true, ctrl: true, shift: false, enabled: true },
      { cmd: uiCmdFilterClear, code: 'KeyL', alt: true, ctrl: true, shift: false, enabled: true },
      { cmd: uiCmdCopy, code: 'KeyC', alt: false, ctrl: true, shift: false, enabled: true },
      { cmd: uiCmdCopyWithHeader, code: 'KeyC', alt: false, ctrl: true, shift: true, enabled: true },
      { cmd: uiCmdEditModeTooggle, code: 'KeyE', alt: true, ctrl: true, shift: true, enabled: true },
      { cmd: uiCmdDebugModeToogle, code: 'KeyD', alt: true, ctrl: true, shift: true, enabled: true }
    ];

  // Subscriptions
  private subscriptionManager: Subscription = new Subscription();
  public command$: Subject<UiCommandEvent> = new Subject<UiCommandEvent>();
  public commandPre$: Subject<UiCommandEvent> = new Subject<UiCommandEvent>();  // Emits before command
  public commandPost$: Subject<UiCommandEvent> = new Subject<UiCommandEvent>(); // Emits after command

  // Grid edit
  private grid: GridComponent;
  private design: GridDesign;

  // Constructor
  constructor(private meta: CerEditMetaService, private data: CerDataService) {
  }

  // Subscriptions
  ngOnDestroy(): void {
    this.touchDestroy();
    this.subscriptionManager.unsubscribe();
  }

  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  public init(grid: GridComponent, design: GridDesign, toolbarCommands: UiCommand[], contextMenuCommands: UiCommand[], keyboardShortcuts: UiKeyboardShortcut[]) {
    this.grid = grid;
    this.design = design;
    this.gridInit();
    this.touchInit();
    this.toolbarInit();
    this.toolbarCommandsAdd(toolbarCommands);
    this.contextMenuCommandsInit(contextMenuCommands);
    this.keyboardShortcutsAdd(keyboardShortcuts);
  }

  // State
  private selectionActive(): boolean {
    return this.selectedRecords()?.length > 0;
  }

  private selectedRecords(): Object[] {
    return this.grid.getSelectedRecords();
  }

  private selectedRecord(): Object {
    var selectedRows = this.selectedRecords();
    return (selectedRows.length >= 1) ? selectedRows[0] : null;
  }

  // Disable grid
  public isDisabled(): boolean {
    return !this.isEnabled();
  }

  public isEnabled(): boolean {
    var enabled: boolean = true;
    if (this.grid && this.grid.element) {
      var cls: DOMTokenList = this.grid.element.classList;
      var clsName = 'app-disable';
      if (cls.contains(clsName)) {
        enabled = false;
      }
    }
    return enabled;
  }

  public disable() {
    this.enable(false);
  }

  public enable(enable: boolean = true) {
    if (this.grid && this.grid.element) {
      var cls: DOMTokenList = this.grid.element.classList;
      var clsName = 'app-disable';
      if (enable) {
        if (cls.contains(clsName)) {
          cls.remove(clsName);
          //document.getElementById('GridParent').classList.remove('wrapper');
        }
      }
      else {
        if (!cls.contains(clsName)) {
          this.grid.element.classList.add(clsName);
          //document.getElementById('GridParent').classList.add('wrapper');
        }
      }
    }
  }

  // Command dispatcher
  public cmdDispatchActionBegin(actionType: UiActionTypeEnum, args: any = null) {
    this.cmdDispatch(UiCommandSourceEnum.ActionBegin, args, null, null, actionType);
  }

  public cmdDispatchActionComplete(actionType: UiActionTypeEnum, args: any = null) {
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, args, null, null, actionType);
  }

  public cmdDispatchCommand(actionType: UiActionTypeEnum, args: any = null) {
    this.cmdDispatch(UiCommandSourceEnum.Command, args, null, null, actionType);
  }

  public cmdDispatch(source: UiCommandSourceEnum, commandId: string, args: any, columnName: string = null, actionType: UiActionTypeEnum = null, rowsData: any[] = null, rowData: any = null, command: UiCommand = null): boolean {
    var event: UiCommandEvent = { source: source, commandId: commandId, args: args, cancel: false, columnName: columnName, actionType: actionType , rowsData: rowsData, rowData: rowData, command: command };

    if (this.isDisabled()) {
      return true;
    }

    this.commandPre$.next(event);

    if (!event.cancel) {
      this.cmdDispatchInternal(event);
    }

    if (!event.cancel) {
      this.command$.next(event);
    }

    if (!event.cancel) {
      this.commandPost$.next(event);
    }

    if (event.cancel === true && event.args) {
      event.args.cancel = true;
    }

    return event.cancel;
  }

  private cmdDispatchInternal(event: UiCommandEvent) {
    switch (event.source) {
      case UiCommandSourceEnum.DesignChange:
        this.toolbarReflow();
        break;
      case UiCommandSourceEnum.Command:
        switch (event.actionType) {
          case UiActionTypeEnum.Reflow:
            this.toolbarReflow();
            break;
        }
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.Created:
            this.toolbarInitComplete();
            break;
          case UiActionTypeEnum.Loaded:
            this.keyboardShortcutsDefaultModify();
            break;
          case UiActionTypeEnum.DataBound:
            this.commandsEnableBySelection(false);
            break;
          case UiActionTypeEnum.Refresh:
            this.toolbarTitleResetCount();
            break;
          case UiActionTypeEnum.RowSelect:
            this.commandsEnableBySelection(true);
            break;
          case UiActionTypeEnum.RowDeselect:
            this.commandsEnableBySelection(false);
            break;
        }
        break;
    }

  }

  public reflow() {
    this.cmdDispatchCommand(UiActionTypeEnum.Reflow);
  }

  public refresh() {
    this.cmdDispatchCommand(UiActionTypeEnum.Refresh);
  }

  public refreshColumns() {
    this.cmdDispatchCommand(UiActionTypeEnum.RefreshColumns);
  }

  /*******************
   * Grid Events
   ******************/

  private gridInit() {
    var g: GridComponent = this.grid;
    this.manage(g.load.subscribe((e: any) => this.gridOnLoad(e)));
    this.manage(g.created.subscribe((e: any) => this.gridOnCreated(e)));
    this.manage(g.destroyed.subscribe((e: any) => this.gridOnDestroyed(e)));
    this.manage(g.dataStateChange.subscribe((e: any) => this.gridOnDataStateChange(e)));
    this.manage(g.beforeDataBound.subscribe((e: any) => this.gridOnDataBoundBegin(e)));
    this.manage(g.dataBound.subscribe((e: any) => this.gridOnDataBoundComplete(e)));
    this.manage(g.dataSourceChanged.subscribe((e: any) => this.gridOndataSourceChangedComplete(e)));
    this.manage(g.actionFailure.subscribe((e: any) => this.gridOnActionFailure(e)));
    this.manage(g.actionBegin.subscribe((e: any) => this.gridOnActionBegin(e)));
    this.manage(g.actionComplete.subscribe((e: any) => this.gridOnActionComplete(e)));
    this.manage(g.rowSelecting.subscribe((e: any) => this.gridOnRowSelecting(e)));
    this.manage(g.rowSelected.subscribe((e: any) => this.gridOnRowSelected(e)));
    this.manage(g.rowDeselected.subscribe((e: any) => this.gridOnRowDeselected(e)));
    this.manage(g.commandClick.subscribe((e: any) => this.gridOnCommandClick(e)));
    this.manage(g.recordClick.subscribe((e: any) => this.gridOnRecordClick(e)));
    this.manage(g.recordDoubleClick.subscribe((e: any) => this.gridOnRecordDoubleClick(e)));
    this.manage(g.toolbarClick.subscribe((e: any) => this.gridOnToolbarClick(e)));
    this.manage(g.contextMenuOpen.subscribe((e: any) => this.gridOnContextMenuOpen(e)));
    this.manage(g.contextMenuClick.subscribe((e: any) => this.gridOnContextMenuClick(e)));
    this.manage(g.beforeOpenAdaptiveDialog.subscribe((e: any) => this.gridOnBeforeOpenAdaptiveDialog(e)));
    this.manage(g.beforeOpenColumnChooser.subscribe((e: any) => this.gridOnBeforeOpenColumnChooser(e)));
    this.manage(g.queryCellInfo.subscribe((e: any) => this.gridOnQueryCellInfo(e)));
    this.manage(g.cellEdit.subscribe((e: any) => this.gridOnCellEdit(e)));
    this.manage(g.headerCellInfo.subscribe((e: any) => this.gridOnHeaderCellInfo(e)));


    if (this.data) {
      if (!this.data.dataManager) {
        this.manage(this.data.data$.subscribe(data => this.gridOnDataLoad(data)));
      }
      this.manage(this.data.fkDataLoaded$.subscribe(dataLoaded => { if (dataLoaded) { this.gridOnDataFKLoad(); } }));
    }
  }

  private gridOnLoad(args: any) {
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, args, null, UiActionTypeEnum.Loaded);
  }

  private gridOnCreated(args: any) {
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, args, null, UiActionTypeEnum.Created);
  }

  private gridOnDestroyed(args: any) {
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, args, null, UiActionTypeEnum.Destroyed);
  }

  private gridOnDataStateChange(args: any) {
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, args, null, UiActionTypeEnum.DataStateChange);
  }

  private gridOnDataLoad(data: object[]) {
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, data, '', UiActionTypeEnum.DataLoad, null);
  }

  private gridOnDataFKLoad() {
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, null, '', UiActionTypeEnum.DataFKLoad, null);
  }

  private gridOnDataBoundBegin(args: any) {
    this.onDataBoundActionBegin(args);
    this.cmdDispatch(UiCommandSourceEnum.ActionBegin, null, args, null, UiActionTypeEnum.DataBound);
  }

  protected gridOnDataBoundComplete(args: any) {
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, args, null, UiActionTypeEnum.DataBound, this.grid.currentViewData);
  }

  protected gridOndataSourceChangedComplete(args: any) {
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, args, null, UiActionTypeEnum.DataSourceChanged);
  }

  private gridOnActionFailure(e: any) {
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, e, '', UiActionTypeEnum.ActionFailure);
  }

  private gridRequestTypeToActionType(requestType: string): UiActionTypeEnum {
    var actionType = enumFromString(UiActionTypeEnum, requestType);
    if (actionType === undefined) {
      console.info("**** Warning: Unhandled action type: " + requestType);
    }
    return actionType;
  }

  public gridOnActionBegin(args: any) {
    var actionType = this.gridRequestTypeToActionType(args.requestType);
    var columnName = args.column?.field;
    var rowData = args.rowData;
    this.cmdDispatch(UiCommandSourceEnum.ActionBegin, null, args, columnName, actionType, rowData);
  }

  private gridOnActionComplete(args: any) {
    var actionType = this.gridRequestTypeToActionType(args.requestType);
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, args, null, actionType, args.rowData);
  }

  private gridOnRowSelecting(args: any) {
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, args, null, UiActionTypeEnum.RowSelecting, args.data);
  }

  private gridOnRowSelected(args: any) {
    this.commandsEnableBySelection(true);
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, args, null, UiActionTypeEnum.RowSelect, args.data);
  }

  private gridOnRowDeselected(args: any) {
    this.commandsEnableBySelection(this.selectionActive());
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, args, null, UiActionTypeEnum.RowDeselect, args.data);
  }

  private gridOnCommandClick(args: any) {
    this.cmdDispatch(UiCommandSourceEnum.Command, null, args, args.column?.name, UiActionTypeEnum.CommandClicked, args.rowData);
  }

  private gridOnRecordClick(args: any) {
    var columnName = args.column?.field;
    var rowData = args.rowData;
    this.cmdDispatch(UiCommandSourceEnum.ActionBegin, null, args, columnName, UiActionTypeEnum.RowClick, rowData);
  }

  private gridOnRecordDoubleClick(args: any) {
    var columnName = args.column?.field;
    var rowData = args.rowData;
    this.cmdDispatch(UiCommandSourceEnum.ActionBegin, null, args, columnName, UiActionTypeEnum.RowDoubleClick, rowData);
  }

  private gridOnToolbarClick(args: any) {
    args.rowData = this.selectedRecord();
    var id: string = args.item?.id;
    var command: UiCommand = (id?.length > 0) ? this.toolbarCommands.find(c => c.id == id) : null;
    this.cmdDispatch(UiCommandSourceEnum.Toolbar, args?.item?.id, args, null, UiActionTypeEnum.ToolbarMenuItem, args.rowData, command);
  }

  private gridOnContextMenuOpen(args: any) {
    args.event = args.event ?? this.touchEventArgs;  // Touch event arguments are passed as event property
    if (this.isDisabled() || (args.rowInfo && !this.contextMenuItemsIsActive(args.rowInfo.target))) {
      args.cancel = true;
    }
    this.cmdDispatch(UiCommandSourceEnum.ContextMenu, args.id, args, args.column ? args.column.field : null, UiActionTypeEnum.ContextMenuOpen, args.rowInfo.rowData, (<any>args.item)?.command);
  }

  public gridOnContextMenuClick(args: ContextMenuClickEventArgs) {
    var id: string = args.item?.id;
    var command: UiCommand = (id?.length > 0) ? this.contextMenuCommands.find(c => (c != null && c.id == id)) : null;
    this.cmdDispatch(UiCommandSourceEnum.ContextMenu, args.item.id, args, args.column ? args.column.field : null, UiActionTypeEnum.ContextMenuItem, this.grid.currentViewData, args.rowInfo.rowData, command);
  }

  private gridOnBeforeOpenColumnChooser(args: any) {
    this.cmdDispatch(UiCommandSourceEnum.ActionBegin, args.id, args, null, UiActionTypeEnum.BeforeOpenColumnChooser);
  }

  private gridOnBeforeOpenAdaptiveDialog(args: any) {
    this.cmdDispatch(UiCommandSourceEnum.ActionBegin, args.id, args, null, UiActionTypeEnum.BeforeOpenAdaptiveDialog);
  }

  private gridOnCellEdit(args: EditEventArgs) {
    //this.cellEditFields.filter(f => f.field == args.column.field)
    //.forEach(f => f.onCellEdit(args));
  }

  // Header cell info
  public gridOnHeaderCellInfo(args: any) {
    if (args.cell?.column) {
      var column = args.cell.column;
      if (column) {
        var fieldMeta: FieldMetadata = column.meta as FieldMetadata;
        if (fieldMeta?.tooltipText?.length > 0 && fieldMeta.tooltipText != fieldMeta.text) {
          var tooltip = new Tooltip({
            position: 'TopCenter',
            content: fieldMeta.tooltipText,
            opensOn: 'Auto',
            openDelay: 1000
          });
          tooltip.appendTo(args.node);
        }
      }
    }
  }

  // Query cell info
  public queryCellInfoEnable: boolean = false;
  private gridOnQueryCellInfo(args: QueryCellInfoEventArgs) {
    if (this.queryCellInfoEnable) {
      this.cmdDispatch(UiCommandSourceEnum.ActionBegin, null, args, null, UiActionTypeEnum.QueryCellInfo);
    }
  }

  public onChatColumnLinkClicked(args: any) {
    let rowInfo = this.grid.getRowInfo(args.target); // Get row information
    args.rowInfo = rowInfo;
    var rowData = rowInfo.rowData;
    this.cmdDispatch(UiCommandSourceEnum.ActionComplete, null, args, 'chat', UiActionTypeEnum.ChatClicked, this.grid.currentViewData, rowData);
  }

  /***************
   * Touch events
   ***************/

  //private touchContextMenuOpenFn: any;

  // Grid’s created event handler
  private touchInit() {
    // Since the Grid element is the context menu’s target, the events are bound to the Grid element
    var options : any = { passive: true };
//    this.grid.element.addEventListener('touchstart', this.onTouchStart.bind(this), options);
//    this.grid.element.addEventListener('touchend', this.onTouchEnd.bind(this), options);

    //this.touchContextMenuOpenFn = this.grid.contextMenuModule.contextMenu.beforeOpen;
    //this.grid.contextMenuModule.contextMenu.beforeOpen.bind(this.touchContextMenuOpen);
  }

  private touchDestroy() {
    var options : any = { passive: true };

//    this.grid.element.removeEventListener('touchstart', this.onTouchStart.bind(this), options);
//    this.grid.element.removeEventListener('touchend', this.onTouchEnd.bind(this), options);
  }

  /*
  private touchContextMenuOpen(e: any) {
    // The Grid context menu beforeOpen event is overridden in order to pass the touch event arguments to is
    // The event arguments stored from 'onlongtouch' event is stored to the event property and the source method is called
    this.touchContextMenuOpenFn.call(this, e);
  }
  */

  private touchTimer: any = null;
  private onTouchStart(e: any) {
    e.preventDefault();
    if (!this.touchTimer) {
      var longPressDuration: number = 500;
      this.touchTimer = setTimeout(this.onTouchLongTouch.bind(this, e), longPressDuration);
    }
  }

  private onTouchEnd(e: any) {
    if (this.touchTimer) {
      clearTimeout(this.touchTimer);
      this.touchTimer = null;
    }
  }

  private touchEventArgs: any;  // Read in gridOnContextMenuOpen(), written in onTouchLong()
  private onTouchLongTouch(e: any) {
    this.touchTimer = null;
    this.touchEventArgs = e; // Store event arguments to be used in context menu open event
    this.grid.contextMenuModule.contextMenu.open(e.touches[0].pageY, e.touches[0].pageX);
  }

  /******************************
  * Toolbar + context menu state
  ******************************/

  private commandsEnableBySelection(selectionActive: boolean) {
    this.grid.toolbar.forEach(i => this.toolbarItemEnabledBySelection(i, selectionActive));
    this.grid.contextMenuItems.forEach(i => this.contextMenuEnabledBySelection(i, selectionActive));
  }

  /*********
  * Toolbar 
  *********/

  private gridToolbar: (ToolbarItems | string | ItemModel | ToolbarItem)[] =
    [
      this.toolbarItemCreate(uiCmdResearch),
      this.toolbarItemCreate(uiCmdColumnsChoose),
      //this.toolbarItemCreate(uiCmdClearFilter),
      this.toolbarItemCreate(uiCmdExportExcel),
      'Edit',
      'Update',
      'Cancel',
      'Add',
      'Delete',
      'Search'
    ];

  private toolbarReflow() {
    if (this.grid && this.grid.toolbarModule && this.grid.toolbarModule.toolbar) {
      this.grid.toolbarModule.toolbar.refreshOverflow();
    }
  }

  private toolbarInit() {
    this.toolbarCRUDInit()
    this.toolbarTitleInit()

    this.gridToolbar = this.gridToolbar.filter(
      mi => this.toolbarSetupItemVisible(mi)
    );

    this.grid.toolbar = this.gridToolbar;
  }

  private toolbarInitComplete() {
    this.grid.toolbarModule.toolbar.overflowMode = 'Popup';
    var simple: boolean = (this.design.simpleToolbar == true);
    var simpleTextHidesPrefixIcon: string[] = ['e-edit', 'e-add', 'e-delete'];

    this.grid.toolbarModule.toolbar.items.forEach(i => {
      if (simple && simpleTextHidesPrefixIcon.includes(i.prefixIcon)) {
        i.showTextOn = 'Overflow';
      }
      switch (i.prefixIcon) {
        case 'e-edit':
          i.tooltipText = 'Rediger valgt post [F2]';
          break;
        case 'e-add':
          i.tooltipText = 'Tilføj ny post [Insert]';
          break;
        case 'e-delete':
          i.tooltipText = 'Slet valgt post [Delete]';
          break;
      }
      //         i.showAlwaysInPopup = true
    });
    this.toolbarReflow();
  }

  public toolbarItemRemove(menuItem: any) {
    this.gridToolbar = this.gridToolbar.filter(mi => mi !== menuItem);
  }

  public toolbarItemCreate(command: UiCommand): ItemModel {
    if (command.separator != undefined) {
      return gridToolbarSeparator;
    }

    var i: ItemModel = {
      id: command.id,
      text: command.text,
      cssClass: command.cssClass,
      htmlAttributes: command.htmlAttributes,
      tooltipText: command.tooltipText,
      prefixIcon: command.iconCss,
      align: command.align,
      disabled: command.disabled,
      showTextOn: command.toolbarShowTextOn ? command.toolbarShowTextOn : "Both"
    };
    (i as any).command = command;
    if (command.needSelection === true) {
      (i as any).needSelection = true;
    }

    return i;
  }

  public toolbarCommandsAdd(customCmd: UiCommand[]) {
    if (customCmd) {
      customCmd.reverse().forEach(c => {
        this.toolbarCommands.push(c);
        this.gridToolbar.unshift(this.toolbarItemCreate(c))
      });
    }
  }


  private toolbarItemEnabledBySelection(item: (string | ItemModel | ToolbarItem), selectionActive: boolean) {
    if (typeof item === "object") {
      if ((<any>item).needSelection === true) {
        this.grid.toolbarModule.enableItems([item.id], selectionActive);
      }
    }
  }

  public toolbarSetupItemVisible(mi: ToolbarItems | string | ItemModel | ToolbarItem): boolean {
    var visible: boolean = true;

    if (this.design.simpleToolbar) {
      if (typeof mi === 'string') {
        switch (<string>mi) {
          case 'Search':
            visible = false;
            break;
        }
      }
      else if ((<any>mi).id) {
        switch ((<any>mi).id) {
          case uiCmdResearch.id:
          case uiCmdColumnsChoose.id:
          case uiCmdExportExcel.id:
          case uiCmdColumnGroup.id:
          case uiCmdResearch.id:
          case uiCmdFilterClear.id:
            visible = false;
            break;
        }
      }
    }

    return visible;
  }

  private toolbarCRUDInit() {
    if (this.meta.viewMetadata) {
      var viewMetadata = this.meta.viewMetadata;
      if (viewMetadata.allowEdit == false) {
        this.toolbarItemRemove('Edit');
      }
      if (viewMetadata.allowCreate == false) {
        this.toolbarItemRemove('Add');
      }
      if (viewMetadata.allowDelete == false) {
        this.toolbarItemRemove('Delete');
      }
    }
    if ((viewMetadata.allowCreate == false && viewMetadata.allowCreate == false) || (this.grid.editSettings.mode == "Dialog")) {
      this.toolbarItemRemove('Cancel');
      this.toolbarItemRemove('Update');
    }
  }

  // Toolbar title
  public gridToolbarItemTitle: ItemModel;
  public gridToolbarItemTitleText: string;

  private toolbarTitleInit() {
    if (this.design?.toolbarTitleFromMetadata) {
      var meta = this.meta.viewMetadata;
      if (meta?.text) {
        this.gridToolbarItemTitle = Object.assign([], gridMenuItemToolbarTitle);
        this.gridToolbarItemTitle.text = meta.text;
        this.gridToolbarItemTitleText = meta.text;
        this.gridToolbar.push(this.gridToolbarItemTitle);
      }
    }
  }

  private onDataBoundActionBegin(args: any) {
    if (args.action !== 'edit') {
      var rowCount: number = args.count;
      var pageIndex: number = 0;
      var pageSize: number = 0;

      var q = args?.query?.queries;
      if (q?.length > 0) {
        var f = args.query.queries.find((f: any) => f.fn == 'onPage' && f.e?.pageIndex > 0 && f.e?.pageSize > 0);
        if (f) {
          pageIndex = f.e.pageIndex;
          pageSize = f.e.pageSize;
        }
      }
      //this.debug('records read: ' + pageSize + '*' + pageIndex + ' total ' + rowCount);
      this.toolbarTitleUpdateCount(pageSize * pageIndex, rowCount);
    }
  }

  private toolbarTitleUpdateCountStyle(startPct: number): string {
    var endPct: number = Math.min(startPct + 10, 100);
    var gradiant = 'color: white; width: auto; background: rgb(63,81,181); background: linear-gradient(90deg, rgba(63,81,181,1) 0%, rgba(63,81,181,1) ' + startPct + '%, rgba(150,150,150,1) ' + endPct + '%, rgba(150,150,150,1) 100%)';
    return gradiant;
  }

  private fetchPct: number = 0;
  private rowsTotal: number = 0;

  private toolbarTitleResetCount() {
    this.fetchPct = 0;
    this.rowsTotal = 0;
  }

  public toolbarTitleUpdateCount(rowsFetched: number, rowsTotal: number) {
    var fetchPct: number = rowsTotal ? Math.min(Math.trunc(rowsFetched * 100 / rowsTotal), 100) : 100;
    if (fetchPct >= this.fetchPct || rowsTotal != this.rowsTotal) {
      this.fetchPct = fetchPct;
      this.rowsTotal = rowsTotal;
      this.toolbarTitleUpdate(this.fetchPct, this.rowsTotal);
    }
  }

  private toolbarTitleUpdate(fetchPct: number, rowsTotal: number) {
    if (this.design?.toolbarTitleFromMetadata && this.gridToolbarItemTitle?.id) {
      var title: string = this.gridToolbarItemTitleText;
      var tooltip = title + ': ' + rowsTotal + ' ' + (rowsTotal == 1 ? 'post' : 'poster') + ' (' + fetchPct + '% hentet)';
      var countTxt: string = rowsTotal.toString();
      if (this.design?.virtualPaging == true) {
        if (fetchPct < 100) {
          countTxt += ' (' + fetchPct + '%)';
        }
      }
      var style = this.toolbarTitleUpdateCountStyle(fetchPct);
      var button = this.grid.element.querySelector('#' + this.gridToolbarItemTitle.id);
      if (button) {
        button.textContent = countTxt;
        if (!button.classList.contains('e-chip')) {
          button.classList.remove('e-btn');
          button.classList.remove('e-tbar-btn');
          button.classList.remove('e-tbtn-txt');
          //button.classList.remove('e-lib');
          button.classList.add('e-chip-list');
          button.classList.add('e-chip');
          //button.classList.add('e-info');
          button.parentElement.classList.remove('e-toolbar-text');
        }
        //          button.setAttribute('style', style);
        button.setAttribute('title', tooltip);
      }
      if (this.grid?.toolbarModule?.toolbar) {
        this.grid.toolbarModule.enableItems([this.gridToolbarItemTitle.id], true);
      }
    }
  }

  /***************
   * Context menu
   ***************/

  public contextMenuOpen(e: any) {
    this.grid.contextMenuModule.contextMenu.open(e.clientY, e.clientX);
  }

  public contextMenuEnabledBySelection(item: any, selectionActive: boolean) {
    if (typeof item === "object") {
      if (item.needSelection === true) {
        this.grid.contextMenuModule.contextMenu.enableItems([item.id], selectionActive);
      }
    }
  }

  public contextMenuItemCreate(cmd: UiCommand, target: string = null): ContextMenuItemModel {
    if (cmd.separator == 'header') {
      return this.gridContextMenuSeparatorHeader;
    }
    if (cmd.separator == 'content') {
      return this.gridContextMenuSeparatorContent;
    }
    var i: ContextMenuItemModel = { id: cmd.id, text: cmd.text, iconCss: cmd.iconCss, target: target ? target : cmd.target };
    if (cmd.needSelection) {
      (<any>i).needSelection = true;
    }
    return i;
  }

  public contextMenuCommandsInit(customCmd: UiCommand[]) {
    if (customCmd?.length > 0) {
      this.contextMenuCommands = customCmd;

      // Header
      var header = customCmd.filter(c => (c.target == '.e-header' || c.separator == 'header') && c.visible !== false);

      if (header.length > 0) {
        this.gridContextMenuItemsHeader.unshift(this.gridContextMenuSeparatorHeader);
        header.reverse().forEach(c => this.gridContextMenuItemsHeader.unshift(this.contextMenuItemCreate(c)));
      }

      // Content
      var content = customCmd.filter(c => (c.target == '.e-content' || c.separator == 'content') && c.visible !== false);
      if (content.length > 0) {
        this.gridContextMenuItemsContent.unshift(this.gridContextMenuSeparatorContent);
        content.reverse().forEach(c => this.gridContextMenuItemsContent.unshift(this.contextMenuItemCreate(c)));
      }

    }

    // Concat Header+Content
    this.gridContextMenuItems = this.gridContextMenuItemsHeader.concat(this.gridContextMenuItemsContent);
    this.grid.contextMenuItems = this.gridContextMenuItems;
  }

  public contextMenuItemsIsActive(target: HTMLElement): boolean {
    var hasItemActive: boolean = false;

    if (this.grid.isEdit) {
      return false;
    }

    if (target) {
      for (let i of this.gridContextMenuItems) {
        if (this.contextMenuItemIsActive(i, target)) {
          hasItemActive = true;
          break;
        }
      }

    }
    return hasItemActive;
  }

  public contextMenuItemIsActive(i: ContextMenuItemModel, target: HTMLElement): boolean {

    if (!i.target || i.target.length <= 1) {
      return true;
    }

    var selector = i.target.substr(1, i.target.length);

    if (selector == '.e-content' && target?.classList?.contains('e-rowcell')) {
      return true;
    }

    while (target) {
      if (target.classList?.contains(selector)) {
        return true;
      }
      target = target.parentElement;
    }

    return false;
  }

  /*********************
   * Keyboard shortcuts
   ********************/

  public gridOnKeyDown(e: KeyboardEvent) {
    if (this.isDisabled()) {
      return;
    }
    var stop: boolean = false;
    this.keyboardShortcuts.forEach(shortcut => {
      if (this.keyboardShortcutMatch(e, shortcut)) {
        var rowData = this.selectedRecord();
        var cancel: boolean = this.cmdDispatch(UiCommandSourceEnum.KeyboardShortcut, shortcut.cmd.id, e, null, UiActionTypeEnum.KeyboardShortcut, this.grid.currentViewData, rowData, shortcut.cmd);
        if (cancel || shortcut.allowsPreventDefault) {
          stop = true;
        }
      }
    });
    if (stop) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  public keyboardShortcutsAdd(shortcuts: UiKeyboardShortcut[]) {
    if (shortcuts) {
      shortcuts.forEach(s => this.keyboardShortcuts.unshift(s));
    }
  }

  public keyboardShortcutEnable(cmdId: string, enable: boolean) {
    //  this.shortcuts.filter(s => s.);
    this.keyboardShortcuts.forEach(shortcut => { });
  }

  public keyboardShortcutMatch(e: KeyboardEvent, s: UiKeyboardShortcut): boolean {
    var match: boolean = (e.code == s.code) && (e.altKey == s.alt) && (e.shiftKey == s.shift) && (e.ctrlKey == s.ctrl) && (s.enabled || s.enabled === undefined);
    return match;
  }

  private keyboardShortcutsDefaultModify() {
    if (this.grid) {
      var configs = (<any>this.grid).keyConfigs;
      if (configs) {
        // Remove default - remapped to 'AddRecord'
        var shiftEnter = configs.shiftEnter;
        if (shiftEnter?.length > 0) {
          configs.shiftEnter = '';
          configs.insert = configs.insert?.length > 0 ? configs.insert + ',' + shiftEnter : shiftEnter;
        }

        // Remove defualt - remapped to 'EditRecord'
        /*
        var enter = configs.enter;
        if (enter?.length > 0) {
          configs.enter = '';
          configs.f2 = configs.f2?.length > 0 ? configs.f2 + ',' + enter : enter;
        }
        */
      }
    }
  }
}  
