import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { AlertDialogArgs, ConfirmDialogArgs, Dialog, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { CerLocaleService } from '../cer-locale/cer-locale.service';

@Injectable({
  providedIn: 'root'
})
export class CerDialogService {

  private sfDialog: Dialog;

  constructor(private localeService: CerLocaleService, private matSnackBar: MatSnackBar) { }

  public snackBarError(text: string | string[], title: string = "Fejl", duration: any = 4000) : any {
    return this.snackBar(text, title, duration); 
  }

  public snackBar(text: string | string[], title: string = "Information", duration: any = 4000) : any {
    var content: string = this.localeService.textToHtml(text);
    console.info('Snack: ' + text + (title ? " (" + title + ")" : ""));
    return this.matSnackBar.open(content, title, { duration: duration });
  }

  public snackBarDismiss() {
    this.matSnackBar.dismiss();
  }
  
  public getDialogContent(): HTMLDivElement
  {
    return (<any> this.sfDialog).contentEle;
  }

  public confirmPrompt(text: string | string[], title: string = "Bekræft"): Promise<boolean> {
    var content: string = this.localeService.textToHtml(text);
    var promise = new Promise<boolean>(resolve => {
      var args: ConfirmDialogArgs = {
        title: title,
        content: content,
        okButton: {
          text: 'OK',
          click: (click) => {
            this.sfDialog.hide();
            this.sfDialog = null;
            resolve(true);
          }
        },
        cancelButton: {
          text: 'Annuller',
          click: (click) => {
            this.sfDialog.hide();
            this.sfDialog = null;
            resolve(false);
          }
        },
        position: { X: 'center', Y: 'center' }
      };
      if (this.sfDialog) {
        this.sfDialog.hide();
        this.sfDialog = null;
      }
      this.sfDialog = DialogUtility.confirm(args);
      var dlg: any = this.sfDialog;
      var buttons: HTMLCollection = dlg?.ftrTemplateContent?.children;
      if (buttons) {
        for (let button of <HTMLButtonElement[]>(Array.from(buttons).filter(e => e.tagName == 'BUTTON'))) {
          button.style.textTransform = "none";
        }
      }
    });
    return promise;
  }

  public showInfo(txt:  string | string[], title: string = null) {
    var content: string = this.localeService.textToHtml(txt);
    var dlg: AlertDialogArgs = {
      title: title ? title : "Information",
      content: content
    };
    DialogUtility.alert(dlg);
  }

  public errorPrompt(txt: string | string[], title: string = "Fejl"): Promise<boolean> {
    console.error('Error: ' + txt + (title ? " (" + title + ")" : ""));
    return this.infoPrompt(txt, title);
  }

  public warningPrompt(text: string | string[], title: string = "Advarsel"): Promise<boolean> {
    var content: string = this.localeService.textToHtml(text);

    return this.infoPrompt(content, title);
  }

  public infoPrompt(text: string | string[], title: string = "Information", resolveValue: boolean = true): Promise<boolean> {
    var content: string = this.localeService.textToHtml(text);
    var promise = new Promise<boolean>(resolve => {
      if (this.sfDialog != null) {
        this.sfDialog.hide();
        this.sfDialog = null;
      }
      this.sfDialog = DialogUtility.alert({
        title: title,
        content: content,
        okButton: {
          text: 'OK',
          click: (click) => {
            this.sfDialog.hide();
            this.sfDialog = null;
            resolve(resolveValue);
          }
        },
        position: { X: 'center', Y: 'center' }
      });
    });
    return promise;
  };

  public confirmPromiseAutoOk(): Promise<boolean> {
    var autoPromise = new Promise<boolean>((resolve, reject) => {
      resolve(true);
    });
    return autoPromise;
  }

  public dialogConfirm(text: string, title: string = "Bekræft"): Promise<boolean> {
    return this.confirmPrompt(text, title);
  }

  public dialogError(text: string, title: string = "Fejl") {
    this.errorPrompt(text, title);
  }

  public dialogWarning(text: string, title: string = "Advarsel") {
    this.errorPrompt(text, title);
  }

  public dialogInfo(text: string, title: string = "Information") {
    this.errorPrompt(text, title);
  }

  public isDtosEqual(dto1: any, dto2: any): boolean {
    var ret: boolean = true;
    if (dto1 && dto2) {
      Object.keys(dto1).forEach(key => { if (!this.isValuesEqual(dto1[key], dto2[key])) { ret = false; } });
    }
    return ret;
  }

  public isValuesEqual(f1 : any, f2: any): boolean {
    var ret: boolean = true;
    if (f1 instanceof Date) {
      ret = this.localeService.isDatesEqual(<Date>f1, <Date>f2);
    }
    else {
      ret = (f1 == f2);
    }
    return ret;
  }

}
