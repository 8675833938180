// Angular
import { Component, OnInit, OnDestroy } from '@angular/core';

// WebApi
import {
  VoucherMessageViewDetailsVm as DetailsVm,
} from "../../api";

import { Subscription } from 'rxjs';
import { VoucherDataService } from '../voucher-data.service';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialogConfig } from '@angular/material/dialog';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';

@Component({
  selector: 'voucher-lookup',
  templateUrl: './voucher-lookup.component.html',
  providers: [VoucherDataService]
})

export class VoucherLookupComponent implements OnInit, OnDestroy {
  constructor(private voucherDataService: VoucherDataService, private route: ActivatedRoute, private routeService: CerAppRouteService,
    private appDialogService: CerDialogService) {
  }

  ngOnInit(): void {
    this.manage(this.route.data.subscribe(data => this.loadQueryData(data)));
    this.manage(this.route.paramMap.subscribe(params => this.loadParams(params)));
    this.manage(this.voucherDataService.detailsVm$.subscribe(vm => this.onDetailVm(vm)));
  }

  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  private loadQueryUrl(url: any) {
    console.log(url);
  }

  private showChat: boolean;
  private dataReady: boolean = false;
  private loadQueryData(data: any) {
    this.showChat = data.showchat ?? false;
    this.dataReady = true;
    this.getDetailVm();
  }

  // Cmd line parameters
  private voucherNum: number;
  private msgId: number;
  private paramsReady: boolean = false;
  private loadParams(params: any) {
    this.voucherNum = params.get("vouchernum") ? Number(params.get("vouchernum")) : null;
    this.msgId = params.get("msgid") ? Number(params.get("msgid")) : null;
    this.paramsReady = true;
    this.getDetailVm();
  }

  private getDetailVm() {
    if (this.dataReady && this.paramsReady)
    if (this.msgId) {
      this.voucherDataService.detailsVmGet(this.msgId);
    }
    else if (this.voucherNum) {
      this.voucherDataService.detailsVmGetByVoucherNum(this.voucherNum);
    }
    else {
      this.appDialogService.dialogError("Besked eller bilag er ikke angivet","Bilag ikke fundet");
    }
  }

  private canOpenDialog: boolean = true;
  private onDetailVm(vm: DetailsVm): void {
    var dto = vm?.voucherMessageViewDto;
    if (dto && this.canOpenDialog) {
      this.canOpenDialog = false;
      var config: MatDialogConfig = this.voucherDataService.voucherDetailsDialogGetConfig(dto, true, false, false, 0, false, this.showChat);
      config.width = '100vw';
      config.height = '100vh';
      config.maxWidth = '100vw';
      this.voucherDataService.voucherDetailsOpen(dto, config).then
      (result => {
          this.routeService.redirectToUrl('/',true);
      });
    }
  }
}
