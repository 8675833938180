import { Component } from '@angular/core';
import { CerAppApplicationInsightsService } from 'src/cer-app/cer-application-insights/cer-app-application-insights.service';
import { VoucherSidenavStateService } from '../../app/voucher/voucher-sidenav-state.service';
import { CerBaseRealtimeService } from 'src/cer-base/cer-base-realtime/cer-base-realtime.service';
import { UserSessionService } from 'src/platform/app-user/user-session-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.css'],
  providers: [VoucherSidenavStateService]
})

export class AppComponent {

  constructor(
    /* Injected here because app depends on it in general */  
    private userSessionService: UserSessionService,
    private realTimeService: CerBaseRealtimeService,
    private voucherSidenavStateService: VoucherSidenavStateService, 
    private appInsightsService: CerAppApplicationInsightsService ) {
    console.log("Application started");
  }
}


