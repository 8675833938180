import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";

import { DataManager } from '@syncfusion/ej2-data';

import { FieldEditChangedArgs, FieldFormatEnum, FieldGroupMetadata, FieldMetadata, FieldWidthEnum, ViewMetadata } from "src/cer/cer-data/cer-data.service";
import { UiActionTypeEnum, UiCommand, UiCommandEvent, UiCommandSourceEnum } from "src/cer/cer-grid/cer-grid-command.service";
import { CerGridComponent } from "src/cer/cer-grid/cer-grid.component";
import { CerAppDialogService } from "src/cer-app/cer-app-dialog/cer-app-dialog.service";

import { ProjectTaskDocComponent } from "./project-task-doc/project-task-doc.component";
import { ProjectClient } from "../api";
import { CerDialogService } from "src/cer/cer-dialog/cer-dialog.service";

@Injectable({ providedIn: 'root' })
export class ProjectTaskService {
  // Project View
  public projectViewMetadata: ViewMetadata = {
    name: 'project-node', dataApiName: 'ProjectNode', text: 'Projekt struktur',
    primaryKey: 'id', titleFields: ['name'], dialogEdit: true, allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };
  public projectFieldMetadata: FieldMetadata[] =
    [
      { name: 'id', text: 'Id', tooltipText: 'Unik nøgle for skabelonen', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS },
      { name: 'level', text: 'Niveau', tooltipText: 'Projektniveau', widthType: FieldWidthEnum.XXS, foreignKeyTableName: 'ProjectLevel', required: true, orderBy: 'Ascending', visible: false, visibleAdd: true, visibleEdit: true, orderByPriority: 1 },
      { name: 'parentId', text: 'Overordnet struktur', tooltipText: 'Overordnet projektstruktur', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectNode', orderByPriority: 2 },
      { name: 'num', text: 'Projektnr.', tooltipText: 'Projektnummer', widthType: FieldWidthEnum.M, visible: false },
      { name: 'name', text: 'Navn', tooltipText: 'Projektnavn', widthType: FieldWidthEnum.M, required: true, orderByPriority: 3 },
      { name: 'responsible', text: 'Ansvarlig', tooltipText: 'Ansvarlig medarbejder for projekt', widthType: FieldWidthEnum.M, foreignKeyTableName: 'User', foreignKeyField: 'shortName', foreignKeyValue: 'shortName' },
      { name: 'taskType', text: 'Opgavetype', tooltipText: 'Type af tilknyttede opgaver', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectTaskType', foreignKeyField: 'num', visible: false, visibleAdd: true, visibleEdit: true },
      { name: 'taskStatus', text: 'Opgavestatus', tooltipText: 'Status omkring opgaveløsning', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectTaskStatus', foreignKeyField: 'num', required: true },
      { name: 'taskResponsibleTeam', text: 'Opgave team', tooltipText: 'Ansvarligt team for opgaver', widthType: FieldWidthEnum.M, foreignKeyTableName: 'Team', foreignKeyField: 'num', visible: false, visibleAdd: true, visibleEdit: true },
      { name: 'taskResponsibleUser', text: 'Opgave ansvarlig', tooltipText: 'Ansvarlig medarbejder for opgaver', widthType: FieldWidthEnum.M, foreignKeyTableName: 'User', foreignKeyField: 'shortName', foreignKeyValue: 'shortName', visible: false, visibleAdd: true, visibleEdit: true }
    ];


  // Task view
  public taskViewMetadata: ViewMetadata = {
    name: 'project-task', dataApiName: 'ProjectTask', text: 'Opgaver', textSingular: 'Opgave',
    primaryKey: 'id', titleFields: ['name'],
    dialogEdit: true, allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };
  public taskFieldMetadata: FieldMetadata[] =
    [
      { name: 'id', text: 'Id', tooltipText: 'Unik nøgle for skabelonen', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS, orderBy: 'Descending', orderByPriority: 2 },
      { name: 'projectNode', text: 'Projekt', tooltipText: 'Projekt', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectNode', onEditChanged: (args) => { this.onProjectNodeChanged(args) } },
      { name: 'type', text: 'Type', tooltipText: 'Opgavetype', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectTaskType', foreignKeyField: 'num', onEditChanged: (args) => { this.onTaskTypeChanged(args) } },
      {
        name: 'product', text: 'Udgivelse', tooltipText: 'Udgivelse', widthType: FieldWidthEnum.M,
        foreignKeyTableName: 'RefData_ProductLookup', foreignKeyField: 'num', foreignKeyValue: 'num',
        foreignKeyColumns: ['num', 'name', 'authors'],
        foreignKeyTitles: ['ISBN', 'Titel', 'Forfatter'],
        foreignKeyColumnsWidth: ['140px', '300px', '220px'],
        popupHeight: '300px', popupWidth: '680px',
        onEditChanged: (args) => { this.onProductChanged(args) }
      },
      { name: 'name', text: 'Navn', tooltipText: 'Overskrift', widthType: FieldWidthEnum.M, orderBy: 'Ascending' },
      { name: 'status', text: 'Status', tooltipText: 'Opgavestatus', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectTaskStatus', foreignKeyField: 'num', foreignKeySortFields: ['id'], orderBy: 'Descending', orderByPriority: 1 },
      { name: 'responsibleTeam', text: 'Team', tooltipText: 'Ansvarligt team', widthType: FieldWidthEnum.M, foreignKeyTableName: 'Team', foreignKeyField: 'num' },
      { name: 'responsibleUser', text: 'Ansvarlig', tooltipText: 'Ansvarlig medarbejder', widthType: FieldWidthEnum.M, foreignKeyTableName: 'User', foreignKeyField: 'shortName', foreignKeyValue: 'shortName' },
      { name: 'description', text: 'Beskrivelse', tooltipText: 'Beskrivelse', format: FieldFormatEnum.TextArea, widthType: FieldWidthEnum.M, visible: false }
    ];

  public taskFieldGroupMetadata: FieldGroupMetadata[] = [
    { idx: 1, tabIdx: 0, name: 'identification', text: 'Opgave', fields: ['id', 'productNode', 'type', 'product', 'name'] },
    { idx: 2, tabIdx: 0, name: 'status', text: 'Status', fields: ['status', 'responsibleTeam', 'responsibleUser'] }
  ];

  public uiCmdTaskContactsCreate: UiCommand =
    { id: 'taskContactsCreate', text: 'Opret kontakter', tooltipText: 'Opret kontakter', iconCss: 'e-menu-icon e-icons-org e-location', target: ".e-content" };
  public uiCmdTaskClose: UiCommand =
    { id: 'taskClose', text: 'Afslut', tooltipText: 'Afslut opgave', iconCss: 'e-menu-icon e-icons-org e-check', target: ".e-content", needSelection: true };

  public taskToolbarCommands: UiCommand[] = [this.uiCmdTaskClose];
  public taskContextMenuCommands: UiCommand[] = [this.uiCmdTaskClose, this.uiCmdTaskContactsCreate];

  // Task view
  public contactViewMetadata: ViewMetadata = {
    name: 'project-task-contact', dataApiName: 'ProjectTaskContact', text: 'Kontakter',
    parentPrimaryKey: 'id', parentForeignKey: 'task',
    primaryKey: 'id', titleFields: ['name'], dialogEdit: true, allowEdit: true, allowCreate: true, allowDelete: true,
    confirmDelete: false, baseFields: ['history']
  };
  public contactFieldMetadata: FieldMetadata[] =
    [
      { name: 'id', text: 'Id', tooltipText: 'Unik nøgle for skabelonen', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS },
      { name: 'task', text: 'Opgave', tooltipText: 'Opgavereference', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectTask', visible: false },
      { name: 'partyRole', text: 'Rolle', tooltipText: 'Kontaktens rolles', widthType: FieldWidthEnum.M, foreignKeyTableName: 'PartyRole', foreignKeyField: 'num' },
      { name: 'name', text: 'Navn', tooltipText: 'Navn på kontakt', widthType: FieldWidthEnum.XL, orderBy: 'Ascending' },
      { name: 'party', text: 'Part', tooltipText: 'Part informations', widthType: FieldWidthEnum.XL, visible: false },
      { name: 'description', text: 'Beskrivelse', tooltipText: 'Beskrivelse', widthType: FieldWidthEnum.XXL, visible: true },
      { name: 'status', text: 'Status', tooltipText: 'Opgavestatus', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectTaskStatus', foreignKeyField: 'num', foreignKeySortFields: ['id'], visible: false },
      { name: 'responsibleTeam', text: 'Team', tooltipText: 'Ansvarligt team', widthType: FieldWidthEnum.M, foreignKeyTableName: 'Team', foreignKeyField: 'num', visible: false },
      { name: 'responsibleUser', text: 'Ansvarlig', tooltipText: 'Ansvarlig medarbejder', widthType: FieldWidthEnum.M, foreignKeyTableName: 'User', foreignKeyField: 'shortName', visible: false }
    ];

  public contactToolbarCommands: UiCommand[] = [this.uiCmdTaskContactsCreate];

  constructor(private projectClient: ProjectClient, private dialog: MatDialog, private uiDialog: CerDialogService, private ui: CerAppDialogService) {
  }

  private taskGrid: CerGridComponent;
  public setTaskGrid(grid: CerGridComponent) {
    this.taskGrid = grid;
  }

  // During editing, when "Product.RequestContractCreate" changes, update "Contract.Type"
  private onProjectNodeChanged(args: FieldEditChangedArgs) {
    var value = args.value;
    if (value) {
      var e = this.taskGrid.editService;
      if (e.fieldValueGet('type') == null) {
        var fieldMeta: FieldMetadata = this.taskFieldMetadata.find(f => f.name == 'projectNode');
        if (fieldMeta) {
          var fkData = fieldMeta.foreignKeyData.find(f => f.id == value);
          if (fkData) {
            if (fkData.taskType) {
              e.fieldValueSet('type', fkData.taskType);

              args.field = 'type';
              args.data.type = fkData.taskType;
              args.value = fkData.taskType;;
              args.previousValue = null;
              this.onTaskTypeChanged(args);
            }
          }
        }
      }
    }
  }

  private onTaskTypeChanged(args: FieldEditChangedArgs) {
    var value = args.value;
    if (value) {
      var e = this.taskGrid.editService;
      var fieldMeta: FieldMetadata = this.taskFieldMetadata.find(f => f.name == 'type');
      var fkData = fieldMeta.foreignKeyData.find(f => f.num == value);
      if (fkData) {
        if (!e.fieldValueGet('status') && fkData.status) {
          e.fieldValueSet('status', fkData.status);
        }
        if (e.fieldValueGet('responsibleTeam') == null) {
          e.fieldValueSet('responsibleTeam', fkData.responsibleTeam);
        }
        if (e.fieldValueGet('responsibleUser') == null) {
          e.fieldValueSet('responsibleUser', fkData.responsibleUser);
        }
        e.fieldDisable('product', !fkData.product);
      }
    }
  }

  private onProductChanged(args: FieldEditChangedArgs) {
    if (args.value != null && args.value != undefined) {
      var e = this.taskGrid.editService;
      e.fieldValueSet('name', args.itemData.name + ' ' + args.itemData.authors);
    }
  }


  public editDescription(grid: CerGridComponent, description$: BehaviorSubject<string>) {
    if (grid != null) {
      var data: any = grid.rowSelectedData();
      if (data?.id != null) {
        grid.disable();

        var config = <MatDialogConfig>{ width: '800px', height: '500px', maxWidth: '95vw', maxHeight: '95vh', data: { html: data.description }, panelClass: 'resizable-mat-dialog-panel' };
        var dialogRef: MatDialogRef<ProjectTaskDocComponent> = this.dialog.open(ProjectTaskDocComponent, config);
        dialogRef.afterClosed().subscribe(result => {
          grid.enable();
          if (result.dataChanged) {
            description$.next(result.html);
            data.description = result.html;
            grid.grid.setCellValue(data.id, 'description', result.html);
            var dm: DataManager = <DataManager>grid.grid.dataSource;
            dm.update(data.id, data);
          }
        });
      }
    }
  }

  public onCommand(event: UiCommandEvent, taskGrid: CerGridComponent, contractGrid: CerGridComponent, description$: BehaviorSubject<string>) {
    switch (event.commandId) {
      case this.uiCmdTaskContactsCreate.id:
        this.taskContactsCreate(taskGrid?.rowSelectedData(), contractGrid);
        break;
      case this.uiCmdTaskClose.id:
        this.taskClose(taskGrid?.rowSelectedData(), taskGrid);
        break;
    }
    switch (event.source) {
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.Add:
            var fieldMeta: FieldMetadata = this.taskFieldMetadata.find(f => f.name == 'projectNode');
            if (<CerGridComponent>event.appComponent == this.taskGrid) {
              var rowData = event.args.data;
              var value = rowData?.projectNode;
              if (value) {
                var projectChangedArgs: FieldEditChangedArgs = {
                  field: 'projectNode',
                  fieldMeta: null,
                  data: rowData,
                  value: value,
                  previousValue: null,
                  args: event.args
                };
                this.onProjectNodeChanged(projectChangedArgs);
              }
            }
            break;
          case UiActionTypeEnum.RowSelect:
            if (event.appComponent == this.taskGrid) {
              var row: any = this.taskGrid.rowSelectedData();
              var description: string = row?.description ? row.description : '';
              description$.next(description);
            }
            break;
        }
        break;
    }
  }

  private taskClose(row: any, taskGrid: CerGridComponent) {
    if (row != null && row.id) {
      this.taskCloseByTaskId(row.id, taskGrid);
    }
  }

  private taskCloseByTaskId(taskId: number, taskGrid: CerGridComponent) {
    if (taskId != null) {
      this.projectClient.taskClose(taskId).subscribe({
        next: (count) => {
          if (count > 0 && taskGrid != null) {
            taskGrid.research();
            this.uiDialog.snackBar(count + ' opgave afsluttet');
          }
        }
      });
    }
  }

  private productEditShow(show: boolean) {
  }

  private taskContactsCreate(row: any, contactGrid: CerGridComponent) {
    if (row != null && row.product) {
      this.taskContactsCreateByTaskId(row.id, contactGrid);
    }
    else {
      this.uiDialog.snackBar('Vælg en opgave med en udgivelse for at oprette kontakter');
    }
  }

  private taskContactsCreateByTaskId(taskId: number, contactGrid: CerGridComponent) {
    if (taskId != null) {
      this.projectClient.taskContactsCreate(taskId).subscribe({
        next: (count) => {
          if (count > 0 && contactGrid != null) {
            contactGrid.research();
          }
        }
      });
    }
  }
}