<cer-form #form  (commmand)="onCommand($event)" [panes]="{ 'right': { visible: 'collapse', size: '40%'}}">
  <ng-template #mainTemplate>
    <cer-grid
      #mainGrid
      [viewMetadata]="viewMetadata"
      [fieldMetadata]="fieldMetadata"
      [toolbarCommands]="toolbarCommands"
      [contextMenuCommands]="contextMenuCommands"
      [keyboardShortcuts]="keyboardShortcuts"
      (commmand)="onCommand($event)"
      dataRelationRole="parent"
    ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-app-file-viewer-tab
      #fileViewerTab
      [tabSelectedIndexDefault]=0
      [enableChat]="false"
      [refTableId]="2"
      [refRowId]="dto?.id"
      [token]="attachmentAccessToken"
      [attachments]="attachmentDtoList"
      [uploadIsActive]="false"
      [enableClose]="false"
    ></cer-app-file-viewer-tab>
  </ng-template>
</cer-form>
