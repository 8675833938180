// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { debounceTime, fromEvent, map, Observable, Subscription } from 'rxjs';

// SyncFusion
import { ColumnModel, ExcelFilterBase, GridComponent, PredicateModel } from '@syncfusion/ej2-angular-grids';

// Cerium
import { GridDesign } from './cer-grid.component';
import { CerGridCommandService, UiActionTypeEnum, uiCmdSearchClear, uiCmdFilterClear, uiCmdSearchBarFocus, UiCommandEvent, UiCommandSourceEnum } from './cer-grid-command.service';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { FieldMetadata } from '../cer-data/cer-data.service';

@Injectable()
export class CerGridFilterService implements OnDestroy {
  // Subscriptions
  private subscriptionManager: Subscription = new Subscription();

  // Grid edit
  private grid: GridComponent;

  private searchInputKeyup$: Observable<KeyboardEvent>;

  // Constructor
  constructor(command: CerGridCommandService) {
    this.subscriptionManager.add(command.commandPre$.subscribe(cmd => this.onCommandPre(cmd)));
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  public init(grid: GridComponent, design: GridDesign) {
    this.grid = grid;
    if (design?.columnFilterVisible) {
      this.grid.filterSettings = { type: 'Excel' };
    }
    this.searchInputKeyup$ = fromEvent<KeyboardEvent>(grid.element, 'keyup');
    this.subscriptionManager.add(this.searchInputKeyup$.pipe(map((event: KeyboardEvent) => event), debounceTime(1000)).subscribe(e => this.onSearchInputDebounced(e)));
  }

  private onCommandPre(event: UiCommandEvent) {
    if (event.cancel) {
      return;
    }
    if (event.source == UiCommandSourceEnum.ActionBegin) {
      switch (event.actionType) {
        case UiActionTypeEnum.FilterChoiceRequest:
          this.onFilterChoiceRequest(event.args);
          break;
        case UiActionTypeEnum.FilterSearchBegin:
          this.onFilterSearchBegin(event.args);
          break;
        case UiActionTypeEnum.Filtering:
          this.onFiltering(event.args);
          break;
        case UiActionTypeEnum.BeforeOpenColumnChooser:
          this.onBeforeOpenColumnChooser(event.args);
          break;
        case UiActionTypeEnum.BeforeCloseColumnChooser:
          this.onBeforeCloseColumnChooser(event.args);
          break;
      }
    }
    if (event.source == UiCommandSourceEnum.ActionComplete) {
      switch (event.actionType) {
        case UiActionTypeEnum.DataBound:
          if (this.searchBarFocusDeferred) {
            this.searchBarFocusDeferred = false;
            if (event.rowsData && event.rowsData.length > 0) {
              this.searchBarFocusDeferredFromDatabound = true;
            }
            this.doSearchBarFocus();
          }
          break;
        case UiActionTypeEnum.RowSelect:
          if (this.searchBarFocusDeferredFromDatabound) {
            this.searchBarFocusDeferredFromDatabound = false;
            this.doSearchBarFocus();
          }
          break;
      }
    }
    switch (event.commandId) {
      case uiCmdFilterClear.id:
        this.doClearFilter();
        break;
      case uiCmdSearchBarFocus.id:
        this.doSearchBarFocus();
        break;
      case uiCmdSearchClear.id:
        this.doSearchBarClear();
        break;

    }
  }

  public doClearFilter() {
    this.grid.clearFiltering();
    this.doSearchBarClear()
    this.grid.clearGrouping();
    this.grid.clearSorting();
  }


  private onSearchInputDebounced(e: KeyboardEvent) {
    var id = (<any>e.target).getAttribute('id');
    if (id && id.indexOf('_searchbar') !== - 1) {
      var searchStr: string = (e.target as HTMLInputElement).value;
      this.grid.search(searchStr);
      this.grid.searchSettings.key = searchStr;
      this.searchBarFocusDeferred = true;
    }
  }

  private searchBarFocusDeferred: boolean = false;
  private searchBarFocusDeferredFromDatabound: boolean = false;
  public doSearchBarFocus(): boolean {
    var ok: boolean = false;
    if (this.grid) {
      var searchBar = document.getElementById(this.grid.element.id + "_searchbar");
      if (searchBar) {
        // Open if closed in popup
        var popup: HTMLElement = searchBar.parentElement;
        while (popup && !popup.classList.contains("e-toolbar")) {
          if (popup.classList.contains("e-popup-close")) {
            popup.classList.remove("e-popup-close");
            popup = null;
          }
          else {
            popup = popup.parentElement;
          }
        }
        searchBar.focus();
        ok = true;
      }
    }
    return ok;
  }

  public doSearchBarClear(): boolean {
    var ok: boolean = false;
    if (this.grid && this.grid.searchSettings.key !== "") {
      this.grid.searchSettings.key = "";
      ok = true;
    }
    return ok;
  }

  private onFilterSearchBegin(args: any) {
    this.filterSettingsSetup(args);
  }

  private onFiltering(args: any) {
  }

  private onFilterChoiceRequest(args: any) {
    var filterfields = [];
    var objFilter = Object.keys(args.filterModel.existingPredicate);
    for (var i = 0; i < objFilter.length; i++) {
      filterfields.push(objFilter[i]);
    }
    filterfields.push(args.filterModel.options.field);
    args.query.distincts = [];
    args.query.select(filterfields); // Created the select query
    if (args.filterModel && args.filterModel instanceof ExcelFilterBase) {
      var excel: ExcelFilterBase = <ExcelFilterBase>args.filterModel;
      this.filterSettingsSetup(args);
    }
  }

  private filterSettingsSetup(args: any) {
    if (args) {
      args.filterChoiceCount = 3000;  // TODO: Take from environment
      //console.log(args);
    }
  }

  // Filter
  public getFieldFilterValueNumber(fieldName: string): number {

    var predicate = this.grid.filterSettings.columns.find(item => item.field == fieldName);
    if (predicate && predicate.operator == 'equal') {
      return <number>predicate.value;
    }
    return null;
  }

  public filterColumnSetOnClient(column: ColumnModel, meta: FieldMetadata, predicate = 'and') {
    if (meta.filterOperator && meta.filterValue != undefined && meta.filterOnClient == true) {
      //{ field: 'OrderDate', matchCase: false, operator: 'equal', predicate: 'or', value: new Date() }]
      if (meta.filterOperator == 'in') {
        if (meta.filterValue.length && meta.filterValue.length > 0) {
          var valueArray: any[] = meta.filterValue;
          valueArray.forEach(v => {
            var metaIn: FieldMetadata = Object.assign({}, meta);
            metaIn.filterOperator = 'equal';
            metaIn.filterValue = v;
            this.filterColumnSetOnClient(column, metaIn, 'or');
          });
        }
      }
      else {
        var p: PredicateModel =
        {
          field: meta.name,
          operator: meta.filterOperator,
          value: meta.filterValue,
          predicate: predicate
        };
        if (this.grid?.filterSettings) {
          if (this.grid.filterSettings.columns.length == 0) {
            this.grid.filterSettings.columns = [p];
          }
          else {
            this.grid.filterSettings.columns.push(p);
          }
        }
      }
    }
  }

  /*****************
   * Column chooser
   *****************/

  private onBeforeOpenColumnChooser(args: any) {
    if (this.grid?.columnChooserModule) {
      var chooser: any = this.grid.columnChooserModule;
      if (chooser.dlgObj) {
        var dlg: Dialog = chooser.dlgObj;
        if (dlg) {
          dlg.allowDragging = true;
          //dlg.enableResize = true;
          var dlgObj: any = dlg;
          //dlgObj.allowMaxHeight = true; // TODO:? ?
          dlgObj.beforeOpen = this.onBeforeOpenColumnChooserDialog.bind(this);
          var dlgEle: HTMLElement = dlg.element;
          if (dlgEle?.style) {
            /*
            var s = dlgEle.style;
            s.top = "0px";
            s.left = "0px";
            s.height = "100%";
            s.width = "100%";
            //s.maxHeight = "60wv";
            //s.maxWidth = "80wv";
            s.zIndex = "99";
            //s.marginTop = "40px";
            //s.marginLeft = "250px";
            s.display = "flex";
            s.flexDirection = "column";
            //s.position = "fixed";
            s.backgroundColor = "white";
            */
          }
        }
      }
    }
  }

  private onBeforeOpenColumnChooserDialog(args: any) {
    console.log(args);
  }

  private onBeforeCloseColumnChooser(args: any) {
    console.log("Closed");
    console.log(args);
  }
}