import { Component, Inject, NgModule, Pipe, PipeTransform, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, NgLocalization, NgLocaleLocalization, CommonModule, DatePipe } from '@angular/common';
import localeDa from '@angular/common/locales/da';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url : string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@NgModule({
  declarations: [
    SafePipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule, // No need to export
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SafePipe,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [
    Component,
    Inject,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'da' },
    { provide: NgLocalization, useClass: NgLocaleLocalization }
  ]

})
export class AngularBaseModule { }
