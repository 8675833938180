<div *ngIf="!hasLogin" class="flex-column flex-100">
  <p>Du er ikke logget ind</p>
  <p>
    <button [routerLink]="['/authentication/login']">
      <mat-icon>login</mat-icon><span>Log ind</span>
    </button>
  </p>
</div>
<div *ngIf="hasLogin" class="flex-column flex-100">
  <div class="flex-column flex-100" *ngIf="!hasUser">
    <h3>Ingen adgang</h3>
    <div>Afvent eller kontakt venligst administrator for status.</div>
  </div>
  <div *ngIf="hasUser" class="flex-column flex-100">
    <div *ngIf="!hasNotifications" class="flex-column flex-100">
      <h3 class="padding-above"><mat-icon>notifications</mat-icon>Notifikationer for:</h3>
      <p>Du har ingen notifikationer.</p>
    </div>
    <div *ngIf="hasNotifications" class="flex-column flex-100">
      <div div class="flex-fixed">
        <h3 class="padding-above">
          <mat-icon color="accent" [matBadgeHidden]="unreadCount == 0" [matBadge]="unreadCount"
            matBadgeColor="primary">notifications</mat-icon>Notifikationer:
        </h3>
      </div>
      <div class="flex-column flex-grow flex-limit-height">
        <ul class="chat-list">
          <ng-container *ngFor="let data of userNotifications; let i = index">
            <li class="chat-list-name" *ngIf="true">
              <div>
                <mat-icon color="info" class="icon-small" *ngIf="data.isNotified">done</mat-icon>{{
                formatDateRelative(data.created) }} {{ data.fromShortName }}
              </div>
            </li>
            <li class="chat-list-item other">
              <a routerLink="{{ data.url }}">
                <div>{{ data.txt }}</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>