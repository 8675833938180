import { BankSettlementStatusEnum } from '../api';
import { VoucherMessageChannelTypeEnum } from '../api';
import { Injectable } from '@angular/core';
import {
  VoucherApprovalStatusEnum,
  VoucherApprovalStatusAllEnum,
  VoucherMessageStatusEnum,
  VoucherMessageStatusAllEnum,
  VoucherPostingTypeEnum,
  VoucherMessageViewDto as MessageDto,
  VoucherViewDto as VoucherDto,
  VoucherPostingViewDto as PostingDto,
  VoucherMessageViewDetailsVm as DetailsVm,
  VoucherMessageViewClient
} from "../api";

import { ContextMenuItemModel, GridComponent, QueryCellInfoEventArgs } from "@syncfusion/ej2-angular-grids";
import { AppStateService } from '../../app-core/app-state/app-state.service';
import { CerAppChatService } from '../../cer-app/cer-app-chat/cer-app-chat.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';

@Injectable()
export class VoucherService {

  constructor(private appStateService: AppStateService, private chatService: CerAppChatService, private client: VoucherMessageViewClient, private snackBar: MatSnackBar) {
  }

  EnableContextMenuByApprovalStatus(grid: GridComponent, items: ContextMenuItemModel[], approvalStatusFrom: VoucherApprovalStatusEnum) {
    VoucherHelper.enableContextMenuByApprovalStatus(grid, items, approvalStatusFrom);
  }
  EnableContextMenuByMessageAndApprovalStatus(grid: GridComponent, items: ContextMenuItemModel[],
    messageStatusFrom: VoucherMessageStatusEnum, approvalStatusFrom: VoucherApprovalStatusEnum) {
    VoucherHelper.enableContextMenuByMessageAndApprovalStatus(grid, items, messageStatusFrom, approvalStatusFrom);
  }

  public IsStatusPosted(approvalStatus: VoucherApprovalStatusEnum): boolean {
    return (approvalStatus == VoucherApprovalStatusEnum.Posted);
  }

  public IsStatusNonCreatedOrPendingOrOnHold(approvalStatus: VoucherApprovalStatusEnum): boolean {
    return (
      approvalStatus != VoucherApprovalStatusEnum.Created &&
      approvalStatus != VoucherApprovalStatusEnum.Pending &&
      approvalStatus != VoucherApprovalStatusEnum.OnHold);
  }

  public IsStatusNonPendingAndOnHold(approvalStatus: VoucherApprovalStatusEnum): boolean {
    return (approvalStatus != VoucherApprovalStatusEnum.Pending && approvalStatus != VoucherApprovalStatusEnum.OnHold);
  }

  public IsStatusPendingOrOnHold(approvalStatus: VoucherApprovalStatusEnum): boolean {
    return (approvalStatus == VoucherApprovalStatusEnum.Pending || approvalStatus == VoucherApprovalStatusEnum.OnHold);
  }

  public IsStatusPendingOrCreated(approvalStatus: VoucherApprovalStatusEnum): boolean {
    return (approvalStatus == VoucherApprovalStatusEnum.Pending || approvalStatus == VoucherApprovalStatusEnum.Created);
  }

  public IsStatusPending(approvalStatus: VoucherApprovalStatusEnum): boolean {
    return (approvalStatus == VoucherApprovalStatusEnum.Pending);
  }

  public IsStatusApproved(approvalStatus: VoucherApprovalStatusEnum): boolean {
    return (approvalStatus == VoucherApprovalStatusEnum.Approved);
  }
  public IsStatusAccountedOrPosted(approvalStatus: VoucherApprovalStatusEnum): boolean {
    return (approvalStatus == VoucherApprovalStatusEnum.Accounted ||
      approvalStatus == VoucherApprovalStatusEnum.Posted);
  }

  //  public VoucherMessageAdjustDatesAsUTC(messageDto: MessageDto) {
  //    messageDto.created = this.CreateDateAsUTC(messageDto.created);
  //    messageDto.recieved = this.CreateDateAsUTC(messageDto.recieved);
  //  }

  public channelTypeEnum(channelType: number): VoucherMessageChannelTypeEnum {
    return <VoucherMessageChannelTypeEnum>channelType;
  }

  public isExpenseVoucher(channelType: number): boolean {
    return this.channelTypeEnum(channelType) == VoucherMessageChannelTypeEnum.ExpenseVoucher;
  }

  public isVendorVoucher(channelType: number): boolean {
    return this.channelTypeEnum(channelType) == VoucherMessageChannelTypeEnum.VendorVoucher;
  }

  public voucherIsComplete(voucherDto: VoucherDto, userIsAdmin: boolean, isExpenseVoucher: boolean): boolean {
    var voucherComplete: boolean = true;

    if (((!voucherDto.balanceAccountNum || !this.isPostingTypeVendor(voucherDto.balancePostingType)) && userIsAdmin && !isExpenseVoucher) ||
      voucherDto.transDate == null ||
      ((voucherDto.documentNum == null || voucherDto.documentNum.length == 0) && userIsAdmin && !isExpenseVoucher) ||
      voucherDto.currency == null || voucherDto.currency == 0 ||
      ((voucherDto.amountCur == null || voucherDto.amountCur == 0) && !isExpenseVoucher)) {
      voucherComplete = false;
    }

    return (voucherComplete);
  }

  public postingIsComplete(postingDto: PostingDto, userIsAdmin: boolean): boolean {
    var postingComplete: boolean = true;

    if (userIsAdmin || !this.isPostingTypeVendor(postingDto.postingType)) {
      if (postingDto.postingType == null || postingDto.postingType == 0 ||
        postingDto.accountNum == null || postingDto.accountNum.length == 0 ||
        postingDto.amountCur == null || postingDto.amountCur == 0) {
        postingComplete = false;
      }
    }
    if (postingComplete && this.isPostingTypeProject(postingDto.postingType)) {
      if (postingDto.projectCostType == null || postingDto.projectCostType.length == 0 ||
        ((postingDto.projectLedgerNum == null || postingDto.projectLedgerNum.length == 0) && userIsAdmin)) {
        postingComplete = false;
      }
    }

    return (postingComplete);
  }

  public postingTypeEnum(postingType: number): VoucherPostingTypeEnum {
    return VoucherHelper.postingTypeAsEnum(postingType);
  }
  
  public isPostingTypeBalance(postingType: number): boolean {
    return [VoucherPostingTypeEnum.Vendor, VoucherPostingTypeEnum.Debtor, VoucherPostingTypeEnum.Bank].includes(this.postingTypeEnum(postingType));
  }

  public isPostingTypeCost(postingType: number): boolean {
    return !this.isPostingTypeBalance(postingType);
  }

  public isPostingTypeProject(postingType: number): boolean {
    return [VoucherPostingTypeEnum.Project, VoucherPostingTypeEnum.ProjectRoyalty].includes(this.postingTypeEnum(postingType));
  }

  public isPostingTypeLedger(postingType: number): boolean {
    return [VoucherPostingTypeEnum.Ledger].includes(this.postingTypeEnum(postingType));
  }

  public isPostingTypeVendor(postingType: number): boolean {
    return [VoucherPostingTypeEnum.Vendor].includes(this.postingTypeEnum(postingType));
  }

  public voucherGridQueryCellInfoFromVmMap(args: QueryCellInfoEventArgs, detailsVmMap: Map<number, DetailsVm>) {
    if (args.column.field === 'chatCount') {
      var data: any = args.data;
      var detailsVm: DetailsVm = null;
      if (detailsVmMap.has(data.msgId)) {
        detailsVm = detailsVmMap.get(data.msgId);
      }
      this.voucherGridQueryCellInfoFromVm(args, detailsVm);
    }
  }

  public voucherGridQueryCellInfoFromVm(args: QueryCellInfoEventArgs, detailsVm: DetailsVm) {
    if (args.column.field === 'chatCount') {
      if (detailsVm && detailsVm.chatDtoList) {
        var n: number = detailsVm.chatDtoList.length;
        if (n > 0) {
          args.cell.classList.add('chat-active');
        }
        else {
          args.cell.classList.add('chat-none');
        }
      }
      else {
        args.cell.classList.add('chat-unknown');
      }
    }

  }

  public CreateDateAsUTC(date: Date): Date {
    if (date != null) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }
    return date;
  }

  public prepareDetailsVm(detailsVm: DetailsVm) {
    this.prepareMsgDto(detailsVm.voucherMessageViewDto);
    //this.chatService.chatAdjustTZ(prepareDetailsVm(detailsVm);
  }

  public prepareVoucherDto(dto: VoucherDto) {
    /*  dto.created = this.CreateDateAsUTC(dto.created);
      dto.modified = this.CreateDateAsUTC(dto.modified);
      dto.recieved = this.CreateDateAsUTC(dto.recieved);
      */
  }

  public prepareMsgDto(dto: MessageDto) {
    /*
    dto.created = this.CreateDateAsUTC(dto.created);
    dto.modified = this.CreateDateAsUTC(dto.modified);
    dto.recieved = this.CreateDateAsUTC(dto.recieved);
    */
  }

  public messageSplitByAttachment(msgId: number, checkedFileNames: string[], successCallBack: any) {
    if (checkedFileNames && checkedFileNames.length > 1) {
      var snackBarRef = this.snackBar.open('Splitter besked', 'Vent venligst...', { duration: undefined });
      this.client.splitByAttachment(msgId, checkedFileNames).subscribe(result => {
        snackBarRef.dismiss();
        if (result) {
          if (successCallBack) {
            successCallBack();
          }
          snackBarRef.dismiss();
        }
        else {
          DialogUtility.alert({ title: "Advarsel", content: "Split kunne ikke gennemføres" });
        }
      }, error => {
        snackBarRef.dismiss();
        this.appStateService.handleApiError(error);
      });
    }
    else {
      DialogUtility.alert({ title: "Advarsel", content: "Split kræver at mindst to dokumenter markeres" });
    }
  }

  public BankStatusCanChange(fromStatus: BankSettlementStatusEnum, toStatus: BankSettlementStatusEnum): boolean {
    if (fromStatus == null) {
      return false;
    }
    switch (toStatus) {
      case BankSettlementStatusEnum.Open:
        return [BankSettlementStatusEnum.Settled, BankSettlementStatusEnum.Selected].includes(fromStatus);
      case BankSettlementStatusEnum.Selected:
        return ![BankSettlementStatusEnum.Settled].includes(fromStatus);
      case BankSettlementStatusEnum.Settled:
        return ![BankSettlementStatusEnum.Settled].includes(fromStatus);
      case BankSettlementStatusEnum.Created:
        return [BankSettlementStatusEnum.PendingApproval, BankSettlementStatusEnum.Approved].includes(fromStatus);
      case BankSettlementStatusEnum.PendingApproval:
        return [BankSettlementStatusEnum.Created, BankSettlementStatusEnum.Approved].includes(fromStatus);
      case BankSettlementStatusEnum.Approved:
        return [BankSettlementStatusEnum.Created, BankSettlementStatusEnum.PendingApproval].includes(fromStatus);
    }
    return false;
  }
}

export class VoucherHelper {
  public static postingTypeAsEnum(postingType: number): VoucherPostingTypeEnum {
    return <VoucherPostingTypeEnum>postingType;
  }

  public static postingTypeIsVendor(postingType: number): boolean {
    return [VoucherPostingTypeEnum.Vendor].includes(VoucherHelper.postingTypeAsEnum(postingType));
  }

  public static postingGridQueryCellInfo(args: any) {
    if (args.column.field == 'vendorBankAccount') {
      var dto: PostingDto = args.data;
      if (dto && VoucherHelper.postingTypeIsVendor(dto.postingType)) {
        if (dto.accountNum && !dto.vendorBankAccount) {
          args.cell.style.backgroundColor = '#eb6565';
        }
      }
    }
  }

  public static enableContextMenuByApprovalStatus(grid: GridComponent, items: ContextMenuItemModel[], approvalStatusFrom: VoucherApprovalStatusEnum) {
    items.forEach(item => {
      var approvalStatusAllTo: VoucherApprovalStatusAllEnum = VoucherHelper.getApprovalStatusFromMenuItemName(item.id);
      if (approvalStatusAllTo != VoucherApprovalStatusAllEnum.All) {
        var approvalStatusTo: VoucherApprovalStatusEnum = <VoucherApprovalStatusEnum><unknown>approvalStatusAllTo;
        var enabled: boolean = this.IsApprovalStatusChangeEnabled(approvalStatusFrom, approvalStatusTo);
        grid.contextMenuModule.contextMenu.enableItems([item.text], enabled);
      }
    });
  }

  public static enableContextMenuByMessageAndApprovalStatus(grid: GridComponent, items: ContextMenuItemModel[],
    messageStatusFrom: VoucherMessageStatusEnum, approvalStatusFrom: VoucherApprovalStatusEnum) {
    items.forEach(item => {
      var messageStatusAllTo: VoucherMessageStatusAllEnum = VoucherHelper.getMessageStatusFromMenuItemName(item.id);
      if (messageStatusAllTo != VoucherMessageStatusAllEnum.All) {
        var messageStatusTo: VoucherMessageStatusEnum = <VoucherMessageStatusEnum><unknown>messageStatusAllTo;
        var enabled: boolean = VoucherHelper.isMessageStatusChangeEnabled(messageStatusFrom, messageStatusTo, approvalStatusFrom);
        grid.contextMenuModule.contextMenu.enableItems([item.text], enabled);
      }
    });
  }

  public static getApprovalStatusFromMenuItemName(itemId: string): VoucherApprovalStatusAllEnum {
    var approvalStatus: VoucherApprovalStatusAllEnum = VoucherApprovalStatusAllEnum.All;
    switch (itemId) {
      case 'setStatusPending':
        approvalStatus = VoucherApprovalStatusAllEnum.Pending;
        break;
      case 'setStatusOnHold':
        approvalStatus = VoucherApprovalStatusAllEnum.OnHold;
        break;
      case 'setStatusApproved':
        approvalStatus = VoucherApprovalStatusAllEnum.Approved;
        break;
      case 'setStatusAccounted':
        approvalStatus = VoucherApprovalStatusAllEnum.Accounted;
        break;
      case 'setStatusPosted':
        approvalStatus = VoucherApprovalStatusAllEnum.Posted;
        break;
    }

    return approvalStatus;
  }

  public static getMessageStatusFromMenuItemName(itemId: string): VoucherMessageStatusAllEnum {
    var messageStatus: VoucherMessageStatusAllEnum = VoucherMessageStatusAllEnum.All;
    switch (itemId) {
      case 'setStatusInbox':
        messageStatus = VoucherMessageStatusAllEnum.Inbox;
        break;
      case 'setStatusVoucher':
        messageStatus = VoucherMessageStatusAllEnum.Voucher;
        break;
      case 'setStatusOnHold':
        messageStatus = VoucherMessageStatusAllEnum.OnHold;
        break;
      case 'setStatusForwarded':
        messageStatus = VoucherMessageStatusAllEnum.Forwarded;
        break;
      case 'setStatusRejected':
        messageStatus = VoucherMessageStatusAllEnum.Rejected;
        break;
    }

    return messageStatus;
  }

  public static IsApprovalStatusChangeEnabled(approvalStatusFrom: VoucherApprovalStatusEnum, approvalStatusTo: VoucherApprovalStatusEnum): boolean {
    return VoucherHelper.isApprovalStatusChangeEnabled(approvalStatusFrom, approvalStatusTo);
  }

  public static isApprovalStatusChangeEnabled(approvalStatusFrom: VoucherApprovalStatusEnum, approvalStatusTo: VoucherApprovalStatusEnum): boolean {
    var enabled: boolean = true;

    if (approvalStatusFrom == null && approvalStatusTo != null) {
      enabled = false;
    }
    else if (approvalStatusFrom == approvalStatusTo) {
      enabled = false;
    }
    else if (approvalStatusFrom == VoucherApprovalStatusEnum.Posted) {
      enabled = false;
    }
    else if (approvalStatusTo == VoucherApprovalStatusEnum.Posted) {
      enabled = (approvalStatusFrom == VoucherApprovalStatusEnum.Accounted);
    }
    else if (approvalStatusFrom == VoucherApprovalStatusEnum.OnHold) {
      enabled = (approvalStatusTo == VoucherApprovalStatusEnum.Pending || approvalStatusTo == VoucherApprovalStatusEnum.Approved);
    }
    if (enabled && approvalStatusFrom == VoucherApprovalStatusEnum.Pending) {
      enabled = (approvalStatusTo != VoucherApprovalStatusEnum.Accounted);
    }
    return enabled;
  }

  public static isMessageStatusChangeEnabled(messageStatusFrom: VoucherMessageStatusEnum, messageStatusTo: VoucherMessageStatusEnum, approvalStatusFrom: VoucherApprovalStatusEnum): boolean {
    var enabled: boolean = true;

    if (approvalStatusFrom != null &&
      [VoucherApprovalStatusEnum.Accounted, VoucherApprovalStatusEnum.Posted, VoucherApprovalStatusEnum.Approved].includes(approvalStatusFrom) &&
      messageStatusTo != VoucherMessageStatusEnum.Voucher) {
      enabled = false;
    }
    else if (messageStatusFrom == null && messageStatusTo != null) {
      enabled = false;
    }
    else if (messageStatusFrom == messageStatusTo) {
      enabled = false;
    }

    return enabled;
  }
}
