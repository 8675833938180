import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { tap } from 'rxjs/operators';
import { ApplicationPaths, QueryParameterNames } from './api-authorization.constants';
import { RoleTypeEnum } from 'src/app/api';
import { AlertDialogArgs, DialogUtility } from '@syncfusion/ej2-angular-popups';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {

  public userIsAuthenticated: boolean;
  public userRoles: RoleTypeEnum[] = null;
  public userName: string = "";

  constructor(private authorize: AuthorizeService, private router: Router) {
  }

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (_next && _next.data && (<any>_next.data).roles) {
      var anyOfRolesRequired: RoleTypeEnum[] = (<any>_next.data).roles;
      if (anyOfRolesRequired.length > 0) {
        var hasAccess: boolean = (this.userIsAuthenticated && this.validateUserHasRole(anyOfRolesRequired));
        if (!hasAccess) {
          this.router.navigate(ApplicationPaths.LoginPathComponents, { queryParams: { [QueryParameterNames.ReturnUrl]: state.url } });
          return false;
        }
      }
    }

    return this.authorize.isAuthenticated()
      .pipe(tap(isAuthenticated => this.handleAuthorization(isAuthenticated, state, _next)));
  }

  private handleAuthorization(isAuthenticated: boolean, state: RouterStateSnapshot, next: ActivatedRouteSnapshot) {
    if (!isAuthenticated) {
      this.router.navigate(ApplicationPaths.LoginPathComponents, {
        queryParams: {
          [QueryParameterNames.ReturnUrl]: state.url
        }
      });
    }
  }

  public handleNoAccess(userName: string) {
    var dlg: AlertDialogArgs = {
      title: "Ingen adgang",
      content: `<p>Der er ikke adgang til systemet med login '${userName}'.</p><p>Afvent eller kontakt administrator for status.</p>`
    };
    DialogUtility.alert(dlg);
  }

  public handleUserSession(userRoles: RoleTypeEnum[], userName: string) {
    this.userIsAuthenticated = userRoles != null &&  userRoles.length > 0;
    this.userRoles = userRoles;
    this.userName = userName;
  }

  public validateUserHasRole(rolesRequired: RoleTypeEnum[]): boolean {
    var hasAccess = true;
    if (rolesRequired.length > 0) {
      var hasAccess = false;
      if (this.userIsAuthenticated) {
        if (this.userRoles != null) {
          this.userRoles.forEach(r => { if (rolesRequired.indexOf(r) >= 0) { hasAccess = true; } });
        }
        if (!hasAccess) {
          this.handleNoAccess(this.userName ?? '[Ikke aktiv]');
        }
      }
    }
    return hasAccess;
  }

}
