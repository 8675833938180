<cer-form #form (commmand)="onCommand($event)">
  <ng-template #mainTemplate>
    <cer-grid #grid 
    [viewMetadata]="viewMetadata" 
    [fieldMetadata]="fieldMetadata" 
    [toolbarCommands]="toolbarCommands"
    [contextMenuCommands]="contextMenuCommands" 
    [keyboardShortcuts]="keyboardShortcuts"
    [editOnDoubleClick]="false"
    (commmand)="onCommand($event)"
    ></cer-grid>
  </ng-template>
</cer-form>
