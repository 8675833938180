import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UiActionTypeEnum,  UiCommandEvent, UiCommandSourceEnum } from '../cer-grid/cer-grid-command.service';

export class ParentDataArgs {
  public row: any;
  public sender: any;
  public stable: boolean;
  
  constructor(row: any, sender: any, stable: boolean = false) {
    this.row = row;
    this.sender = sender;
    this.stable = stable;
  }
}

@Injectable()
export class CerFormService {
  // Form sends event to all components visible on form (grids etc)
  public formCommand$: Subject<UiCommandEvent> = new Subject<UiCommandEvent>();
  public formCommandNext(event: UiCommandEvent) {
    this.formCommand$.next(event);
  }

  // Children sends event to form
  public childCommand$: Subject<UiCommandEvent> = new Subject<UiCommandEvent>();
  public childCommandNext(event: UiCommandEvent) {
    this.childCommand$.next(event);
  }

  // Parent/child data sync: Send new parent data selection
  public parentData$: BehaviorSubject<ParentDataArgs> = new BehaviorSubject<ParentDataArgs>(new ParentDataArgs(null, null));

  private designChangeQueued: boolean = false;
  public formDesignChangeNext(actionType: UiActionTypeEnum, args: any = {}) {
    if (!this.designChangeQueued) {
      this.designChangeQueued = true;
      setTimeout(() => {
        this.designChangeQueued = false;
        var event: UiCommandEvent = { source: UiCommandSourceEnum.DesignChange, commandId: null, args: args, actionType: actionType, columnName: null };
        this.formCommandNext(event);
        //console.log('formDesignChangeNext', actionType);
      }, 200);
    }
  }

  constructor() {
    //console.log('CerFormService');
  }

}
