// Angular
import { Injectable, OnDestroy } from '@angular/core';

// SyncFusion
import { ColumnModel, GridComponent } from '@syncfusion/ej2-angular-grids';

// Cerium
import { GridDesign } from './cer-grid.component';
import { CerGridCommandService } from './cer-grid-command.service';
import { CerFormUserViewType } from '../cer-form/cer-form-persistence.service';
import { FieldMetadata } from '../cer-data/cer-data.service';
import { CerEditMetaService } from '../cer-edit/cer-edit-meta.service';
import { CerGridSelectionService } from './cer-grid-selection.service';

@Injectable()
export class CerGridPersistenceService implements OnDestroy {

  // Grid
  public grid: GridComponent;
  public design: GridDesign;
  public parentDataResetOnRefreshComplete: boolean = false;

  // Constructor
  constructor(
    private command: CerGridCommandService, 
    private meta: CerEditMetaService,
    private selection: CerGridSelectionService) {
  }

  // Subscriptions
  ngOnDestroy(): void {
  }

  public init(grid: GridComponent, design: GridDesign) {
    this.grid = grid;
    this.design = design;
    
  }

  public apply(viewType: CerFormUserViewType, persistenceObject: any): void {
    switch (viewType) {
      case 'complete':
        this.grid.columns.forEach(c => (<ColumnModel> c).visible = true); // Show all columns
        break;
        case 'standard':
        this.grid.columns.forEach(c => {
          var col : any = c;  
          if (col.meta)
          {
            col.visible = col.meta.visible;
          }
        });
        break;
      case 'custom':
        this.applyGridPersistenceObject(persistenceObject);
        break;
    }
  }

  public getId(): string {
    var id: string = null;

    id = 'grid';
    if (this.design?.dataRelationRole) {
      id = id + "-dataRelationRole-" + this.design.dataRelationRole.toString();
    }
    var subId = this.design?.id;
    if (subId && typeof subId === "string") {
      id = id + "-id-" + subId;
    }
    return id;
  }

  public get(): any {
    var o: any = null;
    if (this.grid) {
      o = new Object();
      o['grid'] = JSON.parse(this.getData());
    }
    return o;
  }

  private getData() {
    var keyEntity: string[] = ['sortSettings', 'filterSettings', 'groupSettings', 'columns', 'searchSettings'];
    var includeProperties: any = {
      sortSettings: ['propName', 'columns', 'direction', 'field', 'isFromGroup'],
      filterSettings: ['propName', 'columns', 'field', 'operator', 'value', 'predicate', 'type', 'matchCase', 'ignoreAccent', 'actualfilterValue', 'actualOperator', 'isForeignKeyFilter'],
      groupSettings: ['propName', 'columns', 'field'],
      columns: ['propName', 'columns', 'field', 'autofit', 'visible', 'width'],
      searchSettings: []
    };

    var persistedObject: any = {};
    keyEntity.forEach(key => {
      var currentObjectProp = (<any>this.grid)[key];
      let persistedObjectProp: object = this.copyObjectData(currentObjectProp, includeProperties[key]);
      persistedObject[key] = persistedObjectProp;
    });

    return JSON.stringify(persistedObject);
  }

  private copyObjectData(from: object, includes: string[]): object {
    if (!from) {
      return from; // null, undefined values check
    }
    var isArray: boolean = Array.isArray(from);
    var to: any = isArray ? [] : {};
    if (isArray) {
      (<any[]>from).forEach((value: any) => {
        to.push((typeof value === "object") ? this.copyObjectData(value, includes) : value);
      });
    }
    else {
      for (const key in from) {
        if (!includes || includes.includes(key)) {
          var keyFrom = <keyof typeof from>key;
          var value: any = from[keyFrom];
          if (value != from) {
            var keyTo = <keyof typeof to>key;
            to[keyTo] = (typeof value === "object") ? this.copyObjectData(value, includes) : value;
          }
        }
      }
    }
    return to;
  }

  public applyRefreshComponent()  {
    this.parentDataResetOnRefreshComplete = true;
    this.command.refreshColumns();
    //this.gridService.refreshColumns();
  }
   
  public applyComplete(viewType: CerFormUserViewType) {
    if (this.design?.dataRelationRole != "child") {
      this.applyRefreshComponent();
    }
  }

  public applyPersistence(viewType: CerFormUserViewType, persistenceObject: any) {
    if (viewType == 'custom') {
      if (persistenceObject && persistenceObject['grid'] && this.grid) {
        this.applyGridPersistenceObject(persistenceObject['grid']);
      }
    }
    else {
      this.selection.rowSelectedKeep();
      var changed = this.applyColumnsStandardView(viewType == 'complete');
      if (changed) {
        this.command.refreshColumns();
      }
    };
  }

  public applyGridPersistenceObject(persistenceObject: any) {
    if (persistenceObject?.grid) {
      /*
      var dataManager = this.grid.getDataModule().dataManager;
      var dataJson : Object[] = new Array<Object>();
      var tempDm = new DataManager({ json: dataJson, adaptor: new JsonAdaptor() });

      (<any> this.grid.getDataModule().dataManager) =tempDm;
      (<any> this.grid.renderModule.data) = tempDm;
      this.appGridService.isInPersistenceChange = true;
      */
      var obj : any = persistenceObject?.grid;

      //var isGridDataBoundSave = this.isGridDataBound;
      //this.isGridDataBound = false;
      var columns = obj["columns"];
      var filterColumns = obj['filterSettings']?.['columns'];
      var sortColumns = obj['sortSettings']?.['columns'];
      var groupColumns = obj['groupSettings']?.['columns'];
      var columnChange = this.applyGridPersistenceColumns(columns, filterColumns);
      var filterChange = this.applyGridPersistenceFilterColumns(filterColumns);
      var sortChange = this.applyGridPersistenceSortColumns(sortColumns);
      var groupChange = this.applyGridPersistenceGroupColumns(groupColumns);

      /*
      this.appGridService.isInPersistenceChange = false;
      (<any> this.grid.renderModule.data) = dataManager;
      (<any> this.grid.getDataModule().dataManager) = dataManager;
      */

      var isRefreshed = (filterChange || sortChange || groupChange);

      if (columnChange && !isRefreshed) {
        if (this.design?.dataRelationRole != "parent") {
          this.applyRefreshComponent();
        }
      }
      //this.grid.setProperties(persistenceObject);
      //this.isGridDataBound = isGridDataBoundSave;
    }
  }

  public applyGridPersistenceColumns(columns: any[], persitencefilterColumns: any[]): boolean {
    var grid = this.grid;
    var changed: boolean = false;

    if (columns && columns.length > 0) {
      var indexMax = columns.length - 1
      for (var index: number = 0; index <= indexMax; index++) {
        var c = columns[index];
        var col = grid.getColumnByField(c.field);
        if (col) {
          if (col.index && col.index != index) {
            grid.reorderColumnByIndex(col.index, index);
            changed = true;
            index = indexMax + 1; // Break loop
          }
          if (col.width != c.width ||
            col.visible != c.visible) {
            col.width = c.width;
            col.visible = c.visible;
            changed = true;
          }
          if (persitencefilterColumns) {
            // Update filter uid
            persitencefilterColumns.filter(fc => fc.field == col.field && col.uid).forEach(fc => fc.uid = col.uid);
          }

        }
      }
    }
    return changed;
  }

  private applyGridPersistenceFilterColumns(columns: any[]): boolean {
    var changed = false;
    var grid = this.grid;
    if (grid.filterSettings.columns.length > 0) {
      grid.clearFiltering();
      changed = true;
    }
    if (columns && columns.length > 0) {
      var grouped: Map<string, any[]> = new Map<string, any[]>();
      columns.forEach(c => {
        if (!grouped.has(c.field)) {
          grouped.set(c.field, [c])
        }
        else {
          var arr: any[] = grouped.get(c.field);
          arr.push(c);
          grouped.delete(c.field);
          grouped.set(c.field, arr);
        }
      });

      grouped.forEach((cols: any[], field: string) => {
        var col = grid.getColumnByField(field);
        if (col) {
          var value: any = cols.length > 1 ? cols.map(c => c.value) : cols[0].value;
          var c = cols[0];
          this.grid.filterByColumn(c.field, c.operator, value, c.predicate, c.matchCase, c.ignoreAccent /*, c.actualfilterValue, c.actualOperator */);
          changed = true;
        }
      });
    }
    return changed;
  }

  private applyGridPersistenceSortColumns(columns: any[]): boolean {
    var changed = false;
    var grid = this.grid;

    // Check if change to sorting is needed
    if (grid.sortSettings.columns.length == columns.length) {
      grid.sortSettings.columns.forEach((col, index) => {
        var c = columns[index];
        if (c) {
          if (col.field != c.field || col.direction != c.direction) {
            changed = true;
          }
        }
      });
      if (!changed) {
        return changed;
      }
    }
    if (grid.sortSettings.columns.length > 0) {
      grid.clearSorting();
      changed = true;
    }
    if (columns && columns.length > 0) {
      var multiSort: boolean = false;
      columns.forEach(c => {
        var col = grid.getColumnByField(c.field);
        if (col) {
          this.grid.sortColumn(c.field, c.direction, multiSort);
          multiSort = true
          changed = true;
        }
      });
    }
    return changed;
  }

  private applyGridPersistenceGroupColumns(columns: any[]): boolean {
    var changed = false;
    var grid = this.grid;
    if (grid.groupSettings.columns.length > 0) {
      grid.clearGrouping();
      changed = true;
    }
    if (columns && columns.length > 0) {
      columns.forEach(c => {
        var col = grid.getColumnByField(c);
        if (col) {
          this.grid.groupColumn(col.field);
          changed = true;
        }
      });
    }
    return changed;
  }

  public applyColumnsStandardView(isViewComplete: boolean): boolean {
    var changed: boolean = false;
    if (this.grid?.columns) {
      this.grid.columns.forEach((column: ColumnModel) => {
        if (column.field) {
          var wasVisible: boolean = (column.visible !== false);
          var visible: boolean = wasVisible;
          if (isViewComplete) {
            visible = true;
          }
          else {
            var meta: FieldMetadata = this.meta.fieldMetadata.find(f => f.name == column.field);
            if (meta) {
              visible = (meta.visible !== false);
            }
          }
          if (visible != wasVisible) {
            column.visible = visible;
            changed = true;
          }
        }
      });
    }
    return changed;
  }

}