// Angular
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

// WebApi
import {
  VoucherMessageViewDetailsVm as DetailsVm,
  VoucherPostingViewDto as PostingDto,
  VoucherMessageChannelTypeEnum,
  VoucherPostingTypeEnum
} from "../../api";

// App
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { UiActionTypeEnum, UiCommand, UiCommandEvent, UiCommandSourceEnum, UiKeyboardShortcut } from 'src/cer/cer-grid/cer-grid-command.service';
import { uiCmdBottomClose, uiCmdBottomToggle, uiCmdPaneRightClose, uiCmdPaneRightToggle } from 'src/cer/cer-form/cer-form-panes.service';
import { FieldFormatEnum, FieldMetadata, ViewMetadata } from 'src/cer/cer-data/cer-data.service';

// Voucher
import { VoucherDataService } from '../voucher-data.service';
import { VoucherHelper } from '../voucher.service';

@Injectable(
)

export class VoucherPostingViewService {


  // Ui commands
  private uiCmdDetailToggle = Object.assign(new Object(), uiCmdPaneRightToggle, { text: 'Vis vedhæftninger', tooltipText: 'Vis vehæftninger i højre side [Ctrl]+[Pil højre]' });
  private uiCmdBottomToggle = Object.assign(new Object(), uiCmdBottomToggle, { text: 'Vis kontering', tooltipText: 'Vis kontering nederst [Ctrl]+[Pil ned]' });
  private uiCmdDetailClose = Object.assign(new Object(), uiCmdPaneRightClose, { text: '', tooltipText: 'Luk vedhæftninger [Ctrl]+[Pil op]' });
  private uiCmdBottomClose = Object.assign(new Object(), uiCmdBottomClose, { text: 'Kontering', tooltipText: 'Luk kontering [Ctrl]+[Pil op]' });

  // View
  private viewMetadata: ViewMetadata = {
    name: 'postering', data: [], dataApiName: 'VoucherPostingView', dataApiParams: [],
    text: 'Posteringer', textSingular: 'Postering', titleFields: ['amountCur', 'accountNum'],
    allowEdit: false, allowDelete: false, allowCreate: false,
    parentForeignKey: 'voucherId', parentPrimaryKey: 'id',
    primaryKey: 'id', baseFields: ['id']
  };

  private fieldMetadata: FieldMetadata[] = [
    { name: 'id', text: "Postering id", format: FieldFormatEnum.Integer, visible: false, width: '90px' },
    { name: 'voucherId', text: "Bilag id", format: FieldFormatEnum.Integer, visible: false, width: '90px' },
    { name: 'lineNum', text: "Linjenr.", format: FieldFormatEnum.Integer, visible: false, width: '90px' },
    { name: 'amountCur', text: "Beløb", format: FieldFormatEnum.Number, width: '120px', orderBy: 'Ascending'},
    { name: 'postingTypeName', text: "Type", width: '90px' },
    { name: 'accountNum', text: "Nummer", width: '120px' },
    { name: 'accountDescription', text: "Navn", width: '140px' },
    { name: 'isbn', text: "ISBN", width: '120px', visible: false},
    { name: 'projectCostGroup', text: "Gruppe", width: '120px', visible: false },
    { name: 'projectCostTypeDescription', text: "Projektart", width: '120px' },
    { name: 'projectLedgerNumName', text: "Projektbogføring", width: '120px' },
    { name: 'taxCode', text: "Momskode", width: '80px' },
    { name: 'vendorBankAccount', text: "Bankkonto", width: '120px' },
    { name: 'paymentRef', text: "Betalings Id", width: '120px' },
    { name: 'txt', text: "Posteringstekst", width: '120px' },
    { name: 'created', text: "Oprettet", format: FieldFormatEnum.DateLong, visible: false},
    { name: 'createdByShortName', text: "Oprettet af", width: '100px', visible: false },
    { name: 'modified', text: "Ændret", format: FieldFormatEnum.DateLong, visible: false },
    { name: 'modifiedByShortName', text: "Ændret af", width: '100px', visible: false }
  ];

  private keyboardShortcuts: UiKeyboardShortcut[] = [
    { code: 'ArrowRight', ctrl: true, alt: false, shift: false, cmd: this.uiCmdDetailToggle },
    { code: 'ArrowRight', ctrl: true, alt: false, shift: true, cmd: this.uiCmdDetailToggle },
    { code: 'ArrowLeft', ctrl: true, alt: false, shift: false, cmd: this.uiCmdDetailClose },
    { code: 'ArrowLeft', ctrl: true, alt: false, shift: true, cmd: this.uiCmdDetailClose },
    { code: 'ArrowDown', ctrl: true, alt: false, shift: false, cmd: this.uiCmdBottomToggle },
    { code: 'ArrowDown', ctrl: true, alt: false, shift: true, cmd: this.uiCmdBottomToggle },
    { code: 'ArrowUp', ctrl: true, alt: false, shift: false, cmd: this.uiCmdBottomClose },
    { code: 'ArrowUp', ctrl: true, alt: true, shift: true, cmd: this.uiCmdBottomClose }
  ];

  private toolbarCommands: UiCommand[] = [this.uiCmdBottomClose];
  
  constructor(private voucherDataService: VoucherDataService) {
  }

  public init() {
    this.manage(this.voucherDataService.setupComplete$.subscribe(ok => this.setup()));
    this.manage(this.voucherDataService.detailsVm$.subscribe(vm => this.onDetailVm(vm)));
  }

  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  // Grid support
  private grid: CerGridComponent;
  public initGrid(grid: CerGridComponent) {
    grid.viewMetadata = this.viewMetadata;
    grid.fieldMetadata = this.fieldMetadata;
    grid.toolbarCommands = this.toolbarCommands;
    grid.commmand.subscribe(e => this.onCommand(e));
    this.grid = grid;
  }

  private onDetailVm(vm: DetailsVm): void {
    var postingDtoList: PostingDto[] =  vm?.voucherPostingViewDtoList ?? [];
    if (this.grid) {
      this.grid.dataNext(postingDtoList);
    }
  }

  /*
  private onDetailVmPostingDto(vmPostingDto: PostingDto): void {
    var postingDto: PostingDto = {} as PostingDto;
    Object.assign(postingDto, vmPostingDto);
    if (postingDto?.id) {
      var id: number = postingDto.id;
      var selectedData: any = this.grid?.selection.rowDataById(id);
      if (selectedData) {
        postingDto.modified.setMilliseconds(0);
        postingDto.created.setMilliseconds(0);
        if (!this.grid.selection.rowDataIsEqual(selectedData, postingDto)) {
          if (postingDto.modified < selectedData.modified) {
            console.error("Modified date is older than existing data", postingDto.modified, selectedData.modified);
          }
          else {
            this.grid.selection.rowDataByIdSet(id, postingDto);
          }
        }
      }
    }
  }
  */

  public research() {
    this.grid.selection.rowSelectedKeep();
    this.grid.research();
  }

  // Setup view from data service
  private setup() {
    var hidePayment: boolean = (this.voucherDataService.filterChannelType != VoucherMessageChannelTypeEnum.VendorVoucher);
    if (this.voucherDataService.filterMyVouchers) {
      this.fieldMetadataByNameHide('postingTypeName');
      this.fieldMetadataByNameHide('projectLedgerNumName');
      this.fieldMetadataByNameHide('taxCode');
      hidePayment = true;
  
      var postingType : FieldMetadata = this.fieldMetadataByName('postingType');
      if (postingType) {
        postingType.filterValue =[VoucherPostingTypeEnum.Ledger, VoucherPostingTypeEnum.Project, VoucherPostingTypeEnum.ProjectRoyalty];
        postingType.filterOperator = 'in';
      }
    }
    if (hidePayment) {
      this.fieldMetadataByNameHide('vendorBankAccount');
      this.fieldMetadataByNameHide('paymentRef');
    }
  }

  private fieldMetadataByNameHide(fieldName: string) {
    var field: FieldMetadata = this.fieldMetadata.find(f => f.name == fieldName);
    if (field) {
      field.visible = false;
    }
  }

  private fieldMetadataByName(fieldName: string): FieldMetadata {
    return this.fieldMetadata.find(f => f.name == fieldName);
  }


  private onCommand(e: UiCommandEvent): void {
    if (e.actionType == UiActionTypeEnum.QueryCellInfo) {
      this.gridQueryCellInfo(e.args);
    }
    else if (e.source == UiCommandSourceEnum.ActionComplete &&
      e.actionType == UiActionTypeEnum.DataBound) {
      this.enableColumnsDatabound(e);
    }
  }

  private gridQueryCellInfo(args: any) {
    VoucherHelper.postingGridQueryCellInfo(args);
  }

  private enableColumnsDatabound(e: any) {
    var postingDtoList: PostingDto[] = e.rowData;

    /*
    var vendorBankAccountMissing: boolean = false;

    if (postingDtoList?.length > 0 &&
      this.voucherDataService.filterChannelType == VoucherMessageChannelTypeEnum.VendorVoucher &&
      !this.voucherDataService.filterMyVouchers) {

      vendorBankAccountMissing = postingDtoList?.filter(p => <VoucherPostingTypeEnum>p.postingType == VoucherPostingTypeEnum.Vendor &&
        p.accountNum && !p.vendorBankAccount).length > 0;
    }
    var column = this.grid.grid.getColumnByField('vendorBankAccount');
    if (column?.visible != vendorBankAccountMissing) {
      column.visible = vendorBankAccountMissing;
      this.grid.grid.refreshColumns();
    }
    */
  }
}


/*
var filterApprovalStatusCreate: boolean = this.voucherDataService.filterApprovalStatus == VoucherApprovalStatusAllEnum.Created;
var filterMyApprovals: boolean = this.voucherDataService.filterMyApprovals;
if (filterApprovalStatusCreate && filterMyApprovals) {
  var category = this.fieldMetadataByName('category');
  if (category) {
    category.visible = true;
  }
}

*/