import { Pipe, PipeTransform } from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { DatePipe, getLocaleNumberFormat, NumberFormatStyle, registerLocaleData, DecimalPipe } from '@angular/common';
import { format as dateFormat, differenceInCalendarDays, differenceInMinutes } from 'date-fns';
import { da as dateDa } from 'date-fns/locale';
import { Internationalization } from '@syncfusion/ej2-base';

import localeDa from '@angular/common/locales/da';

registerLocaleData(localeDa);
  
@Pipe({
  name: 'CerAmountPipe'
})
export class CerAmountPipe implements PipeTransform {

  transform(value: number | string, locale?: string): string {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2
    }).format(Number(value));
  }

}

@Injectable()
export class CerIntlService {

  private internationalizationParseDecimalHelper: Function = null;
  private internationalizationHelper: Internationalization = null;


  // Date format
  private dateFormat: any = { type: "date", format: "dd.MM.yyyy" };
  private dateTimeFormat: any = { type: "date", format: "dd.MM.yyyy HH:mm" };
  private dateFormatWeekDay: any = { type: "date", format: "EEEE dd.MM.yyyy" };
  private dateFormatToday: any = { type: "date", format: "HH:mm" };

  constructor(@Inject(LOCALE_ID) private locale: string) {
    console.log("intl service instantiated");
  }

  private intlHelper(): Internationalization {
    if (!this.internationalizationHelper) {
      this.internationalizationHelper = new Internationalization();
    }
    return this.internationalizationHelper;
  }

  private intlParseDecimalHelper(): Function {
    if (!this.internationalizationParseDecimalHelper) {
      this.internationalizationParseDecimalHelper = this.intlHelper().getNumberParser({ format: 'N2', useGrouping: true });
    }
    return this.internationalizationParseDecimalHelper;
  }

  public datePipe(): DatePipe {
    return new DatePipe(this.locale);
  }

  public decimalPipe(): DecimalPipe {
    return new DecimalPipe(this.locale);
  }

  public getDecimalFormat(): string {
    return getLocaleNumberFormat(this.locale, NumberFormatStyle.Decimal);
  }

  public formatDecimal(decimal: number): string {
    return new Intl.NumberFormat(this.locale, {minimumFractionDigits: 2 }).format(Number(decimal));
  }

  public parseDecimal(decimalString: string): number {
    var decimal: number = 0;
    try {
      decimal = this.intlParseDecimalHelper()(decimalString);
    }
    catch
    {
    }
    return decimal;
  }


}



