import { ColumnModel } from "@syncfusion/ej2-angular-grids";
import { CerDataService, FieldGroupMetadata, FieldMetadata, TabMetadata, ViewMetadata } from "../cer-data/cer-data.service";
import { Injectable } from "@angular/core";

@Injectable()
export class CerEditMetaService {

  constructor(private data: CerDataService) {
  }

  public viewMetadata: ViewMetadata = null;
  public fieldMetadata: FieldMetadata[] = null;
  public fieldGroupMetadata: FieldGroupMetadata[] = null;
  public tabMetadata: TabMetadata[] = null;

  init(viewMetada: ViewMetadata, fieldMetadata: FieldMetadata[], fieldGroupMetadata: FieldGroupMetadata[], tabMetadata: TabMetadata[]) {
    this.viewMetadata = viewMetada;
    this.fieldMetadata = fieldMetadata;
    this.fieldGroupMetadata = fieldGroupMetadata;
    this.tabMetadata = tabMetadata;
  }

  public fieldMetaGet(fieldName: string): FieldMetadata {
    return this.data.fieldMetadata.find(f => f.name == fieldName);
  }

  public columnMetaGet(column: ColumnModel): FieldMetadata {
    return column ? (<any>column).meta : null;
  }

  public isFieldVisible(c: ColumnModel, isAdd: boolean): boolean {
    var visible: boolean = c.visible !== false;
    var meta = this.columnMetaGet(c);
    if (meta) {
      if (isAdd) {
        if (meta.visibleAdd === true) {
          visible = true;
        }
        if (meta.visibleAdd === false) {
          visible = false;
        }
      }
      else {
        if (meta.visibleEdit === true) {
          visible = true;
        }
        if (meta.visibleEdit === false) {
          visible = false;
        }
      }
      if (meta.currentRowVisible === true) {
        visible = true;
      }
      if (meta.currentRowVisible === false) {
        visible = false;
      }
    }
    return visible;
  }
}
