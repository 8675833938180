import { UserDto } from '../../app/api';

export class AppUsersLogic {

  public users: UserDto[] = null;

  public userById(id: number): UserDto {
    return this.users ? this.users.find(u => u.id == id) : null;
  }

  public userByShortName(shortName: string): UserDto {
    return this.users ? this.users.find(u => u.shortName.toUpperCase() == shortName.toUpperCase()) : null;
  }

  public userByName(name: string): UserDto {
    return this.users ? this.users.find(u => u.name.toUpperCase() == name.toUpperCase()) : null;
  }

  public userNameById(id: number): string {
    var user = this.users ? this.users.find(u => u.id == id) : null;
    return user ? user.shortName : null;
  }

  public ShortNameById(id: number): string {
    var user = this.users ? this.users.find(u => u.id == id) : null;
    return user ? user.name : null;
  }

  public EmailById(id: number): string {
    var user = this.users ? this.users.find(u => u.id == id) : null;
    return user ? user.email : null;
  }

  public avatarById(id: number): string {
    var user = this.users ? this.users.find(u => u.id == id) : null;
    return user && user.shortName.length > 0 ? user.shortName.charAt(0) : '?';
  }

  public photoUrlById(id: number): string {
    var user = this.users ? this.users.find(u => u.id == id) : null;
    return user ? '/assets/users/' + user.shortName.toLocaleLowerCase + '.jpg' : '/assets/users/turbine.jpg';
  }


}
