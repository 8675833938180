import { Column, DatePickerEditCell, IGrid } from "@syncfusion/ej2-angular-grids";
import { FieldEditChangedArgs, FieldMetadata } from "../cer-data/cer-data.service";
import { DatePicker } from "@syncfusion/ej2-angular-calendars";
import { CerLocaleService } from "../cer-locale/cer-locale.service";

export class CerGridEditDatePicker {
  private parent: any;
  private fieldMeta: FieldMetadata;
  private baseEditCell: DatePickerEditCell;
  private baseEditCellAny: any;
  private input: HTMLInputElement;
  private obj: DatePicker;

  constructor(parent: IGrid, baseEditCell: DatePickerEditCell, fieldMeta: FieldMetadata, private localeService: CerLocaleService) {
    this.parent = parent;
    this.baseEditCell = baseEditCell;
    this.baseEditCellAny = <any>baseEditCell;
    this.fieldMeta = fieldMeta;

    (<any>baseEditCell).create_orig = baseEditCell.create;
    this.baseEditCell.create = this.create.bind(this);

    (<any>baseEditCell).destroy_orig = baseEditCell.destroy;
    baseEditCell.destroy = this.destroy.bind(this);

    (<any>baseEditCell).write_orig = baseEditCell.write;
    baseEditCell.write = this.write.bind(this);

    (<any>baseEditCell).read_orig = baseEditCell.read;
    baseEditCell.read = this.read.bind(this);
  }

  public create(args: any): any {
    if (this.baseEditCellAny.create_orig) {
      this.input = this.baseEditCellAny.create_orig(args);
      return this.input;
    }
  }

  public write(args: {
    rowData: Object;
    element: Element;
    column: Column;
    requestType: string;
    row: Element;
  }) {
    if (this.baseEditCellAny.write_orig) {
      var ret = this.baseEditCellAny.write_orig(args);
      this.obj = this.baseEditCellAny.obj;
    }
    if (this.fieldMeta) {
      this.addCerEventListener();
    }
  }

  public destroy() {
    if (this.baseEditCellAny.destroy_orig) {
      return this.baseEditCellAny.destroy_orig();
    }
    if (this.fieldMeta) {
      this.removeCerEventListener();
    }
  }

  public read(element: Element): Date {
    return this.obj.value;
  }

  private onInputChanged(args: any) {
    var value: string = this.input?.value;
    if (value?.length > 0 && this.obj) {
      var d: Date = this.localeService.parseDate(value);
      if (d && d != this.obj.value) {
        this.obj.value = d;
        (<any> this.obj).changeTrigger(args);
      }
    }
  }

  private onEditChanged(args: any) {
    var value : Date = this.obj.value;
    
    if (this.fieldMeta?.onEditChanged) {
      var changedArgs: FieldEditChangedArgs = {
        field: this.fieldMeta?.field,
        fieldMeta: this.fieldMeta,
        value: value,
        previousValue: this.obj,
        data: null,
        args: args
      }
      this.fieldMeta.onEditChanged(changedArgs);
    }
  }

  private addCerEventListener() {
    this.input.addEventListener("change", this.onInputChanged.bind(this));
    this.obj.addEventListener("change", this.onEditChanged.bind(this));
  };

  private removeCerEventListener() {
    this.input.removeEventListener("change", this.onInputChanged.bind(this));
    this.obj.removeEventListener("change", this.onEditChanged.bind(this));
  }
}

