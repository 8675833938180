import { ErrorHandler, NgModule } from '@angular/core';

import { AngularBaseModule } from '../external-modules/angular-base.module';
import { MaterialDesignModule } from '../external-modules/material-design.module';
import { AuthorizationModule } from '../external-modules/authorization.module';
import { SyncFusionModule } from '../external-modules/syncfusion.module';
import { HotkeyModule, IHotkeyOptions } from 'angular2-hotkeys';
import { AngularDeviceInformationService } from 'angular-device-information';

import * as PDFJSViewer from 'pdfjs-dist/web/pdf_viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // https://moribvndvs.github.io/ng2-idle/quickstart - this includes the core NgIdleModule but includes keepalive providers for easy wireup

import { CerIntlService } from '../cer-platform/cer-intl/cer-intl.service';
import { CerSessionService } from './cer-session/cer-session.service';
import { CerIdleService } from './cer-idle/cer-idle.service';
import { CerCookieService } from './cer-cookie/cer-cookie.service';

var cerDeclarations : any[] = [
];

// Hotkeys
var hotkeyOptions: IHotkeyOptions = {
  disableCheatSheet: false,
  cheatSheetHotkey: '?',
  cheatSheetCloseEsc: true,
  cheatSheetCloseEscDescription: 'Luk denne liste',
  cheatSheetDescription: 'Liste genvejstaster'
};

var cerImports = [
  AngularBaseModule,
  AuthorizationModule,
  SyncFusionModule,
  MaterialDesignModule,
  PdfViewerModule,
  HotkeyModule.forRoot(hotkeyOptions),
  NgIdleKeepaliveModule.forRoot()
];

var cerProviders  = [
  AngularDeviceInformationService,
  CerCookieService,
  CerIdleService,
  CerIntlService,
  CerSessionService
];

var cerExports = [].concat(cerDeclarations).concat(cerImports);

@NgModule({
  declarations: cerDeclarations,
  imports: cerImports,
  providers: cerProviders,
  exports: cerExports
})
export class CerPlatformModule { }
