import { Component } from '@angular/core';
import {  FieldFormatEnum, FieldMetadata, FieldWidthEnum, ViewMetadata } from 'src/cer/cer-data/cer-data.service';


@Component({
  selector: 'app-mail-channel',
  templateUrl: './app-mail-channel.component.html',
  styleUrls: ['./app-mail-channel.component.css']
})
export class AppMailChannelComponent {

  // View
  public viewMetadata: ViewMetadata = {
    name: 'mailChannels', dataApiName: 'MailChannel', text: 'Udgående mail kanaler',
    primaryKey: 'id', titleFields: ['name'], dialogEdit: true, allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };
  // Fields
  public fieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for kanalen', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS },
    { name: 'name', text: 'Navn', tooltipText: 'Navn (reference til teknisk opsætning)', widthType: FieldWidthEnum.M, orderBy: 'Ascending' },
    { name: 'active', text: 'Aktiv', tooltipText: 'Er kanalen aktiv?', format: FieldFormatEnum.CheckBox },
    ];

  constructor() {
  }
}
