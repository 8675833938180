import { Injectable, OnDestroy } from '@angular/core';
import { User } from 'oidc-client';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthenticationResultStatus, AuthorizeService } from 'src/api-authorization/authorize.service';

export type SessionState = 'inactive' | 'activate' | 'active' | 'paused' | 'expired' | 'noAccess';

export class SessionUser {
  public id_token: string;
  public access_token: string;
  public name: string;
}

@Injectable({
  providedIn: 'root'
})
export class CerSessionService implements OnDestroy {

  private subscriptionManager$: Subscription = new Subscription();

  public isAuthenticated$ = new BehaviorSubject<boolean>(false);
  private oidcUser$ = new BehaviorSubject<User>(null);
  public sessionUser$ = new BehaviorSubject<SessionUser>(null);
  public state$ = new BehaviorSubject<SessionState>('inactive');

  constructor(private authorizeService: AuthorizeService) {
    this.subscriptionManager$.add(
      this.authorizeService.getUser().subscribe(oidcUser => this.oidcUserChanged(oidcUser))
    );

    this.subscriptionManager$.add(
      this.authorizeService.authenticationResultStatusSubject.subscribe(loginStatus => this.onAuthenticationResultStatus(loginStatus))
    );

    this.subscriptionManager$.add(
      this.state$.subscribe(state => this.onStateChanged(state))
    );
  }
  
  public ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }
  
  private onAuthenticationResultStatus(loginStatus: AuthenticationResultStatus) {
    //console.log("LOGIN STATUS: "+loginStatus);
    if (loginStatus == AuthenticationResultStatus.Success)
    {
      //console.log('LOGIN SUCCESS STATUS');
      this.activate();
    }
  }
    
  private onStateChanged(state: SessionState) {
    console.log('STATE: ' + state);
  }

  private oidcUserChanged(oidcUser: User) {
    if (oidcUser == null) {
      if (this.state$.value != 'inactive') {
        this.deactivate();
      }
    }
    else {
      var oidcUserPrev: User = this.oidcUser$.getValue();
      if (oidcUserPrev == null || oidcUserPrev.access_token != oidcUser.access_token) {
        this.oidcUser$.next(oidcUser);
        this.activate();
      }
    }
  }

  private sessionUserBuild(oidcUser: User): SessionUser {
    var sessionUser: SessionUser = null;

    if (oidcUser) {
      sessionUser = new SessionUser();
      sessionUser.id_token = oidcUser.id_token;
      sessionUser.access_token = oidcUser.access_token;
      sessionUser.name = oidcUser.profile.name;
    }

    return sessionUser;
  }

  public activate() {
    var oidcUser = this.oidcUser$.value;
    if (oidcUser != null) {
      this.sessionUser$.next(this.sessionUserBuild(oidcUser));
      this.state$.next('activate');
    }
  }

  public noAccess() {
    this.isAuthenticated$.next(true);
    this.state$.next('noAccess');
  }

  public active() {
    this.isAuthenticated$.next(true);
    this.state$.next('active');
    this.waitForUserExpiry();
  }

  public deactivate() {
    this.sessionUser$.next(null);
    this.oidcUser$.next(null);
    this.isAuthenticated$.next(false);
    this.state$.next('inactive');
  }

  public pause() {
    this.state$.next('paused');
  }

  public expire() {
    this.state$.next('expired');
  }

  private waitForUserExpiry() {
    var oidcUser: User = this.oidcUser$.getValue();
    if (oidcUser != null) {
      var waitTime = (oidcUser.expires_in - 60) * 1000;  // 1 minute before expiry
      setTimeout(() => {
        this.checkUserExpiry();
      },
        waitTime);
    }
  }

  public ping() {
  }

  private checkUserExpiry() {
    var state: string = this.state$.getValue();
    if (state != null && state != 'inactive') {
      var oidcUser = this.oidcUser$.value;
      if (oidcUser != null) {
        if (oidcUser.expires_in <= 5*60) {  // 5 minutes before expiry
          this.expire();
        }
        else {
          this.waitForUserExpiry();  // Make sure we check again
        }
      }
    }
  }
}
