// Angular
import { Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

// SyncFusion
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { CerLocaleService } from 'src/cer/cer-locale/cer-locale.service';

// App
@Component({
  selector: 'cer-date-edit',
  template: '<ejs-datepicker></ejs-datepicker>'
})

export class CerDateEditComponent {

  /*
  private _value: Date;
  @Input()
  set value(val: Date) {
    this.valueChange.emit(val);
    this._value = val;
  }
  get value() {
    return this._value;
  }
  @Output()
  correctChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  */
  @Input() defaultToday: boolean = true;
  @Input() default: Date;
  //@Output() getValue: Date

  private isInitComplete: boolean = false;

  public datePicker: DatePickerComponent = null;
  @ViewChild(DatePickerComponent, { static: false }) set setViewChildDatePicker(datePicker: DatePickerComponent) {
    this.datePicker = datePicker;
    this.init();
  }

  constructor(public localeService: CerLocaleService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  public init() {
    if (!this.isInitComplete) {
      if (this.datePicker) {
        this.isInitComplete = true;
        this.setupDefault();
        this.setupOnChange();
      }
    }
  }

  private setupDefault() {
    if (this.defaultToday) {
      this.setValue(this.localeService.today());
    }
  }
  private setupOnChange() {
    var localeService = this.localeService;
    this.datePicker.element.onchange = (args) => {
      var parsed = localeService.parseDate(this.datePicker.element.innerText);
      this.datePicker.element.innerText = localeService.formatDate(parsed);
    }
  }

  public setValue(date: Date) {
    this.datePicker.value = date;
  }
}
