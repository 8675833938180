// Enums/Parameters
import { RoleTypeEnum } from "../api";

// Navigation to router
const NavigationAll: Object[] = [
  {
    nodeId: 'contract', nodeText: 'Stamdata', roles: [RoleTypeEnum.BaseData, RoleTypeEnum.BaseDataBasic],
    nodeChild: [
      { nodeId: 'product/list', url: 'product/list', nodeText: 'Udgivelser', tooltip: 'Vis udgivelser', roles: [/*RoleTypeEnum.BaseData,*/ RoleTypeEnum.TennantAdmin] },
      { nodeId: 'contract/list', url: 'contract/list', nodeText: 'Kontrakter', tooltip: 'Vis kontrakter', roles: [/*RoleTypeEnum.BaseData,*/ RoleTypeEnum.TennantAdmin] },
      { nodeId: 'contract/party-list', url: 'contract/party-list', nodeText: 'Forfattere', tooltip: 'Vis forfattere', roles: [RoleTypeEnum.BaseData] },
      { nodeId: 'product/list-all', url: 'product/list-all', nodeText: 'Udgivelser (historisk)', tooltip: 'Vis alle historiske udgivelser', roles: [RoleTypeEnum.BaseData, RoleTypeEnum.BaseDataBasic] },
      { nodeId: 'contract/list-all', url: 'contract/list-all', nodeText: 'Kontrakter (historisk)', tooltip: 'Vis alle historiske kontrakter', roles: [RoleTypeEnum.BaseData, RoleTypeEnum.TennantAdmin] },
      { nodeId: 'product/bom', url: 'product/bom', nodeText: 'Bogpakker', tooltip: 'Vis alle bogpakker', roles: [RoleTypeEnum.BaseData, RoleTypeEnum.TennantAdmin] },
      { nodeId: 'masterdata/external/nemhandel', url: 'masterdata/external/nemhandel', nodeText: 'Nemhandel', tooltip: 'Vis NemHandel adresser', roles: [/*RoleTypeEnum.BaseData, */ RoleTypeEnum.TennantAdmin] }
    ]
  },
  {
    nodeId: 'rights', nodeText: 'Forfatterafregning', roles: [RoleTypeEnum.RightsBasic, RoleTypeEnum.RightsAdmin],
    nodeChild: [
      { nodeId: 'rights/approval-my', url: 'rights/approval-my', nodeText: 'Mine godkendelser', tooltip: 'Mine forfatterafregning godkendelser', roles: [RoleTypeEnum.RightsBasic] },
      { nodeId: 'rights/approval', url: 'rights/approval', nodeText: 'Alle godkendelser', tooltip: 'Alle forfatterafregning godkendelser', roles: [RoleTypeEnum.RightsBasic, RoleTypeEnum.RightsAdmin] }
    ]
  },
  {
    nodeId: 'royalty', nodeText: 'Royaltyberegning', roles: [RoleTypeEnum.RoyaltyApprover],
    nodeChild: [
      { nodeId: 'royalty/contract-list', url: 'royalty/contract-list', nodeText: 'Kontrakter', tooltip: 'Vis og godkend royalty kontrakter' },
      //    { nodeId: 'royalty/contract-editor-list', url: 'royalty/contract-editor-list', nodeText: 'Kontrakter pr. redaktør', tooltip: 'Vis royalty kontrakter pr. redaktør' },
      //    { nodeId: 'royalty/contract-publisher-list', url: 'royalty/contract-publisher-list', nodeText: 'Kontrakter pr. forlag', tooltip: 'Vis royalty kontrakter pr. forlag' },
      { nodeId: 'royalty/publisher-list', url: 'royalty/publisher-list', nodeText: 'Afregning forlag', tooltip: 'Vis godkendelse og afrengn royalty pr. forlag' }
    ]
  },
  {
    nodeId: 'voucher-my', nodeText: 'Bilagsgodkendelse', tooltip: 'Vis liste over mine godkendelser', roles: [RoleTypeEnum.VoucherExpenseCreate, RoleTypeEnum.VoucherApproval],
    nodeChild: [
      { nodeId: 'voucher/my/create', url: 'voucher/my/create', nodeText: 'Opret', tooltip: 'Opret bilag', roles: [RoleTypeEnum.VoucherExpenseCreate] },
      { nodeId: 'voucher/my/created', url: 'voucher/my/created', nodeText: 'Oprettede', tooltip: 'Vis liste over mine oprettede beskeder', roles: [RoleTypeEnum.VoucherExpenseCreate] },
      { nodeId: 'voucher/my/pending', url: 'voucher/my/pending', nodeText: 'Til godkendelse', tooltip: 'Vis liste over mine "Til godkendelse"', roles: [RoleTypeEnum.VoucherApproval] },
      { nodeId: 'voucher/my/onhold', url: 'voucher/my/onhold', nodeText: 'Afventer', tooltip: 'Vis liste over mine afventende godkendelser', roles: [RoleTypeEnum.VoucherApproval] },
      { nodeId: 'voucher/my/approved', url: 'voucher/my/approved', nodeText: 'Godkendte', tooltip: 'Vis liste over mine godkendte', roles: [RoleTypeEnum.VoucherApproval] },
      { nodeId: 'voucher/my/all', url: 'voucher/my/all', nodeText: 'Alle', tooltip: 'Vis liste over alle mine godkendelser', roles: [RoleTypeEnum.VoucherApproval] }
    ]
  },
  {
    nodeId: 'voucher-vendor', nodeText: 'Kreditorfakturaer', roles: [RoleTypeEnum.VoucherVendorAdmin],
    nodeChild: [
      {
        nodeId: 'voucher-vendor-message', nodeText: 'Beskeder', tooltip: 'Vis liste over modtagne beskeder',
        nodeChild: [
          { nodeId: 'voucher/vendor/message/inbox', url: 'voucher/vendor/message/inbox', nodeText: 'Indbakke', tooltip: 'Vis liste over indbakke beskeder til fordeling' },
          { nodeId: 'voucher/vendor/message/onhold', url: 'voucher/vendor/message/onhold', nodeText: 'Afventer', tooltip: 'Vis liste med bilag der afventer' },
          { nodeId: 'voucher/vendor/message/statement', url: 'voucher/vendor/message/statement', nodeText: 'Kontoudtog', tooltip: 'Vis liste med beskeder der er kontoudtog' },
          { nodeId: 'voucher/vendor/message/forwarded', url: 'voucher/vendor/message/forwarded', nodeText: 'Videresendt', tooltip: 'Vis liste med beskeder der er videresendt' },
          { nodeId: 'voucher/vendor/message/rejected', url: 'voucher/vendor/message/rejected', nodeText: 'Annulleret', tooltip: 'Vis liste med beskeder der er annulleret' },
          { nodeId: 'voucher/vendor/message/all', url: 'voucher/vendor/message/all', nodeText: 'Alle', tooltip: 'Vis liste med alle beskeder' }
        ]
      },
      {
        nodeId: 'voucher-vendor-voucher', nodeText: 'Godkendelser', tooltip: 'Vis liste over godkendelser',
        nodeChild: [
          { nodeId: 'voucher/vendor/voucher/pending', url: 'voucher/vendor/voucher/pending', nodeText: 'Til godkendelse', tooltip: 'Vis liste over "Til godkendelse"' },
          { nodeId: 'voucher/vendor/voucher/onhold', url: 'voucher/vendor/voucher/onhold', nodeText: 'Afventer', tooltip: 'Vis liste over afventende godkendelser' },
          { nodeId: 'voucher/vendor/voucher/approved', url: 'voucher/vendor/voucher/approved', nodeText: 'Godkendte', tooltip: 'Vis liste over godkendte' },
          { nodeId: 'voucher/vendor/voucher/accounted', url: 'voucher/vendor/voucher/accounted', nodeText: 'Afsluttede', tooltip: 'Vis liste over afsluttede godkendelser' },
          { nodeId: 'voucher/vendor/voucher/posted', url: 'voucher/vendor/voucher/posted', nodeText: 'Bogførte', tooltip: 'Vis liste over bogførte godkendelser' },
          { nodeId: 'voucher/vendor/voucher/all', url: 'voucher/vendor/voucher/all', nodeText: 'Alle', tooltip: 'Vis liste over alle godkendelser' }
        ]
      }
    ]
  },
  {
    nodeId: 'voucher-expense', nodeText: 'Udgiftsbilag', roles: [RoleTypeEnum.VoucherExpenseAdmin],
    nodeChild: [
      {
        nodeId: 'voucher-expense-message', nodeText: 'Beskeder', tooltip: 'Vis liste over modtagne beskeder',
        nodeChild: [
          { nodeId: 'voucher/expense/message/inbox', url: 'voucher/expense/message/inbox', nodeText: 'Indbakke', tooltip: 'Vis liste over indbakke beskeder til fordeling' },
          { nodeId: 'voucher/expense/message/onhold', url: 'voucher/expense/message/onhold', nodeText: 'Afventer', tooltip: 'Vis liste med bilag der afventer' },
          { nodeId: 'voucher/expense/message/statement', url: 'voucher/expense/message/statement', nodeText: 'Kontoudtog', tooltip: 'Vis liste med beskeder der er kontoudtog' },
          { nodeId: 'voucher/expense/message/forwarded', url: 'voucher/expense/message/forwarded', nodeText: 'Videresendt', tooltip: 'Vis liste med beskeder der er videresendt' },
          { nodeId: 'voucher/expense/message/rejected', url: 'voucher/expense/message/rejected', nodeText: 'Annulleret', tooltip: 'Vis liste med beskeder der er annulleret' },
          { nodeId: 'voucher/expense/message/all', url: 'voucher/expense/message/all', nodeText: 'Alle', tooltip: 'Vis liste med alle beskeder' }
        ]
      },
      {
        nodeId: 'voucher-expense-voucher', nodeText: 'Bilag', tooltip: 'Vis liste over udgiftsbilag',
        nodeChild: [
          { nodeId: 'voucher/expense/voucher/created', url: 'voucher/expense/voucher/created', nodeText: 'Oprettede', tooltip: 'Vis liste over oprettede bilag' },
          { nodeId: 'voucher/expense/voucher/pending', url: 'voucher/expense/voucher/pending', nodeText: 'Til godkendelse', tooltip: 'Vis liste over "Til godkendelse"' },
          { nodeId: 'voucher/expense/voucher/onhold', url: 'voucher/expense/voucher/onhold', nodeText: 'Afventer', tooltip: 'Vis liste over afventende bilag' },
          { nodeId: 'voucher/expense/voucher/approved', url: 'voucher/expense/voucher/approved', nodeText: 'Godkendte', tooltip: 'Vis liste over godkendte bilag' },
          { nodeId: 'voucher/expense/voucher/accounted', url: 'voucher/expense/voucher/accounted', nodeText: 'Afsluttede', tooltip: 'Vis liste over afsluttede bilag' },
          { nodeId: 'voucher/expense/voucher/posted', url: 'voucher/expense/voucher/posted', nodeText: 'Bogførte', tooltip: 'Vis liste over bogførte bilag' },
          { nodeId: 'voucher/expense/voucher/all', url: 'voucher/expense/voucher/all', nodeText: 'Alle', tooltip: 'Vis liste over alle bilag' },
          { nodeId: 'voucher/expense/voucher/request', url: 'voucher/expense/voucher/request', nodeText: 'Anmodninger', tooltip: 'Vis liste over bilagsanmodninger' }
        ]
      },
      {
        nodeId: 'voucher-expense-bank', nodeText: 'Bank', tooltip: 'Bankfunktioner',
        nodeChild: [
          { nodeId: 'bank/transaction/batch', url: 'bank/transaction/batch', nodeText: 'Indlæsninger', tooltip: 'Indlæsninger af transaktioner' },
          { nodeId: 'bank/settlement', url: 'bank/settlement', nodeText: 'Afstemning', tooltip: 'Bank afstemninger' },
          { nodeId: 'bank/settlement/list', url: 'bank/settlement/list', nodeText: 'Historik', tooltip: 'List over gennemførte bank afstemninger' }
        ]
      }
    ]
  },
  {
    nodeId: 'voucher-manual-voucher', nodeText: 'Manuelle bilag', tooltip: 'Vis liste over manuelle bilag', roles: [RoleTypeEnum.VoucherManualAdmin],
    nodeChild: [
      { nodeId: 'voucher/manual/voucher/posted', url: 'voucher/manual/voucher/posted', nodeText: 'Bogførte', tooltip: 'Vis liste over manuelt bogførte bilag' }
    ]
  },
  {
    nodeId: 'sales', nodeText: 'Salg', roles: [RoleTypeEnum.Webshop],
    nodeChild: [
      { nodeId: 'sales/order/list', url: 'sales/order/list', nodeText: 'Salgsordrer', tooltip: 'Vis liste med salgsordrer' },
      { nodeId: 'sales/invoice/accounted', url: 'sales/invoice/accounted', nodeText: 'Faktura kladder', tooltip: 'Vis salgsordrer til faktuering' },
      { nodeId: 'sales/invoice/posted', url: 'sales/invoice/posted', nodeText: 'Bogførte', tooltip: 'Vis bogførte salgsfakturaer' }
    ]
  },
  {
    nodeId: 'projects', nodeText: 'Opgavestyring', roles: [RoleTypeEnum.ProjectBasic, RoleTypeEnum.ProjectTask, RoleTypeEnum.ProjectStructure, RoleTypeEnum.ProjectAdmin],
    nodeChild: [
      { nodeId: 'project/task/my/open', url: 'project/task/my/open', nodeText: 'Mine åbne opgaver', tooltip: 'Vis mine åbne opgaver', roles: [RoleTypeEnum.ProjectBasic] },
      { nodeId: 'project/task/team/open', url: 'project/task/team/open', nodeText: 'Team åbne opgaver', tooltip: 'Vis åbne team opgaver', roles: [RoleTypeEnum.ProjectBasic] },
      { nodeId: 'project/task/my', url: 'project/task/my', nodeText: 'Mine opgaver', tooltip: 'Vis mine opgaver', roles: [RoleTypeEnum.ProjectBasic] },
      { nodeId: 'project/task/team', url: 'project/task/team', nodeText: 'Team opgaver', tooltip: 'Vis team opgaver', roles: [RoleTypeEnum.ProjectBasic] },
      { nodeId: 'project/task', url: 'project/task', nodeText: 'Opgaver', tooltip: 'Vis opgaver', roles: [RoleTypeEnum.ProjectStructure] },
      { nodeId: 'project/node/my', url: 'project/node/my', nodeText: 'Mine projekter', tooltip: 'Mine projekter', roles: [RoleTypeEnum.ProjectTask]  },
      { nodeId: 'project/node', url: 'project/node', nodeText: 'Projektstruktur', tooltip: 'Vis projektstruktur', roles: [RoleTypeEnum.ProjectStructure]  }
    ]
  },
  {
    nodeId: 'system-admin', nodeText: 'System administration', roles: [RoleTypeEnum.GlobalAdmin, RoleTypeEnum.TennantAdmin, RoleTypeEnum.VoucherExpenseAdmin, RoleTypeEnum.VoucherVendorAdmin, RoleTypeEnum.RoyaltyAdmin],
    nodeChild: [
      {
        nodeId: 'system-admin-exchange', nodeText: 'Dataudveksling', roles: [RoleTypeEnum.TennantAdmin],
        nodeChild: [
          { nodeId: 'exchange/queue', url: 'exchange/queue', nodeText: 'Forslag', tooltip: 'Dataudveksling forslag der venter på frigivelse' },
          { nodeId: 'exchange/queue-part', url: 'exchange/queue-party', nodeText: 'Forslag forfatter', tooltip: 'Dataudveksling forslag til forfatter der venter på frigivelse' },
          { nodeId: 'exchange/waiting', url: 'exchange/waiting', nodeText: 'Venter', tooltip: 'Dataudveksling der venter' },
          { nodeId: 'exchange/failed', url: 'exchange/failed', nodeText: 'Fejl', tooltip: 'Dataudveksling med fejl' },
          { nodeId: 'exchange/all', url: 'exchange/all', nodeText: 'Alle', tooltip: 'Alle Dataudvekslinger' }]
      },
      {
        nodeId: 'system-admin-user', nodeText: 'Brugeradministration', roles: [RoleTypeEnum.GlobalAdmin],
        nodeChild: [
          { nodeId: 'user/list', url: 'user/list', nodeText: 'Brugere', tooltip: 'Brugere' }
        ]
      },
      {
        nodeId: 'systen-admin-voucher', nodeText: 'Bilagsgodkendelse', roles: [RoleTypeEnum.VoucherVendorAdmin],
        nodeChild: [
          { nodeId: 'voucher/category', url: 'voucher/category', nodeText: 'Bilagskategorier', tooltip: 'Opdeling af forslag til kontering af bilag' },
          { nodeId: 'voucher/validation-status', url: 'voucher/validation-status', nodeText: 'Valideringsstatus', tooltip: 'Valideringsstatus for bilag' },
          { nodeId: 'voucher/channel/user', url: 'voucher/channel/user', nodeText: 'Kanal superbrugere', tooltip: 'Superbruger på kanaler for bilaggodkendelse' },
          { nodeId: 'voucher/channel/next-voucher', url: 'voucher/channel/next-voucher', nodeText: 'Kanal bilagsnumre', tooltip: 'Kanalers næste bilagsnummer' },
          { nodeId: 'voucher/posting-history', url: 'voucher/posting-history', nodeText: 'Bilag e-mail afsendere', tooltip: 'Automatisk forslag til kreditor/finanskonti ud fra e-mail afsender' }

        ]
      },
      {
        nodeId: 'system-admin-bank', nodeText: 'Bank', roles: [RoleTypeEnum.VoucherExpenseAdmin],
        nodeChild: [
          { nodeId: 'bank/account', url: 'bank/account', nodeText: 'Konti', tooltip: 'Bankkonti' },
          { nodeId: 'bank/credit-card', url: 'bank/credit-card', nodeText: 'Kreditkort', tooltip: 'Kreditkort' },
          { nodeId: 'bank/account/source', url: 'bank/account/source', nodeText: 'Kilder bankkonto', tooltip: 'Bankkonto kilder til posteringer' },
          { nodeId: 'bank/transaction/source', url: 'bank/transaction/source', nodeText: 'Kilder posteringer', tooltip: 'Kilder til posteringer' },
          { nodeId: 'bank/settlement/group', url: 'bank/settlement/group', nodeText: 'Kilde grupper', tooltip: 'Gruppering af kilder for posteringer' }
        ]
      },
      {
        nodeId: 'sales-admin', nodeText: 'Salg', roles: [RoleTypeEnum.VoucherExpenseAdmin],
        nodeChild: [
          { nodeId: 'sales/channel', url: 'sales/channel', nodeText: 'Salgskanaler', tooltip: 'Vis salgskanaler' },
          { nodeId: 'sales/order/status', url: 'sales/order/status', nodeText: 'Salg status', tooltip: 'Vis salg status' },
          { nodeId: 'sales/order/line/type', url: 'sales/order/line/type', nodeText: 'Salg linjetyper', tooltip: 'Vis salg linjetyper' },
          { nodeId: 'sales/payment/status', url: 'sales/payment/status', nodeText: 'Salg afregningstyper', tooltip: 'Vis salg afregningstyper' },
        ]
      },
      {
        nodeId: 'logistics-admin', nodeText: 'Logistik', roles: [RoleTypeEnum.VoucherExpenseAdmin],
        nodeChild: [
          { nodeId: 'logistics/warehouse', url: 'logistics/warehouse', nodeText: 'Lagersteder', tooltip: 'Vis lagersteder' },
          { nodeId: 'logistics/order/profile', url: 'logistics/order/profile', nodeText: 'Ordreprofiler', tooltip: 'Vis logistik ordreprofiler' }
        ]
      },
      {
        nodeId: 'system-admin-projects', nodeText: 'Opgavestyring', roles: [RoleTypeEnum.ProjectAdmin],
        nodeChild: [
          { nodeId: 'project/team', url: 'project/team', nodeText: 'Teams', tooltip: 'Vis projektteams' },
          { nodeId: 'project/task-type', url: 'project/task-type', nodeText: 'Opgavetype', tooltip: 'Vis opgavetyper' },
          { nodeId: 'project/task-status', url: 'project/task-status', nodeText: 'Opgavestatus', tooltip: 'Vis opgavestatus' },
          { nodeId: 'project/level', url: 'project/level', nodeText: 'Projektniveauer', tooltip: 'Vis projektniveauer' }
        ]
      },
      {
        nodeId: 'systen-admin-form-recognizer', nodeText: 'Formulargenkendelse', roles: [RoleTypeEnum.TennantAdmin, RoleTypeEnum.VoucherExpenseAdmin, RoleTypeEnum.VoucherVendorAdmin],
        nodeChild: [
          { nodeId: 'form-recognizer/recognizer', url: 'form-recognizer/recognizer', nodeText: 'Formular opsætning', tooltip: 'Formular genkendelse opsætning' },
          { nodeId: 'form-recognizer/document', url: 'form-recognizer/document', nodeText: 'Dokument historik', tooltip: 'Dokument historik for genkendelse' },
        ]
      },
      {
        nodeId: 'system-admin-mail', nodeText: 'Mail', roles: [RoleTypeEnum.TennantAdmin],
        nodeChild: [
          { nodeId: 'mail/message-channel-in', url: 'mail/message-channel-in', nodeText: 'Kanaler indgående', tooltip: 'Indgående mail kanaler (fakture/bilag)' },
          { nodeId: 'mail/message-channel-out', url: 'mail/message-channel-out', nodeText: 'Kanaler udgående', tooltip: 'Udgående mail kanaler' },
          { nodeId: 'mail/template', url: 'mail/template', nodeText: 'Skabeloner', tooltip: 'Mail skabeloner' }
        ]
      },
      {
        nodeId: 'system-admin-product', nodeText: 'Udgivelser', roles: [RoleTypeEnum.TennantAdmin],
        nodeChild: [
          { nodeId: 'product/series', url: 'product/series', nodeText: 'Udgivelsesserier', tooltip: 'Vis serier af udgivelser' },
          { nodeId: 'product/format', url: 'product/format', nodeText: 'Udgivelsesformater' },
          { nodeId: 'product/budget-category', url: 'product/budget-category', nodeText: 'Udgivelse budgetarter' },
          { nodeId: 'product/category', url: 'product/category', nodeText: 'Udgivelseskategorier' },
          { nodeId: 'product/group', url: 'product/group', nodeText: 'Udgivelsesgrupper' },
          { nodeId: 'product/pool', url: 'product/pool', nodeText: 'Printpuljer' },
          { nodeId: 'product/status', url: 'product/status', nodeText: 'Udgivelsesstatus' },
          { nodeId: 'product/theme', url: 'product/theme', nodeText: 'Udgivelsestemaer' },
          { nodeId: 'product/type', url: 'product/type', nodeText: 'Udgivelsestyper' },
          { nodeId: 'party/list', url: 'party/list', nodeText: 'Kontakter', tooltip: 'Kontakter' },
          { nodeId: 'party/role', url: 'party/role', nodeText: 'Kontaktroller', tooltip: 'Kontaktroller' }
        ]
      },
      {
        nodeId: 'system-admin-contract', nodeText: 'Kontrakter', roles: [RoleTypeEnum.TennantAdmin],
        nodeChild: [
          { nodeId: 'contract/type', url: 'contract/type', nodeText: 'Aftaletyper' }
        ]
      },
      {
        nodeId: 'system-admin-rights', nodeText: 'Forfatterafregning', roles: [RoleTypeEnum.RightsAdmin],
        nodeChild: [
          { nodeId: 'rights/period', url: 'rights/period', nodeText: 'Perioder', tooltip: 'Forfatterafregning perioder' }
        ]
      },
      {
        nodeId: 'system-admin-royalty', nodeText: 'Royalty', roles: [RoleTypeEnum.RoyaltyAdmin],
        nodeChild: [
          { nodeId: 'royalty/period', url: 'royalty/period', nodeText: 'Perioder', tooltip: 'Royalty perioder' }
        ]
      },
      {
        nodeId: 'system-admin-status', nodeText: 'Systemstatus', roles: [RoleTypeEnum.GlobalAdmin],
        nodeChild: [
          { nodeId: 'status/jobs', url: 'status/jobs', nodeText: 'Jobafvikling', tooltip: 'Vis status for jobafvikling' }
        ]
      },
      {
        nodeId: 'settings-proces', nodeText: 'Processer', roles: [RoleTypeEnum.TennantAdmin],
        nodeChild: [
          { nodeId: 'setting/data/sequence', url: 'setting/data/sequence', nodeText: 'Nummerserier', tooltip: 'Opsætning af nummerserier' },
          { nodeId: 'setting/data/process', url: 'setting/data/process', nodeText: 'Dataprocesser', tooltip: 'Opsætning af dataprocesser' },
          { nodeId: 'setting/data/process-stage', url: 'setting/data/process-stage', nodeText: 'Dataproces stadier', tooltip: 'Opsætning af dataproces stadier' },
          { nodeId: 'setting/data/table', url: 'setting/data/table', nodeText: 'Tabeller', tooltip: 'Opsætning af tabeller' }
        ]
      },
      {
        nodeId: 'system-admin-setting', nodeText: 'Opsætning', roles: [RoleTypeEnum.GlobalAdmin],
        nodeChild: [
          { nodeId: 'setting/route', url: 'setting/route', nodeText: 'Menupunkter', tooltip: 'Vis menupunkter' },
          { nodeId: 'setting/user/route', url: 'setting/user/route', nodeText: 'Visninger', tooltip: 'Brugeropsætning af visninger' }
        ]
      }
    ]
  }
];
export { NavigationAll };

