<cer-form #form [panes]="panes">
  <ng-template #mainTemplate>
    <cer-grid #grid [viewMetadata]="viewMetadata" [fieldMetadata]="fieldMetadata"
      [fieldGroupMetadata]="fieldGroupMetadata" [tabMetadata]="tabMetadata" [toolbarCommands]="toolbarCommands" 
      [contextMenuCommands]="contextMenuCommands" [keyboardShortcuts]="keyboardShortcuts" (commmand)="onCommand($event)" dataRelationRole="parent" 
      ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid #detailGrid [viewMetadata]="detailViewMetadata" [fieldMetadata]="detailFieldMetadata"
      [toolbarCommands]="detailToolbarCommands" [keyboardShortcuts]="keyboardShortcuts" mode="simpleToolbar" autoFocus="disabled" dataRelationRole="child"
      (commmand)="onDetailCommand($event)"></cer-grid>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid #bottomGrid [viewMetadata]="bottomViewMetadata" [fieldMetadata]="bottomFieldMetadata"
      [toolbarCommands]="bottomToolbarCommands" [keyboardShortcuts]="keyboardShortcuts" mode="simpleToolbar" autoFocus="disabled" dataRelationRole="child"
      (commmand)="onBottomCommand($event)"></cer-grid>
  </ng-template>
</cer-form>