import { FieldFormatEnum, FieldMetadata, FieldWidthEnum, ViewMetadata } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class BankAccountFormAdapter extends CerAppFormModelAdapter {

  public override init() {

    this.viewMetadata = {
      name: 'bank-konti', dataApiName: 'BankAccount', text: 'Konti', textSingular: 'Konto',
      primaryKey: 'id', titleFields: ['name'], baseFields: ['id','history']
    };

    this.fieldMetadata =
      [
      { name: 'name', text: 'Navn', tooltipText: 'Navn på konto', widthType: FieldWidthEnum.L },
      { name: 'currency', text: 'Valuta', foreignKeyTableName: 'Currency', foreignKeyValue: 'iso' },
      { name: 'ledgerAccount', text: 'Finanskontonr', foreignKeyTableName: 'FinanceLedgerAccount', foreignKeyField: 'num', foreignKeyValue: 'numName', foreignKeyColumns: ['num','name'], foreignKeyTitles: ['Konto','Navn'] },
      { name: 'bankIban', text: 'IBAN' },
      { name: 'bankRegNum', text: 'Bank regnr.' },
      { name: 'bankAccountNum', text: 'Bank kontonr.' },
      { name: 'active', text: 'Aktiv', format: FieldFormatEnum.CheckBox },
      { name: 'openingBalanceCur', text: 'Opstart saldo', format: FieldFormatEnum.Amount },
      { name: 'openingBalanceDate', text: 'Opstart dato', format: FieldFormatEnum.DateShort }
      ];

    this.rightPaneWidthPct = 50;
  }

}
