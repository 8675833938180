import { Component, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import { STEPPER_GLOBAL_OPTIONS, StepperOrientation } from '@angular/cdk/stepper';

import { UiKeyboardShortcut, UiCommand, UiCommandEvent } from '../cer-grid/cer-grid-command.service';
import { CerFormService } from '../cer-form/cer-form.service';
import { CerStepperService } from './cer-stepper.service';
import { CerLocaleService } from '../cer-locale/cer-locale.service';

export const uiCmdSplitterReduce: UiCommand = { id: 'uiCmdPaneReduce', text: 'Reducer detaljer' };
export const uiCmdSplitterExpand: UiCommand = { id: 'uiCmdPaneExpand', text: 'Udvid detaljer' };

export const formShortcutSplitterReduce: UiKeyboardShortcut = { cmd: uiCmdSplitterReduce, code: 'PageUp', alt: true, ctrl: false, shift: true, enabled: true }; // Alt+Shift+PgUp
export const formShortcutSplitterExpand: UiKeyboardShortcut = { cmd: uiCmdSplitterExpand, code: 'PageDown', alt: true, ctrl: false, shift: true, enabled: true }; // Alt+Shift+PgDn

export interface INextStep {
  title: string;
  disabled?: boolean;
  editable?: boolean;
  startedDate?: Date;
  scheduledDate?: Date;
  completedDate?: Date;
  assignedName?: string;
  icon?: string;
  formGroup?: FormGroup;

  completed?: boolean;
  overdue?: boolean;
  scheduled?: boolean;
  started?: boolean;

  id?: string;
  cssClass?: string;
  statusIcon?: string;
  statusText?: string;
};

@Component({
  selector: 'cer-stepper',
  templateUrl: './cer-stepper.component.html',
  styleUrls: ['./cer-stepper.component.css'],
  providers: [CerStepperService]
})
export class CerStepperComponent implements OnInit, OnDestroy {
  @Input() steps: INextStep[];
  @Input() isLinear: boolean = false;
  @Output() commmand: EventEmitter<UiCommandEvent> = new EventEmitter<UiCommandEvent>();

  /*
  @ViewChild(SplitterComponent) set setSplitter(splitter: SplitterComponent) {
    if (splitter && !this.splitter) {
      this.splitter = splitter;
      //splitter.paneSettings[1]
      splitter.paneSettings[1].collapsed = (this.detail === 'hide');
      this.splitterDetailVisibleRefresh({ intialLoad: true });
    }
  }
*/
  private subscriptionManager: Subscription = new Subscription();

  private designChangeQueued: boolean = false;
  public orientation: Observable<StepperOrientation>;
  public orientationVertical: Observable<boolean>;

  public stepClass = "HEJ";

  constructor(private stepperService: CerStepperService, private breakpointObserver: BreakpointObserver, private formService: CerFormService, private localeService: CerLocaleService) {
    this.orientation = this.breakpointObserver
      .observe('(min-width: 600px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
    this.orientationVertical = this.breakpointObserver
      .observe('(min-width: 600px)')
      .pipe(map(({ matches }) => (!matches)));

  }

  ngOnInit(): void {
    this.subscriptionManager.add(this.formService.childCommand$.subscribe(event => this.onChildEvent(event)));
    this.setupSteps();
  }

  private setupSteps() {
    var i: number = 0;
    this.steps
    this.steps.forEach(s => { s.id = 'idx-' + i++; this.setupStep(s) });
  }

  private setupStep(s: INextStep) {
    var now = new Date();
    s.completed = (s.completedDate != null);
    s.scheduled = !s.completed && s.scheduledDate != null;
    s.overdue = s.scheduled && s.scheduledDate < now;
    s.scheduled = s.scheduled && !s.overdue;
    s.started = !s.completed && !s.scheduled && !s.overdue && s.startedDate != null;

    s.cssClass =
      s.completed ? "next-step-completed " :
        s.scheduled ? "next-step-scheduled " :
          s.overdue ? "next-step-overdue " :
            "";
    s.statusIcon =
      s.completed ? "done" :
        s.overdue ? "notification_important" :
          s.scheduled ? "calendar_today" :
            s.started ? "add" :
              "";
    s.statusText =
      s.completed ? this.formatDate(s.completedDate) + ' færdig' :
        s.overdue ? this.formatDate(s.scheduledDate) + ' forventet' :
          s.scheduled ? this.formatDate(s.scheduledDate) + ' plan' :
            s.started ? this.formatDate(s.startedDate) + ' start' :
              '';
  }

  private formatDate(d: Date): string {
    return this.localeService.formatDateRelative(d);
  }

  public stepsByState(state: 'open' | 'scheduled' | 'overdue' | 'completed'): INextStep[] {
    return this.steps.filter(f =>
      (state == 'completed' && f.completed === true) ||
      (state == 'overdue' && f.overdue === true) ||
      (state == 'scheduled' && f.scheduled === true) ||
      (state == 'open' && (f.completed !== true && f.overdue !== true && f.scheduled !== true))
    );
  }


  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  public commandEmit(event: UiCommandEvent) {
    this.commmand.emit(event);
    this.formService.formCommandNext(event);
  }

  public onChildEvent(event: UiCommandEvent) {
    switch (event.commandId) {
      /*
      case uiCmdClear.id:
        if (this.detailCanClose === 'active') {
          event.cancel = this.splitterHide();
        }
        break;
      case uiCmdSplitterReduce.id:
        this.splitterReduce();
        event.cancel = true;
        break;
      case uiCmdSplitterExpand.id:
        this.splitterExpand();
        event.cancel = true;
        break;
    */
    }
  }
}
