import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppUser, UserSessionService } from 'src/platform/app-user/user-session-service';

@Component({
  selector: 'cer-app-login-menu',
  templateUrl: './cer-app-login-menu.component.html',
  styleUrls: ['./cer-app-login-menu.component.css']
})
export class CerAppLoginMenuComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
  public userName: string = '';
  public userNameShort: string = '';
  public defaultIdentityActive: boolean = true;

  constructor(private userSessionService: UserSessionService) { }

  ngOnInit() {
    this.isAuthenticated = this.userSessionService.userIsAuthenticated$;
    this.userSessionService.user$.subscribe(user => this.initUser(user));
  }

  private initUser(user: AppUser): void {
    if (user?.dto) {
      this.userName = user.dto.name;
      this.userNameShort = user.dto.shortName;
      this.defaultIdentityActive = user.vm.environment.defaultIdentityActive;

    }
    else {
      this.userName = '';
      this.userNameShort = '';
    }
  }
}
