<div class="flex-column flex-grow">
  <div class="flex-row flex-grow flex-shrink">
    <div class="flex-column flex-grow flex-shrink" style="padding: 10px">
      <div>
        <h2>Aktive roller for {{ vm?.user?.shortName }}:</h2>
      </div>
      <div class="flex-grow flex-shrink">
        <ejs-listbox #selectedListBox [dataSource]="selectedData" [toolbarSettings]="toolbarSettings"
          [allowDragAndDrop]="true" [fields]="fields" scope="#combined-list" height="460px">
        </ejs-listbox>
      </div>
    </div>
    <div class="flex-column flex-grow flex-shrink" style="padding: 10px">
      <div>
        <h2>Mulige roller:</h2>
      </div>
      <div class="flex-grow flex-shrink">
        <ejs-listbox [dataSource]="remainingData" [allowDragAndDrop]="true" [allowFiltering]="true" [fields]="fields"
        id="combined-list" scope="#combined-list" height="460px"></ejs-listbox>
      </div>
    </div>
  </div>
  <mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" (click)="saveAndclose()">
      <i class="material-icons">done</i>Gem og luk
    </button>
    <button mat-button mat-raised-button (click)="close(false)" title="Annuller">
      <i class="material-icons">clear</i>Annuller
    </button>
  </mat-dialog-actions>
</div>