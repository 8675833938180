import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { FieldFormatEnum } from '../../../cer/cer-data/cer-data.service';

export class BankAccountTransactionSourceGroupFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    this.viewMetadata = {
      name: 'bank-transaktion-kilde-gruppe', dataApiName: 'BankAccountTransactionSourceGroup', text: 'Grupper',
      primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: false, baseFields: ['id','history']
    };

    this.fieldMetadata =
      [
      { name: 'name', text: 'Gruppenavn' },
      { name: 'sumOrder', text: 'Sortering', format: FieldFormatEnum.Integer, orderBy: 'Ascending' }
      ];
  }

}
