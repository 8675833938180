import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class ProductStatusFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'udgivelse-status', dataApiName: 'ProductStatus', text: 'Status, udgivelser', textSingular: 'Status, udgivelse',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'sorting', 'history']
    };
  }
}
