import { ColumnModel, GridComponent, IEditCell } from "@syncfusion/ej2-angular-grids";

export class CerGridEditTextArea implements IEditCell {
    constructor(private grid: GridComponent, private column: ColumnModel) {
    }
  
    public create() {
      var textAreaId = '#' + (<any>this.grid).parent.element.id + this.column.field;
      return '<textarea id="' + textAreaId + '"></textarea>';
    }
    public read(args: any) {
      return args.val();
    }
    public write(args: any) {
    }
  }