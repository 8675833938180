<div *ngIf="fields" style="height: 100%; flex-grow: 1">
  <ejs-treeview
    height="100%"
    width="100%"
    id="treeview"
    #treeview
    [fields]="fields"
    [fullRowNavigable]="true"
    expandOn="Click"
    (nodeSelected)="onNodeSelected($event)"
  >
    <ng-template #nodeTemplate="" let-data="">
      <span>{{ data.nodeText }}</span>
      <span class="e-badge e-badge-primary" *ngIf="data.count">{{
        data.count
      }}</span>
    </ng-template>
  </ejs-treeview>
</div>
