import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import { FieldMetadata } from "src/cer/cer-data/cer-data.service";
import { UiCommand } from "src/cer/cer-grid/cer-grid-command.service";
import { CerAppRouteService } from "src/cer-app/cer-app-route/cer-app-route.service";
import { CerAppDialogService, DialogInput } from "src/cer-app/cer-app-dialog/cer-app-dialog.service";
import { AppStateService } from "src/app-core/app-state/app-state.service";
import { CerGridComponent } from "src/cer/cer-grid/cer-grid.component";

import { AfregningstabelDto, ProductClient, ProductSyncCommand } from "../api";
import { lastValueFrom, take, takeLast } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ProductService {

    public uiCmdSyncSelected: UiCommand =
        { text: 'Synkroniser markeret', tooltipText: 'Synkroniser markerede udgivelser fra oprindelig kontrakt database', id: 'syncSelected', target: ".e-content", needSelection: true, iconCss: 'e-menu-icon e-icons-org e-repeat' };
    public uiCmdSyncNum: UiCommand =
        { text: '', tooltipText: 'Synkroniser valgfri udgivelse fra oprindelig kontrakt database', id: 'syncNum', iconCss: 'e-menu-icon e-icons-org e-repeat', align: 'Right' };
    public uiCmdBudgetCreateStandard: UiCommand =
        { text: 'Std.', tooltipText: 'Opret alle standard budgetværdier', id: 'budgetCreateStandard', iconCss: 'e-menu-icon e-icons-org e-add-below', align: 'Center' };
    public uiCmdBudgetApprove: UiCommand =
        { text: 'Godkend', tooltipText: 'Godkend budgetværdier', id: 'budgetApprove', iconCss: 'e-menu-icon e-icons-org e-redo', align: 'Center' };

    constructor(private stateService: AppStateService, private routeService: CerAppRouteService,
        private client: ProductClient, private ui: CerAppDialogService, private snackBar: MatSnackBar) {
    }

    public budgetUpdateFromProductList(grid: CerGridComponent, productDtos: any[], create: boolean, approve: boolean) {
        if (productDtos?.length > 0) {
            var productLegacyIds: number[] = [];
            productDtos.forEach(product => {
                var legacyId: number = product?.legacyId;
                if (legacyId) {
                    productLegacyIds.push(legacyId);
                }
            });
            if (productLegacyIds.length > 0) {
                this.sync(grid, false, create, approve, productLegacyIds, null, null);
            }
            else {
                this.snackBar.open('Budget vedligehold', 'Budget vedligehold ikke muligt. Ingen Id tilgængelig.', { duration: 5000 });
            }
        }
        else {
            this.snackBar.open('Budget vedligehold', 'Ingen udgivelse er markeret', { duration: 5000 });
        }

    }

    public syncFromProductAllList(grid: CerGridComponent, productAllDtos: any[]) {
        if (productAllDtos?.length > 0) {
            var legacyIds: number[] = [];
            productAllDtos.forEach(dto => {
                var legacyId: number = dto.id;
                if (legacyId != null) {
                    legacyIds.push(legacyId);
                }
            });
            if (legacyIds.length > 0) {
                this.sync(grid, true, false, false, legacyIds, null, null);
            }
        }
    }

    public syncFromProductList(grid: CerGridComponent, productDtos: any[]) {
        if (productDtos?.length > 0) {
            var legacyIds: number[] = [];
            productDtos.forEach(dto => {
                var legacyId: number = dto.legacyId;
                if (legacyId != null) {
                    legacyIds.push(legacyId);
                }
            });
            if (legacyIds.length > 0) {
                this.sync(grid, true, false, false, legacyIds, null, null);
            }
            else {
                var num = productDtos[0]?.num;
                if (num?.length > 0) {
                    this.sync(grid, true, false, false, null, num, null);
                }
            }
        }
        else {
            this.snackBar.open('Synkroniser', 'Ingen udvigelse er markeret', { duration: 1000 });
        }
    }

    public syncNumFromPrompt(grid: CerGridComponent, numMeta: FieldMetadata, projectNumMeta: FieldMetadata) {
        var inputs: DialogInput[] = [
            { label: numMeta.text, id: numMeta.name, placeholder: 'Angiv ISBN', value: '', element: null },
            { label: projectNumMeta.text, id: projectNumMeta.name, placeholder: '.. eller angiv Projektnr.', value: '', element: null }
        ];
        this.ui.inputPrompt('Angiv udgivelse der skal synkroniseres fra oprindelig database', this.uiCmdSyncNum.tooltipText, inputs).then(
            result => {
                if (result.ok) {
                    var productNumValue: string = result.dialogInputs.find(i => i.id == numMeta.name).value;
                    var projectNumValue: string = result.dialogInputs.find(i => i.id == projectNumMeta.name).value;
                    if (productNumValue?.length > 0 || projectNumValue?.length > 0) {
                        this.sync(grid, true, false, false, null, productNumValue, projectNumValue);
                    }
                }
            }
        );
    }

    public sync(grid: CerGridComponent, sync: boolean, budgetCreate: boolean, budgetApprove: boolean, legacyIds: number[], productNum: string, projectNum: string) {
        var cmd: ProductSyncCommand = new ProductSyncCommand();
        var title: string = null;
        if (legacyIds?.length > 0) {
            if (legacyIds.length == 1) {
                title = ' udgivelse';
            }
            else {
                title = legacyIds.length + ' udgivelser';
            }
            cmd.legacyIds = legacyIds;
        }
        else if (productNum?.length > 0) {
            title = Array.isArray(productNum) ? productNum.length + ' udgivelser' : (productNum ? productNum : projectNum);
            cmd.productNums = [productNum];
        }
        else if (projectNum?.length > 0) {
            title = Array.isArray(productNum) ? productNum.length + ' udgivelser' : (productNum ? productNum : projectNum);
            cmd.projectNums = [projectNum];
        }
        if (title?.length > 0) {
            if (budgetCreate) {
                title = 'Opretter budget for ' + title;
                cmd.budgetCreate = true;
                cmd.budgetApprove = budgetApprove;
            }
            else if (budgetApprove) {
                title = 'Godkender budget for ' + title;
                cmd.budgetApprove = true;
            }
            if (sync) {
                title = 'Synkroniserer ' + title;
                cmd.legacySync = true;
            }
            if (cmd.legacySync || cmd.budgetCreate || cmd.budgetApprove) {
                this.syncCommand(grid, title, cmd);
            }
        }
    }

    public syncCommand(grid: CerGridComponent, title: string, cmd: ProductSyncCommand) {
        var snackBarRef = this.snackBar.open(title, 'Vent venligst...', { duration: undefined });

        this.routeService.isLoading$.next(true);
        this.client.sync(cmd).subscribe(
            result => {
                snackBarRef.dismiss();
                var res: string = result?.toString() ?? '0';
                if (cmd.budgetCreate) {
                    res += ' budgetpost' + (result == 1 ? '' : 'er') + ' oprettet';
                }
                else if (cmd.budgetApprove) {
                    res += ' budgetpost' + (result == 1 ? '' : 'er') + ' godkendt';
                }
                else {
                    res += ' udgivelse' + (result == 1 ? '' : 'r') + ' synkroniseret';
                }
                this.snackBar.open(res);
                this.routeService.isLoading$.next(false);
                grid.research();
            },
            error => {
                snackBarRef.dismiss();
                this.routeService.isLoading$.next(false);
                grid.research();
                this.stateService.handleApiError(error);
            }
        );
    }

    public async productRightsQuery(product: any): Promise<AfregningstabelDto[]> {
        return lastValueFrom<AfregningstabelDto[]>(this.client.productRights(product.num).pipe(take(1)));
    }

}