import * as signalR from "@microsoft/signalr";
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CerBaseRealtimeServerService {
  private hubConnection: signalR.HubConnection;
  public  isStarted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isConnected: boolean = false;

  constructor() {
  }

  public on(method: string, callback: (...args: any[]) => void) {
    if (this.isStarted$.getValue() == true) {
      this.hubConnection.on(method, callback);
    }
  }
  
  public start(loginToken: string) {
    if (!this.isConnected && loginToken) {
      this.isConnected = true;
      this.startConnection(loginToken);
    }
  }

  public stop() {
    if (this.isStarted$.getValue() == true) {
      this.isConnected = false
      this.isStarted$.next(false);
      this.hubConnection.stop();
    }
  }

  private startConnection = (loginToken: string) => {

    this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Warning)
      .withUrl('/realtimehub', { accessTokenFactory: () => loginToken })
      .withAutomaticReconnect()
      .build();
    this.hubConnection
      .start()
        .then(() => 
          this.isStarted$.next(true)
      )
        .catch(err => {
          console.log('Realtime service: Error while starting connection: ' + err);
          this.isStarted$.next(false);
          this.isConnected = false;
        });
  }
}
