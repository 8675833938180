<cer-form #form [panes]="panes">
  <ng-template #mainTemplate>
    <cer-grid
      #tableGrid
      id="tableGrid"
      [viewMetadata]="tableViewMetadata"
      [fieldMetadata]="tableFieldMetadata"
      [fieldGroupMetadata]="tableFieldGroupMetadata"
      [toolbarCommands]="tableToolbarCommands"
      dataRelationRole="parent"
    ></cer-grid>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid
      #lineGrid
      id="lineGrid"
      [viewMetadata]="lineViewMetadata"
      [fieldMetadata]="lineFieldMetadata"
      dataRelationRole="child"
      autoFocus="disabled"
    ></cer-grid>
  </ng-template>
</cer-form>
