import { DataManager } from '@syncfusion/ej2-data';
import { CerAppFormModelAdapter } from 'src/platform/models/cer-app-form-model-adapter';
import { createElement } from '@syncfusion/ej2-base';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CerAppFormGridDialogComponent } from 'src/cer-app/cer-app-form-models/cer-app-form-grid-dialog/cer-app-form-grid-dialog.component';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { CerDropDownList } from 'src/cer/cer-control/cer-drop-down-list/cer-drop-down-list';

export class DialogInput {
  id: string;
  label: string;
  placeholder: string;
  value?: string;
  valueNumber?: number;
  element: HTMLElement;
  type?: 'DropDownList' | 'CheckBox';
  dataSource?: object[];
  fields?: any;
  ej2?: any;
  maxLen?: number;
}

export class DialogOptions {
  public formModelAdapter?: CerAppFormModelAdapter;
  public data?: any;
}
export class DialogResult {
  public ok: boolean;
  public dialogInputs?: DialogInput[];
  public result?: any;
  public error?: any;
}

@Injectable({
  providedIn: 'root'
})
export class CerAppDialogService {


  constructor(private dialogService: CerDialogService, private matDialog: MatDialog) { }


  public inputPrompt(bodyHeader: string, title: string = "Angiv værdi", dialogInputs: DialogInput[]): Promise<DialogResult> {
    var bodyInputs = '';
    var content: string = bodyHeader + bodyInputs;

    var promise = new Promise<DialogResult>(resolve => {
      this.dialogService.confirmPrompt(content, title).then(
        ok => {
          if (dialogInputs) {
            for (let i of dialogInputs) {
              if (i.element) {
                if (i.type == 'CheckBox') {
                  i.valueNumber = (<any>i.element).checked;
                }
                else {
                  i.value = (<any>i.element).value;
                }
              }
            }
          }
          resolve({
            ok: ok,
            dialogInputs: dialogInputs
          });
        });
    });
    var txtDiv: HTMLDivElement = this.dialogService.getDialogContent();
    if (txtDiv && dialogInputs) {
      txtDiv.classList.add('flex-column');
      txtDiv.classList.add('dialog-text');
      for (var i of dialogInputs) {
        if (i.label) {
          var lbl: any = createElement('label');
          lbl.innerText = i.label;
          lbl.classList.add('dialog-field');
          var input: any = createElement('input')
          if (i.type == 'CheckBox') {
            input.type = 'checkbox';
            input.classList.add('e-boolcell');
            input.classList.add('e-control');
            input.classList.add('e-checkbox');
            input.value = i.value;
          }
          else {
            input.type = 'text';
            input.classList.add('e-input');
          }
          input.placeholder = i.placeholder;
          input.id = i.id;
          input.value = i.value;
          lbl.appendChild(input);
          if (i.type) {
            switch (i.type) {
              case 'DropDownList':
                var options = {};
                var DDL = new CerDropDownList(options);
                DDL.fields = i.fields ? i.fields : { text: 'name', value: 'id' };
                DDL.allowFiltering = true;
                DDL.showClearButton = true;
                DDL.dataSource = new DataManager(i.dataSource);
                DDL.appendTo(input);
                i.ej2 = DDL;
                DDL.value = i.valueNumber;
                break;
            }
          }
          txtDiv.append(lbl);
          i.element = input;
        }
      }
    }

    return promise;
  };



  public showDialogFormGridAdapter(formAdapterType: any, options: DialogOptions = null): Promise<DialogResult> {
    return this.showDialogFormAdapter(CerAppFormGridDialogComponent, formAdapterType, options);
  }

  public showDialogFormAdapter(formModelComponent: any, formAdapterType: any, options: DialogOptions = null): Promise<DialogResult> {
    if (!options) {
      options = new DialogOptions();
    }
    var data: any = options?.data ? options.data : {};
    if (formAdapterType) {
      data.formAdapterType = formAdapterType;
    }
    return this.showDialogComponent(formModelComponent, null, options);
  }

  public showDialogComponent(component: any, config: MatDialogConfig = null, options: DialogOptions = null): Promise<DialogResult> {
    return new Promise<DialogResult>(resolve => {
      if (!config) {
        config = { width: '90vw', height: '90vh', maxWidth: '90vw', disableClose: true, data: options }
      };
      var dlg = this.matDialog.open(component, config);
      dlg.afterClosed().subscribe(result => resolve(<DialogResult>result));
      dlg.backdropClick().subscribe(result => {
        resolve(<DialogResult>{ ok: false })
      });
      //dlg.keydownEvents().subscribe(result => {
      //  console.log(result);
      //  resolve(<DialogResult>{ ok: false })
      //});
      //})
      //      .catch(function(error) {
      //    throw error;
    });
  }

  public handleApiError(e: any) {
    if (this.handleApiStatusMessage(e)) {
      return;
    }

    // WebApi validation errors - returned with status 200 (because of SF DM limit) (204? future)
    if (e.error) {
      var error: any = null;
      if (Array.isArray(e.error)) {
        error = e.error[0];
      }
      else {
        error = e.error;
      };
      if (error.title) {
        var title = error.title;
        var msg = this.getObjectAsHtml(error.errors);
        this.dialogService.errorPrompt(msg, title ?? "Fejl");
      }
      else if (error.message) {
        this.dialogService.errorPrompt(error.message, "Fejl");
        return;
      }
    }

    if (e.error) {
      if (e.error.error && e.error.error instanceof XMLHttpRequest) {
        this.handleApiErrorMessage(e.error.error, true);
      }
      else if (e.error[0]?.error) {
        var e = e.error[0].error;
        this.handleApiErrorMessage(e, true);
      }
      else {
        if (e.error.length > 0) {
          var error = e.error[0];
          if (error.error) {
            this.handleApiErrorMessage(error.error, true);
          }
        }
      }
    }
  }

  private getObjectAsHtml(o: any): string {
    var msg: string = '';
    for (var prop in o) {
      msg += '<h4 class="list-heading">' + (prop ? prop : '') + '</h4><ul>';
      var arr = o[prop];
      for (var i = 0; i < arr.length; i++) {
        msg += '<li>' + arr[i] + '</li>';
      }
      msg += '</ul>';
    }
    return msg;
  }

  private handleApiErrorMessage(error: any, showError: boolean = true) {
    if (this.handleApiStatusMessage(error)) {
      return;
    }

    var msg: string = null;
    var title: string = null;

    if (error) {
      if (error.headers) {
        var wwwAuthenticate: string = error.headers['www-authenticate'];
        if (wwwAuthenticate?.startsWith('Bearer error="invalid_token"')) {
          console.error(wwwAuthenticate);
          title = 'Du er ikke logget ind.';
          msg = 'Log venligst ind igen.';
        }
      }

      if (!msg && error.error) {

        if (!msg) {
          if ((!error.headers ||
            error.headers['content-type'] == 'application/problem+json; charset=utf-8') && error.response) {
            var response = JSON.parse(error.response);
            if (response) {
              if (response.detail) {
                msg = response.detail;
              }
              if (response.title) {
                title = response.title;
              }
              if (response.errors) {
                msg = this.getObjectAsHtml(response.errors);
              }
            }
          }
        }

        if (!msg) {
          var e = error.errors;
          if (e?.title) {
            title = e.title;
            if (!msg) {
              msg = '';
            }
            var g = Object.keys(e.errors)[0];
            if (g) {
              msg += '<h4 class="list-heading">' + (g ? g : '') + '</h4><ul>';
              var txts = e.errors[g];
              for (var i = 0; i < txts.length; i++) {
                msg += '<li>' + txts[i] + '</li>';
              }
              msg += '</ul>';
            }
          }
        }

        if (!msg) {
          if ((!error.headers || error.headers['content-type'] == 'application/problem+json; charset=utf-8'
          ) && error.response) {
            var response = JSON.parse(error.response);
            if (response) {
              if (response.detail) {
                msg = response.detail;
              }
              if (response.title) {
                title = response.title;
              }
              if (response.errors) {
                if (!msg) {
                  msg = '';
                }
                for (var prop in response.errors) {
                  msg += '<h4 class="list-heading">' + (prop ? prop : '') + '</h4><ul>';
                  var arr = response.errors[prop];
                  for (var i = 0; i < arr.length; i++) {
                    msg += '<li>' + arr[i] + '</li>';
                  }
                  msg += '</ul>';
                }
              }
            }
          }
        }
      }

      if (!msg) {
        if (error?.status == 401) {
          title = "Der er ikke adgang til API.";
          msg = "Log venligst ind igen.";
        }
      }

      if (!msg) {
        msg = "API fejl" + (error.statusName ? " - " + error.statusName : "") + ".";
      }

      if (showError) {
        this.dialogService.errorPrompt(msg, title ?? "Serverfejl");
      }
    }
  }

  public handleApiStatusMessage(r: any): boolean {
    if ((r.status && r.status == 400) && r.response) {
      var response: any = r.response;
      if (typeof  response === 'string' || response instanceof String) {
        response = JSON.parse(<string> response);
      }
      if (response && response.title && response.errors) {
        var title = response.title;
        var msg = this.getObjectAsHtml(response.errors);
        this.dialogService.errorPrompt(msg, title ?? "Fejl");
        return true;
      }
    }
    return false;
  }

}
