import { FieldFormatEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from 'src/platform/models/cer-app-form-model-adapter';

export class ProjectTaskTypeFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    this.viewMetadata = {
      name: 'projekt-opgave-type', dataApiName: 'ProjectTaskType', text: 'Opgavetyper', textSingular: 'Opgavetype',
      primaryKey: 'num', baseFields: ['id','num','history']
    };

    this.fieldMetadata =
      [
        { name: 'num', text: 'Opgavetype' },
        { name: 'name', text: 'Opgavetypenavn' },
        { name: 'status', text: 'Opgavestatus', foreignKeyTableName: 'ProjectTaskStatus', foreignKeyField: 'num', foreignKeySortFields: ['id'] }, 
        { name: 'responsibleTeam', text: 'Opgaveteam', foreignKeyTableName: 'Team', foreignKeyField: 'num' }, 
        { name: 'responsibleUser', text: 'Opgaveansvarlig', foreignKeyTableName: 'User', foreignKeyField: 'num' }, 
        { name: 'product', text: 'Specfik udgivelse?', format: FieldFormatEnum.CheckBox }, 
        { name: 'productRoles', text: 'Kontakt tilknytning?', format: FieldFormatEnum.CheckBox }
      ];
  }

}
