// Angular
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// Grid
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { CerAppFileViewerTabComponent } from 'src/cer-app/cer-app-file-viewer-tab/cer-app-file-viewer-tab.component';

// Api
import { ChatDto, LinkedAttachmentDto, VoucherMessageViewDetailsVm } from 'src/app/api';

// App
import { VoucherDataService } from '../voucher-data.service';
import { VoucherMessageViewService } from './vouchermessage-view.service';
import { VoucherAttachmentService } from '../voucher-attachment.service';
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';
import { UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum } from 'src/cer/cer-grid/cer-grid-command.service';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';

@Component({
  selector: 'vouchermessage-list',
  templateUrl: './vouchermessage-list.component.html',
  styleUrls: ['../voucher.css'],
  providers: [VoucherMessageViewService, VoucherDataService, VoucherAttachmentService]
})

export class VoucherMessageListComponent implements OnInit, OnDestroy {

  // File Viewer Tab State
  protected attachments: LinkedAttachmentDto[];
  protected chats: ChatDto[];
  protected attachmentToken: string;
  protected attachmentRefRowId: number;
  protected attachmentSaveUrl: string;
  protected attachmentRemoveUrl: string;
  protected attachmentTabSelectedIndexDefault: number = 0;


  protected panes: CerFormPanesSettings = {
    main: { visible: 'show', size: '60%' },
    right: { visible: 'show', size: '40%' },
    orientationPrimary: 'horizontal'
  };

  constructor(
    private messageViewService: VoucherMessageViewService,
    private voucherDataService: VoucherDataService,
    protected attachmentService: VoucherAttachmentService) {
  }

  public ngOnInit(): void {
    this.voucherDataService.init();
    this.messageViewService.init();
    this.manage(this.voucherDataService.detailsVm$.subscribe(vm => this.onDetailVm(vm)));
  }

  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  public ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  // Form
  private form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.initFormAndFileViewerTab();
    }
  }

  // FileViewerTab
  private tabAttachments: CerAppFileViewerTabComponent;
  @ViewChild('tabAttachments', { static: false }) set tabContent(content: CerAppFileViewerTabComponent) {
    if (content && !this.tabAttachments) {
      this.tabAttachments = content;
      this.initFormAndFileViewerTab();
    }
  }

  private initFormAndFileViewerTab() {
    if (this.form && this.tabAttachments) {
      this.attachmentService.init(this.form, this.tabAttachments);
      this.messageViewService.initForm(this.form);
    }
  }

  // Grid
  private grid: CerGridComponent;
  @ViewChild('grid', { static: false }) set gridContent(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
      this.messageViewService.initGrid(this.grid);
      this.voucherDataService.initGrid(this.grid);
      this.manage(this.grid.commmand.subscribe(cmd => this.onGridCommand(cmd)));
    }
  }

  protected onGridCommand(cmd: UiCommandEvent) {
    if (cmd.actionType == UiActionTypeEnum.ChatClicked) {
      this.attachmentTabSelectedIndexDefault = this.attachments.length;
    }
  }

  // File viewer
  private onDetailVm(vm: VoucherMessageViewDetailsVm) {
    this.chats = vm?.chatDtoList ?? [];
    this.attachmentToken = vm?.voucherMessageViewDto?.messageToken;
    this.attachmentRefRowId = vm?.voucherMessageViewDto?.id;
    this.attachments = vm?.voucherMessageAttachmentDtoList ?? [];
    this.attachmentTabSelectedIndexDefault = this.attachmentService.getAttachmentSelectextIdxDefault(this.attachments);
    this.attachmentSaveUrl = vm?.attachmentsSaveUrl;
    this.attachmentRemoveUrl = vm?.attachmentsRemoveUrl;
  }

  protected onAttachmentTabSelectedIndexChanged(index: number) {
    this.attachmentTabSelectedIndexDefault = index;
    this.messageViewService.voucherDetailsDialogAttachmentIdxDefault = index;
  }

  protected onAttachmentCheckBoxApply(checkedFileNames: string[]) {
    var id: number = this.tabAttachments.refRowId;
    if (id) {
      var callback = () => {
        this.voucherDataService.detailsVmMap.delete(id); // force reload
        this.messageViewService.research();
      }
      this.attachmentService.messageSplitByAttachmentHere(id, checkedFileNames, callback);
    }
  }
}