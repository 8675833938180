import { Component } from '@angular/core';
import { FieldFormatEnum, FieldMetadata, FieldWidthEnum, ViewMetadata } from 'src/cer/cer-data/cer-data.service';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';

@Component({
  selector: 'app-form-recogizer',
  templateUrl: './app-form-recogizer.component.html',
  styleUrls: ['./app-form-recogizer.component.css']
})

export class AppFormRecogizerComponent {

  protected panes: CerFormPanesSettings = {
    main: { visible: 'show', size: '30%' },
    right: { visible: 'show', size: '70%' },
    orientationPrimary: 'horizontal'
  };

  // View
  public viewMetadata: ViewMetadata = {
    name: 'formulargenkendelser', dataApiName: 'FormRecognizer', text: 'Opsætninger', textSingular: 'Opsætning',
    primaryKey: 'id', titleFields: ['name'], dialogEdit: false, baseFields: ['id', 'history']
  };
  // Fields
  public fieldMetadata: FieldMetadata[] =
    [
      { name: 'name', text: 'Navn', tooltipText: 'Navn', required: true, widthType: FieldWidthEnum.M },
      { name: 'type', text: 'Genkendelsestype', tooltipText: 'Reference til bagvedlæggende genkendelse', widthType: FieldWidthEnum.M, foreignKeyTableName: 'FormRecognizerType' }
    ];

  // Detail view
  public detailViewMetadata: ViewMetadata = {
    name: 'formulargenkendelse-felter', dataApiName: 'FormRecognizerField', text: 'Felter', textSingular: 'Felt',
    primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'formRecognizer', titleFields: ['key'], baseFields: ['id', 'history']
  };

  // Detail  fields
  public detailFieldMetadata: FieldMetadata[] =
    [
      { name: 'type', text: 'Felt', tooltipText: 'Grundlæggende felt', widthType: FieldWidthEnum.S, foreignKeyTableName: 'FormRecognizerFieldType', orderBy: 'Ascending' },
      { name: 'key', text: 'Søgenøgle udtryk', tooltipText: '', widthType: FieldWidthEnum.M },
      { name: 'value', text: 'Værdi udtryk', tooltipText: '', widthType: FieldWidthEnum.M },
      { name: 'replaceFrom', text: 'Erstat fra', tooltipText: '', widthType: FieldWidthEnum.M },
      { name: 'replaceTo', text: 'Erstat med', tooltipText: '', widthType: FieldWidthEnum.M },
      { name: 'format', text: 'Datoformat', tooltipText: 'Datoformat (se https://docs.microsoft.com/en-us/dotnet/standard/base-types/formatting-types)', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true },
      { name: 'culture', text: 'Landeformat', tooltipText: 'F.eks. for US engelsk brug "en-us" eller dansk "da"', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true },
      { name: 'valueNext', text: 'Næste værdi', tooltipText: '', widthType: FieldWidthEnum.XS, format: FieldFormatEnum.CheckBox },
      { name: 'valueConstant', text: 'Konstant værdi', tooltipText: 'Nøgle bruges ikke', widthType: FieldWidthEnum.XS, format: FieldFormatEnum.CheckBox },
      { name: 'keyValuePair', text: 'Nøgle+værdi sæt', tooltipText: '', widthType: FieldWidthEnum.XS, format: FieldFormatEnum.CheckBox },
    ];

  constructor() { }
}
