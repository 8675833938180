import { Column, ICellFormatter } from "@syncfusion/ej2-angular-grids";
import { RightsApprovalStatusEnum, VoucherValidationStatusEnum } from "src/app/api";

export class VoucherFormatterValidationStatus implements ICellFormatter {
  getValue(column: Column, data: Object): Object {
    var rowData: any = data;
    if (!rowData) {
      return '';
    }
    var status: VoucherValidationStatusEnum = rowData.validationStatus;
    var statusList: any[] = (<any>column.dataSource).dataSource.json;
    var statusText: string = statusList.find(s => s.id == status).name;

    var cls: string = ''
    switch (status) {
      case VoucherValidationStatusEnum.None:
        cls = '';//'e-primary';
        return '';
        break;
      case VoucherValidationStatusEnum.Pending:
        cls = 'e-warning';
        break;
      case VoucherValidationStatusEnum.Extracted:
        cls = 'e-warning';
        break
      case VoucherValidationStatusEnum.Failed:
        cls = 'e-danger';
        break
      case VoucherValidationStatusEnum.Validated:
        cls = "e-success";
        break
      case VoucherValidationStatusEnum.Accepted:
        cls = "e-success";
        break
    }

    

    return '<div class="e-chip-list e-chip-list-small"><div class="e-chip e-chip-small ' + cls + '"><div class="e-chip-text">' + statusText + '</div></div></div>';
  }
}
