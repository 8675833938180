<div class="flex-column full-height">
  <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Om {{appName}}</h1>
  <mat-dialog-content [formGroup]="form" class="flex-column flex-grow overflow-auto">
    <mat-form-field *ngIf="hasLicence">
      <textarea matInput placeholder="Licens" cols="40" rows="3" style="width: 100%" formControlName="license"
        [readonly]="true">
      </textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Copyright</mat-label>
      <input matInput floatLabel="always" style="width: 100%" formControlName="copyright" [readonly]="true" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Kontakt</mat-label>
      <input matInput floatLabel="always" style="width: 100%" formControlName="contact" [readonly]="true" />
    </mat-form-field>
    <mat-form-field *ngIf="hasLicence">
      <mat-label>App version</mat-label>
      <input matInput floatLabel="always" style="width: 100%" formControlName="appVersion" [readonly]="true" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Klient version</mat-label>
      <input matInput floatLabel="always" style="width: 100%" formControlName="clientVersion"
        [readonly]="true" />
    </mat-form-field>
    <button mat-button accent *ngIf="hasSystemUpdate" (click)="clientUpdate()">
      <mat-icon>system_update</mat-icon>Opdater klient
    </button>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>
      Luk
    </button>
  </mat-dialog-actions>
</div>
