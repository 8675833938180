import { Column, ICellFormatter } from "@syncfusion/ej2-angular-grids";
import { RightsCloseStatusEnum } from "src/app/api";

export class RightsFormatterCloseStatus implements ICellFormatter {
  getValue(column: Column, data: Object): Object {
    var rowData: any = data;
    if (!rowData) {
      return '';
    }
    var status : RightsCloseStatusEnum = rowData.closeStatus;
    var statusList : any[] = (<any> column.dataSource).dataSource.json;
    var statusText : string = statusList.find(s => s.id == status).name;
    
    var cls: string = ''
    switch (status) {
      case RightsCloseStatusEnum.None:
        cls = ''; //'e-primary';
        break;
      case RightsCloseStatusEnum.Completed:
        cls = 'e-success';
        break;
      case RightsCloseStatusEnum.ModifiedOngoing:
        cls = 'e-danger';
        break;
      case RightsCloseStatusEnum.ModifiedForApproval:
        cls = 'e-info'; 
        break
      case RightsCloseStatusEnum.Cancelled:
        cls = "e-warning"; 
        break
    }
    return '<div class="e-chip-list"><div class="e-chip ' + cls + '"><div class="e-chip-text">' + statusText + '</div></div></div>';
    }
}
