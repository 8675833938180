import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AppUsersLogic } from './app-users.logic';
import { UserDto } from 'src/app/api';
import { UserSessionService } from './user-session-service';

@Injectable({
  providedIn: 'root'
})
export class AppUsersService implements OnDestroy {

  private subscriptionManager$: Subscription = new Subscription();
  
  public users$ : BehaviorSubject<UserDto[]> = new BehaviorSubject<UserDto[]>(null);

  constructor(userSessionService: UserSessionService) { 
    this.subscriptionManager$.add(
      userSessionService.user$.subscribe(user => this.users$.next(user?.vm?.users))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  public usersLogic(): AppUsersLogic {
    var usersLogic = new AppUsersLogic();
    usersLogic.users = this.users$.getValue();
    return usersLogic;
  }

  public userById(id: number): UserDto {
    return this.usersLogic().userById(id);
  }

  private userByShortName(shortName: string): UserDto {
    return this.usersLogic().userByShortName(shortName);
  }

  private userByName(name: string): UserDto {
    return this.usersLogic().userByName(name);
  }

  public userNameById(id: number): string {
    return this.usersLogic().userNameById(id);
  }

  private ShortNameById(id: number): string {
    return this.usersLogic().ShortNameById(id);
  }

  private EmailById(id: number): string {
    return this.usersLogic().EmailById(id);
  }

  public avatarById(id: number): string {
    return this.usersLogic().avatarById(id);
  }

  private photoUrlById(id: number): string {
    return this.usersLogic().photoUrlById(id);
  }
}