// Angular
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { HotkeyModule, IHotkeyOptions } from 'angular2-hotkeys';

// Core app

import { CerAppLayoutComponent } from 'src/cer-app/cer-app-layout/cer-app-layout.component';

import { AppCoreModule } from 'src/app-core/app-core.module';

import { AppHomeComponent } from 'src/app-core/app-home/app-home.component';
import { AppUserComponent } from '../platform/app-user/app-user.component';
import { AppComponent } from 'src/app-core/app/app.component';
import { AppDataTableFormAdapter } from 'src/app-core/table/form-adapter/app-data-table.form.adapter';
import { AppExchangeMsgQueueComponent } from '../app-core/app-exchange/app-exchange-msg-queue/app-exchange-msg-queue.component';
import { AppExchangeMsgViewComponent } from '../app-core/app-exchange/app-exchange-msg-view/app-exchange-msg-view.component';
import { AppFormRecogizerComponent } from 'src/app-core/app-form-recognizer/app-form-recogizer/app-form-recogizer.component';
import { AppFormRecogizerDocumentComponent } from 'src/app-core/app-form-recognizer/app-form-recognizer-document/app-form-recogizer-document.component';
import { AppMailChannelComponent } from 'src/app-core/app-mail/app-mail-channel/app-mail-channel.component';
import { AppMailTemplateComponent } from 'src/app-core/app-mail/app-mail-template/app-mail-template.component';

// Cer App references
import { CerAppFormGridComponent } from '../cer-app/cer-app-form-models/cer-app-form-grid/cer-app-form-grid.component';
import { CerAppFormGridDetailComponent } from '../cer-app/cer-app-form-models/cer-app-form-grid-detail/cer-app-form-grid-detail.component';
import { CerAppJobSchedulerComponent } from '../cer-app/cer-app-job-scheduler/cer-app-job-scheduler.component';
import { CerAppMetaRouteFormAdapter } from 'src/cer-app/cer-app-meta/cer-app-meta-route.form.adapter';
import { CerAppMetaUserRouteFormAdapter } from 'src/cer-app/cer-app-meta/cer-app-meta-user-route.form.adapter';

// Rights
import { RightsPeriodComponent } from './rights/rights-period/rights-period.component';
import { RightsApprovalComponent } from './rights/rights-approval/rights-approval.component';

// Royalty
import { RoyaltyApproveComponent } from './royalty/royalty-approve.component';
import { RoyaltyApproveDetailComponent } from './royalty/royalty-approve-detail.component';
import { RoyaltyForlagComponent } from './royalty/royalty-forlag.component';

import { RoyaltyPeriodFormAdapter } from './royalty/royalty-period/royalty-period.form.adapter';

// Webshop
import { SalesOrderComponent } from './sales/sales-order.component';
import { SalesPaymentTypeFormAdapter } from './sales/form-adapter/sales-payment-type.form.adapter';
import { SalesChannelFormAdapter } from './sales/form-adapter/sales-channel.form.adapter';
import { SalesOrderTypeFormAdapter } from './sales/form-adapter/sales-order-type.form.adapter';
import { SalesOrderLineTypeFormAdapter } from './sales/form-adapter/sales-order-line-type.form.adapter';

// Voucher
import { VoucherMessageListComponent } from './voucher/vouchermessage-list/vouchermessage-list.component';
import { VoucherListComponent } from './voucher/voucher-list/voucher-list.component';
import { VoucherLookupComponent } from './voucher/voucher-lookup/voucher-lookup.component';
import { VoucherDetailComponent } from './voucher/voucher-detail/voucher-detail.component';
import { VoucherCreateMobileComponent } from './voucher/voucher-create-mobile/voucher-create-mobile.component';

import { VoucherMessageMailVendorHistoryFormAdapter } from './voucher/voucher-message-mail-vendor-history.form.adapter';
import { VoucherMessageChannelNextVoucherFormAdapter } from './voucher/voucher-message-channel-next-voucher.form.adapter';
import { VoucherMessageChannelFormAdapter } from './voucher/voucher-message-channel.form.adapter';
import { VoucherMessageChannelUserFormAdapter } from './voucher/voucher-message-channel-user.form.adapter';
import { VoucherRequestFormAdapter } from './voucher/voucher-request.form.adapter';
import { VoucherCategoryFormAdapter } from './voucher/voucher-category.form.adapter';
import { VoucherValidationStatusFormAdapter } from './voucher/voucher-validation-status.form.adapter';

// Expense voucher
import { BankAccountSettlementComponent } from './bank/settlement/bank-account-settlement.component';
import { BankAccountFormAdapter } from './bank/bank-account.form.adapter';
import { BankAccountSourceFormAdapter } from './bank/bank-account-source.form.adapter';
import { BankAccountTransactionSourceGroupFormAdapter } from './bank/transactions/bank-account-transaction-source-group.form.adapter';
import { BankAccountTransactionSourceFormAdapter } from './bank/transactions/bank-account-transaction-source.form.adapter';
import { BankAccountTransactionBatchFormAdapter } from './bank/transactions/bank-account-transaction-batch.form.adapter';
import { BankAccountTransactionSettlementFormAdapter } from './bank/settlement/bank-account-settlement.form.adapter';
import { BankCreditCardFormAdapter } from './bank/bank-credit-card.form.adapter';

// Enums/Parameters
import { VoucherMessageStatusAllEnum, VoucherApprovalStatusAllEnum, RoleTypeEnum, VoucherMessageChannelTypeEnum } from "./api";

//import { ProjectNodeComponent } from './project/project-node/project-node.component';
import { ProjectTaskDocComponent } from './project/project-task-doc/project-task-doc.component';

import { ProjectTaskStatusFormAdapter } from './project/project-task-status.form.adapter';
import { ProjectTaskTypeFormAdapter } from './project/project-task-type.form.adapter';
import { ProjectLevelFormAdapter } from './project/project-level';
import { ProjectTaskComponent } from './project/project-task/project-task.component';

// Data processes
import { AppDataProcessFormAdapter } from 'src/app-core/app-data-process/app-data-process.form.adapter';
import { AppDataProcessStageFormAdapter } from 'src/app-core/app-data-process/app-data-process-stage.form.adapter';
import { AppNumberSequenceFormAdapter } from 'src/app-core/app-number-sequence/app-number-sequence.form.adapter';

// Party
import { PartyFormAdapter } from './contract/form-adapter/party.form.adapter';

// Contract
import { ContractComponent } from './contract/contract.component';
import { ContractLegacyComponent } from './contract/contract-legacy/contract-legacy.component';
import { ForfatterComponent } from './contract/forfatter/forfatter.component';

import { ContractTypeFormAdapter } from './contract/form-adapter/contract-type.form.adapter';

// Product
import { ProductComponent } from './product/product.component';
import { ProductLegacyComponent } from './product/product-legacy/product-legacy.component';

import { ProductCategoryFormAdapter } from './product/form-adapter/product-category.form.adapter';
import { ProductGroupFormAdapter } from './product/form-adapter/product-group.form.adapter';
import { ProductPoolFormAdapter } from './product/form-adapter/product-pool.form.adapter';
import { ProductStatusFormAdapter } from './product/form-adapter/product-status.form.adapter';
import { ProductThemeFormAdapter } from './product/form-adapter/product-theme.form.adapter';
import { ProductTypeFormAdapter } from './product/form-adapter/product-type.form.adapter';
import { ProductBudgetCategoryFormAdapter } from './product/form-adapter/product-budget-category.form.adapter';
import { ProductBOMFormAdapter } from './product/form-adapter/product-bom.form.adapter';
import { ProductFormatFormAdapter } from './product/form-adapter/product-format.form.adapter';
import { ProductSeriesFormAdapter } from './product/form-adapter/product-series.form.adapter';

// Master data
import { RefDataNemHandelsRegisterFormAdapter } from './masterdata/form-adapter/ref-data-nem-handels-register.form.adapter';
import { CerAmountPipe } from 'src/cer-platform/cer-intl/cer-intl.service';
import { LogisticsWarehouseFormAdapter } from './logistics/logistics-warehouse';
import { LogisticsOrderProfileFormAdapter } from './logistics/logistics-order-profile';
import { TeamFormAdapter } from './project/team.form.adapter';
import { PartyRoleFormAdapter } from './contract/form-adapter/party-role.form.adapter';


// Hotkeys
var hotkeyOptions: IHotkeyOptions = {
  disableCheatSheet: false,
  cheatSheetHotkey: '?',
  cheatSheetCloseEsc: true,
  cheatSheetCloseEscDescription: 'Luk denne liste',
  cheatSheetDescription: 'Liste genvejstaster'
};

// Routes
var appRoutes: Routes = [
  {
    path: '', component: CerAppLayoutComponent, canActivate: [AuthorizeGuard],
    children: [
      { path: '', component: AppHomeComponent, canActivate: [AuthorizeGuard], pathMatch: 'full', data: { title: 'Forside' } },
      { path: 'product/list', component: ProductComponent, canActivate: [AuthorizeGuard], data: { title: 'Udgivelser', roles: [RoleTypeEnum.BaseData] } },
      { path: 'product/list-all', component: ProductLegacyComponent, canActivate: [AuthorizeGuard], data: { title: 'Udgivelser (historisk)', roles: [RoleTypeEnum.BaseData, RoleTypeEnum.BaseDataBasic] } },
      { path: 'contract/list', component: ContractComponent, canActivate: [AuthorizeGuard], data: { title: 'Kontrakter', roles: [RoleTypeEnum.BaseData] } },
      { path: 'contract/list-all', component: ContractLegacyComponent, canActivate: [AuthorizeGuard], data: { title: 'Kontrakter (historisk)', roles: [RoleTypeEnum.BaseData] } },
      { path: 'contract/party-list', component: ForfatterComponent, canActivate: [AuthorizeGuard], data: { title: 'Forfattere', roles: [RoleTypeEnum.BaseData] } },
      { path: 'product/bom', component: CerAppFormGridDetailComponent, canActivate: [AuthorizeGuard], data: { title: 'Bogpakker', formAdapterType: ProductBOMFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'product/series', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Udgivelsesserier', formAdapterType: ProductSeriesFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'product/format', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Udgivelsesformater', formAdapterType: ProductFormatFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'product/category', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Udgivelseskategorier', formAdapterType: ProductCategoryFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'product/group', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Udgivelsesgrupper', formAdapterType: ProductGroupFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'product/pool', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Printpuljer', formAdapterType: ProductPoolFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'product/status', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Udgivelsesstatus', formAdapterType: ProductStatusFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'product/theme', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Udgivelsestemaer', formAdapterType: ProductThemeFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'product/type', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Udgivelsestyper', formAdapterType: ProductTypeFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'product/budget-category', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Udgivelse budgetarter', formAdapterType: ProductBudgetCategoryFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'party/list', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Kontakter', formAdapterType: PartyFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'party/role', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Kontakt roller', formAdapterType: PartyRoleFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'contract/type', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Kontrakttyper', formAdapterType: ContractTypeFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'sales/order/list', component: SalesOrderComponent, canActivate: [AuthorizeGuard], data: { title: 'Salgsordrer', roles: [RoleTypeEnum.Webshop] } },
      { path: 'sales/invoice/accounted', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Faktura kladder', channelType: VoucherMessageChannelTypeEnum.SalesVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Accounted, roles: [RoleTypeEnum.Webshop] } },
      { path: 'sales/invoice/posted', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Bogførte fakturaer', channelType: VoucherMessageChannelTypeEnum.SalesVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Posted, roles: [RoleTypeEnum.Webshop] } },
      { path: 'sales/payment/status', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Salg betalingstyper', formAdapterType: SalesPaymentTypeFormAdapter, roles: [RoleTypeEnum.Webshop] } },
      { path: 'sales/order/status', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Salg ordretyper', formAdapterType: SalesOrderTypeFormAdapter, roles: [RoleTypeEnum.Webshop] } },
      { path: 'sales/order/line/type', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Salg ordrelinjetyper', formAdapterType: SalesOrderLineTypeFormAdapter, roles: [RoleTypeEnum.Webshop] } },
      { path: 'sales/channel', component: CerAppFormGridDetailComponent, canActivate: [AuthorizeGuard], data: { title: 'Salgskanaler', formAdapterType: SalesChannelFormAdapter, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'logistics/order/profile', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Ordre profiler', formAdapterType: LogisticsOrderProfileFormAdapter, roles: [RoleTypeEnum.Webshop] } },
      { path: 'logistics/warehouse', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Lagersteder', formAdapterType: LogisticsWarehouseFormAdapter, roles: [RoleTypeEnum.Webshop] } },
      { path: 'voucher/my/create', component: VoucherCreateMobileComponent, canActivate: [AuthorizeGuard], data: { title: 'Indsend bilag', roles: [RoleTypeEnum.VoucherExpenseCreate] } },
      { path: 'voucher/my/created', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Mine oprettelser', approvalStatus: VoucherApprovalStatusAllEnum.Created, myApprovals: true, roles: [RoleTypeEnum.VoucherExpenseCreate] } },
      { path: 'voucher/my/pending', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Mine "Til godkendelse"', approvalStatus: VoucherApprovalStatusAllEnum.Pending, myApprovals: true, roles: [RoleTypeEnum.VoucherApproval] } },
      { path: 'voucher/my/approved', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Mine godkendte', approvalStatus: VoucherApprovalStatusAllEnum.Approved, myApprovals: true, roles: [RoleTypeEnum.VoucherApproval] } },
      { path: 'voucher/my/onhold', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Mine afventende godkendelser', approvalStatus: VoucherApprovalStatusAllEnum.OnHold, myApprovals: true, roles: [RoleTypeEnum.VoucherApproval] } },
      { path: 'voucher/my/all', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Alle mine godkendelser', approvalStatus: VoucherApprovalStatusAllEnum.All, myApprovals: true, roles: [RoleTypeEnum.VoucherApproval] } },
      { path: 'voucher/chat/:msgid', component: VoucherLookupComponent, canActivate: [AuthorizeGuard], data: { title: 'Vis godkendelse', showChat: true, roles: [RoleTypeEnum.VoucherApproval] } },
      { path: 'voucher/lookup/:vouchernum', component: VoucherLookupComponent, canActivate: [AuthorizeGuard], data: { title: 'Vis bilag', roles: [RoleTypeEnum.VoucherApproval] } },
      { path: 'voucher/vendor/message/inbox', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Indbakke beskeder til fordeling', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, messageStatus: VoucherMessageStatusAllEnum.Inbox, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/message/voucher', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Beskeder sendt til godkendelse', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, messageStatus: VoucherMessageStatusAllEnum.Voucher, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/message/onhold', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Beskeder der afventer', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, messageStatus: VoucherMessageStatusAllEnum.OnHold, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/message/forwarded', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Beskeder der er videresendt', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, messageStatus: VoucherMessageStatusAllEnum.Forwarded, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/message/rejected', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Beskeder der er annulleret', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, messageStatus: VoucherMessageStatusAllEnum.Rejected, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/message/statement', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Beskeder med kontoudtog', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, messageStatus: VoucherMessageStatusAllEnum.AccountStatement, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/message/all', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Alle beskeder', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, messageStatus: VoucherMessageStatusAllEnum.All, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/voucher/created', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Oprettede bilag', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Created, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/voucher/pending', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Til godkendelse', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Pending, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/voucher/approved', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Godkendte', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Approved, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/voucher/onhold', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Afventende godkendelser', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, approvalStatus: VoucherApprovalStatusAllEnum.OnHold, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/voucher/accounted', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Afsluttede godkendelser', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Accounted, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/voucher/posted', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Bogførte godkendelser', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Posted, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/vendor/voucher/all', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Alle godkendelser', channelType: VoucherMessageChannelTypeEnum.VendorVoucher, approvalStatus: VoucherApprovalStatusAllEnum.All, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/expense/message/inbox', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Indbakke beskeder til fordeling', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, messageStatus: VoucherMessageStatusAllEnum.Inbox, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/message/voucher', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Beskeder bilag', VoucherMessageChannelTypeEnum: VoucherMessageChannelTypeEnum.ExpenseVoucher, messageStatus: VoucherMessageStatusAllEnum.Voucher, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/message/onhold', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Beskeder der afventer', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, messageStatus: VoucherMessageStatusAllEnum.OnHold, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/message/forwarded', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Beskeder der er videresendt', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, messageStatus: VoucherMessageStatusAllEnum.Forwarded, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/message/rejected', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Beskeder der er annulleret', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, messageStatus: VoucherMessageStatusAllEnum.Rejected, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/message/statement', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Beskeder med kontoudtog', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, messageStatus: VoucherMessageStatusAllEnum.AccountStatement, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/message/all', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Alle beskeder', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, messageStatus: VoucherMessageStatusAllEnum.All, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/voucher/created', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Oprettede bilag', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Created, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/voucher/pending', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Bilag til godkendelse', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Pending, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/voucher/approved', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Godkendte bilag', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Approved, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/voucher/onhold', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Afventende bilag', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, approvalStatus: VoucherApprovalStatusAllEnum.OnHold, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/voucher/accounted', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Afsluttede bilag', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Accounted, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/voucher/posted', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Bogførte bilag', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Posted, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/voucher/all', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Alle bilag', channelType: VoucherMessageChannelTypeEnum.ExpenseVoucher, approvalStatus: VoucherApprovalStatusAllEnum.All, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/expense/voucher/request', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Bilagsanmodninger', formAdapterType: VoucherRequestFormAdapter, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/manual/message/all', component: VoucherMessageListComponent, canActivate: [AuthorizeGuard], data: { title: 'Alle beskeder', channelType: VoucherMessageChannelTypeEnum.ManualVoucher, messageStatus: VoucherMessageStatusAllEnum.All, roles: [RoleTypeEnum.VoucherManualAdmin] } },
      { path: 'voucher/manual/voucher/posted', component: VoucherListComponent, canActivate: [AuthorizeGuard], data: { title: 'Manuelt bogførte bilag', channelType: VoucherMessageChannelTypeEnum.ManualVoucher, approvalStatus: VoucherApprovalStatusAllEnum.Posted, roles: [RoleTypeEnum.VoucherManualAdmin] } },
      { path: 'voucher/category', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Bilagskategorier', formAdapterType: VoucherCategoryFormAdapter, roles: [RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/validation-status', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Valideringsstatus', formAdapterType: VoucherValidationStatusFormAdapter, roles: [RoleTypeEnum.VoucherVendorAdmin, RoleTypeEnum.VoucherExpenseAdmin] } },
      { path: 'voucher/channel/user', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Kanal superbrugere', formAdapterType: VoucherMessageChannelUserFormAdapter, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/channel/next-voucher', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Kanal bilagsnumre', formAdapterType: VoucherMessageChannelNextVoucherFormAdapter, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'voucher/posting-history', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Bilag e-mail afsendere', formAdapterType: VoucherMessageMailVendorHistoryFormAdapter, roles: [RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'royalty/contract-list', component: RoyaltyApproveComponent, canActivate: [AuthorizeGuard], data: { title: 'Royaltyberegning, kontrakter', view: 'standard', roles: [RoleTypeEnum.RoyaltyApprover] } },
      { path: 'royalty/contract-editor-list', component: RoyaltyApproveComponent, canActivate: [AuthorizeGuard], data: { title: 'Royaltyberegning, kontrakter pr. redaktør',  view: 'editor', roles: [RoleTypeEnum.RoyaltyApprover] } },
      { path: 'royalty/contract-publisher-list', component: RoyaltyApproveComponent, canActivate: [AuthorizeGuard], data: { title: 'Royaltyberegning, kontrakter pr. forlag', view: 'publisher', roles: [RoleTypeEnum.RoyaltyApprover] } },
      { path: 'royalty/publisher-list', component: RoyaltyForlagComponent, canActivate: [AuthorizeGuard], data: { title: 'Royaltyberegning, afregning forlag', roles: [RoleTypeEnum.RoyaltyAdmin] } },
      { path: 'royalty/period', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Perioder Royalty', formAdapterType: RoyaltyPeriodFormAdapter,  roles: [RoleTypeEnum.RoyaltyAdmin] } },
      { path: 'rights/approval-my', component: RightsApprovalComponent, canActivate: [AuthorizeGuard], data: { title: 'Mine forfatterafregninger', myApprovals: true, roles: [RoleTypeEnum.RightsBasic] } },
      { path: 'rights/approval', component: RightsApprovalComponent, canActivate: [AuthorizeGuard], data: { title: 'Alle forfatterafregninger', myApprovals: false, roles: [RoleTypeEnum.RightsBasic, RoleTypeEnum.RightsAdmin] } },
      { path: 'rights/period', component: RightsPeriodComponent, canActivate: [AuthorizeGuard], data: { title: 'Perioder Forfatterafregning', roles: [RoleTypeEnum.RightsAdmin] } },
      { path: 'exchange/queue', component: AppExchangeMsgQueueComponent, canActivate: [AuthorizeGuard], data: { title: 'Dataudveksling forslag', roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'exchange/queue-party', component: ForfatterComponent, canActivate: [AuthorizeGuard], data: { title: 'Dataudveksling forslag forfatter', fromExchange: true, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'exchange/waiting', component: AppExchangeMsgViewComponent, canActivate: [AuthorizeGuard], data: { title: 'Dataudveksling venter', waiting: true, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'exchange/failed', component: AppExchangeMsgViewComponent, canActivate: [AuthorizeGuard], data: { title: 'Dataudveksling fejl', failed: true, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'exchange/all', component: AppExchangeMsgViewComponent, canActivate: [AuthorizeGuard], data: { title: 'Dataudveksling alle status', roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'form-recognizer/recognizer', component: AppFormRecogizerComponent, canActivate: [AuthorizeGuard], data: { title: 'Formular opsætning', roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'form-recognizer/document', component: AppFormRecogizerDocumentComponent, canActivate: [AuthorizeGuard], data: { title: 'Dokument historik', roles: [RoleTypeEnum.TennantAdmin, RoleTypeEnum.VoucherVendorAdmin] } },
      { path: 'mail/message-channel-in', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Mail indgående kanaler', formAdapterType: VoucherMessageChannelFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'mail/message-channel-out', component: AppMailChannelComponent, canActivate: [AuthorizeGuard], data: { title: 'Mail udgående kanaler', roles: [RoleTypeEnum.TennantAdmin, RoleTypeEnum.TennantAdmin] } },
      { path: 'mail/template', component: AppMailTemplateComponent, canActivate: [AuthorizeGuard], data: { title: 'Mail skabeloner', roles: [RoleTypeEnum.TennantAdmin, RoleTypeEnum.TennantAdmin] } },
      { path: 'user/list', component: AppUserComponent, canActivate: [AuthorizeGuard], data: { title: 'Brugere', roles: [RoleTypeEnum.GlobalAdmin] } },
      { path: 'bank/account', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Bankkonti', formAdapterType: BankAccountFormAdapter, roles: [RoleTypeEnum.VoucherExpenseBank] } },
      { path: 'bank/credit-card', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Kreditkort', formAdapterType: BankCreditCardFormAdapter, roles: [RoleTypeEnum.VoucherExpenseBank] } },
      { path: 'bank/account/source', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Kilder til bankkonto', formAdapterType: BankAccountSourceFormAdapter, roles: [RoleTypeEnum.VoucherExpenseBank] } },
      { path: 'bank/transaction/batch', component: CerAppFormGridDetailComponent, canActivate: [AuthorizeGuard], data: { title: 'Indlæsninger', formAdapterType: BankAccountTransactionBatchFormAdapter, roles: [RoleTypeEnum.VoucherExpenseBank] } },
      { path: 'bank/transaction/source', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Kilder posteringer', formAdapterType: BankAccountTransactionSourceFormAdapter, roles: [RoleTypeEnum.VoucherExpenseBank] } },
      { path: 'bank/settlement', component: BankAccountSettlementComponent, canActivate: [AuthorizeGuard], data: { title: 'Bankafstemning', roles: [RoleTypeEnum.VoucherExpenseBank] } },
      { path: 'bank/settlement/list', component: CerAppFormGridDetailComponent, canActivate: [AuthorizeGuard], data: { title: 'Bankafstemning historik', formAdapterType: BankAccountTransactionSettlementFormAdapter, roles: [RoleTypeEnum.VoucherExpenseBank] } },
      { path: 'bank/settlement/group', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Bankafstemning grupper', formAdapterType: BankAccountTransactionSourceGroupFormAdapter, roles: [RoleTypeEnum.VoucherExpenseBank] } },
      { path: 'project/node', component: ProjectTaskComponent, canActivate: [AuthorizeGuard], data: { title: 'Projektstruktur', roles: [RoleTypeEnum.ProjectStructure], type: 'project' } },
      { path: 'project/node/my', component: ProjectTaskComponent, canActivate: [AuthorizeGuard], data: { title: 'Mine projekter', roles: [RoleTypeEnum.ProjectTask], type: 'project', params: ['$filterMy'] } },
      { path: 'project/task', component: ProjectTaskComponent, canActivate: [AuthorizeGuard], data: { title: 'Opgaver', roles: [RoleTypeEnum.ProjectStructure], type: 'task' } },
      { path: 'project/task/my', component: ProjectTaskComponent, canActivate: [AuthorizeGuard], data: { title: 'Mine opgaver', roles: [RoleTypeEnum.ProjectBasic], type: 'task', params: ['$filterMy'] } },
      { path: 'project/task/team', component: ProjectTaskComponent, canActivate: [AuthorizeGuard], data: { title: 'Team opgaver', roles: [RoleTypeEnum.ProjectBasic], type: 'task', params: ['$filterMyTeam'] } },
      { path: 'project/task/my/open', component: ProjectTaskComponent, canActivate: [AuthorizeGuard], data: { title: 'Mine åbne opgaver', roles: [RoleTypeEnum.ProjectBasic], type: 'task', params: ['$filterMy','$filterStatusOpen'] } },
      { path: 'project/task/team/open', component: ProjectTaskComponent, canActivate: [AuthorizeGuard], data: { title: 'Team åbne opgaver', roles: [RoleTypeEnum.ProjectBasic], type: 'task', params: ['$filterMyTeam','$filterStatusOpen'] } },
      { path: 'project/task-type', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Opgavetyper', formAdapterType: ProjectTaskTypeFormAdapter, roles: [RoleTypeEnum.ProjectAdmin] } },
      { path: 'project/task-status', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Opgavestatus', formAdapterType: ProjectTaskStatusFormAdapter, roles: [RoleTypeEnum.ProjectAdmin] } },
      { path: 'project/level', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'Projektniveauer', formAdapterType: ProjectLevelFormAdapter, roles: [RoleTypeEnum.ProjectAdmin] } },
      { path: 'project/team', component: CerAppFormGridDetailComponent, canActivate: [AuthorizeGuard], data: { title: 'Teams', formAdapterType: TeamFormAdapter, roles: [RoleTypeEnum.ProjectAdmin] } },
      { path: 'masterdata/external/nemhandel', component: CerAppFormGridComponent, canActivate: [AuthorizeGuard], data: { title: 'NemHandel', formAdapterType: RefDataNemHandelsRegisterFormAdapter, roles: [RoleTypeEnum.BaseData] } },
      { path: 'status/jobs', component: CerAppJobSchedulerComponent, canActivate: [AuthorizeGuard], data: { title: 'Jobafvikling', roles: [RoleTypeEnum.GlobalAdmin] } },
      { path: 'setting/route', component: CerAppFormGridDetailComponent, canActivate: [AuthorizeGuard], data: { title: 'Menupunkt titler', formAdapterType: CerAppMetaRouteFormAdapter, roles: [RoleTypeEnum.GlobalAdmin] } },
      { path: 'setting/user/route', component: CerAppFormGridDetailComponent, canActivate: [AuthorizeGuard], data: { title: 'Brugeropsætning sider', formAdapterType: CerAppMetaUserRouteFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'setting/data/table', component: CerAppFormGridDetailComponent, canActivate: [AuthorizeGuard], data: { title: 'Tabeller', formAdapterType: AppDataTableFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'setting/data/sequence', component: CerAppFormGridDetailComponent, canActivate: [AuthorizeGuard], data: { title: 'Nummerserier', formAdapterType: AppNumberSequenceFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'setting/data/process', component: CerAppFormGridDetailComponent, canActivate: [AuthorizeGuard], data: { title: 'Dataprocesser', formAdapterType: AppDataProcessFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } },
      { path: 'setting/data/process-stage', component: CerAppFormGridDetailComponent, canActivate: [AuthorizeGuard], data: { title: 'Dataprocesser stadier', formAdapterType: AppDataProcessStageFormAdapter, roles: [RoleTypeEnum.TennantAdmin] } }
    ]
  }];

// Module
@NgModule({
  declarations: [
    BankAccountSettlementComponent,
    ContractComponent,
    ContractLegacyComponent,
    ForfatterComponent,
    ProductComponent,
    ProductLegacyComponent,
    //ProjectNodeComponent,
    ProjectTaskComponent,
    ProjectTaskDocComponent,
    RightsPeriodComponent,
    RightsApprovalComponent,
    RoyaltyApproveComponent,
    RoyaltyApproveDetailComponent,
    RoyaltyForlagComponent,
    SalesOrderComponent,
    VoucherCreateMobileComponent,
    VoucherDetailComponent,
    VoucherListComponent,
    VoucherLookupComponent,
    VoucherMessageListComponent
  ],
  imports: [
    AppCoreModule,
    //    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    RouterModule.forRoot(appRoutes),
    HotkeyModule.forRoot(hotkeyOptions)
  ],
  exports: [RouterModule],
  providers: [CerAmountPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

